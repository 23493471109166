var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "100 vW" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" Allmänna kategorier ")]),
                  _c("v-data-table", {
                    staticClass: "elevation-1 whitespace",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.topics,
                      "sort-by": "topic_name",
                      "singel-select": "",
                      "item-key": "topic_name",
                      search: _vm.search,
                      dense: "",
                      "items-per-page": -1,
                      loading: _vm.loading,
                      "loading-text": "Laddar...",
                      "footer-props": {
                        showFirstLastPage: true,
                        "items-per-page-text": "Rader per sida",
                        "items-per-page-all-text": "Alla"
                      }
                    },
                    on: { "click:row": _vm.handleRowClick },
                    scopedSlots: _vm._u([
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            !item.locked
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editItem(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-pencil ")]
                                )
                              : _vm._e(),
                            !item.locked
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteItem(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-delete ")]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-toolbar",
                              {
                                staticStyle: { display: "none" },
                                attrs: { flat: "" }
                              },
                              [
                                _c("v-toolbar-title"),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "600px" },
                                    model: {
                                      value: _vm.dialogDelete,
                                      callback: function($$v) {
                                        _vm.dialogDelete = $$v
                                      },
                                      expression: "dialogDelete"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5" },
                                          [
                                            _vm._v(
                                              "Vill du verkligen ta bort kategori: " +
                                                _vm._s(
                                                  _vm.editedItem.topic_name
                                                ) +
                                                "?"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "blue darken-1",
                                                  text: ""
                                                },
                                                on: { click: _vm.closeDelete }
                                              },
                                              [_vm._v("Avbryt")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "blue darken-1",
                                                  text: ""
                                                },
                                                on: {
                                                  click: _vm.deleteItemConfirm
                                                }
                                              },
                                              [_vm._v("OK")]
                                            ),
                                            _c("v-spacer")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "600px" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" Ny kategori ")]
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.dialogCreateNew,
                                      callback: function($$v) {
                                        _vm.dialogCreateNew = $$v
                                      },
                                      expression: "dialogCreateNew"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5" },
                                          [_vm._v("Ny kategori")]
                                        ),
                                        _c(
                                          "v-container",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: "Namn",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.topic_name,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "topic_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.topic_name"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", {
                                                  attrs: { cols: "12" }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "blue darken-1",
                                                  text: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.dialogCreateNew = false
                                                  }
                                                }
                                              },
                                              [_vm._v("Avbryt")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "blue darken-1",
                                                  text: ""
                                                },
                                                on: {
                                                  click: _vm.CreateNewConfirm
                                                }
                                              },
                                              [_vm._v("OK")]
                                            ),
                                            _c("v-spacer")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "footer.prepend",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2",
                                attrs: { color: "primary", dark: "" },
                                on: {
                                  click: function($event) {
                                    _vm.dialogCreateNew = true
                                  }
                                }
                              },
                              [_vm._v(" Ny allmän kategori ")]
                            ),
                            _c("v-spacer"),
                            _c("v-text-field", {
                              staticClass: "mb-5",
                              attrs: {
                                "append-icon": "mdi-magnify",
                                label: "Sök kategori",
                                "single-line": "",
                                "hide-details": ""
                              },
                              on: { keyup: _vm.clearOnEscape },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            }),
                            _c("v-spacer")
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(" Filtervariabler som används i filterdefinitioner ")
                  ]),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.haveReceived,
                        expression: "haveReceived"
                      }
                    ],
                    staticClass: "elevation-1",
                    attrs: {
                      items: _vm.filterAttributes,
                      headers: _vm.filterheaders,
                      "item-key": "id",
                      "items-per-page": 10,
                      height: 600,
                      "footer-props": {
                        "items-per-page-text": "Rader per sida",
                        "items-per-page-all-text": "Alla"
                      },
                      update: _vm.update
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            !item.locked
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editFilterItem(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-pencil ")]
                                )
                              : _vm._e(),
                            !item.locked
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFilterItem(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-delete ")]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "footer.prepend",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2",
                                attrs: { color: "primary", dark: "" },
                                on: { click: _vm.newFilterItem }
                              },
                              [_vm._v(" Ny filterkategori ")]
                            ),
                            _c("v-spacer")
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filterselected,
                      callback: function($$v) {
                        _vm.filterselected = $$v
                      },
                      expression: "filterselected"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }