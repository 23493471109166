<template>
  <v-app v-if="updateValueAtEvent != update">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          :color="color"
          dark
          permanent
          rail
          :width="$store.state.withOfDrawer"
          :height="regAreaHight"
          :redraw="update"
        >
          <v-list>
            <v-list-item
              v-for="(item, i) in regItems"
              :key="i"
              :to="item.route" 
              router
            >
              <v-list-item-action>
                <div
                  v-if="nrOfRegRequests > 0 && item.icon == 'mdi-account-check'"
                >
                  <v-badge color="red" :content="nrOfRegRequests">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  <v-icon>{{ item.icon }}</v-icon>
                </div>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
            <v-list-item>
         <v-icon  v-if="$store.state.withOfDrawer===null" withjustify-end @click="ToggleDrawerWith">keyboard_double_arrow_left</v-icon>
         <v-icon  v-else withjustify-end @click="ToggleDrawerWith">keyboard_double_arrow_right</v-icon>
         </v-list-item>
        </v-navigation-drawer>
        <v-container
          id="regArea"
          fluid
          class=" ma-0"
          style=" max-width: 100vw;"
        >
          <router-view />
        </v-container>
      </v-layout>
    </v-card>
  </v-app>
</template>

<script>
// import Vue from 'vue'
// const apiUrl = "https://api.homesvithome.se/v1.0/forms";
import hshApi from "../services/hshapi";
// import $_ from "lodash";
// import moment from "moment";

export default {
  name: "RegistrationArea",
  // props: [],
  async created() {
    this.fetchAccountApplication()
    setInterval(this.checkRequestsStatus, 60000);
    this.$root.$off("applicantslist-table-have-applicants")
    this.$root.$on("applicantslist-table-have-applicants",(nrOfApplicants) => {
      console.log("Got event applicantslist-table-have-applicants",nrOfApplicants)
      this.nrOfRegRequests = nrOfApplicants
    });
    // this.$store.getters.getMessages
    window.addEventListener("resize", () => {
      // Resize of screen
      // console.log("Window size change");
      this.update += 1;
    });
    if (this.update== 0) {  // Just install once
      this.update += 1
      this.$root.$on("new-tab-selected",() => {
        this.updateValueAtEvent=this.update // force rerender of navigation list
        this.$forceUpdate();
        this.$nextTick(() => {
          this.update += 1
        });
        console.log("Got event new-tab-selected",this.update)
      });
    }
  },
  data() {
    return {
      form_names: "Loading...",
      token_expiry: "Loading...",
      color: "blue darken-4",
      drawer: true,
      right: true,
      rightDrawer: false,
      update: 0,
      updateValueAtEvent:0,
      accountsApplication: [],
      haveReceived: false,
      nrOfRegRequests: 0,
      regItems: [
        {
          title: "Respondenter",
          icon: "mdi-account",
          route: "/responders",
        },
        {
          title: "Ansökningar",
          icon: "mdi-account-check",
          route: "/AccountApplication",
        },
        {
          title: "Ny kontoansökan",
          icon: "mdi-account-plus",
          route: "/AddApplicant",
        },
      ],
      actionMessages: ["wantContact"],
      nrOfMessages: 0,
    };
  },
  computed: {
    title() {
      return "Admin - " + this.$route.name ? this.$route.name : "";
    },
    navigationDrawerMiniVariantAlways() {
      return this.$store.state.navigationDrawerMiniVariantAlways;
    },
    regAreaHight() {
      this.update;
      let height;
      try {
        const e = document.getElementById("regArea").clientHeight;
        height = `${e}px`;
      } catch {
        height = "100vh";
      }
      // console.log("regAreaHight", height);
      return height;
    },
  },
  methods: {
    fetchAccountApplication() {
      hshApi
        .accountApplication()
        .then((res) => {
          if (res.status == 200) {
            this.accountsApplication = res.data;
            this.accountsApplication.forEach((u) => {
              u.ct_name = u.ct_first_name + " " + u.ct_last_name;
            });
            this.haveReceived = true;
            this.nrOfRegRequests = this.accountsApplication.length;
            this.$store.dispatch("setnrOfRegRequests",this.nrOfRegRequests);
            console.log("AccountApplications", this.accountsApplication);
          }
        })
        .catch((err) => {
          console.error("Failed to fetch accountApplications",err);
        });
    },
    // Calculate if there is any messages that require action
    async checkRequestsStatus() {
      this.fetchAccountApplication()
    },
    Logout() {
      this.$keycloak.logout({ redirectUri: window.location.toString() });
    },
    ToggleDrawerWith() {
      if (this.$store.state.withOfDrawer===null) {
        this.$store.state.withOfDrawer=55
      } else {
        this.$store.state.withOfDrawer=null
      }
    }
  },
  mounted() {
      this.update;
  },
};
</script>
