<template>
  <v-container>
    <v-row>
      <v-col v-if="ready">
        <FormsTable />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="ready">
        <FormsForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormsTable from "../components/FormsTable.vue";
import FormsForm from "../components/FormsForm.vue";
export default {
  components: {
    FormsTable,
    FormsForm,
  },
  data() {
    return {
      data: -1, // Not used
      ready: false,
    };
  },
  async beforeMount() {
    await this.$store.dispatch("fetchForms");
    this.ready = true;
    console.log("Forms:beforeCreated Have fetched forms");
  },
  beforeRouteLeave(to, from, next) {
     if (this.$store.state.applStore.hasUnsavedChanges) {
       const answer = window.confirm(
         "Du har osparade ändringar. Vill du verkligen byta sida?"
      );
      if (answer) {
        this.$store.state.applStore.hasUnsavedChanges = false
        next();
      } else {
        next(false);
      }
    } else {
      next()
    }
  },
};
</script>
