<template>
    <div>
        <TopicsList/>
    </div>
</template>

<script>
import TopicsList from '../components/TopicsList.vue'
    export default {
        components:{
            TopicsList
        },
        data() {
            return {
            }
        }
    }
</script>

<style  scoped>

</style>