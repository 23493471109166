var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingStatus == false,
            expression: "loadingStatus == false"
          }
        ]
      },
      [
        _c(
          "v-card",
          { attrs: { color: "primary", dark: "" } },
          [
            _c(
              "v-card-text",
              [
                _vm._v(" Var god dröj... "),
                _c("v-progress-linear", {
                  staticClass: "mb-0",
                  attrs: { indeterminate: "", color: "white" }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingStatus == true,
            expression: "loadingStatus == true"
          }
        ]
      },
      [
        [
          _c("v-data-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.haveReceived,
                expression: "haveReceived"
              }
            ],
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.accountsApplicationsTable,
              "single-select": _vm.singleSelect,
              "item-key": "id",
              search: _vm.search,
              "show-select": "",
              "footer-props": {
                "items-per-page-text": "Rader per sida",
                "items-per-page-all-text": "Alla"
              }
            },
            on: { input: _vm.TableSelectChange },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c("v-text-field", {
                      staticClass: "mb-5 mx-6",
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Sök",
                        "single-line": "",
                        "hide-details": ""
                      },
                      on: { keyup: _vm.clearOnEscape },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.terminal_id",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.haveTerminal(item)
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "pl-3",
                                                  attrs: {
                                                    color: "grey",
                                                    dark: ""
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-desktop-classic ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Har terminal hemma")])]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.nb",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  item.email_exists
                                    ? _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "blink",
                                              attrs: { small: "", color: "red" }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-exclamation-thick ")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-center d-flex align-center justify-space-around"
                          },
                          [_vm._v(" " + _vm._s(item.nb) + " ")]
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.nrOfProperties",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("popup-list", {
                      attrs: {
                        dataToShow: item.nrOfProperties,
                        title: "Data om personen",
                        headers: _vm.possibleHelp,
                        items: item.moment
                      }
                    })
                  ]
                }
              },
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mb-5",
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Sök",
                        "single-line": "",
                        "hide-details": ""
                      },
                      on: { keyup: _vm.clearOnEscape },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    }),
                    _c("v-spacer")
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }