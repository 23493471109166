<template>
  <div>
    <!-- <div v-for="quest in questions" :key="quest.id"> -->
      <div v-if="quest!=-1">
      <v-card elevation="2" min-height="100%">
        <v-row>
          <v-col cols="6">
            <table :class="typeSize(quest)">
              <tr>
                <td>Fråga:</td>
                <td>{{ quest.i18n.sv.text }}</td>
              </tr>
              <tr>
                <td>Id:</td>
                <td>{{ quest.id }}</td>
              </tr>
              <tr>
                <td>Skapare:</td>
                <td>{{ quest.author }}</td>
              </tr>
              <tr>
                <td>Kategori:</td>
                <td>{{ quest.topic_name }}</td>
              </tr>
              <tr>
                <td>Typ:</td>
                <td>{{ quest.format }}</td>
              </tr>
            </table>
          </v-col>
          <v-col>
            <table style="{align-center;text-align:left;}">
              <tr><th>Svarsalternativ</th></tr>
               <!-- <div v-for="answer in quest.answers" :key="answer.id">
                <tr>
                  <td>
                    <div
                      class="box"
                      :style="{
                        'background-color': answer.ui_hint.bgcolor,
                      }"
                    >
                      <span class="mx-24"></span>
                    </div>
                  </td>
                  <td class="boxtext">
                    {{ answer.i18n.sv.text }}
                  </td>
                </tr>
              </div> -->
             <div v-for="answer in answersToShow" :key="answer.id">
                    <table style="{align-center}">
                      <tr>
                        <td>
                          <!-- <img :src="urlimg[answer.i18n.sv.media.image.id]" class="imageUrl"> -->
                          <div v-if="useImage(answer)" > <img :src="imgSrc(answer)" class="imageUrl"> </div>
                          <div v-else
                            class="box"
                            :style="{
                              'background-color': answer.ui_hint.bgcolor,
                            }"
                          >
                            <span class="mx-24"></span>
                          </div>
                        </td>
                        <td>
                           {{answer.i18n.sv.text }}
                        </td>
                      </tr>
                    </table>
                  </div>
            
            
            </table>
          </v-col>
        </v-row>
      </v-card>
      <!-- </v-layout> -->
      <v-divider class="my-6"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [],
      quest:-1,
      urlimg:[],
      update:0,
    };
  },
  computed:{
    answersToShow () {
      this.update
      return this.quest.answers // Computed property make it update on change
    }  
  },
  async mounted() {
    this.questions = this.$store.getters.getQuestions;
    if (this.questions.length == 0) {
      await this.$store.dispatch('fetchQuestions')
      this.questions = this.$store.getters.getQuestions;
    }
    this.$root.$off("questiontable-question-clicked")
    this.$root.$on("questiontable-question-clicked", (item) => {
      console.log("QuestionForm2: Choosen quest received:" + item.id,item,this.questions);
      this.questions = this.$store.getters.getQuestions;
      this.quest=item
      this.update++
    });
  },
  methods: {
    typeSize(item) {
      if (item.id % 2 == 0) {
        return "pl-0 ml-0";
      }
      return "pl-0 ml-0";
    },
    async useImage(answer) {
      // this.formItem.questions.
        console.log("useImage got",answer)
      try {
        if (this.$_.get(answer,'i18n.sv.media.image.id',0) > 0) {
          this.urlimg[answer.i18n.sv.media.image.id] = this.$store.getters.getImageById(answer.i18n.sv.media.image.id)
          return true
        }
      } catch (error) {
        console.error("useImage",error)
      }
      console.log("useImage returns false")
      return false
    },
    imgSrc(answer) {
      if (this.$_.get(answer,'i18n.sv.media.image.id',0) > 0) {
        return this.urlimg[answer.i18n.sv.media.image.id]
      }
      return ""
    },
  },
};
</script>

<style scoped>
td {
  padding-left: 6px;
}
.boxtext {
  text-align: center ;
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
 .imageUrl {
         max-width:30px;
         max-height:100%;
         justify-content: left;
         align-content: flex-start;
    }
</style>
