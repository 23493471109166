var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("surveys-table", {
                    attrs: {
                      showNewButton: false,
                      emitMessage: _vm.emitMessage,
                      startSearch: _vm.startSearch
                    },
                    on: { selectSurvey: _vm.ServeySelected }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [_c("SurveyInstanceForm", { ref: "instansForm" })], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }