<template>
  <v-row justify="center" align="end">
    <v-dialog v-model="dialog" width="auto" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Välj frågor
        </v-btn>
      </template>
      <v-card>
        <div v-if="loadingStatus">
          <v-container fluid mx-5 pt-10 px-5 fill-height>
            <v-row>
              <v-col cols="5">
                <v-data-table
                  :headers="questionHeaders"
                  :items="questions"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  :search="search"
                  sort-by="topic_name"
                  item-key="question.id"
                  show-expand
                  disable="false"
                  class="elevation-3"
                  v-model="selected"
                  :footer-props="{
                    'items-per-page-text': 'Rader per sida',
                    'items-per-page-all-text': 'Alla',
                  }"
                  @click:row="handleRowClick"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Frågebank</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template #body="props">
                    <draggable
                      :list="props.items"
                      tag="tbody"
                      :options="availableItemOptions"
                      :clone="onCloneCallback"
                      @end="onDropCallback"
                    >
                      <tr v-for="(quest, index) in props.items" :key="index">
                        <td>
                          <v-icon small class="page__grab-icon">
                            mdi-arrow-all {{ what("props", props) }}
                          </v-icon>
                        </td>
                        <td>{{ quest.question.i18n.sv.text }}</td>
                        <td>{{ quest.question.id }}</td>
                        <td>{{ quest.question.author }}</td>
                        <td>{{ quest.question.topic_name }}</td>
                      </tr>
                      <!-- <data-table-row-handler
                   UW user.question.i18n.sv.text
                    v-for="(item, index) in props.items"
                    :key="index"
                    :item="item"      
                    :headers="questionHeaders"
                  > -->
                      <!-- <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Frågor</v-toolbar-title>
                    </v-toolbar>
                  </template> -->
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <h5 class="my-4">Svarsalternativ</h5>
                          <table>
                            <tr
                              v-for="(answer, index) in item.answers"
                              :key="index"
                            >
                              <div
                                class="box mr-2 mt-1"
                                :style="{
                                  'background-color': answer.ui_hint.bgcolor,
                                }"
                              ></div>
                              <span> - {{ answer.i18n.sv.text }}</span>
                            </tr>
                          </table>
                          <div class="my-4"></div>
                        </td>
                      </template>
                      <!-- </data-table-row-handler>  -->
                    </draggable>
                  </template>
                  <template v-slot:footer.prepend>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Sök fråga"
                      single-line
                      hide-details
                      class="mb-5"
                      v-on:keyup="clearOnEscape"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="2" max-height>
                <h4 align="center" class="pb-5">Instruktion</h4>
                <h5 align="left">Nya frågor:</h5>
                Drag önskad fråga från frågebanken till vänster över till
                formuläret [{{ clonedItems.name }}] till höger
                <h5 align="left" class="pt-10">Sortera frågor:</h5>
                Ändra ordningen på frågorna i tabellen för 'Valda frågor' genom
                att drar och släpper dem i den ordning du vill ha dem
              </v-col>
              <v-col cols="5" class="pr-12">
                <v-data-table
                  :headers="questionHeaders"
                  :items="currentQuestions"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  item-key="id"
                  show-expand
                  class="elevation-3"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title
                        >Valda frågor - {{ clonedItems.name }}</v-toolbar-title
                      >
                    </v-toolbar>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      More info about {{ item.name }}
                    </td>
                  </template>
                  <!-- v-model="clonedItems"  v-model="clonedItems.questions" -->
                  <!-- Of some reason does props need to be here for makeing drop function to work -->
                  <template #body="props">
                    <draggable
                      :list="clonedItems.questions"
                      tag="tbody"
                      :options="clonedItemOptions"
                      :move="onMoveCallback"
                      :clone="onCloneCallback"
                      @end="onDropCallback"
                      :ondrop="onDropCallback"
                    >
                      <tr
                        v-for="(quest, index) in clonedItems.questions"
                        :key="index"
                      >
                        <td>
                          <v-icon small class="page__grab-icon">
                            mdi-arrow-all {{ what("clonedItems", clonedItems) }}
                          </v-icon>
                        </td>

                        <td>{{ quest.question.i18n.sv.text }}</td>
                        <td>{{ quest.question.id }}</td>
                        <td>quest.author</td>
                        <td>{{ quest.question.topic_name }}</td>
                        <td>
                          <v-icon small @click="deleteQuest(quest, index)">
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                    {{ props.items.id }}
                  </template>
                  <template v-slot:footer disable-pagination> </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="my-8">
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Avbryt</v-btn
              >
              <v-btn color="blue darken-1" text @click="saveForm">Spara</v-btn>
            </v-row>
          </v-container>
        </div>
        <div v-else>
          <v-card color="primary" dark>
            <v-card-text>
              Var god dröj...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { mapGetters} from 'vuex';
// https://dev.to/andynoir/draggable-table-row-with-vuejs-vuetify-and-sortablejs-1o7l
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: ["form"],
  data() {
    return {
      expanded: [],
      search: "",
      singleExpand: true,
      questionHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Fråga", value: "question.i18n.sv.text" },
        { text: "Id", value: "question.id" },
        { text: "Skapare", value: "question.author" },
        { text: "Kategori", value: "question.topic_name" },
        { text: "", value: "data-table-expand" },
      ],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      selected: [],
      availableItemOptions: {
        group: {
          name: "items",
          pull: "clone",
          put: false,
        },
        sort: false,
      },
      clonedItems: [{}],
      clonedItemOptions: {
        group: "items",
      },
    };
  },
  computed: {
    loadingStatus() {
      console.log(
        `QuestionSelector: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
    questions: {
      get() {
        const origQuestions = this.$store.getters.getQuestions;
        let newQuestions = [];
        origQuestions.forEach((quest) => {
          newQuestions.push({
            enabled: true,
            enable_rule: "",
            question: quest,
          });
        });
        console.log("newQuestions", newQuestions);
        return newQuestions; // this.$store.getters.getQuestions;
      },
      set(newName) {
        return newName;
      },
    },
    currentQuestions() {
      return this.$store.getters.getCurrentForm.questions;
    },
    questionsOrig() {
      return this.$store.getters.getQuestions;
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.clonedItems = JSON.parse(JSON.stringify(this.form));
        console.log("QuestionSelecetor: Watch dialog", this.clonedItems);
      }
    },
  },
  created() {
    console.log("create QuestionSelector");
    // this.$store.dispatch("fetchQuestions");
    console.log(this.questions);
  },
  beforemount() {
    this.$store.dispatch("fetchQuestions");
  },
  mounted() {
    console.log("Mount QuestionSelector");
    this.$root.$off("question-selector-dialog-clicked");
    this.$root.$on("question-selector-dialog-clicked", (visible) => {
      if (visible) {
        console.log(`watch dialog ${visible}`, this.questions);
      }
    });
    // this.questions = this.$store.getters.getQuestions;
    console.log(this.questions);
  },
  methods: {
    what(what, info) {
      console.log(`What?(${what})`, info);
      return "what?";
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    focusQuestionForm(item) {
      this.selected = [];
      this.selected.push(item);
    },
    handleRowClick(item) {
      console.log("QuestionSelectorLeft: You clicked " + item.name);
      console.log(this.selected);
      this.focusQuestionForm(item);
    },
    onCloneCallback(item) {
      // Create a fresh copy of item
      console.log("onCloneCallback", item);
      const cloneMe = JSON.parse(JSON.stringify(item));

      return cloneMe;
    },
    onMoveCallback(evt, originalEvent) {
      const item = evt.draggedContext.element;
      const itemIdx = evt.draggedContext.futureIndex;

      console.log("onMoveCallback", originalEvent, itemIdx);

      if (item.locked) {
        return false;
      }

      return true;
    },
    onDropCallback(evt, originalEvent) {
      console.log("onDropCallback", originalEvent);
    },
    deleteQuest(quest, index) {
      console.log("delete", quest, index);
      this.clonedItems.questions.splice(index, 1);
    },
    closeDelete() {
      this.dialog = false;
    },
    async saveForm() {
      console.log(this.clonedItems);
      try {
        await this.$store.dispatch("saveForm", this.clonedItems);
      } catch (error) {
        console.log(error);
      }
      // this.$store.dispatch("setCurrentFormId", this.clonedItems.id);
      this.$root.$emit("formtable-form-clicked", this.clonedItems);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
td {
  padding-left: 6px;
}
.box {
  float: left;
  height: 10px;
  width: 10px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
</style>
