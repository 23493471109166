var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "DialogCard",
            { attrs: { title: "", actions: _vm.actions } },
            [
              _c(
                "v-card-title",
                { staticClass: "my-1 py-1 black white--text" },
                [
                  _vm._v("Lägg till nya respondenter... "),
                  _c("v-spacer"),
                  _c("v-checkbox", {
                    staticClass: "align-center justify-center",
                    attrs: { color: "gray", value: "true", dark: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "align-center justify-center pt-3"
                                },
                                [_vm._v("Lägg till som passivt formulär")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2135645144
                    ),
                    model: {
                      value: _vm.markRespondentDone,
                      callback: function($$v) {
                        _vm.markRespondentDone = $$v
                      },
                      expression: "markRespondentDone"
                    }
                  })
                ],
                1
              ),
              _c("RespondersList", {
                key: _vm.key,
                ref: "respondersList",
                attrs: { responders: _vm.items, survey: _vm.survey }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }