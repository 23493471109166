var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { staticClass: "mx-24" }),
    _c("div", {
      staticClass: "box",
      style: {
        "background-color": _vm.color
      },
      on: { click: _vm.HshColorPicker }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }