<template>
  <DialogCard :actions="actions" class="dialog-card">
    <v-row fluid class="dialog-card">
      <v-col cols="12">
        <v-card-title class="mb-4 pb-4 blue darken-4 white--text">
          Ändra/skapa Filtervariabel/respondent data {{ item.name }}
        </v-card-title>
        <v-col cols="6">
          <v-form v-model="valid" ref="form" class="py-6">
            <v-text-field
              v-model="item.id"
              disabled
              label="Id"
              class="px-2"
            ></v-text-field>
            <v-text-field
              v-model="item.name"
              :disabled="type == 'edit'"
              label="Filtervariabel/respondent data"
              class="px-2"
              :rules="nameRules"
              required
            ></v-text-field>
            <v-text-field
              v-model="item.description"
              label="Beskrivning"
              class="px-2"
              :rules="nameRules"
              
            ></v-text-field>
            <v-combobox
              v-model="item.groupname"
              :items="availableGroups"
              label="Filterkategori"
            />
            <v-select
              v-model="item.type"
              :items="typeitems"
              label="Typ av variabel"
              required
            ></v-select>
            <v-text-field
              v-model="item.valid_values"
              label="Möjliga värden"
              class="px-2"
            ></v-text-field>
            <v-text-field
              v-model="item.default_value"
              label="default värden"
              class="px-2"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-col>
    </v-row>
  </DialogCard>
</template>

<script>
import hshApi from "../services/hshapi";

export default {
  name: "EditFilterAttributes",
  props: ["title", "$store", "type", "item", "groupsArray"],
  data() {
    return {
      topic_name: "",
      valid: true,
      nameRules: [(v) => !!v || "Name is required"],
      filterAttributes: [],
      nrOfHits: -1,
      haveReceived: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Filtervariabel/respondent\xa0data", value: "name" },
        { text: "Beskrivning", value: "description" },
        { text: "Filterkategori", value: "groupname" },
        { text: "Typ", value: "type" },
        { text: "Möjliga värden", value: "valid_values" },
      ],
      typeitems: [
        "string",
        "integer",
        "boolean",
        "decimal",
        "datetime",
        "date",
        "time",
      ],
      //   queryTopics:['adam','bertil']
      //   ChatGPT
    };
  },
  created() {
    console.log("EditFilter created", this.item);
  },
  computed: {
    availableGroups() {
      return this.groupsArray;
    },
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: this.type == "edit" ? "Uppdatera" : "Skapa",
          color: "blue darken-1",
          handle: () => {
            if (this.type == "edit") {
              this.updateFilter();
            } else {
              this.createFilterAttribute();
            }
            return this.item;
          },
        },
      };
    },
  },
  methods: {
    async updateFilter() {
      try {
        const res = await hshApi.updateFilterAttribute(
          this.$keycloak.tokenParsed.organisation,
          this.item
        );
        this.$store.state.applStore.hasUnsavedChanges = false;
        console.log("updateFilter", res);
      } catch (error) {
        await this.$dialog.error({
          text: `Filtervärden gick inte att uppdatera (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
    },
    async createFilterAttribute() {
      try {
        this.item.owner = this.$keycloak.tokenParsed.organisation;
        const res = await hshApi.createFilterAttribute(this.item);
        console.log("createFilterAttribute", res, this.item);
        this.$store.state.applStore.hasUnsavedChanges = false;
      } catch (error) {
        await this.$dialog.error({
          text: `FilterAttribut gick inte att skapa (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
    },
    async fetchAttributes() {
      try {
        const res = await hshApi.getFilterAttributes(
          this.$keycloak.tokenParsed.organisation
        );
        console.log("EditFilter: fetchAttributes", res);
        this.filterAttributes = res;
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch getFilterAttributes",
          err
        );
      }
    },
    async testFilter() {
      try {
        this.nrOfHits = -1;
        this.responders = [];
        const res = await hshApi.runFilter(this.item.name);
        console.log("testFilter: fetchAttributes", res);
        this.nrOfHits = res.length;
        const allrespondents = this.$store.getters.getReponders;
        const temp = allrespondents.filter((r) => res.includes(r.id));
        this.responders = temp.slice(0, 10);

        console.log(
          "testFilter: fetchAttributes responders",
          this.responders,
          allrespondents,
          res
        );
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch result from runFilter",
          err
        );
      }
    },
    clearFilter() {
      this.nrOfHits = -1;
      this.responders = [];
    },
  },
  async mounted() {
    console.log("EditFilter: mounted");
    await this.fetchAttributes();
    this.haveReceived = true;
  },
};
</script>
<style scoped>
.dialog-card {
  width: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  margin: 0px;
}
p {
  text-align: left;
  color: darkblue;
  padding-left: 12px;
  margin: 0 !important;
}
.hsh {
  padding: 4px;
  padding-left: 12px;
}
</style>
