var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    { attrs: { title: "Kategori", actions: _vm.actions } },
    [
      _c("v-text-field", {
        attrs: { label: "Kategori", icon: "mdi-shape" },
        model: {
          value: _vm.category,
          callback: function($$v) {
            _vm.category = $$v
          },
          expression: "category"
        }
      }),
      _c("v-text-field", {
        attrs: { label: "värde/värden", icon: "lock" },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }