var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DialogCard", { attrs: { title: "", actions: _vm.actions } }, [
        _vm.ready
          ? _c(
              "div",
              [
                _c(
                  "v-card",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "d-flex", attrs: { cols: "2" } },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-card-title",
                                  { staticClass: "justify-right" },
                                  [_vm._v(" Starttid: ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-datetime-picker", {
                                  attrs: {
                                    label:
                                      "Ändra starttid från " + _vm.starts_at,
                                    timeFormat: "HH:mm",
                                    "time-picker-props": _vm.timeProps,
                                    clearText: "Avbryt"
                                  },
                                  model: {
                                    value: _vm.startDate,
                                    callback: function($$v) {
                                      _vm.startDate = $$v
                                    },
                                    expression: "startDate"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "1" } }),
                            _c(
                              "v-col",
                              { staticClass: "d-flex", attrs: { cols: "2" } },
                              [
                                _c("v-spacer"),
                                _c("v-card-title", [_vm._v(" Sluttid: ")])
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-datetime-picker", {
                                  attrs: {
                                    label: "Ändra sluttid från " + _vm.ends_at,
                                    timeFormat: "HH:mm",
                                    "time-picker-props": _vm.timeProps,
                                    clearText: "Avbryt"
                                  },
                                  model: {
                                    value: _vm.endDate,
                                    callback: function($$v) {
                                      _vm.endDate = $$v
                                    },
                                    expression: "endDate"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "1" } }),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.startDate == _vm.starts_at &&
                                        _vm.endDate == _vm.ends_at,
                                      color: "success"
                                    },
                                    on: { click: _vm.UpdateTimeSettings }
                                  },
                                  [_vm._v(" Uppdatera tiderna ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-title",
                      { staticClass: "my-1 py-1 black white--text" },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c("v-data-table", {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.items,
                            rerender: _vm.rerender,
                            "hide-default-header": false,
                            "hide-default-footer": true,
                            "disable-pagination": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.actions",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    !item.locked
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteItem(item)
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-delete ")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3666967361
                          ),
                          model: {
                            value: _vm.selected,
                            callback: function($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }