<template>
  <div>
    <v-container>
      <v-row>
        <v-card
          
          outlined
          min-width="80%"
          :style="vCardStyle(answer)"
        >
          <v-list-item two-line class="cardstyle" style="width: 100%;">
        <v-container >
          <v-row class="pt-10 pb-5" >
            <v-col cols="12" md="2">
            <!-- <img v-if="useImage" :src="imgUrl" :class="imgURLCl" /> -->
            <HshImage
              v-model="answerIcon"
              label="Svarsikon"
            />
            </v-col>
            <v-col cols="12" md="10" >

            <div ref="divbox" :class="getTextSpec(answer)">
              <v-text-field
                label="Svaret:"
                v-model="answer.i18n.sv.text"
                class="shrink ml-4"
                style="width: 710px"
              />
            </div>
            </v-col>
          </v-row>
        </v-container>
          </v-list-item>
        </v-card>
      </v-row>

      <v-row class="pt-4">
        <v-col cols="12" md="1">
          <hsh-color
            :color="answer.ui_hint.bgcolor"
            title="Svarsfärg"
            @newColor="AnswerColorChange"
            style="margin-left:10px;"
          >
          </hsh-color>
        </v-col>
        <!-- <v-col cols="12" md="1"> -->
          <v-text-field
            label="Svarets värde:"
            v-model="answer.value"
            class="px-0 !important; mx-0 !important shrink; my-2"
            style="width: 80px"
          />
        <!-- </v-col> -->

        <v-col cols="12" md="1">
          <v-icon
            v-if="isAudioPresent"
            x-large
            @click="playSound(answer.i18n.sv.media.audio.id)"
          >
            mdi-play-box-outline
          </v-icon>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            label="Nuvarande fras text:"
            v-model="description"
            class="shrink my-2 py-0"
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Nuvarande fras namn:"
            v-model="name"
            class="shrink; my-2 py-0"
            readonly
          />
        </v-col>

        <v-col cols="12" md="1">
          <v-btn color="primary" class="mt-2" @click="HshPhrasePickerAnswer"
            >Välj ny fras...</v-btn
          >
        </v-col>
      </v-row>
      <!-- <v-select v-model="selectedPhrase" :rerender="rerender" :items="getMediaFiles" label="Ändra fras till" @change="changePhrase()" item-value="id"  item-text="text">
        <template v-slot:selection="{ item }">
          {{ item.text }}
        </template>
      </v-select> -->
    </v-container>
  </div>
</template>

<script>
import $_ from "lodash";
import HshImage from "./HshImage.vue";
import HshColor from "./HshColor.vue";
import PhrasePicker from "./HshPhrasePicker.vue";

export default {
  components: {
    HshImage,
    HshColor,
  },
  name: "EditAnswer",
  props: ["answer", "index", "isCurrentAnswer", "hightLightAnsw"],
  data() {
    return {
      imgUrl: "",
      newColor: "",
      answerIcon: {},
      selectedPhrase: {},
      rerender: 1,
      allMediaFiles: [],
      description: "",
      name: ""
    };
  },
  async created() {
    if (this.$_.get(this.answer, "i18n.sv.media.image.id", false)) {
      this.imgUrl = await this.$store.getters.getImageById(
        this.answer.i18n.sv.media.image.id
      );
      console.log(
        "EditAnswer imageToUse ",
        this.answer.i18n.sv.media.image.id,
        this.imgUrl
      );
      this.answerIcon = {
        id: this.answer.i18n.sv.media.image.id,
        url: this.imgUrl,
      };
      this.allMediaFiles = this.$store.state.medialist.filter(
        (m) => m.type == "audio"
      );
      try {
        this.description = this.answer.i18n.sv.media.audio.description
        this.name = this.answer.i18n.sv.media.audio.name
      } catch {
        console.log("EditAnswer:created - No description or name")
      }
    }
  },
  watch: {
    name: function () {
      if ($_.get(this.quest,"i18n.sv.media.audio.name",null) != null) {
        this.quest.i18n.sv.media.audio.name = this.name
      }
    },
     description: function() {
      if ($_.get(this.answer,"i18n.sv.media.audio.description",null) != null) {
        this.answer.i18n.sv.media.audio.description = this.description
      }
    },
    answerIcon() {
      console.log("AnswerIcon", this.answerIcon);
      $_.set(this.answer, 'i18n.sv.media.image.id', this.answerIcon.id);
      // this.answer.i18n.sv.media.image.id = this.answerIcon.id;
      this.imgUrl = this.answerIcon.url;
      if (this.answer.i18n.sv.media.image.id == -1){
        this.answer.i18n.sv.media.image={}
      }
    },
  },
  computed: {
    getMediaFiles() {
      const arr = this.allMediaFiles;
      const arr2 = arr.map((e) => ({
        text: e.description + " (" + e.name + ")",
        id: e.id,
      }));
      arr2.sort();
      console.log("getMediaFiles", arr2);
      this.rerender;
      return arr2;
    },
    useImage() {
      if (this.$_.get(this.answer, "i18n.sv.media.image.id", 0) > 0) {
        return true;
      }
      return false;
    },
    imageToUse() {
      return this.imgUrl;
    },
    isAudioPresent() {
      try {
        if (this.answer.i18n.sv.media.audio.id > 0) {
          return true;
        }
      } catch {
        return false;
      }
      return false;
    },
  },
  methods: {
    async HshPhrasePickerAnswer() {
      const oldPhrase = this.answer.i18n.sv.media.audio;
      const qCopy = JSON.parse(JSON.stringify(this.answer));
      const changePhrase = await this.$dialog.show(PhrasePicker, {
        waitForResult: true,
        title: this.title,
        i18nBase: qCopy,
        $store: this.$store,
        env: this,
        width:900
      }); // Show dialog
      if (changePhrase !== "cancel" && changePhrase !== undefined) {
        this.$emit("newPhrase", changePhrase);
        // this.$store.state.applStore.hasUnsavedChanges=true
        console.log("HshPhrasePickerAnswer emit dialog res", changePhrase);
        this.answer.i18n.sv.media.audio = changePhrase;
        this.description = changePhrase.description
        this.name = changePhrase.name
        console.log("HshPhrasePickerAnswer",this.description,this.name)

      } else {
        this.$emit("newPhrase", oldPhrase);
        console.log("HshPhrasePickerAnswer emit old res", oldPhrase);
      }
    },
    changePhrase() {
      const phrase = this.allMediaFiles.find(
        (m) => m.id == this.selectedPhrase
      );
      console.log("ChangePhrase", this.selectedPhrase, phrase);
      if (phrase != undefined) {
        this.answer.i18n.sv.media.audio = phrase;
      }
      this.rerender++;
    },
    playSound(id) {
      console.log(`EditQuestion:playSound Will play id ${id}`);
      this.playPhrase(id);
    },
    AnswerColorChange(color) {
      if (typeof color === "string") {
        this.answer.ui_hint.bgcolor = color;
      } else {
        this.answer.ui_hint.bgcolor = color.hex;
      }
      console.log(
        "AnswerColorChange",
        this.answer.ui_hint.bgcolor,
        typeof color
      );
    },
    getTextSpec(answer) {
      // console.log("Answers: getTextSpec")
      let classes = "mb-1 wrap-text";
      try {
        // console.log("getTextSpec ",answer.i18n.sv.text.length)
        if (answer.i18n.sv.text.length > 54) {
          if (answer.i18n.sv.text.length > 88) {
            classes += " text-h6 smallest";
          } else {
            classes += " text-h6 smaller";
          }
        } else {
          classes += " normal";
        }
      } catch {
        classes += " text-h5";
      }
      try {
        if (this.hightLightAnsw == answer.i18n.sv.media.audio.id) {
          // classes += " blink";
        }
      } catch {
        Function.prototype();
      }

      return classes;
    },
    questColor(answer) {
      try {
        return answer.ui_hint.icon.color;
      } catch {
        return "green";
      }
    },
    hexToRgbA(hex, opacity) {
      var c;
      try {
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            "," +
            opacity +
            ")"
          );
        }
      } catch {
        return hex;
      }
      return hex;
    },
    vCardStyle(answer) {
      console.log(
        "vCardStyle:",
        this.isCurrentAnswer,
        answer,
        this.currentAnswer
      );
      let mystyle = `border: 2px; padding:4px; margin-left:20px; solid #000000; background:${this.hexToRgbA(
        "#000",
        0.05
      )};`;
      if (answer == "" || answer == null) {
        return mystyle;
      }
      let thickness = 2;
      if (this.isCurrentAnswer) {
        // thickness = 6;
      }
      try {
        if (this.hightLightAnsw == answer.i18n.sv.media.audio.id) {
          // thickness = 8;
        }
      } catch {
        Function.prototype(); // nop
      }
      try {
        mystyle = `border: ${thickness}px solid ${
          answer.ui_hint.bgcolor
        }; padding:4px; margin-left:20px; background:${this.hexToRgbA(
          answer.ui_hint.bgcolor,
          0.05
        )};`;
      } catch {
        console.log(mystyle);
      }
      let tmp = this.$refs;
      console.log("divbox height", tmp, this.$refs.divbox);
      return mystyle;
    },
  },
};
</script>
<style scoped>
.vcard {
  margin: 6px;
  }
.cardstyle {
  font-size: 25px;
  max-height: 80px;
  margin-left: 8px;
}
.smallest {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.0225em !important;
  font-family: "Roboto", sans-serif !important;
}

.smaller {
  /* Jag kan inte bestämma det just nu för jag vet inte bestämma det just nu för jag vet inte */
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.1rem;
  letter-spacing: 0.0425em !important;
  font-family: "Roboto", sans-serif !important;
}

.normal {
  font-size: 1.5rem !important;
  font-weight: 500;
  line-height: 1.3rem;
  letter-spacing: 0.0425em !important;
  font-family: "Roboto", sans-serif !important;
}
.imgUrl {
  max-width: 60px;
  max-height: 100%;
  justify-content: left;
  align-content: flex-start;
}

/*  Blink classes */

.blink {
  animation: blinker 0.6s linear infinite;
  /* color: #1c87c9;
        font-size: 30px;
        font-weight: bold;
        font-family: sans-serif; */
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.blink-one {
  animation: blinker-one 1s linear infinite;
}
@keyframes blinker-one {
  0% {
    opacity: 0;
  }
}
.blink-two {
  animation: blinker-two 1.4s linear infinite;
}
@keyframes blinker-two {
  100% {
    opacity: 0;
  }
}
</style>
