<template>
  <div>
    <div v-if="!hide">
      <v-icon @click="toggleDataTable">mdi-arrow-down-drop-circle-outline</v-icon>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="questions"
        :key="rerender"
        sort-by="name"
        class="elevation-1 whitespace"
        :search="search"
        dense
        :single-select="true"
        :loading="!loadingStatus"
        loading-text="Laddar..."
        :items-per-page="itemsPerPage"
        :page="page"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Rader per sida',
          'items-per-page-options': [5, 10, 15, -1],
          'items-per-page-all-text': 'Alla',
        }"
        @click:row="handleRowClick"
        @update:options="handlerItemsPerPage($event)"
      >
        <!-- @click:row="handleRowClick" -->
        <!-- v-if="loadingStatus" -->
        <!-- items-per-page=5 -->

        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.locked || !isOwner(item)" small
            >mdi-pencil-lock-outline</v-icon
          >
          <v-tooltip v-if="!item.locked" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                v-if="isOwner(item)"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span class="text-center d-flex align-center justify-space-around"
              >ändra frågan</span
            >
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="!item.locked && isOwner(item)"
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span class="text-center d-flex align-center justify-space-around"
              >ta bort frågan</span
            >
          </v-tooltip>
          <!-- <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small @click="copyItem(item)">
                mdi-content-copy
              </v-icon>
            </template>
            <span class="text-center d-flex align-center justify-space-around"
              >Kopiera frågan till ny fråga</span
            >
          </v-tooltip> -->
        </template>
        <template v-slot:item.answers="{ item }">
          {{ nrOfAnswers(item) }}
        </template>
        <template v-slot:top>
          <v-toolbar flat style="display: none">
            <v-toolbar-title></v-toolbar-title>
            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="text-h5"
                  >Vill du verkligen ta bort denna fråga?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Avbryt</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.i18n.sv.media.image="{ item }">
          <v-hover>
            <v-img
              slot-scope="{ hover }"
              v-if="hover"
              :src="item.i18n.sv.media.image"
              width="300px"
              height="auto"
            >
            </v-img>
            <v-img v-else :src="item.i18n.sv.media.image" width="20px" height="auto">
            </v-img>
          </v-hover>
        </template>

        <template v-slot:item.i18n.sv.media.audio="{ item }">
          <v-icon
            v-if="isAudioPresent(item)"
            small
            @click="playSound(item.i18n.sv.media.audio.id)"
          >
            mdi-play-box-outline
          </v-icon>
        </template>

        <template v-slot:footer.prepend>
          <!-- <v-btn color="primary" dark class="mb-2"  v-on:click="editItem(null)"> -->
          <v-btn class="mb-2" dark color="primary" @click="createNewQuestion">
            <v-icon left dark> mdi-plus-thick </v-icon>
            Ny fråga...
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök fråga"
            single-line
            hide-details
            class="mb-5"
            v-on:keyup="clearOnEscape"
          ></v-text-field>
          <v-spacer></v-spacer>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-icon @click="toggleDataTable">mdi-arrow-right-drop-circle-outline</v-icon>
      expandera listan med frågor...
    </div>
  </div>
</template>

<script>
import editQuestion from "./EditQuestion.vue";
export default {
  name: "Questions",
  // props: ["questionId"],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      hide: false,
      language: "sv",
      // bus: new Vue(),
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Fråga", value: "i18n.sv.text" },
        { text: "typ", value: "topic_name" },
        { text: "bildlänk fråga", value: "i18n.sv.media.image" },
        { text: "ljudlänk fråga", value: "i18n.sv.media.audio" },
        { text: "Antal svarsalternativ", value: "answers" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      // question: [],
      editedIndex: -1,
      editedItem: {},
      itemIdToDelete: undefined,
      defaultItem: {},
      selected: [],
      rerender: 0,
      itemsPerPage: 10,
      page: 1,
    };
  },
  watch: {},
  computed: {
    questionTitle() {
      return this.editedIndex === -1
        ? "Ny fråga..."
        : this.editedItem[this.editedIndex].name;
    },
    questions() {
      console.log(
        "QuestionTable: Hämtar alla questions ",
        this.$store.getters.getQuestions
      );
      this.rerender;
      // return this.$store.state.questions
      return this.$store.getters.getQuestions;
    },
    loadingStatus() {
      console.log(
        `QuestionsTable: loadingStatus(${this.$store.getters.getLoadingStatus})`,
        this.$store.state.questions
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  beforeMount() {
    this.editedItem = {
      ...this.$store.state.questionItemTemplate,
      nrOfquestions: 0,
    };
    this.defaultItem = this.editedItem;
    this.$store.dispatch("fetchQuestions", this.editedItem.id);
    this.$root.$off("questiontable-question-toggle-hide");
    this.$root.$on("questiontable-question-toggle-hide", () => {
      console.log("Got hide toggle event");
      this.hide = !this.hide;
    });
    console.log("questionTable beforeMount");
  },
  mounted() {
    this.focusCurrentquestionId();
  },
  methods: {
    handlerItemsPerPage(event) {
      console.log("handlerItemsPerPage ", event);
      // Store even on rerendering of table
      this.itemsPerPage = event.itemsPerPage;
      this.page = event.page;
    },
    nrOfAnswers(item) {
      try {
        // console.log(item,this.language)
        return item.answers.length;
      } catch {
        return 0;
      }
    },
    isAudioPresent(item) {
      try {
        if (item.i18n.sv.media.audio.id > 0) {
          return true;
        }
      } catch {
        return false;
      }
      return false;
    },
    isImagePresent(item) {
      try {
        if (item.i18n.sv.media.image.id > 0) {
          return true;
        }
      } catch {
        return false;
      }
      return false;
    },
    isOwner(item) {
      try {
        console.log(
          "Ownership:",
          item.owner_org_id,
          this.$keycloak.tokenParsed.organisation
        );
        return item.owner_org_id == this.$keycloak.tokenParsed.organisation;
      } catch {
        return false;
      }
    },
    playSound(id) {
      console.log(`QuestionForm:playSound Will play id ${id}`);
      this.playPhrase(id);
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    focusCurrentquestionId() {
      const item = this.$store.getters.getCurrentQuestion;
      console.log("focusCurrentquestionId", item);
      if (item != null) {
        this.focusQuestionquestion(item); //uw
      }
    },
    focusQuestionquestion(item) {
      console.log("emit questiontable-question-clicked");
      this.$root.$emit("questiontable-question-clicked", item);
      this.selected = [];
      this.selected.push(item);
    },
    handleRowClick(item) {
      console.log("questionTable: You clicked ", item);
      // this.$store.dispatch("setCurrentQuestionId", item.id);
      this.focusQuestionquestion(item);
      console.log("Clicked row", item);
      // this.$root.$emit("questiontable-question-clicked", item);
      // this.selected = []
      // this.selected.push(item)
    },
    async editItem(item) {
      // alert("editItem You clicked pen " , item);
      // this.editedIndex = this.editedItem.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem = JSON.parse(JSON.stringify(item));

      console.log("QuestionsTable editItem input", this.editedItem);
      const res = await this.$dialog.show(editQuestion, {
        waitForResult: true,
        width: "90%",
        question: this.editedItem,
        $store: this.$store,
      }); // Show dialog
      //this.dialog = true;
      // this.bus.$emit('show')
      if (res != undefined && res != "cancel") {
        console.log("QuestionsTable editItem", res);
        await this.$store.dispatch("saveQuestion", res);
        this.editedItem = res;
        const index = this.$store.state.questions.findIndex((q) => q.id == res.id);
        console.log(
          "editItem ",
          index,
          this.$store.state.questions[index],
          JSON.stringify(res)
        );
        this.rerender++;

        this.focusQuestionquestion(res);
      } else {
        console.log("QuestionsTable editItem - Canceled", res);
        this.focusCurrentquestionId();
      }
    },
    createNewQuestion() {
      const item = JSON.parse(
        '{"id":-1,"answers":[{"i18n":{"sv":{"media":{"audio":{},"image":{"description":"Ledsen gubbe","hash":{"md5":"6393865f9a041d2448f2e7c84d5b880e"},"id":676,"lang":"sv","name":"sad.png","topic_id":163,"topic_name":"Allmänt","type":"image"}},"text":"Här är ditt svar"}},"ui_hint":{"bgcolor":"#2645E7"},"value":"0"}],"format":"SingleNumeric","i18n":{"sv":{"media":{"audio":{},"image":{"description":"Detta är en test","hash":{"md5":"decfc054ab0b9c90a94df1169a3e1210"},"id":677,"lang":"sv","name":"Test_Name_utan_space","topic_id":163,"topic_name":"Allmänt","type":"image"}},"text":"Här är din nya fråga"}},"topic_id":161,"topic_name":"Måltider","ui_hint":{"bgcolor":"#C3C3C3"}}'
      );
      this.editItem(item);
    },

    deleteItem(item) {
      console.log("deleteItem", item);
      // this.editedIndex = this.editedItem.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.itemIdToDelete = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.itemIdToDelete !== undefined) {
        this.$store.dispatch("deleteQuestion", this.itemIdToDelete);
      }
      // this.editedItem.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.editedItem[this.editedIndex], this.editedItem);
      } else {
        this.editedItem.push(this.editedItem);
      }
      this.close();
    },
    itemRowBackground(item) {
      if (this.selected.length <= 0) {
        return "style-2";
      }
      console.log(
        "itemRowBackground QuestionTable called " + item.id + " " + this.selected[0].id
      );
      return item.id == this.selected[0].id ? "style-1" : "style-2";
    },
    toggleDataTable() {
      this.$root.$emit("questiontable-question-toggle-hide");
    },
  },
};
</script>
