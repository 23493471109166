<template>
  <div>
    <span class="mx-24"></span>
    <div
      class="box"
      :style="{
        'background-color': color,
      }"
      @click="HshColorPicker"
    ></div>
  </div>
</template>

<script>
import HshColorPicker from './HshColorPicker.vue'

export default {
 name: "HshColor",
  props: ["color", "title"],
  data() {
    return {
    };
  },
  methods: {
    async HshColorPicker() {
        const oldColor= this.color
      const changeColor = await this.$dialog.show(HshColorPicker, { waitForResult: true, title:this.title,color:this.color} ); // Show dialog
      if (changeColor !== 'cancel') {
        this.$emit("newColor", changeColor);
        // this.$store.state.applStore.hasUnsavedChanges=true
        console.log('HshColorPicker emit dialog res',changeColor)
      } else {
        this.$emit("newColor", oldColor);
        console.log('HshColorPicker emit old res',oldColor)
      }
    }
  }   
}
</script>
<style scoped>
.boxtext {
  text-align: center;
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
</style>