var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    { attrs: { title: _vm.startCategory, actions: _vm.actions } },
    [
      _c("v-text-field", {
        attrs: { label: "Kategori" },
        model: {
          value: _vm.topic_name,
          callback: function($$v) {
            _vm.topic_name = $$v
          },
          expression: "topic_name"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }