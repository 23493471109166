<template>
  <div style="max-width: 100 vW">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title> Allmänna kategorier </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="topics"
            sort-by="topic_name"
            singel-select
            item-key="topic_name"
            class="elevation-1 whitespace"
            :search="search"
            dense
            :items-per-page="-1"
            :loading="loading"
            loading-text="Laddar..."
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-text': 'Rader per sida',
              'items-per-page-all-text': 'Alla',
            }"
            @click:row="handleRowClick"
          >
            <!-- @click:row="handleRowClick" -->
            <!-- items-per-page=5 -->

            <template v-slot:item.actions="{ item }">
              <v-icon v-if="!item.locked" small @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon v-if="!item.locked" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat style="display: none">
                <v-toolbar-title></v-toolbar-title>
                <v-dialog v-model="dialogDelete" max-width="600px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Vill du verkligen ta bort kategori:
                      {{ editedItem.topic_name }}?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Avbryt</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Create new Survey -->
                <v-dialog v-model="dialogCreateNew" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Ny kategori
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">Ny kategori</v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="form.topic_name"
                            label="Namn"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"> </v-col>
                      </v-row>
                    </v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogCreateNew = false"
                        >Avbryt</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="CreateNewConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:footer.prepend>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on:click="dialogCreateNew = true"
              >
                Ny allmän kategori
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Sök kategori"
                single-line
                hide-details
                class="mb-5"
                v-on:keyup="clearOnEscape"
              ></v-text-field>
              <v-spacer></v-spacer>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card>
          <v-card-title>
            Filtervariabler som används i filterdefinitioner
          </v-card-title>
          <v-data-table
            v-show="haveReceived"
            v-model="filterselected"
            :items="filterAttributes"
            :headers="filterheaders"
            item-key="id"
            class="elevation-1"
            :items-per-page="10"
            :height="600"
            :footer-props="{
              'items-per-page-text': 'Rader per sida',
              'items-per-page-all-text': 'Alla',
            }"
            :update="update"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="!item.locked" small @click="editFilterItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon v-if="!item.locked" small @click="deleteFilterItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:footer.prepend>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on:click="newFilterItem"
              >
                Ny filterkategori
              </v-btn>
              <v-spacer></v-spacer>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Vue from "vue"
// import SurveyForm from "./SurveyForm";
import EditAttributeFilter from "./EditFilterAttributes.vue";
import hshApi from "../services/hshapi";
import editTopic from "./EditTopic.vue";
export default {
  components: {
    // SurveyForm
  },
  props: {
    someprop: {
      type: String,
      default: "someprop",
    },
  },
  name: "topicstable",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      dialogCreateNew: false,
      selected: [],
      form: {
        topic_name: "",
      },
      // bus: new Vue(),

      headers: [
        {
          text: "Kategori",
          align: "start",
          sortable: true,
          value: "topic_name",
        },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      // topics: [],
      editedIndex: -1,
      editedItem: { topic_name: "" },

      // data for filters
      haveReceived: true,
      filterheaders: [
        { text: "Id", value: "id" },
        { text: "Filtervariabel / respondent\xa0data", value: "name" },
        { text: "Beskrivning", value: "description" },
        { text: "Filterkategori", value: "groupname" },
        { text: "Typ", value: "type" },
        { text: "Möjliga värden", value: "valid_values" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      filterselected: [],
      filterAttributes: [],
      update: 0,
    };
  },
  beforeMount() {
    this.fetchAttributes();
    // this.$store.dispatch("fetchtopics");
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Ny undersökning"
        : this.topics[this.editedIndex].name;
    },
    topics() {
      return this.$store.getters.getTopics;
    },
    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
  },
  methods: {
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    async handleRowClick(item, row) {
      //   await this.$store.dispatch("setCurrentSurveyId", item.id);
      //   row.select(true)
      this.selected = [];
      this.selected.push(item);
      console.log("You clicked " + item.topic_name, this.selected, row);
    },
    async editItem(topic) {
      console.log("topic");
      const topicItem = this.$store.getters.getTopics.find(
        (t) => topic.topic_name == t.topic_name
      );
      if (topicItem) {
        const res = await this.$dialog.show(editTopic, {
          waitForResult: true,
          topic: topicItem,
        }); // Show dialog
        console.log("editItem", res, topicItem);
        if (res != "cancel") {
          // Make a new responder
          const topic = {
            id: topicItem.topic_id,
            name: res.topic_name,
          };
          this.$store.dispatch("updateTopics", topic);
        }
      } else {
        console.error("topic missing");
      }
    },

    deleteItem(item) {
      this.editedIndex = this.topics.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const id = this.editedItem.topic_id;
      this.$store.dispatch("deleteTopics", id);
      this.topics.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    CreateNewConfirm() {
      console.log("Ny kategori ", this.form);
      if (
        !this.$store.getters.getTopics.find(
          (t) => this.form.topic_name == t.topic_name
        )
      ) {
        const topic = {
          name: this.form.topic_name,
        };
        this.$store.dispatch("newTopics", topic);
        this.dialogCreateNew = false;
      } else {
        this.$dialog.notify.error("Kategorin finns redan ", {
          position: "top-left",
          timeout: 5000,
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.topics[this.editedIndex], this.editedItem);
      } else {
        this.topics.push(this.editedItem);
      }
      this.close();
    },
    async fetchAttributes() {
      try {
        const res = await hshApi.getFilterAttributes(
          this.$keycloak.tokenParsed.organisation
        );
        console.log("TopicsList: fetchAttributes", res);
        this.filterAttributes = res;
        // this.filterAttributes.map((f) => {
        //   if (f.name.startsWith("*")) {
        //     f.locked = true;
        //   }
        // });
      } catch (err) {
        console.error("TopicsList: Failed to fetch getFilterAttributes", err);
      }
    },
    async deleteFilterItem(item) {
      console.log("deleteFilterItem", item);
      await this.$dialog.info({
        text: `Är du säker på att du vill ta bort filterattributet?`,
        title: `Bekräftelse av borttagning filterattribut`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return this.deleteFilterAttribute(item);
            },
          },
        },
      });
    },
    async deleteFilterAttribute(item) {
      console.log("deleteFilterAttribute", item);
      try {
        const res = await hshApi.deleteFilterAttribute(
          this.$keycloak.tokenParsed.organisation,
          this.item
        );
        this.$store.state.applStore.hasUnsavedChanges = false;
        console.log("deleteFilterAttribute", res);
      } catch (error) {
        await this.$dialog.error({
          text: `Filterattribut gick inte att ta bort (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
    },

    async editFilterItem(item) {
      console.log("editFilterItem", item);
      const groupArr = this.createGroupsArray();
      const res = await this.$dialog.show(EditAttributeFilter, {
        waitForResult: true,
        width: "90%",
        title: "Ändra filterattribut",
        $store: this.$store,
        type: "edit",
        item: item,
        groupsArray: groupArr,
      }); // Show dialog
      console.log("editFilterItem gave dialog res", res);
      this.fetchAttributes();
      this.update++; // Is this needed? Want to trig update of table.
    },
    async newFilterItem() {
      console.log("newFilterItem");
      const groupArr = this.createGroupsArray();
      const item = {};
      const res = await this.$dialog.show(EditAttributeFilter, {
        waitForResult: true,
        width: "90%",
        title: "Nytt filterattribut",
        $store: this.$store,
        type: "new",
        item: item,
        groupsArray: groupArr,
      }); // Show dialog
      console.log("newFilterItem gave dialog res", res);
      this.fetchAttributes();
      this.update++;
    },
    createGroupsArray() {
      const groupedItems = this.filterAttributes
        .map((item) => item.groupname)
        .filter(
          (groupname, index, array) => array.indexOf(groupname) === index
        );
      console.log("createGroupsArray", groupedItems, this.filterAttributes);
      return groupedItems;
    },
  },
};
</script>
<style scoped>
.whitespace {
  white-space: pre;
  text-align: right;
  /* border: 3px solid green; */
}
.dialog-card {
  width: 100%;
  max-width: 100%;
  padding: 0px;
  margin: 10px;
  border: 1px solid red;
}
p {
  text-align: left;
  color: darkblue;
  padding-left: 12px;
  margin: 0 !important;
}
.hsh {
  padding: 4px;
  padding-left: 12px;
}
</style>
