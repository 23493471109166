import Vue from "vue";
import App from "./App.vue";
// import Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger/dist";
import router from "./router";
import store from "./store";
// import vuetify from "./plugins/vuetify";
// import Vuetify from "vuetify/lib/framework";
import Vuetify from "vuetify"; 
import VuetifyDialog from "vuetify-dialog";
import VueMarkdown from "vue-markdown";
import VuePrism from 'vue-prism'
import moment from "moment";
import templates from "./templates.js";
import authentication from "@/plugins/authentication";
import hsh_methods from "@/plugins/helpfunctions"
import 'prismjs'
import 'prismjs/themes/prism.css'
import _ from 'lodash'
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "github-markdown-css";
import VueApexCharts from 'vue-apexcharts'
import VTiptap from "@peepi/vuetify-tiptap";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

Vue.use(VTiptap);
Vue.config.productionTip = false;
Vue.use(VueLogger);
Vue.component('vue-markdown', VueMarkdown);

const vuetify = new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: { dark: false }
});
Vue.use(VuetifyDialog, { context: { vuetify } })
Vue.use(Vuetify); //<----
import "vuetify/dist/vuetify.min.css";

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Make globally accessible
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, '$_', { value: _ });

// Vue.prototype.$moment = moment
Vue.prototype.$templates = templates;
Vue.prototype.$vue = Vue;
Vue.use(authentication);
Vue.use(VuePrism)
Vue.mixin({
  methods: hsh_methods
})
// Vue.config.productionTip = false;

const options = {
  // url: "http://192.168.10.20:8080/realms/hsh", // "https://sso.homesvithome.se/auth",
  // realm: "hsh",
  // clientId: "frontend1",
  onLoad: "login-required",
  // flow: "standard",
  // scope: "openid forms_read admin_read",
  // enableLogging: true,
  // pkceMethod: "S256",
};

console.log("Keycloak Auth",Vue.$keycloak)

Vue.$keycloak
.init(options)  // options { onLoad: "login-required", checkLoginIframe: false }
.then((auth) => {
    console.log("Keycloak Auth",auth)
    if (!auth) {
      Vue.$log.info("Entering !auth block");
      window.location.reload();
    } else {
      console.log("Keycloak is OK what do we have?",Vue.$keycloak.tokenParsed.scope)
      const scopepos = Vue.$keycloak.tokenParsed.scope.search("frontend_full")
      if (scopepos < 1) {
        Vue.$keycloak.logout();
        window.location.href="https://idle.homesvithome.se/"
      } else {
        new Vue({
          router,
          store,
          vuetify,
          render: (h) => h(App),
        }).$mount("#app");
      }
    }
  });

