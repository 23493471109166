var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-8" }, [
        _c("label", { staticClass: "btn btn-default p-0" }, [
          _c("input", {
            ref: "file",
            attrs: { type: "file", accept: _vm.accept },
            on: { change: _vm.selectImage }
          })
        ])
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm float-right",
            attrs: { disabled: !_vm.currentImage },
            on: { click: _vm.upload }
          },
          [_vm._v(" Upload ")]
        )
      ])
    ]),
    _vm.currentImage
      ? _c("div", { staticClass: "progress" }, [
          _c(
            "div",
            {
              staticClass: "progress-bar progress-bar-info",
              style: { width: _vm.progress + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.progress,
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            },
            [_vm._v(" " + _vm._s(_vm.progress) + "% ")]
          )
        ])
      : _vm._e(),
    _vm.previewImage
      ? _c("div", [
          _c("div", [
            _c("img", {
              staticClass: "preview my-3",
              attrs: { src: _vm.previewImage, alt: "" }
            })
          ])
        ])
      : _vm._e(),
    _vm.message
      ? _c(
          "div",
          { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
          [_vm._v(" " + _vm._s(_vm.message) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }