<template>
  <div>
    <h1>Reports</h1>
    <v-container>
      <!-- ====[The table with Servey Instances to select from]====== -->
      <v-row>
        <v-col>
          <SurveysInstancesTable @selectSurveyInstance="surveInstanceChoosen"/>
        </v-col>
      </v-row>
      <!-- ====[Waiting Spinner]===================================== -->
      <div v-if="waiting">
        <div class="spinner spinner-border" style=""></div>
      </div>
      <!-- ====[The Report Graphs area]============================== -->
      <v-row>
        <v-col>
          <v-card class="elevation-3">
            <div v-if="reportToShow">
              <div>
                <v-divider class="mb-5"></v-divider>
                <!-- ====[The CSV image]============================= -->
                <div class="image-container">
                  <v-img
                    alt="Csv file"
                    class="shrink mr-2"
                    contain
                    src="../assets/downloadCsv.png"
                    transition="scale-transition"
                    width="40"
                    position="top"
                    @click.stop="fetchCsvFile()"
                  />
                </div>
                <!-- ====[The Filter Selection area]================= -->
                <h3 class="mb-5 ml-10">Välj filter för jämförelse</h3>
                <v-select
                  v-model="filterToUse"
                  :items="allFilterNames"
                  chips
                  deletable-chips
                  clearable
                  label="Pivot"
                  multiple
                  outlined
                  prepend-icon="mdi-filter-variant"
                  solo
                  :menu-props="{ y: 'below', offsetY: true }"
                ></v-select>
                <v-btn
                  color="primary"
                  class="ml-10"
                  :disabled="waiting"
                  @click="filterChoosen"
                >
                  Uppdatera graf
                </v-btn>
                <h6 class="ml-10 mt-5"><i>Esc för att stänga...</i></h6>
                <v-divider class="mb-5"></v-divider>
              </div>
              <!-- ====[The Graphs area]============================= -->
              <v-card
                class="d-flex align-stretch flex-wrap"
                color="grey lighten-2"
                flat
                tile
                min-height="200"
              >
                <!-- ====[One Card for Each Graph]=================== -->
                <v-card
                  v-for="(quest, index2) in surveyInstanceReports"
                  :key="index2"
                  class="pa-2"
                  outlined
                  title
                  width="50%"
                >
                  <!-- ====[Graph Header]============================ -->
                  <h3 class="ma-4">
                    {{ quest.barchart.bar_options.chart.id }}
                  </h3>
                  <div>
                    <!-- ====[The Bar-Graph]========================= -->
                    <apexchart
                      width="500"
                      type="bar"
                      :options="barOptionsNumbers(quest)"
                      :series="quest.barchart.bar_series"
                    ></apexchart>
                    <!-- quest.report.barchart.bar_options"  -->
                    <!-- ====[The Bar-Graph Legends]================= -->
                    <div v-if="quest.showLegend">
                      <div
                        class="text-h7"
                        v-for="(textLegend, index3) in quest.barchart.bar_options.xaxis
                          .categories"
                        :key="index3"
                      >
                        {{ index3 + 1 }}. {{ textLegend }}
                      </div>
                      <p></p>
                    </div>
                    <!-- ====[The Bar-Graph Meta info]=============== -->
                    <h4>
                      Antal besvarade
                      {{ quest.metadata.nbr_of_respondent_answers }} /
                      {{ quest.metadata.total_nbr_of_respondents }}
                    </h4>
                  </div>
                </v-card>
              </v-card>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SurveysInstancesTable from "../components/SurveysInstancesTable.vue";
import hshApi from "../services/hshapi";

export default {
  components: {
    SurveysInstancesTable,
  },
  data: function () {
    return {
      currentSurveyInstance: {},
      surveyInstanceReports: [],
      reportToShow: false,
      item: {},
      updatingGraph: false,
      // Filter associated variables
      allFilters: [],
      allFilterNames: [],
      filterToUse: [],
      // Profiling
      loading: false,
      startTime: 0,
      profileName: "",
    };
  },

  async beforeMount() {
    await this.$store.dispatch("fetchSurveys");
    this.$store.dispatch("fetchQuestions");
  },

  async mounted() {
    // this.$root.$off("surveysInstanceTable-survey-clicked");
    // this.$root.$on("surveysInstanceTable-survey-clicked", (item) => {
    //   this.reportToShow = false;
    //   console.log("Report: SurveyInstance received:" + item.name, item);
    //   this.getReportBelongingToCurrentSurveysInstance(item);
    // });
    this.allFilters = await this.hshFetchFilter();
    this.allFilterNames = this.allFilters.map((m) => m.name);
    console.log(
      "Report mounted: SurveyInstance hshFetchFilter:",
      this.allFilters,
      this.allFilterNames
    );
  },

  computed: {
    waiting: function () {
      return this.updatingGraph;
    },
  },

  methods: {
    surveInstanceChoosen(item) {
      this.reportToShow = false;
      console.log("Report: surveInstanceChoosen received:" + item.name, item);
      this.getReportBelongingToCurrentSurveysInstance(item);
    },
    startProfiling(name) {
      this.profileName = name;
      this.startTime = new Date();
    },

    stopProfiling() {
      var timeDiffMilliseconds = new Date() - this.startTime;
      console.log(
        "Elased time for " + this.profileName + ": " + timeDiffMilliseconds + " ms"
      );
    },

    getMaxTextLength(arrayOfText) {
      return Math.max(...arrayOfText.map((o) => o.length));
    },

    showLegend(barOptions) {
      const maxTextLength = this.getMaxTextLength(barOptions.xaxis.categories);
      return maxTextLength >= 23;
    },

    createArrayOfIntegers(length) {
      return Array(length)
        .fill()
        .map((_, i) => i + 1);
    },

    barOptionsNumbers(quest) {
      let barOptions = quest.barchart.bar_options;
      quest.showLegend = this.showLegend(barOptions);
      if (quest.showLegend) {
        let nbrOfCategories = barOptions.xaxis.categories.length;
        barOptions = JSON.parse(JSON.stringify(barOptions));
        barOptions.xaxis.categories = this.createArrayOfIntegers(nbrOfCategories);
      }
      return barOptions;
    },

    async getReportBelongingToCurrentSurveysInstance(itemobj) {
      this.updatingGraph = true;
      this.currentSurveyInstance = JSON.parse(JSON.stringify(itemobj));
      console.log(
        "getReportBelongingToCurrentSurveysInstance startObject",
        this.currentSurveyInstance
      );
      let reports;
      try {
        this.startProfiling("GetReport");
        reports = await hshApi.fetchReport(
          this.currentSurveyInstance.id,
          0,
          this.filterToUse
        );
        this.surveyInstanceReports = reports.data;
      } catch (error) {
        if (error.response.status != 404) {
          console.error("getReportBelongingToCurrentSurveysInstance dispatch", error);
          this.allFilters = [];
          this.allFilterNames = [];
          this.filterToUse = [];
          this.surveyInstanceReports = [];
        }
      } finally {
        this.stopProfiling();
        console.log("Reports:", this.surveyInstanceReports);
      }
      this.reportToShow = true;
      this.updatingGraph = false;
    },

    async filterChoosen() {
      console.log("filterChoosen", this.filterToUse);
      const res = await this.getReportBelongingToCurrentSurveysInstance(
        this.currentSurveyInstance
      );
      console.log("filterChoosen res", res);
    },

    async fetchCsvFile() {
      console.log("fetchCsvFile", this.id);
      try {
        const response = await hshApi.getSurveyInstanceCvs(this.currentSurveyInstance.id);
        const html = response.data;
        const blob = new Blob([html], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.currentSurveyInstance.name}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  float: left;
  margin-right: 10px;
  margin-left: 10px;
}
.spinner {
  color: blue;
  margin-left: 45%;
  margin-top: 20px;
}
</style>
