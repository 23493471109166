<template>
  <div>
    <DialogCard :title="title" :actions="actions">
      <upload-file :metadata="compileMetadata()" ref="filehandler"></upload-file>
        <v-select
        v-model="type"
        :items="typeItems"
        label="Typ av fil"
        required
      ></v-select>
      <v-text-field
        v-model="description"
        label="Beskrivning av filen här..."
      ></v-text-field>
      <v-select
        v-model="lang"
        :items="langItems"
        label="Språk"
        required
      ></v-select>
      <!-- <v-text-field
        v-model="name"
        label="Filnamn (utan mellanslag)"
      ></v-text-field> -->
      <v-select
        v-model="topic_id"
        :items="topics"
        label="Kategori"
        required
      ></v-select>
    </DialogCard>
  </div>
</template>

<script>
import UploadFile from "./UploadFile.vue";

export default {
  components: {
    UploadFile,
  },
  name: "HshUploadFile",
  props: ["title","$store"],
  // emits: ["update:modelValue"],
  data() {
    return {
      privFile: "",
      showPicker: false,
      type:"image",
      typeItems:["image","audio"],
      description: "",
      lang: "sv",
      name: "",
      topic_id: 163,
      langItems:this.$store.state.languages,
    };
  },
  mount() {
    console.log("HshUploadFile", this.showpicker, this.privColor,this.$store.state.languages);
    this.langItems=this.$store.state.languages
  },
  methods: {
    compileMetadata() {
      return {
        description:  this.description,
        lang:         this.lang,
        name:         this.name,
        topic_id:     parseInt(this.topic_id)
      }
    },
  },
  watch: {
    // description: function () {
    //   console.log("HshUploadFile description cahnge ", this.description);
    // //   this.signalChange(this.showpicker);
    // },
    type: function() {
      console.log("watch: type cahnge",this.type)
      if (this.type == "audio") {
        this.$refs.filehandler.changeAccept("audio/wav, audio/ogg")
      } else {
        this.$refs.filehandler.changeAccept("image/*")
      }
    }
  },
  computed: {
    topics() {
      console.log("topics" , this.$store)
      // return ["adam","eva"]
     return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
    actions() {
      return {
        cancel: {
          text: "Stäng",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        // ok: {
        //   text: "OK",
        //   color: "blue darken-1",
        //   handle: () => {
        //     return this.privColor;
        //   },
        // },
      };
    },
  },
};
</script>

<style scoped>
.boxtext {
  text-align: center;
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
.imageUrl {
  max-width: 30px;
  max-height: 100%;
  justify-content: left;
  align-content: flex-start;
}
</style>
