var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingStatus
    ? _c("div", [
        _vm.isThereACurrentSurvey
          ? _c(
              "div",
              [
                _c(
                  "v-form",
                  {
                    attrs: { "max-width": "90%" },
                    model: {
                      value: _vm.dialog,
                      callback: function($$v) {
                        _vm.dialog = $$v
                      },
                      expression: "dialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "4", sm: "6", md: "4" }
                                      },
                                      [
                                        _vm.surveyChoosed
                                          ? _c("v-card-title", [
                                              _vm._v(" Starta undersökningen ")
                                            ])
                                          : _vm._e(),
                                        _c("v-card-subtitle", [
                                          _c("b", [
                                            _vm._v(_vm._s(_vm.editedItem.name))
                                          ])
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "4", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-datetime-picker", {
                                          attrs: {
                                            label: "Starttid",
                                            disabled: _vm.editedItem.locked,
                                            timeFormat: "HH:mm",
                                            "time-picker-props": _vm.timeProps,
                                            clearText: "Avbryt",
                                            textFieldProps:
                                              _vm.dateTimeTextFieldProps
                                          },
                                          on: { input: _vm.dateTimeGiven },
                                          model: {
                                            value: _vm.startDate,
                                            callback: function($$v) {
                                              _vm.startDate = $$v
                                            },
                                            expression: "startDate"
                                          }
                                        }),
                                        _c("v-checkbox", {
                                          staticClass:
                                            "align-center justify-center",
                                          attrs: {
                                            color: "gray",
                                            value: "true"
                                          },
                                          on: { click: _vm.dateTimeCheck },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "align-center justify-center pt-3"
                                                                        },
                                                                        "span",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "Skapa en repeterande undersökning"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          377374766
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Om du vill att denna undersökning skall repeteras automatiskt "
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            1213556580
                                          ),
                                          model: {
                                            value: _vm.markIsRepeatSurvey,
                                            callback: function($$v) {
                                              _vm.markIsRepeatSurvey = $$v
                                            },
                                            expression: "markIsRepeatSurvey"
                                          }
                                        }),
                                        _vm.markIsRepeatSurveyBool
                                          ? _c(
                                              "v-card",
                                              [
                                                _c("v-card-title", [
                                                  _vm._v(
                                                    " Repeterande undersökning "
                                                  )
                                                ]),
                                                _c(
                                                  "v-container",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "6" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "small-font",
                                                                                attrs: {
                                                                                  label:
                                                                                    "Antal ggr ",
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  maxlength:
                                                                                    "4",
                                                                                  type:
                                                                                    "number"
                                                                                },
                                                                                on: {
                                                                                  input:
                                                                                    _vm.checkRepeate
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.repeate,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.repeate = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "repeate"
                                                                                }
                                                                              },
                                                                              on
                                                                            )
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  2666868093
                                                                )
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Antalet gånger som en undersökning skall genomföras"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "6" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  right: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "v-select",
                                                                                _vm._g(
                                                                                  {
                                                                                    staticClass:
                                                                                      "small-font",
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm.periodItems,
                                                                                      "item-text":
                                                                                        "name",
                                                                                      label:
                                                                                        "Period enhet",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      maxlength: 4
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.period,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.period = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "period"
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                )
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  3477103153
                                                                )
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Vilken period som undersökningen skall ha. per dag/vecka/månad etc."
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "6" }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "digit-input small-font",
                                                              attrs: {
                                                                label:
                                                                  _vm.frequencyText,
                                                                outlined: "",
                                                                dense: "",
                                                                maxlength: "4",
                                                                type: "number"
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.checkFrequence
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.frequence,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.frequence = $$v
                                                                },
                                                                expression:
                                                                  "frequence"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "6" }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "digit-input small-font",
                                                              attrs: {
                                                                label:
                                                                  "Varaktighet timmar",
                                                                outlined: "",
                                                                dense: "",
                                                                maxlength: "4",
                                                                type: "number"
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.checkTimespan,
                                                                change:
                                                                  _vm.checkTimespan
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.surveyTimespanHours,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.surveyTimespanHours = $$v
                                                                },
                                                                expression:
                                                                  "surveyTimespanHours"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "4", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-datetime-picker", {
                                          attrs: {
                                            label: "Sluttid",
                                            disabled:
                                              _vm.editedItem.locked ||
                                              _vm.markIsRepeatSurveyBool,
                                            timeFormat: "HH:mm",
                                            "time-picker-props": _vm.timeProps,
                                            clearText: "Avbryt",
                                            textFieldProps:
                                              _vm.dateTimeTextFieldProps
                                          },
                                          on: { input: _vm.dateTimeGiven },
                                          model: {
                                            value: _vm.endDate,
                                            callback: function($$v) {
                                              _vm.endDate = $$v
                                            },
                                            expression: "endDate"
                                          }
                                        }),
                                        _c("v-checkbox", {
                                          staticClass:
                                            "align-center justify-center",
                                          attrs: {
                                            color: "gray",
                                            value: "true"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "align-center justify-center pt-3"
                                                                        },
                                                                        "span",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "Lägg till som passivt formulär"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          1364542285
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Om undersökningen inte skall resultera i aktiviteter, utan användas t.ex. för självinitierade synpunkter "
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            451397256
                                          ),
                                          model: {
                                            value: _vm.markRespondentDone,
                                            callback: function($$v) {
                                              _vm.markRespondentDone = $$v
                                            },
                                            expression: "markRespondentDone"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "div",
                                        [
                                          _c("v-divider", {
                                            staticClass: "mb-5"
                                          }),
                                          _c("h3", { staticClass: "mb-5" }, [
                                            _vm._v(
                                              "Antal respondenter " +
                                                _vm._s(
                                                  _vm.nrOfRespondentsSelected
                                                )
                                            )
                                          ]),
                                          _c("h5", [
                                            _vm._v(
                                              " Valda Filter tillför " +
                                                _vm._s(
                                                  _vm.nrOfMatchingRespondents
                                                ) +
                                                " respondenter "
                                            )
                                          ]),
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.allFilterNames,
                                              chips: "",
                                              "deletable-chips": "",
                                              clearable: "",
                                              label: "Pivot",
                                              multiple: "",
                                              outlined: "",
                                              "prepend-icon":
                                                "mdi-filter-variant",
                                              solo: "",
                                              "menu-props": {
                                                y: "below",
                                                offsetY: true
                                              }
                                            },
                                            on: { change: _vm.filterChoosen },
                                            model: {
                                              value: _vm.filterToUse,
                                              callback: function($$v) {
                                                _vm.filterToUse = $$v
                                              },
                                              expression: "filterToUse"
                                            }
                                          }),
                                          _c("h6", [
                                            _c("i", [
                                              _vm._v("Esc för att stänga...")
                                            ])
                                          ]),
                                          _c("v-divider", {
                                            staticClass: "mb-5"
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "elevation-10 pa-4 mt-10"
                                            },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "mb-5" },
                                                [_vm._v("Respondenter")]
                                              ),
                                              !_vm.useSelction
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("RespondersList", {
                                                        key:
                                                          _vm.rerenderTrigger,
                                                        ref: "respondersList",
                                                        attrs: {
                                                          survey:
                                                            _vm.currentSurvey
                                                        },
                                                        on: {
                                                          responderClicked:
                                                            _vm.responderClicked
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "blue darken-1", text: "" },
                                on: { click: _vm.close }
                              },
                              [_vm._v(" Avbryt ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: !_vm.isPossibleToStartSurvey
                                },
                                on: { click: _vm.startSurvey }
                              },
                              [_vm._v(" Starta undersökning ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }