<template>
  <div v-if="loadingStatus">
    <v-container class="px-0">
      <v-card class="px-6 py-4" max-width="1920">
        <v-row>
          <v-col cols="4">
            <h5>
              <table>
                <tr>
                  <td>Formulärnamn:</td>
                  <td>{{ formItem.name }}</td>
                </tr>
                <tr>
                  <td>Id:</td>
                  <td>{{ formItem.id }}</td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td>
                    <span v-if="formItem.locked">Låst!</span
                    ><span v-else>OLåst!</span>
                  </td>
                </tr>
                <tr>
                  <td>Antal Frågor:</td>
                  <td>{{ nrOfQuestions }}</td>
                </tr>
              </table>
            </h5>
          </v-col>
          <v-col cols="4">
            <h5>
              <table>
                <tr>
                  <td>Skapad:</td>
                  <td>{{ wasCreated }}</td>
                </tr>
                <tr>
                  <td>Senast ändrad:</td>
                  <td>{{ wasModified }}</td>
                </tr>
                <tr>
                  <td>Tillhör org:</td>
                  <td>{{ formItem.owner_org_name }}</td>
                </tr>
                <tr>
                  <td>Skapad av:</td>
                  <td>{{ formItem.author }}</td>
                </tr>
              </table>
            </h5>
          </v-col>
          <v-col cols="4">
            <h5>
              <table>
                <tr>
                  <td>Kategori:</td>
                  <td>{{ formItem.topic_name }}</td>
                </tr>
              </table>
            </h5>
          </v-col>
        </v-row>
        <v-row class="pb-6" justify="center" align="end">
          <QuestionSelector :form="currentForm" />
          <StartSurveyFromForm :form="currentForm" buttontext="Skapa och starta undersökning (genväg)" />
          <FormPreview
            :url="'https://form.homesvithome.se/demoform/' + formItem.id"
          />
        </v-row>
        <v-divider class="my-6"></v-divider>
        <h5>
          <div v-for="quest in formItem.questions" :key="quest.id">
            <v-card elevation="2" min-height="100%">
              <v-row>
                <v-col cols="6">
                  <table :class="typeSize(quest)">
                    <tr>
                      <td>Fråga:</td>
                      <td>{{ quest.question.i18n.sv.text }}</td>
                    </tr>
                    <tr>
                      <td>Id:</td>
                      <td>{{ quest.question.id }}</td>
                    </tr>
                    <tr>
                      <td>Skapare:</td>
                      <td>{{ quest.author }}</td>
                    </tr>
                    <tr>
                      <td>Villkor:</td>
                      <td>
                        <v-card
                          class="mx-2"
                          max-width="400"
                          tile
                          @click="setRule(formItem, quest)"
                        >
                          <template>
                            <v-list-item
                              class="hsh-list"
                              v-for="(rule, index) in rule(quest)"
                              :key="index"
                            >
                              <v-list-item-subtitle
                                class="hsh-list"
                                v-text="rule"
                              ></v-list-item-subtitle>
                            </v-list-item>
                          </template>
                        </v-card>
                        <!-- <ul>
                          <li v-for="(rule, index) in rule(quest)" :key="index">
                            {{ rule }}
                          </li>
                        </ul> -->
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <!-- <v-btn color="primary" class="ma-2" @click="setRule(formItem,quest)"
                        >
                        Sätt villkor...
                        </v-btn> -->
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col>
                  <div
                    v-for="answer in quest.question.answers"
                    :key="answer.id"
                  >
                    <table style="{align-center}">
                      <tr>
                        <td>
                          <!-- <img :src="urlimg[answer.i18n.sv.media.image.id]" class="imageUrl"> -->
                          <div v-if="useImage(answer)">
                            <img :src="imgSrc(answer)" class="imageUrl" />
                          </div>
                          <div
                            v-else
                            class="box"
                            :style="{
                              'background-color': answer.ui_hint.bgcolor,
                            }"
                          >
                            <span class="mx-24"></span>
                          </div>
                        </td>
                        <td>
                          {{ answer.i18n.sv.text }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <!-- </v-layout> -->
            <v-divider class="my-6"></v-divider>
          </div>
        </h5>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import FormPreview from "./FormPreview.vue";
import QuestionSelector from "./QuestionSelector.vue";
import EditFormRule from "./EditFormRule.vue";
import StartSurveyFromForm from "./StartSurveyFromForm.vue";
// import CreateForm from "./CreateForm.vue";

export default {
  components: {
    FormPreview,
    StartSurveyFromForm,
    QuestionSelector,
  },
  name: "FormsForm",
  data() {
    return {
      show: false,
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      hide: false,
      surveyBtn: false,
      // bus: new Vue(),
      headers: [
        {
          text: "Fråga",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "typ", value: "topic_id" },
        { text: "bildlänk fråga", value: "image_url" },
        { text: "ljudlänk fråga", value: "audio_url" },
        { text: "Antal svarsalternativ", value: "nrOfAnswers" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      // form: [],
      formIndex: -1,
      formItem: {},
      defaultItem: {},
      selected: [],
      urlimg: [],
    };
  },
  computed: {
    currentForm() {
      this.getCurrentForm();
      const form = this.formItem;
      console.log("FormsForm: currentForm()", form);
      return form;
    },
    loadingStatus() {
      console.log(
        `QuestionForm: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return (
        this.$store.getters.getLoadingStatus == 0 &&
        this.$store.getters.getCurrentFormId > 0
      );
    },
    nrOfQuestions() {
      if (this.formItem.questions) {
        return this.formItem.questions.length;
      } else {
        return "";
      }
    },

    wasCreated() {
      if (this.formItem.created) {
        return this.$moment(this.formItem.created).format("YY-MM-DD HH:mm");
      } else {
        return "";
      }
    },
    wasModified() {
      return this.$moment(this.formItem.modified).format("YY-MM-DD HH:mm");
    },
  },
  mounted() {
    console.log("FormsForm: mounted()");
    // this.bus.$on('show',this.show)
    this.$root.$off("formtable-form-clicked");
    this.$root.$on("formtable-form-clicked", (item) => {
      console.log(
        "FormsForm: Dialog received:",
        item,
        this.$store.getters.getLoadingStatus
      );
      this.formItem = item;
      // this.getCurrentForm()
      console.log("FormsForm: Dialog received2:", this.formItem);
    });

    // end of eventhandler
    this.formItem = this.$store.getters.getCurrentForm;
    if (this.formItem === null) {
      console.log("FormsForm: No current form:");
      this.formItem = this.$templates.form;
    }
    this.$store.dispatch("fetchQuestions");
  },
  methods: {
    rule(quest) {
      console.log("quest", quest);
      if (quest.enabled) {
        return "-";
      }
      const ruleDesc = this.parseComment(quest.enable_rule);
      if (ruleDesc.length > 0) {
        return ruleDesc;
      }
      return quest.enable_rule;
    },
    async setRule(form, quest) {
      console.log("setRule for quest", form);
      const changedRule = await this.$dialog.show(EditFormRule, {
        waitForResult: true,
        title: `Sätta regel för om fråga skall visas:`,
        $store: this.$store,
        form: form,
        question: quest,
        width: 900,
      }); // Show dialog
      console.log("editFormRule gave dialog res", changedRule);
      if (changedRule != "cancel") {
        const index = this.formItem.questions.findIndex(
          (q) => q.question.id == quest.question.id
        );
        if (changedRule == "delete") {
          this.formItem.questions[index].enabled = true;
          this.formItem.questions[index].enable_rule = "true";
        } else {
          // Make a new responder
          //  this.$store.dispatch('saveForm',changedRule)
          if (index >= 0) {
            this.formItem.questions[index].enabled = false;
            this.formItem.questions[index].enable_rule = changedRule;
            try {
              await this.$store.dispatch("saveForm", this.formItem);
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    },
    async useImage(answer) {
      // this.formItem.questions.
      try {
        if (this.$_.get(answer, "i18n.sv.media.image.id", 0) > 0) {
          this.urlimg[answer.i18n.sv.media.image.id] =
            this.$store.getters.getImageById(answer.i18n.sv.media.image.id);
          return true;
        }
      } catch (error) {
        console.error("useImage", error);
      }
      console.log("useImage returns false");
      return false;
    },
    imgSrc(answer) {
      if (this.$_.get(answer, "i18n.sv.media.image.id", 0) > 0) {
        return this.urlimg[answer.i18n.sv.media.image.id];
      }
      return "";
    },
    imgUrl(answer) {
      console.log("imgUrl", answer);
      if (this.$_.get(answer, "i18n.sv.media.image.id", false)) {
        try {
          this.urlimg[answer.i18n.sv.media.image.id] =
            this.$store.getters.getImageById(answer.i18n.sv.media.image.id);
          console.log(
            "imgUrl fetch",
            answer,
            this.urlimg[answer.i18n.sv.media.image.id]
          );
          return this.urlimg[answer.i18n.sv.media.image.id];
        } catch (error) {
          console.error("Error imgUrl", error);
        }
      }
      return null;
    },

    async getCurrentForm() {
      try {
        this.formItem = await this.$store.getters.getCurrentForm;
      } catch (error) {
        console.log("getCurrentForm after fetch error ", error);
      }
      console.log("getCurrentForm after fetch", this.formItem);
    },
    typeSize(item) {
      if (item.id % 2 == 0) {
        return "pl-10 ml-10";
      }
      return "pl-0 ml-0";
    },
    playSound(url) {
      console.log(`QuestionForm:playSound Will play ${url}`);
      var audio = new Audio(url); // path to file
      audio.play();
    },
    handleRowClick(item) {
      console.log("FormForm: You clicked ", item);
      console.log(this.selected);
      this.$store.dispatch("setCurrentFormId", item.id);
      this.focusQuestionForm(item);
      // this.$root.$emit("formtable-form-clicked", item);
      // this.selected = []
      // this.selected.push(item)
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    focusQuestionForm(item) {
      this.$root.$emit("questiontable-form-clicked", item);
      this.selected = [];
      this.selected.push(item);
    },
    editItem(item) {
      // alert("You clicked pen " + item.name);
      this.formIndex = this.forms.indexOf(item);
      this.formItem = Object.assign({}, item);
      //this.dialog = true;
      // this.bus.$emit('show')
      console.log("Emit show");
    },

    deleteItem(item) {
      this.formIndex = this.forms.indexOf(item);
      this.formItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.forms.splice(this.formIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.formItem = Object.assign({}, this.defaultItem);
        this.formIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formItem = Object.assign({}, this.defaultItem);
        this.formIndex = -1;
      });
    },

    save() {
      if (this.formIndex > -1) {
        Object.assign(this.forms[this.formIndex], this.formItem);
      } else {
        this.forms.push(this.formItem);
      }
      this.close();
    },
    itemRowBackground(item) {
      if (this.selected.length <= 0) {
        return "style-2";
      }
      console.log(
        "itemRowBackground FormForm called " +
          item.id +
          " " +
          this.selected[0].id
      );
      return item.id == this.selected[0].id ? "style-1" : "style-2";
    },
  },
};
</script>

<style scoped>
td {
  padding-left: 6px;
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
.imageUrl {
  max-width: 30px;
  max-height: 100%;
  justify-content: left;
  align-content: flex-start;
}
.hsh-list {
  max-height: 1.1em !important;
  padding: 2px !important;
  min-height: 20px !important;
}
</style>
