import moment from "moment";
import hshApi from "../services/hshapi";
class LocalStore {
  static phraseFiFo = [];
  static currentPhrase = "";
  static currentEndFunction = undefined;
  static currentStartFunction = undefined;
  static currentId = -1;

  static push(phraseObj) {
    this.phraseFiFo.push(phraseObj);
  }
  static shift() {
    const obj = this.phraseFiFo.shift();
    if (obj.type == "audio") {
      LocalStore.currentPhrase = obj.url;
      LocalStore.currentStartFunction = obj.startFunction;
      LocalStore.currentEndFunction = obj.endFunction;
      LocalStore.currentId = obj.id;
    } else {
      LocalStore.currentId = -1;
    }
    return obj;
  }
}

const hsh_methods = {
  playPhraseWithName: async function (name, startFunc, endFunc) {
    if (this.$store.state.applStore.nosound) return;
    try {
      let phraseId = await this.$store.getters.getAudioMetaDataWithName(name);
      if (phraseId == 0) {
        console.error("playPhraseWithName didn't find phrase ", name);
        // await this.hsh_checkInfrastructure()
        return false;
      }
      await this.playPhrase(phraseId, startFunc, endFunc);
      return true;
    } catch (error) {
      console.log("playPhraseWithName throw exception");
    }
    return false;
  },
  stopAndClearAllPhrases: function () {
    const url = LocalStore.currentPhrase;
    console.log("stopAndClearAllPhrases", url);
    if (url != "") {
      url.pause();
      url.currentTime = 0;
      url.src =
        "data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=";
      LocalStore.currentPhrase = "";
      LocalStore.currentEndFunction = undefined;
    }
    LocalStore.phraseFiFo = [];
  },
  playnext: function (event) {
    if (event.type == "ended") {
      // setTimeout( () => {
      LocalStore.currentPhrase = "";
      if (LocalStore.currentEndFunction != undefined) {
        LocalStore.currentEndFunction(LocalStore.currentId);
        LocalStore.currentEndFunction = undefined;
      }
      console.log("playPhrase LocalStore", LocalStore.phraseFiFo);
      if (LocalStore.phraseFiFo.length > 0) {
        const obj = LocalStore.shift();
        if (obj.type === "audio") {
          const url = obj.url;
          if (url != undefined) {
            url.onended = this.playnext;
            if (LocalStore.currentStartFunction != undefined) {
              LocalStore.currentStartFunction(LocalStore.currentId);
            }
            console.log(
              `playPhrase Now play phrase `,
              url,
              moment().format("MM/DD/YYYY h:mm:ss.SSS A")
            );
            try {
              url.play();
            } catch (e) {
              console.log("playnext play failed", e);
              let event = new Event("play");
              event.type == "ended";
              url.dispatch(event);
            }
          }
        } else if (obj.type === "silence") {
          console.log(
            `playPhrase Now start wait ${obj.millisec}`,
            moment().format("MM/DD/YYYY h:mm:ss.SSS A")
          );
          setTimeout(() => {
            console.log(
              `playPhrase waited for ${obj.millisec} ms`,
              moment().format("MM/DD/YYYY h:mm:ss.SSS A")
            );
            this.playnext(event);
          }, obj.millisec);
        }
      }
      // },700)
    }
  },
  playPhrase: async function (id, startFunction, endFunction) {
    if (this.$store.state.applStore.nosound) return;
    try {
      console.log("playPhrase url before", id, startFunction, endFunction);
      let url = await this.$store.getters.getAudioById(id);
      console.log("playPhrase url", url);
      if (url == undefined) {
        await this.$store.dispatch("getAudioMedia", id);
        url = await this.$store.getters.getAudioById(id);
      }
      console.log("playPhrase url2", url);
      if (url == undefined) {
        return false;
      }
      if (LocalStore.currentPhrase == "") {
        console.log(
          "playPhrase Will play - in queue => ",
          LocalStore.phraseFiFo.length
        );
        if (startFunction != undefined) {
          console.log("playPhrase Will call start ");
          startFunction(id);
        }
        LocalStore.currentPhrase = url;
        try {
          url.onended = this.playnext;
          url
            .play()
            .then()
            .catch((e) => {
              LocalStore.currentPhrase = "";
              console.log("playPhrase rejected", e);
              if (endFunction != undefined) {
                console.log("playPhrase Will call end ");
                endFunction(id);
              }
            });
        } catch (error) {
          LocalStore.currentPhrase = "";
          console.log("playPhrase error", error);
          if (endFunction != undefined) {
            console.log("playPhrase Will call end ");
            endFunction(id);
          }
        }
      } else {
        console.log(
          "playPhrase is playing - queue it up ",
          LocalStore.currentPhrase
        );
        LocalStore.push({
          type: "audio",
          url: url,
          id: id,
          startFunction: startFunction,
          endFunction: endFunction,
        });
        console.log("playPhrase pushed", url);
      }
      return true;
    } catch (error) {
      console.log("playPhrase throw exception");
    }
    return false;
  },
  playPause: async function (ms) {
    if (this.$store.state.applStore.nosound) return;
    console.log(
      "playPhrase Now play pause ",
      ms,
      moment().format("MM/DD/YYYY h:mm:ss.SSS A")
    );
    LocalStore.push({
      type: "silence",
      millisec: ms,
    });
  },
  playQuestion: async function (question, stopCurrent, startFunc, endFunc) {
    if (this.$store.state.applStore.nosound) return;
    if (stopCurrent) {
      this.stopAndClearAllPhrases();
    }
    const phraseId = question.i18n.sv.media.audio.id;
    if (phraseId > 0) {
      await this.playPhraseWithName("SFR_Qis.wav", startFunc, endFunc);
      await this.playPause(500);
      await this.playPhrase(phraseId, startFunc, endFunc);
    }
    await this.playPause(1000);
    await this.playPhraseWithName("SFR_ChooseOneA.wav", startFunc, endFunc);
    await this.playPause(700);

    for (let i = 0; i < question.answers.length; i++) {
      if (question.answers[i].i18n.sv.media.audio.id > 0) {
        const res = await this.playPhrase(
          question.answers[i].i18n.sv.media.audio.id,
          startFunc,
          endFunc
        );
        console.log("playQuestion answ", res);
        if (i + 2 == question.answers.length) {
          // we are before last item
          await this.playPause(1000);
          await this.playPhraseWithName("SFR_Or.wav", startFunc, endFunc);
          console.log("playQuestion p1000");
        } else {
          await this.playPause(700);
          console.log("playQuestion p700");
        }
      }
    }
  },
  async getImage(id) {
    let url = await this.$store.getters.getImageById(id);
    if (url == undefined) {
      await this.$store.dispatch("getImgMedia", id);
      url = await this.$store.getters.getImageById(id);
    }
    console.log("getImage: Fetch id ", id, url);
    return url;
  },

  async hsh_setupInfrastructure() {
    if (this.$store.state.infrastructureSetupDone == false) {
      this.$store.state.loadingStatus += 1; // Avoid emit signal of fetch complete
      try {
        await this.$store.dispatch("getMediaList");
        if (this.$route.params.id > 0) {
          await this.$store.dispatch("setCurrentFormId", this.$route.params.id);
          await this.$store.dispatch("fetchForm", this.$route.params.id);
          if (this.$route.query.redo != "yes") {
            this.$store.dispatch("clearAnswers");
          }
          // const form = await this.$store.getters.getCurrentForm;
          // form.questions.forEach((item) => {
          //   if (this.$_.get(item, "question.i18n.sv.media.image", false)) {
          //     this.$store.dispatch(
          //       "getImgMedia",
          //       item.question.i18n.sv.media.image.id
          //     );
          //     console.log("getImageMedia in loop");
          //   }
          //   if (this.$store.state.applStore.nosound == false) {
          //     if (
          //       this.$_.get(item, "question.i18n.sv.media.audio", undefined) !=
          //       undefined
          //     ) {
          //       this.$store.dispatch(
          //         "getAudioMedia",
          //         item.question.i18n.sv.media.audio.id
          //       );
          //     }
          //   }
          //   item.question.answers.forEach((answ) => {
          //     if (
          //       this.$_.get(answ, "i18n.sv.media.image", undefined) != undefined
          //     ) {
          //       this.$store.dispatch(
          //         "getImgMedia",
          //         answ.i18n.sv.media.image.id
          //       );
          //     }
          //     if (this.$store.state.applStore.nosound == false) {
          //       if (
          //         this.$_.get(answ, "i18n.sv.media.audio", undefined) !=
          //         undefined
          //       ) {
          //         this.$store.dispatch(
          //           "getAudioMedia",
          //           answ.i18n.sv.media.audio.id
          //         );
          //       }
          //     }
          //   });
          // });
        }
        // if (this.$store.state.applStore.nosound == false) {
        //   [
        //     "notificationDecorative01.wav",
        //     "SFR_ForYPushGreen.wav",
        //     "SFR_ForNPushRed.wav",
        //     "SFR_CorrectPushGreen.wav",
        //     "SFR_RegrettPushRed.wav",
        //     "SFR_Thanks.wav",
        //     "SFR_ThanksforA.wav",
        //     "SFR_GoodNextQ.wav",
        //     "SFR_LastQ.wav",
        //     "SFI_HelloSomeQtoA.wav",
        //     "SFR_YouhaveA.wav",
        //     "SFR_Or.wav",
        //   ].forEach((medianame) => {
        //     const audioId =
        //       this.$store.getters.getAudioMetaDataWithName(medianame);
        //     this.$store.dispatch("getAudioMedia", audioId);
        //   });
        // }
      } catch (e) {
        console.log("hsh_setupInfrastructure crash", e);
      }
      this.$store.state.loadingStatus -= 1;
      this.$store.state.infrastructureSetupDone = true;
    }
  },
  async hsh_checkInfrastructure() {
    if (this.$store.state.medialist.length == 0) {
      await this.$store.dispatch("getMediaList");
    }
  },

  // Rule handling to decide what question to show
  //   function test1() {
  //     // The rule is a logic expression containing variables with
  //     // names that start with a q (for question-id) and follows
  //     // by a numeric value, which is the id of the referenced
  //     // question.
  //     // Constant numeric values represents an answer-id.
  //     // So...
  //     //     q30 == 199
  //     // means:
  //     // If question with id = 30 has been answerd with an answer who's
  //     // id is 199, the this rule returns true.
  //     //
  //     let rule = "(q30 == 199 || q32 == 201) && q33 == 177"

  //     // This objects describes what ansers (id's) has been given
  //     // for a certain question. The key is the question id and
  //     // the value is the answer id.
  //     answers = {
  //         30: 200,
  //         32: 201,
  //         33: 177,
  //     }
  //     console.log("Evaluating rule:", rule)
  //     console.log("With answer inputs:", answers)
  //     parse(rule, answers);
  //     console.log("The rule is fullfilled:", parse(rule, answers))
  // }

  parse(rule, answers) {
    try {
      for (let [key, value] of new Map(Object.entries(answers))) {
        rule = rule.replace("q" + key.toString(), JSON.stringify(value));
      }
      return eval(rule);
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  parseComment(comment) {
    let rows = [];
    let answer;
    rows.push("F: " + comment.replace(/.*##-(.*)-##(.*)/, "$1"));
    do {
      comment = RegExp.$2;
      answer = comment.replace(/.*?\$\$-(.*?)-\$\$(.*)/, "$1");
      if (answer !== "") {
        rows.push(" S: " + answer);
      }
    } while (answer !== "");
    return rows;
  },

  moments() {
    const moments = [
      {
        text: "Relation med vårdtagare",
        value: "relation",
        group_val: "Allmänt",
        type: "hsh-relation",
      },
      {
        text: "Anhörig vårdtagare förnamn",
        value: "ct_first_name",
        group_val: "Allmänt",
        type: "hsh-input-text",
      },
      {
        text: "Anhörig vårdtagare efternamn",
        value: "ct_last_name",
        group_val: "Allmänt",
        type: "hsh-input-text",
      },
      {
        text: "Toalettbesök",
        value: "toiletVisit",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Dusch/bad",
        value: "shower",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "På- och avklädning",
        value: "dressing",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Måltidshjälp",
        value: "mealAssistance",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Tillsyn och trygghetsringning",
        value: "supervision",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Ledsagning",
        value: "accompaniment",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Födelseår",
        value: "ct_born",
        group_val: "Allmänt",
        type: "hsh-input-text",
      },
      {
        text: "Beviljade timmar per månad",
        value: "ct_hours",
        group_val: "Allmänt",
        type: "hsh-input-text",
      },
      {
        text: "Inköp",
        value: "purchase",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Tvätt",
        value: "laundry",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Städning",
        value: "cleaning",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Trygghetslarm",
        value: "securityAlarm",
        group_val: "Hjälpinsatser",
        type: "hsh-checkbox",
      },
      {
        text: "Ser dåligt",
        value: "seeBadly",
        group_val: "Sinnen & förmågor",
        type: "hsh-checkbox",
      },
      {
        text: "Hör dåligt",
        value: "hearBadly",
        group_val: "Sinnen & förmågor",
        type: "hsh-checkbox",
      },
      {
        text: "Svårt att hänga med när man pratar",
        value: "demented",
        group_val: "Sinnen & förmågor",
        type: "hsh-checkbox",
      },
      {
        text: "Jag använder rullator inomhus",
        value: "walkerHome",
        group_val: "Sinnen & förmågor",
        type: "hsh-checkbox",
      },
      {
        text: "Jag använder rullator utomhus",
        value: "walkerOut",
        group_val: "Sinnen & förmågor",
        type: "hsh-checkbox",
      },
    ];
    return moments;
  },
  relations() {
    const relation = [
      { text: "Anhörig", value: "relative" },
      { text: "Vårdtagare", value: "careTaker" },
      { text: "Vårdgivare", value: "employee" },
    ];
    return relation;
  },
  async hshFetchFilter() {
    console.log("Filter: fetchFilter  ");
    try {
      const res = await hshApi.getFilters(
        this.$keycloak.tokenParsed.organisation
      );
      return res;
    } catch (error) {
      await this.$dialog.error({
        text: `Filtervärden gick inte att hämta (${error.message} - ${error.response.data})`,
        title: `Varning!`,
        actions: {
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
    }
  },
  hshCheckInput(value, gui_type, interval) {
    const typeItems = [
      "string",
      "integer",
      "boolean",
      "decimal",
      "datetime",
      "date",
      "time",
      "limitedInteger",
      "dropdown",
      "multiselect",
    ];
    console.log("hshCheckInput", value, "=>", gui_type, "=>", interval);
    if (!typeItems.includes(gui_type)) {
      console.error("Invalid type provided");
      return;
    }

    let regex;
    switch (gui_type) {
      case "string":
      case "dropdown":
      case "multiselect":
        regex = /^[^&|;:]*$/;
        break;
      case "integer":
        regex = /^[-+]?\d*$/;
        break;
      case "boolean":
        regex = /^(true|false)$/;
        break;
      case "decimal":
        regex = /^[-+]?\d*\.\d+|\d+\.\d*$/;
        break;
      case "datetime":
        if (value == "") {
          return true;
        }
        regex =
          /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)?$/;
        break;
      case "date":
        if (value == "") {
          return true;
        }
        regex = /^\d{4}-[01]\d-[0-3]\d$/;
        if (regex.test(value)) {
          console.log("Will check ",value)
          const momentDate = this.$moment(value, "YYYY-MM-DD", true);
          if (momentDate.isValid()) {
            const dateAfter = moment("1900-01-01");
            if (momentDate.isAfter(dateAfter)) {
              return true;
            }
          }
        }
        return false;
      case "time":
        if (value == "") {
          return true;
        }
        regex = /^[0-2]\d:[0-5]\d:[0-5]\d$/;
        break;
      case "limitedInteger":
        if (value == "") {
          return true;
        }
        regex = /^[-+]?\d*$/;
        if (regex.test(value)) {
          if (
            value >= parseInt(interval[0]) &&
            value <= parseInt(interval[1])
          ) {
            return true;
          }
        }
        return false;

      default:
        console.error("Invalid gui_type provided");
        return;
    }

    return regex.test(value);
  },
};

// str="##-Om svaren på frågan 'Vilket område anser du är viktigast att förbättra?' -## är:-$$-Ett svar-$$ $$-Ett svar2 -$$"
// rows=parseComment(str)
// rows.forEach((r) =>
//   console.log(r)
// )

// function createSilentAudio (time, freq = 44100){
//   const length = time * freq;
//   const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
//   if(! AudioContext ){
//     console.log("No Audio Context")
//   }
//   const context = new AudioContext();
//   const audioFile = context.createBuffer(1, length, freq);
//   return URL.createObjectURL(bufferToWave(audioFile, length));
// }

// function bufferToWave(abuffer, len) {
//   let numOfChan = abuffer.numberOfChannels,
//     length = len * numOfChan * 2 + 44,
//     buffer = new ArrayBuffer(length),
//     view = new DataView(buffer),
//     channels = [], i, sample,
//     offset = 0,
//     pos = 0;

//   // write WAVE header
//   setUint32(0x46464952);
//   setUint32(length - 8);
//   setUint32(0x45564157);

//   setUint32(0x20746d66);
//   setUint32(16);
//   setUint16(1);
//   setUint16(numOfChan);
//   setUint32(abuffer.sampleRate);
//   setUint32(abuffer.sampleRate * 2 * numOfChan);
//   setUint16(numOfChan * 2);
//   setUint16(16);

//   setUint32(0x61746164);
//   setUint32(length - pos - 4);

//   // write interleaved data
//   for(i = 0; i < abuffer.numberOfChannels; i++)
//     channels.push(abuffer.getChannelData(i));

//   while(pos < length) {
//     for(i = 0; i < numOfChan; i++) {             // interleave channels
//       sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
//       sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
//       view.setInt16(pos, sample, true);          // write 16-bit sample
//       pos += 2;
//     }
//     offset++                                     // next source sample
//   }

//   // create Blob
//   return new Blob([buffer], {type: "audio/wav"});

//   function setUint16(data) {
//     view.setUint16(pos, data, true);
//     pos += 2;
//   }

//   function setUint32(data) {
//     view.setUint32(pos, data, true);
//     pos += 4;
//   }
// }

export default hsh_methods;
