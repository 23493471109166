import axios from "axios";

const apiClient = axios.create({
  baseURL: "http://localhost:3000",
  // baseURL: "http://192.168.10.139:3000",
  // baseURL: "http://172.20.10.9:3000",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// =================================================
// Surveys
// =================================================
const SurveyService = {
  async getTestData() {    
    const response = await apiClient.get("/testdata");
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async getSurveys() {
    const response = await apiClient.get("/surveys");
    return response.data;
  },
  async createSurvey(survey) {
    delete survey.id;
    const response = await apiClient.post("/surveys", survey);
    return response.data;
  },
  async updateSurvey(survey) {
    const response = await apiClient.put(`/surveys/${survey.id}`, survey);
    return response.data;
  },
  async getSurveyReport(surveyId) {
    // const response = await apiClient.get(`/survey_report/${surveyId}`);
    const response = await apiClient.get(`/survey_report`);
    console.warn(`getSurveyReport Asks not for survey ${surveyId}`,response)
    return response.data;
  },
  
  // =================================================
  // Forms => Destructure the form 
  // https://stackoverflow.com/questions/17781472/how-to-get-a-subset-of-a-javascript-objects-properties
  // =================================================
  async getForms(belongToSurveyId) {
    const response = await apiClient.get(`/forms?servey=${belongToSurveyId}`);
    console.log("SurveyService:");
    console.log(
      `SurveyService: Fetch forms to survey with Id ${belongToSurveyId}` +
        response.data
    );
    console.log(response);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async createForm(form) {
    delete form.id;
    const response = await apiClient.post("/forms", form);
    return response.data;
  },
  async updateForm(form) {
    const response = await apiClient.put(`/forms/${form.id}`, form);
    return response.data;
  },

  // =================================================
  // Questions
  // =================================================

  async getQuestions() {
    const response = await apiClient.get(`/questions`);
    console.log(`SurveyService: Fetch questions  ` + response.data);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async createQuestion(quest) {
    delete quest.id;
    const response = await apiClient.post("/quests", quest);
    return response.data;
  },
  async updateQuestion(quest) {
    const response = await apiClient.put(`/quests/${quest.id}`, quest);
    return response.data;
  },
};

export default SurveyService;
