<template>
  <DialogCard :title="title" :actions="actions" v-if="questions.length > 1">
    <v-card height="600px">
      <v-card-title>
        <!-- {{question.question.i18n.sv.text}} -->
        Välj fråga och svar nedan!
      </v-card-title>
      <v-card-text>
        <v-container>
             <v-col cols="12">
               <v-text-field v-model="filterName" label="Filternamn:"></v-text-field>
            </v-col>
            <v-switch v-model="isSwitchOn" :label="SwitchLabel"></v-switch>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="questionChoosen"
                :items="questions"
                label="Villkorsfråga"
                required
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="answersChoosen"
                :items="answers"
                attach
                chips
                required
                label="Vilka svar skall krävas för att uppfylla filtret?"
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </DialogCard>
</template>

<script>
export default {
  props: ["title", "$store", "question"],

  data() {
    return {
      topic_name: "",
      answersChoosen: [],
      questionChoosen: {},
      questionsArray: [],
      questions: [],
      isSwitchOn: true,
      filterName: "",

      //   queryTopics:['adam','bertil']
    };
  },
  created() {
    console.log("EditFilterQuestionRule Created", this, this.questions);
  },
  async mounted() {
    await this.$store.dispatch("fetchQuestions");
    this.questionsArray = this.$store.getters.getQuestions;
    this.questions = this.questionsArray.map((fq) => ({
      text: this.$_.get(fq, "i18n.sv.text", "error").trim(),
      value: this.$_.get(fq, "id", 0),
    }));
    this.questions.sort((a, b) => {
      if (a["text"] > b["text"]) {
        return 1;
      }
      if (a["text"] < b["text"]) {
        return -1;
      }
      return 0;
    });
    console.log(
      "EditFilterQuestionRule Mounted",
      this,
      this.questions,
      this.questionsArray
    );
  },
  computed: {
    SwitchLabel() {
        if (this.isSwitchOn) {
            return "Filtrera ut alla som har svarat enligt nedan på frågan"
        } else {
            return "Filtrera ut alla som INTE har svarat enligt nedan på frågan"
        }
    },
    // questions() {
    //   const filteredQuest = this.form.questions.filter((fq) => {
    //     if (fq.question.id === this.question.question.id) return false
    //     return true
    //   })
    //   console.log("EditFilterQuestionRule questions",this.form,filteredQuest)

    //   const quest = filteredQuest.map((fq) => ({
    //     text: this.$_.get(fq,"question.i18n.sv.text","error"),
    //     value:   this.$_.get(fq,"question.id",0)
    //   }))
    //   console.log("EditFilterQuestionRule questions",quest)
    //   return quest
    // },
    answers() {
      const question = this.questionsArray.find(
        (q) => q.id == this.questionChoosen
      );
      console.log("EditFilterQuestionRule answers A", question, this.questionChoosen);
      if (question !== undefined) {
        const answ = question.answers;
        const ansArr = answ.map((a) => ({
          text: this.$_.get(a, "i18n.sv.text", "error"),
          value: a.value,
        }));
        return ansArr;
      }
      return [];
    },
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        delete: {
          text: "Ta bort",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            const index = this.form.questions.findIndex(
              (q) => q.question.id == this.question.question.id
            );
            const ruleIndex = this.form.questions.findIndex(
              (q) => q.question.id == this.questionChoosen
            );
            if (ruleIndex > index) {
              // Huups warn confirm
              this.$dialog.notify.error(
                "Fel ordning på frågorna. Villkorsfrågan besvaras just nu efter hanterad fråga",
                {
                  position: "top-left",
                  timeout: 5000,
                }
              );
            }
            console.log(
              "EditFilterQuestionRule returns",
              this.questions,
              this.answersChoosen,
              this.questionChoosen,
              this.form.questions[index]
            );
            let expression = "";
            let comment = "";
            const answers = this.answers;
            if (this.answersChoosen.length > 1) {
              this.answersChoosen.forEach((a) => {
                // Create expression with comments like
                // q30.includes(200) && q30.includes(203) && !q30.includes(199) // ##- <question> -## [$$- <answer> -$$,...]
                if (expression == "") {
                  expression = `q${this.questionChoosen}.includes(${a})`;
                  const quest = this.questions.find(
                    (q) => q.value == this.questionChoosen
                  );
                  comment = `##-${quest.text}-## $$-${
                    answers.find((a2) => a2.value === a).text
                  }-$$ `;
                } else {
                  expression += ` || q${this.questionChoosen}.includes(${a})`;
                  comment += "$$-" + answers.find((a2) => a2.value === a).text + "-$$";
                }
              });
            } else {
              const quest = this.questions.find((q) => q.value == this.questionChoosen);
              comment = `##-'${quest.text}'-## $$-${
                answers.find((a2) => a2.value === this.answersChoosen[0]).text
              }-$$`;
              expression = `q${this.questionChoosen}.includes(${this.answersChoosen[0]})`;
            }
            expression += " // " + comment;
            return expression; // this.form;
          },
        },
      };
    },
  },
};
</script>
