<template>
  <DialogCard title="Kategori" :actions="actions">
    <v-text-field label="Kategori" icon="mdi-shape" v-model="category"/>
    <v-text-field label="värde/värden" icon="lock" v-model="value"/>
  </DialogCard>
</template>
<script>
export default {
  // overlay: 'default',
  //   asyncData () {
  //         return new Promise(resolve => {
  //           setTimeout(resolve, 3000)
  //         })
  // },
  data () {
    return {
      category: '',
      value: ''
    }
  },
  computed: {
    actions () {
      return {
        category: {
          flat: true,
          text: 'OK',
         handle: () => {
             return {category:this.category,value:this.value}
        //    return new Promise(resolve => {
        //     setTimeout(resolve, 13000)
        //     })
          }
        }
      }
    }
  }
}
</script>
