<template>
  <DialogCard :title="title" :actions="actions"  >
       <v-card height="600px">
        <v-card-title>
          {{question.question.i18n.sv.text}}
        </v-card-title>
        <v-card-text >
          <v-container >
            <v-row>
              <v-col cols="12">
                <v-select
            v-model="questionChoosen"
            :items="questions"
            
            label="Villkorsfråga"
            required
          ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="answersChoosen"
                  :items="answers"
                  attach
                  chips
                  required
                  label="Vilka svar skall krävas för att frågan skall aktiveras?"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        </v-card>
  </DialogCard>
</template>



<script>
export default {
  props:['form','title','$store','question'],
 
  data() {
    return {
      topic_name: "",
      answersChoosen:[],
      questionChoosen:{}

    //   queryTopics:['adam','bertil']
    };
  },
  created() {
      console.log("EditForm",this, this.form)
  },
  computed: {
    questions() {
      const filteredQuest = this.form.questions.filter((fq) => {
        if (fq.question.id === this.question.question.id) return false
        return true
      })
      console.log("EditFormRule questions",this.form,filteredQuest)

      const quest = filteredQuest.map((fq) => ({
        text: this.$_.get(fq,"question.i18n.sv.text","error"),
        value:   this.$_.get(fq,"question.id",0)
      }))
      console.log("EditFormRule questions",quest)
      return quest
    },
    answers() {
      const question = this.form.questions.find((q) => q.question.id==this.questionChoosen)
      console.log("EditFormRule answers A",question,this.questionChoosen)
      if (question !== undefined) {
        const answ = question.question.answers
        const ansArr = answ.map((a) => ({
          text:this.$_.get(a,"i18n.sv.text","error"),
          value: a.value
        }))
        return ansArr
      }
      return [];
    },
   actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        delete: {
          text: "Ta bort",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            const index=this.form.questions.findIndex((q) => q.question.id==this.question.question.id)
            const ruleIndex=this.form.questions.findIndex((q) => q.question.id==this.questionChoosen)
            if (ruleIndex > index) {
              // Huups warn confirm
               this.$dialog.notify.error("Fel ordning på frågorna. Villkorsfrågan besvaras just nu efter hanterad fråga", {
              position: "top-left",
              timeout: 5000,
            })}
            console.log("EditFormRule returns",this.questions,this.answersChoosen,this.questionChoosen,this.form.questions[index])
            let expression=""
            let comment = ""
            const answers=this.answers
            if (this.answersChoosen.length>1) {
              this.answersChoosen.forEach((a) => {
                // Create expression with comments like
                // q30.includes(200) && q30.includes(203) && !q30.includes(199) // ##- <question> -## [$$- <answer> -$$,...]
                if (expression == "") {
                  expression = `q${this.questionChoosen}.includes(${a})`
                  const quest = this.questions.find((q) => q.value==this.questionChoosen)
                  comment = `##-${quest.text}-## $$-${answers.find((a2)=> a2.value===a ).text}-$$ `
                } else {
                  expression += ` || q${this.questionChoosen}.includes(${a})`
                  comment += '$$-'+ answers.find((a2)=> a2.value===a ).text + '-$$'
                }
              })
            } else {
              const quest = this.questions.find((q) => q.value==this.questionChoosen)
              comment = `##-'${quest.text}'-## $$-${answers.find((a2)=> a2.value===this.answersChoosen[0] ).text}-$$`
              expression = `q${this.questionChoosen}.includes(${this.answersChoosen[0]})`
            }
            expression += ' // ' + comment 
            return expression // this.form;   
          },
        },
      };
    },
  }
}
</script>
