<template>
  <v-app>
    <v-card>
      <v-layout fill-height justify-space-between>
     <!-- <v-hover v-slot="{ hover }"> -->
      <v-navigation-drawer
        :color="color"
        dark
        permanent
        rail
        :width="$store.state.withOfDrawer"
        height="100vh"
      >
      <!-- expand-on-hover 
        v-model="drawer"
        :mini-variant="!hover || navigationDrawerMiniVariantAlways"
        :clipped="true" -->
        <v-list>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.route"
            router
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      
         <v-list-item>
         <v-icon  v-if="$store.state.withOfDrawer===null" withjustify-end @click="ToggleDrawerWith">keyboard_double_arrow_left</v-icon>
         <v-icon  v-else withjustify-end @click="ToggleDrawerWith">keyboard_double_arrow_right</v-icon>
         </v-list-item>
      </v-navigation-drawer>
    <!-- </v-hover> -->
    <!-- <v-main style="border: 1px solid red;"> -->
      <v-container 
         id="surveyArea"
        fluid 
        class="ma-0" 
        style="max-width: 100 vW;">
        <router-view />
      </v-container>
    <!-- </v-main> -->
      </v-layout>
    </v-card>
  </v-app>
</template>

<script>
// const apiUrl = "https://api.homesvithome.se/v1.0/forms";
// import axios from "axios";


export default {
  name: "SurveyArea",
  // props: ["keycloak"],
  async created() {
    // console.log(this.keycloak.tokenParsed);
    // this.$store.commit("setKeycloakToken", this.$keycloak.tokenParsed.name);
    this.$store.state.mainVueInstance=this
          // Why fetch twice? I think it's not neccessary
          // console.log("fetch base media",this.$keycloak);
          // this.$store.dispatch('getTopics')
          // await this.$store.dispatch('getMediaList');

          // console.log("fetch media",this.$store.state.medialist);
          // this.$store.state.medialist.forEach( (item) => {
          //   if (item.type=="image") {
          //     this.$store.dispatch('getImgMedia',item.id)
          //   } else if (item.type=="audio") {
          //     this.$store.dispatch('getAudioMedia',item.id)
          //   }
          // })
          // this.$store.dispatch("fetchForms");    
          // this.$store.dispatch("fetchResponders");
          // this.$store.dispatch("getTerminals");
    // As a start poll messages to se if someone require action
  },
  data() {
    return {
      form_names: "Loading...",
      token_expiry: "Loading...",
      color: "blue darken-4",
      drawer: true,
      right: true,
      rightDrawer: false,
      // title: "Home SvIT Home Admin App",
      items: [
        { title: "Startsidan", icon: "mdi-home", route: "/" },
        { title: "Respondenter", icon: "mdi-account", route: "/responders" },
        // { title: "Urvalsgrupper", icon: "mdi-account-multiple", route: "/selections" },
        { title: "Undersökningar", icon: "mdi-folder", route: "/surveys" },
        { title: "Formulär", icon: "mdi-form-select", route: "/forms" },
        { title: "Frågor", icon: "mdi-pencil", route: "/questions" },
        { title: "Filter", icon: "mdi-filter", route: "/filter" },
        { title: "Kategorier & Filtervariabler", icon: "mdi-shape", route: "/categories" },
        { title: "Starta undersökning", icon: "mdi-run-fast", route: "/SurveysInstances" },
        { title: "Rapporter", icon: "mdi-poll", route: "/reports" },
        // { title: "Login", icon: "mdi-flag", route: "/login" },
      ],
     
    };
  },
  computed: {
    title() {
      return "Admin - " + this.$route.name ? this.$route.name : "";
    },
    navigationDrawerMiniVariantAlways() {
      return this.$store.state.navigationDrawerMiniVariantAlways;
    },
  },
  methods: {
    // Calculate if there is any messages that require action
    Logout() {
      this.$keycloak.logout({ redirectUri: window.location.toString() })
    },
    ToggleDrawerWith() {
      if (this.$store.state.withOfDrawer===null) {
        this.$store.state.withOfDrawer=55
      } else {
        this.$store.state.withOfDrawer=null
      }
    }
  },
};
</script>
<style>
@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 100vW !important; /* your new value for max-width */
  }
}
</style>