<template>
  <div v-if="loadingStatus">
    <div v-if="isThereACurrentSurvey">
      <v-form v-model="dialog" max-width="90%">
        <v-card>
          <v-card-title>
            Formulärhantering för undersökning "{{ surveyName }}"
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4" sm="6" md="4">
                  <v-text-field v-model="surveyName" label="Namn"> </v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-card class="elevation-10 pa-4">
                    <h3 class="mb-5">Ingående Formulär</h3>
                    <v-divider class="mb-5"></v-divider>
                    <SurveyFormsTable
                      :survey="currentSurvey"
                      :key="rerenderTrigger"
                      ref="formsList"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-col cols="4"> </v-col>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Avbryt </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="!possibleToSave">
              Spara
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import SurveyFormsTable from "./SurveyFormsTable.vue";

export default {
  components: {
    SurveyFormsTable,
  },
  name: "SurveyForm",
  props: {},

  data() {
    return {
      dialog: true,
      useSelction: false,
      editedItem: {},
      surveyName: "",
      rerenderTrigger: 0,
      startDate: "",
      endDate: "",
      possibleToSave: false,
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
        format: "24hr",
      },
      dateTimeTextFieldProps: {},
    };
  },

  computed: {
    currentSurvey() {
      console.log("SurveysForm: currentSurvey()", this.$store.getters.getCurrentSurvey);
      return this.$store.getters.getCurrentSurvey;
    },

    isThereACurrentSurvey() {
      if (this.$store.state.currentSurveyId > 0) {
        return true;
      }
      return false;
    },

    currentForm() {
      console.log("SurveysForm: currentForm()");
      return this.$store.getters.getCurrentForm;
    },

    loadingStatus() {
      console.log(
        `SurveysForm: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },

  watch: {
    surveyName: function (val) {
      if (val != this.editedItem.name) {
        this.possibleToSave = true;
      }
      this.editedItem.name = val;
    },
  },

  methods: {
    show() {
      this.dialog = true;
    },

    close() {
      console.log("Nu stänger vi SurveyForm");
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(
          JSON.stringify(this.$store.getters.getCurrentSurvey)
        );
      });
      this.$store.dispatch("setCurrentSurveyId", -1);
      this.surveyTableClicked(null);
      this.$store.dispatch("fetchSurveys");
    },

    async save() {
      const selectedForms = this.$refs.formsList.choosenForms();
      this.editedItem.forms = [];
      selectedForms.forEach((f) => {
        this.editedItem.forms.push({ form_id: f.id, days_offset: parseInt(f.days_offset) });
      });
      if (this.editedItem.forms.length>0){
        this.editedItem.forms[0].days_offset=0 // first offset must be 0
      }
      console.log("save-f", this.editedItem, selectedForms);
      await this.$store.dispatch("saveSurvey", this.editedItem);
      this.$store.state.applStore.hasUnsavedChanges = false;
      this.surveyTableClicked(this.editedItem);
      this.$store.dispatch("fetchSurveys");
    },

    async deleteSelection(index) {
      this.editedItem.selections.splice(index, 1);
      await this.$store.dispatch("updateSurvey", this.editedItem);
    },
    async surveyTableClicked(item) {
      if (item == null) {
        await this.$store.dispatch("setCurrentSurveyId", -1);
        return;
      }
      console.log("SurveyForm: Dialog received:" + item.name);
      this.editedItem = JSON.parse(JSON.stringify(this.$store.getters.getCurrentSurvey));
      this.surveyName = this.editedItem.name;
      this.possibleToSave = false;
      this.rerenderTrigger += 1;
      console.log(
        "SurveyForm: After fetchForm:" +
          item.name +
          " (" +
          this.editedItem.id +
          ") now have nrofforms=" +
          this.$store.getters.getForms.length,
        this.rerenderTrigger
      );
    }    
  },

  mounted() {
    console.log("SurveyForm: clear all surveyformtable-close handlers");
    this.$root.$off("surveyformtable-close");
    this.$root.$on("surveyformtable-close", () => {
      if (this.$store.state.applStore.hasUnsavedChanges) {
        this.possibleToSave = true;
      }
    });
    if (this.$store.state.currentSurveyId > 0) {
      this.surveyTableClicked(this.$store.getters.getCurrentSurvey)
    } else {
      this.surveyName = "Inget valt ännu...";  // Shall not shows at all in fact
    }
    console.log("SurveyForm mounted...",this.$store.state.currentSurveyId);
  },
};
</script>
<style scoped></style>
