<template>
  <DialogCard :title="startCategory" :actions="actions">
    <v-text-field label="Kategori" v-model="topic_name" />
  </DialogCard>
</template>
<script>
export default {
    props:['topic'],
  // overlay: 'default',
  //   asyncData () {
  //         return new Promise(resolve => {
  //           setTimeout(resolve, 3000)
  //         })
  // },
  data() {
    return {
      topic_name: "",
      startCategory:''
    };
  },
  created() {
      console.log("EditTopic",this, this.topic)
      this.topic_name= this.topic.topic_name
      this.startCategory = 'Ändra kategori ' + this.topic.topic_name
  },
  computed: {
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            return {
              topic_name: this.topic_name,
            };
          },
        },
      };
    },
  },
};
</script>
