var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      [
        _vm.triggResponderTable > 0
          ? _c(
              "div",
              [
                _c("v-data-table", {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.responderTable(),
                    "single-select": _vm.singleSelect,
                    "item-key": "id",
                    triggResponderTable: _vm.triggResponderTable,
                    search: _vm.search,
                    "footer-props": {
                      showFirstLastPage: true,
                      "items-per-page-text": "Rader per sida",
                      "items-per-page-all-text": "Alla"
                    }
                  },
                  on: { "click:row": _vm.rowClicked },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteItem(item)
                                  }
                                }
                              },
                              [_vm._v(" mdi-delete ")]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "grey",
                                                    dark: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.updateJwt(item)
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-update ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "background-color": "red",
                                      color: "white"
                                    }
                                  },
                                  [_vm._v("Uppdatera id-nyckeln")]
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.jwt",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "grey",
                                                    dark: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.copyJwt(item)
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-content-copy ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Kopiera långa nyckeln")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { right: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "grey",
                                                    dark: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.copyHash(item)
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-content-copy ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Kopiera korta nyckeln")])]
                            )
                          ]
                        }
                      },
                      {
                        key: "footer.prepend",
                        fn: function() {
                          return [
                            _c("v-spacer"),
                            _c("v-text-field", {
                              staticClass: "mb-5",
                              attrs: {
                                "append-icon": "mdi-magnify",
                                label: "Sök",
                                "single-line": "",
                                "hide-details": ""
                              },
                              on: { keyup: _vm.clearOnEscape },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            }),
                            _c("v-spacer")
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2544528415
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "v-btn",
          {
            staticClass: "mt-4",
            attrs: { color: "primary", dark: "" },
            on: { click: _vm.addNewResponder }
          },
          [_vm._v(" lägg till Respondent ")]
        ),
        _c(
          "v-dialog",
          {
            attrs: { "max-width": "80%", persistent: "" },
            model: {
              value: _vm.dialogHandleResponder,
              callback: function($$v) {
                _vm.dialogHandleResponder = $$v
              },
              expression: "dialogHandleResponder"
            }
          },
          [
            _c("ResonderForm", {
              attrs: { responder: _vm.currentResponder },
              on: { "form-ready": _vm.closeResponderForm }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-4" }, [
      _c("h3", [_vm._v("Respondenter")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }