<template>
  <div v-if="ready">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="surveys"
      class="elevation-1 whitespace"
      :search="startSearch"
      dense
      :items-per-page="5"
      :options.sync="options"
      :loading="loading"
      :triggSurveyTable="triggSurveyTable"
      loading-text="Laddar..."
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Rader per sida',
        'items-per-page-all-text': 'Alla',
      }"
      @click:row="handleRowClick"
    >
      <template v-slot:item.actions="{ item }">
        <div v-if="showNewButton">
          <v-icon v-if="item.locked" small>mdi-sort-variant-lock</v-icon>
          <v-icon v-if="!item.locked" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:top>
        <v-toolbar flat style="display: none">
          <v-toolbar-title></v-toolbar-title>

          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >Vill du verkligen ta bort denna undersökning?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Avbryt</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Create new Survey -->
          <v-dialog v-model="dialogCreateNew" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Ny undersökning
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">Ny undersökning?</v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.name"
                      label="Namn"
                      required
                      ref="dialogCreateNewName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="form.queryTopic"
                      :items="queryTopics"
                      label="Kategori"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogCreateNew = false"
                  >Avbryt</v-btn
                >
                <v-btn color="blue darken-1" text @click="CreateNewConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:footer.prepend>
        <v-btn
          v-if="showNewButton"
          color="primary"
          dark
          class="mb-2"
          v-on:click="dialogCreateNewfunction"
        >
          Ny undersökning
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Sök undersökningsnamn"
          single-line
          hide-details
          class="mb-5"
          v-on:keyup="clearOnEscape"
        ></v-text-field>
        <v-spacer></v-spacer>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    showNewButton: {
      type: Boolean,
      default: true,
    },

    emitMessage: {
      type: String,
      default: "surveytable-survey-clicked-from-default-message",
    },

    startSearch: {
      type: String,
      default: "",
    },
  },

  name: "SurveysTable",

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      dialogCreateNew: false,
      ready: false,
      selected: [],
      options: {
        itemsPerPage: 5,
      },
      form: {
        name: "",
      },
      headers: [
        {
          text: "Id",
          value: "id",
          sortable: true,
          align: "start",
        },
        {
          text: "Undersökningsnamn",
          sortable: true,
          value: "name",
        },
        { text: "Antal formulär", value: "forms.length" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      triggSurveyTable: 0,
      editedIndex: -1,
      editedItem: {
        name: "",
        startDate: "-",
        endDate: 0,
        nrOfForms: 0,
        answerRate: 0,
        selection: "Alla",
        locked: false,
      },
      defaultItem: {
        name: "",
        startDate: "-",
        endDate: 0,
        nrOfForms: 0,
        answerRate: 0,
        selection: "Alla",
        locked: false,
      },
    };
  },

  beforeMount() {
    console.log("showNewButton", this.showNewButton);
    this.$root.$on(this.emitMessage, async (item) => {
      console.log("Received event ",this.emitMessage,item)
    });
    console.log(`SurveyTable eventhandler ${this.emitMessage} is setuped...`);
    this.selected = [];
  },

  async mounted() {
    const res = await this.$store.getters.getSurveys;
    console.log(
      "SurveyTable mounted got ",
      res,
      this.$store.state.currentSurveyId,
      this.startSearch
    );
    this.ready = true;
    this.search = this.startSearch;
    this.sortByDesc("id");
  },

  watch: {
    startSearch: function () {
      this.search = this.startSearch;
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Ny undersökning"
        : this.surveys[this.editedIndex].name;
    },

    surveys() {
      if (this.$store.state.currentSurveyId > 0) {
        const index = this.$store.state.surveys.findIndex(
          (s) => s.id == this.$store.state.currentSurveyId
        );
        if (index >= 0) {
          console.log(
            "Surveys computed... ",
            this.$store.state.surveys[index].forms.length,
            this.$store.state.surveys[index]
          );
        }
      }
      return this.$store.state.surveys;
    },

    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
  },

  methods: {
    sortByDesc(column) {
      this.surveys.sort((a, b) => {
        if (a[column] > b[column]) {
          return -1;
        }
        if (a[column] < b[column]) {
          return 1;
        }
        return 0;
      });
    },
    clearOnEscape(event) {
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    selectItem(item) {
      if (item == null) {
        this.selected = [];
      } else {
        this.selected = [];
        this.selected.push(item);
      }
    },
    async handleRowClick(item) {
      console.log("SurveyTable: You clicked " + item.name + " emit=>", this.emitMessage);
      await this.$store.dispatch("setCurrentSurveyId", item.id);
      this.$emit("selectSurvey", item);
      this.selectItem(item)
      this.focusCurrentFormId();
      this.triggSurveyTable += 1;
    },

    editItem(item) {
      this.editedIndex = this.surveys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log("Emit show");
    },

    deleteItem(item) {
      this.editedIndex = this.surveys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("deleteSurvey", this.editedItem);
      this.selected = [];
      this.$emit("selectSurvey", null);
      this.closeDelete();
      //await this.$store.dispatch("setCurrentSurveyId", -1);
      this.selected = [];
    },
    dialogCreateNewfunction() {
      this.dialogCreateNew = true;
      this.$nextTick(() => {
        const theElement = this.$refs.dialogCreateNewName.$el;
        const input = theElement.querySelector(
          "input:not([type=hidden]),textarea:not([type=hidden])"
        );
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 0);
        }
      });
    },
    async CreateNewConfirm() {
      console.log("CreateNewConfirm()");
      console.log("------------------");
      this.dialogCreateNew = false;
      this.form.id = -1;
      this.form.forms = [];
      console.log("   The Form Data:", this.form);
      await this.$store.dispatch("saveSurvey", this.form);
      this.sortByDesc("id");
      this.selected = [];
      const item = this.surveys[0]; // first item in list just sorted
      this.selected.push(item);
      await this.$store.dispatch("setCurrentSurveyId", item.id);
      this.$emit("selectSurvey", item);
      console.log("--(Done)----------", this.surveys);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.surveys[this.editedIndex], this.editedItem);
      } else {
        this.surveys.push(this.editedItem);
      }
      this.close();
    },
    focusCurrentFormId() {
      console.log("focusCurrentFormId() called");
      const item = this.selected[0];
      if (item != null) {
        this.focusFilterForm(item);
      }
    },
    focusFilterForm(item) {
      console.log("focusFilterForm", item);
      this.$root.$emit("filtertable-form-clicked", item);
      this.selected = [];
      this.selected.push(item);
      // this.jumpToSelection();
    },
    jumpToSelection: function () {
      console.log("jumpToSelection", this.options.itemsPerPage);
      if (this.options.itemsPerPage === -1) return; // All is choosen
      this.$nextTick(() => {
        let selected = this.selected[0];
        if (selected === undefined) return;
        let page = Math.ceil(
          (this.surveys.findIndex((survey) => survey.id === selected.id) + 1) /
            this.options.itemsPerPage
        );
        console.log("jumpToSelection page 1st", page, selected);
        this.pagination.sortBy = "id";
        this.$nextTick(() => {
          this.pagination.page = page;
          console.log("jumpToSelection page 2nd", page);
        });
      });
    },
  },
};
</script>
<style scoped>
.whitespace {
  white-space: pre;
  text-align: right;
}
</style>
