<template>
  <DialogCard
    :title="'Ändra fråga (Språk: Svenska) ID:' + quest.id"
    :actions="actions"
  >
    <div class="shrink">Skapare:{{ token.name }}</div>
    <v-container>
    <h2>Frågan</h2>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn class="mr-10" @click="HshUploadFileActivate" color="primary">
          Ladda upp media filer...
        </v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" md="1">
          <hsh-color
            :color="quest.ui_hint.bgcolor"
            title="Frågefärg"
            @newColor="QuestColorChange"
          >
          </hsh-color>
        </v-col>
        <v-col cols="12" md="2">
          <HshImage v-model="questIcon" label="Frågeikon" />
        </v-col>
        <v-col cols="12" md="9">
          <v-text-field
            label="Frågan:"
            v-model="quest.i18n.sv.text"
            class="shrink"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="1">
          <v-icon
            v-if="isAudioPresent"
            x-large
            @click="playSound(quest.i18n.sv.media.audio.id)"
          >
            mdi-play-box-outline
          </v-icon>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Nuvarande fras text:"
            v-model="description"
            class="shrink"
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Nuvarande fras namn:"
            v-model="name"
            class="shrink"
            readonly
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-btn color="primary" class="mt-2" @click="HshPhrasePickerQuest"
            >Välj ny fras...</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            v-model="queryTopic"
            :items="queryTopics"
            label="Kategori"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="queryType"
            :items="queryTypes"
            label="Typ av fråga"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <h2>Svarsalternativ</h2>
      <br>
      <div v-for="(answer,index) in quest.answers" :key="index">
        <v-card
          elevation
        >
        <v-row >
        <v-col cols="12" md="10">
            <EditAnswer :answer="answer"></EditAnswer>
        </v-col>
        <v-col cols="12" md="2">

            <v-icon large @click="deleteAnswer(answer,index)" class="py-2">
              mdi-delete
            </v-icon>
        </v-col>
            
        </v-row>
        </v-card>
        <br>
          <v-divider></v-divider>
      </div>
      <v-row>
        <v-btn class="pa-4 mx-4" @click="CreateNewAnswer" color="primary">
          <v-icon
            left
            dark
          >
            mdi-plus-thick
          </v-icon>
          Lägg till svar...
        </v-btn>
      </v-row>
    </v-container>
  </DialogCard>

  <!-- </DialogCard> -->
</template>
<script>
// import { component } from 'vue/types/umd';

// import HshColorPicker from './HshColorPicker.vue'
import $_ from "lodash";
import HshColor from "./HshColor.vue";
import HshImage from "./HshImage.vue";
import HshUploadFile from "./HshUploadFile.vue";
import EditAnswer from "./EditAnswer.vue";
import PhrasePicker from "./HshPhrasePicker.vue";

export default {
  components: {
    HshColor,
    HshImage,
    EditAnswer,
  },
  props: ["question", "$store"],
  // overlay: 'default',
  //   asyncData () {
  //         return new Promise(resolve => {
  //           setTimeout(resolve, 3000)
  //         })
  // },
  data() {
    return {
      uwtext: "Uffe",
      types: ["hex"],
      type: "hex",
      hex: "#000000",
      quest: "",
      dialogEQ: true,
      queryTopic: "",
      // queryTopics: [],
      picker: {},
      showPicker: false,
      selectedPhrase: "",
      selectedPhrase2: {},
      description: "",
      name: "",
      rerender: 0,
      urlimg: [],
      imgUrl: {},
      questIcon: {},
      questIcon2: {},
      queryTypes: ["Ett svarsalternativ", "Multipla svar", "Skala 1-10","Skala 0-100","Ljudinspelning","Fri text"],
      queryTypeReal:['SingleNumeric','MultiNumeric','Scale1-10','Scale0-100','Record','FreeText'],
      queryType: "Ett svarsalternativ",
      newAnswerJSONString:
        '{"i18n":{"sv":{"media":{"audio":{"description":"Ja, alltid","hash":{"md5":"83220c9d9aaf42af156ca133d604d7ca"},"id":123,"lang":"sv","name":"Option_YesAlways.wav","topic_id":0,"topic_name":"","type":"audio"},"image":{"description":"Ledsen gubbe","hash":{"md5":"6393865f9a041d2448f2e7c84d5b880e"},"id":668,"lang":"sv","name":"sad.png","topic_id":163,"topic_name":"Allmänt","type":"image"}},"text":"Ja, alltid"}},"ui_hint":{"bgcolor":"#0BFF08"},"value":"4"}',
        // '{"i18n":{"sv":{"media":{"audio":{"description":"Trist och smaklös","hash":{"md5":"2b9f4cbd9b595376faff7998badbb6de"},"id":33,"lang":"sv","name":"q1.wav","topic_id":161,"topic_name":"Måltider","type":"audio"},"image":{"description":"Ledsen gubbe","hash":{"md5":"6393865f9a041d2448f2e7c84d5b880e"},"id":42,"lang":"sv","name":"sad.png","topic_id":163,"topic_name":"Allmänt","type":"image"}},"text":"Trist och smaklös"}},"ui_hint":{"bgcolor":"#cc0000"},"value":"-1"}',
    };
  },
  async created() {
    console.log("Question", this.title, this.question, this);
    console.log("created", this.$store);
    this.quest = this.question;
    // this.queryTopics = this.getTopics();
    this.queryTopic = {text:this.quest.topic_name,value:this.quest.topic_id};
    console.log("created queryTopic", this.queryTopics);
    this.hex = this.quest.ui_hint.bgcolor;
    const id = this.$_.get(this.quest, "i18n.sv.media.image.id", 0);
    if (id > 0) {
      this.imgUrl = await this.$store.getters.getImageById(id);
      this.questIcon = {
        id: this.quest.i18n.sv.media.image.id,
        url: this.urlimg[this.quest.i18n.sv.media.image.id],
      };
    }
    try {
      this.description = this.quest.i18n.sv.media.audio.description
      this.selectedPhrase = this.quest.i18n.sv.media.audio.description;
      this.name = this.quest.i18n.sv.media.audio.name
    } catch {
      this.selectedPhrase = "";
    }
    this.uwtext = this.quest.ui_hint.bgcolor;
    if (this.uwtext === undefined || this.uwtext === "") this.uwtext = "#777";
    console.log("Created Edit:", this.selectedPhrase, this.uwtext, this.quest);
  },
  watch: {
    name: function () {
      if ($_.get(this.quest,"i18n.sv.media.audio.name",null) != null) {
        this.quest.i18n.sv.media.audio.name = this.name
      }
    },
    description: function() {
      if ($_.get(this.quest,"i18n.sv.media.audio.description",null) != null) {
        this.quest.i18n.sv.media.audio.description = this.description
      }
    },
    uwtext: function () {
      console.log("uwtext", this.uwtext);
    },
    questIcon: function () {
      console.log("questIcon", this.questIcon);
      this.QuestImageChange(this.questIcon);
    },
    selectedPhrase2: function () {
      console.log(
        "selectedPhrase2",
        this.selectedPhrase2,
        this.quest.i18n.sv.media.audio
      );
      // this.changePhrase()
    },
    queryType: function() {
      const index = this.queryTypes.findIndex(x => x == this.queryType)
      if (index >= 0) {
        this.quest.format=this.queryTypeReal[index]
        console.log("queryType set to",this.quest.format)
      }
    },
    queryTopic: function () {
      const topic = this.queryTopics.find((x) => x.value == this.queryTopic)
      if (topic !== undefined) {
        this.quest.topic_name=topic.text
        this.quest.topic_id=topic.value
        console.log("queryTopic set to =>",topic,this.quest.topic_id,this.quest.topic_name,this.queryTopic)
      } else {
        console.log("queryTopic not found =>",this.quest.topic_id,this.quest.topic_name,this.queryTopic)
      }
    }
  },
  computed: {
    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
    color: {
      get() {
        console.log("color get");
        return this[this.type];
      },
      set(v) {
        console.log("color set", v);
        this[this.type] = v;
        this.quest.ui_hint.bgcolor = v;
      },
    },
    getMediaFiles() {
      const arr = this.$store.state.medialist.filter((m) => m.type == "audio");
      // const arr2 = arr.map((e) => ({text:e.description + " (" + e.name + ")", id:e.id}));
      const arr2 = arr.map((e) => ({ text: e.description, id: e.id }));
      arr2.sort((a, b) => (a.text > b.text ? 1 : -1));
      console.log("getMediaFiles", arr2);
      this.rerender;
      return arr2;
    },
    getImageFiles() {
      // const arr = this.$store.state.medialist.filter(
      //   (m) => m.type == 'image'
      // );
      const arr = this.$store.state.imgmedia_url;
      // console.log("getImageFiles",arr)
      return arr.sort((o1, o2) => o1.id - o2.id);
    },
    showColor() {
      console.log("color show");
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
    token() {
      // console.log('KeyCloak token', this.$keycloak)
      // return {token:{name:'Ulf Wilhelmsson'}}
      return this.$keycloak.tokenParsed;
    },
    isAudioPresent() {
      try {
        if ($_(this.quest,"i18n.sv.media.audio.id",0) > 0) {
          return true;
        }
      } catch {
        return false;
      }
      return false;
    },
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        saveAsNew: {
          text: "Spara som ny",
          color: "blue darken-1",
          handle: () => {
            console.log("actions",this.quest)
            this.quest.id=-1;
            return this.quest;
          },
        },
        ok: {
          text: "Uppdatera",
          color: "blue darken-1",
          handle: () => {
            return this.quest;
          },
        },
        
      };
    },
  },
  methods: {
    async deleteAnswer(answer, index) {
      console.log("deleteAnswer",answer,index)
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill ta bort svaret?`,
        title: `Ta bort svar`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        }
      })
      if (res === 'ok') {
        this.quest.answers.splice(index,1)
        this.rerender++
      }
    },
    async HshPhrasePickerQuest() {
      const oldPhrase = this.quest.i18n.sv.media.audio;
      const qCopy = JSON.parse(JSON.stringify(this.quest));
      const changePhrase = await this.$dialog.show(PhrasePicker, {
        waitForResult: true,
        title: this.title,
        i18nBase: qCopy,
        $store: this.$store,
        env: this,
        width:900
      }); // Show dialog
      if (changePhrase !== "cancel" && changePhrase !== undefined) {
        this.$emit("newPhrase", changePhrase);
        // this.$store.state.applStore.hasUnsavedChanges=true
        console.log("HshPhrasePickerQuest emit dialog res", changePhrase);
        this.quest.i18n.sv.media.audio = changePhrase;
        this.description = changePhrase.description
        this.name = changePhrase.name
      } else {
        this.$emit("newPhrase", oldPhrase);
        console.log("HshPhrasePickerQuest emit old res", oldPhrase);
      }
    },
    changePhrase(item) {
      // const phrase = this.allMediaFiles.find((m) => m.id== this.selectedPhrase2)
      const phrases = this.$store.state.medialist.filter(
        (m) => m.type == "audio"
      );
      if (phrases != undefined) {
        const phrase = phrases.find((m) => m.id == item.id);
        // const phrase = phrases[this.selectedPhrase2]
        console.log(
          "changePhrase",
          item,
          this.selectedPhrase2,
          this.quest.i18n.sv.media.audio,
          phrase
        );
        if (phrase != undefined) {
          this.quest.i18n.sv.media.audio = phrase;
        }
      }
      this.rerender++;
    },
    CreateNewAnswer() {
      let jsonString = this.$_.get(this.quest, "answers[0]", undefined);
      if (jsonString == undefined) {
        console.log("Create answer - no template",this.newAnswerJSONString)
        jsonString = this.newAnswerJSONString;
        this.quest.answers.push(JSON.parse(jsonString));
      } else {
        this.quest.answers.push(JSON.parse(JSON.stringify(jsonString)));
      }
      console.log(
        "CreateNewAnswer JSONstring",
        JSON.stringify(this.quest.answers[0])
      );
    },
    async HshUploadFileActivate() {
      const res = await this.$dialog.show(HshUploadFile, {
        waitForResult: true,
        title: "Ladda upp fil",
        $store: this.$store,
      }); // Show dialog
      if (res == "OK") {
        //this.$emit("", changeColor);
        // this.$store.state.applStore.hasUnsavedChanges=true
        console.log("HshUploadFile emit dialog res OK", res);
      } else {
        console.log("HshUploadFile don't emit res", res);
      }
    },
    // async HshColorPickerQuestion() {
    //   const changeColor = await this.$dialog.show(HshColorPicker, { waitForResult: true, title:'Välj frågefärg',color:this.quest.ui_hint.bgcolor} ); // Show dialog
    //   if (changeColor !== undefined) {
    //     this.quest.ui_hint.bgcolor =  changeColor
    //      this.$store.state.applStore.hasUnsavedChanges=true
    //   }
    //   console.log('CallHshColorPicker gave dialog res',changeColor)
    // },
    QuestColorChange(color) {
      if (typeof color === "string") {
        this.quest.ui_hint.bgcolor = color;
      } else {
        this.quest.ui_hint.bgcolor = color.hex;
      }
      console.log("QuestColorChange", this.quest.ui_hint.bgcolor, typeof color);
    },
    QuestImageChange(image) {
      console.log("QuestImageChange", image);
      this.$_.set(this.quest, "i18n.sv.media.image.id", image.id);
      this.quest.i18n.sv.media.image.url = image.url;
      this.urlimg[this.quest.i18n.sv.media.image.id] = image.url;
    },
    useImage(answer) {
      // this.formItem.questions.
      console.log("useImage got", answer, this.questIcon, this.questIcon2);
      try {
        if (this.$_.get(answer, "i18n.sv.media.image.id", 0) > 0) {
          this.urlimg[answer.i18n.sv.media.image.id] =
            this.$store.getters.getImageById(answer.i18n.sv.media.image.id);
          return true;
        }
      } catch (error) {
        console.error("useImage", error);
      }
      console.log("useImage returns false");
      return false;
    },
    imgSrc(answer) {
      if (this.$_.get(answer, "i18n.sv.media.image.id", 0) > 0) {
        return this.urlimg[answer.i18n.sv.media.image.id];
      }
      return "";
    },
    onFileChange(e) {
      console.log("onFileChange", e);
      var files = e.target.files || e.dataTransfer.files;
      console.log("onFileChange", files);
      if (!files.length) return;
      // this.createImage(files[0]);
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      console.log("removeImage", e);
      this.image = "";
    },
    getTopics() {
      return this.$store.state.queryTopics.map((qt) => {
        return qt.topic_name;
      });
    },
    playSound(id) {
      console.log(`EditQuestion:playSound Will play id ${id}`);
      this.stopAndClearAllPhrases();
      this.playPhrase(id);
    },
  },
};
</script>

<style scoped>
td {
  padding-left: 6px;
}
.boxtext {
  text-align: center;
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
.imageUrl {
  max-width: 30px;
  max-height: 100%;
  justify-content: left;
  align-content: flex-start;
}

table,
th,
td {
  border: 1px solid black;
}

.border {
  border: 1px solid black;
}

.v-list {
  border: 1px solid black !important;
  height: 8em; /* or any height you want */
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  overflow-y: auto;
}
.smaller {
  min-height: 2px !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 2px;
}
</style>
