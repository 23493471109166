var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "v-card",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-6 pt-8", attrs: { cols: "5" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "my-1 py-1 black white--text" },
                        [_vm._v(" Uppgifter " + _vm._s(_vm.name) + " ")]
                      ),
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _vm._l(_vm.responderItems, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: index,
                                        attrs: { cols: "12", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: item.label,
                                            disabled: !item.enabled
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass:
                                          "align-center justify-center",
                                        attrs: { color: "gray" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { left: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "align-center justify-center pt-3"
                                                                      },
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c("nobr", [
                                                                      _vm._v(
                                                                        "e-postavisering"
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        803012827
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Undersökningar och meddelanden kommar att aviseras via e-post "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2849487875
                                        ),
                                        model: {
                                          value: _vm.notify_by_email,
                                          callback: function($$v) {
                                            _vm.notify_by_email = $$v
                                          },
                                          expression: "notify_by_email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass:
                                          "align-right justify-center",
                                        attrs: { color: "gray" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { left: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "align-center justify-center pt-3"
                                                                      },
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "terminalavisering"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3243496916
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Undersökningar och meddelanden kommar att aviseras via notiser eller direkt i hemtermianlen "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1460237667
                                        ),
                                        model: {
                                          value: _vm.notify_by_terminal,
                                          callback: function($$v) {
                                            _vm.notify_by_terminal = $$v
                                          },
                                          expression: "notify_by_terminal"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              ),
                              _c("v-divider", {
                                staticStyle: {
                                  color: "white",
                                  "border-color": "white",
                                  "background-color": "white"
                                }
                              }),
                              _c("v-divider", {
                                staticStyle: {
                                  color: "white",
                                  "border-color": "white",
                                  "background-color": "white"
                                }
                              }),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        absolute: "",
                                        bottom: "",
                                        right: "",
                                        color: "blue darken-1",
                                        text: ""
                                      },
                                      on: { click: _vm.updateResponder }
                                    },
                                    [_vm._v(" Uppdatera uppgifter ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "ml-6 pt-8", attrs: { cols: "5" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "my-1 py-1 black white--text" },
                        [_vm._v(" Filterparametrar ")]
                      ),
                      _c("v-data-table", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.haveReceived,
                            expression: "haveReceived"
                          }
                        ],
                        staticClass: "elevation-1",
                        attrs: {
                          items: _vm.usertable,
                          "item-key": "value",
                          "item-class": "row-height-50",
                          "group-by": "groupname",
                          "items-per-page": -1,
                          height: 700,
                          "footer-props": {
                            "items-per-page-text": "Rader per sida",
                            "items-per-page-all-text": "Alla"
                          },
                          update: _vm.update
                        },
                        on: {
                          input: _vm.TableSelectChange,
                          click: _vm.TableSelectChange
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "group.header",
                              fn: function(ref) {
                                var group = ref.group
                                var headers = ref.headers
                                var items = ref.items
                                var isOpen = ref.isOpen
                                var toggle = ref.toggle
                                return [
                                  _c(
                                    "th",
                                    { attrs: { colspan: 2 } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          ref: group,
                                          attrs: {
                                            small: "",
                                            icon: "",
                                            "data-open": isOpen
                                          },
                                          on: { click: toggle }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              isOpen
                                                ? "mdi-chevron-up"
                                                : "mdi-chevron-down"
                                            ) + " "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        " " + _vm._s(items[0].groupname) + " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("tr", [
                                    _c("td", [
                                      _c("span", [
                                        _c("strong", [
                                          _vm._v(_vm._s(item.description))
                                        ])
                                      ])
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.multiselect4(item),
                                        on: {
                                          click: function($event) {
                                            return _vm.TableSelectChange(item)
                                          },
                                          input: function($event) {
                                            return _vm.TableSelectChange(item)
                                          }
                                        }
                                      },
                                      [
                                        [
                                          "string",
                                          "date",
                                          "datetime",
                                          "time"
                                        ].find(function(e) {
                                          return e == item.gui_type
                                        })
                                          ? _c("span", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.resp_value,
                                                    expression:
                                                      "item.resp_value"
                                                  }
                                                ],
                                                staticStyle: {
                                                  border: "1px solid black",
                                                  padding: "3px !important"
                                                },
                                                domProps: {
                                                  value: item.resp_value
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item,
                                                      "resp_value",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ])
                                          : ["dropdown"].find(function(e) {
                                              return e == item.gui_type
                                            })
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-select", {
                                                  staticClass: "pr-6",
                                                  attrs: {
                                                    items: item.choices,
                                                    label: item.description
                                                  },
                                                  model: {
                                                    value: item.resp_value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "resp_value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.resp_value"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : ["multiselect"].find(function(e) {
                                              return e == item.gui_type
                                            })
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: item.choices,
                                                    chips: "",
                                                    "deletable-chips": "",
                                                    label: item.name,
                                                    multiple: "",
                                                    outlined: ""
                                                  },
                                                  model: {
                                                    value: item.resp_value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "resp_value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.resp_value"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : ["checkbox"].find(function(e) {
                                              return e == item.gui_type
                                            })
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    color: "gray",
                                                    value: "true"
                                                  },
                                                  model: {
                                                    value: item.resp_value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "resp_value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.resp_value"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : ["integer", "limitedInteger"].find(
                                              function(e) {
                                                return e == item.gui_type
                                              }
                                            )
                                          ? _c("span", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.resp_value,
                                                    expression:
                                                      "item.resp_value"
                                                  }
                                                ],
                                                staticStyle: {
                                                  border: "1px solid black",
                                                  padding: "3px !important"
                                                },
                                                domProps: {
                                                  value: item.resp_value
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item,
                                                      "resp_value",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "footer.prepend",
                              fn: function() {
                                return [_c("v-spacer")]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1753496209
                        ),
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      }),
                      _c("v-divider", {
                        staticStyle: {
                          color: "white",
                          "border-color": "white",
                          "background-color": "white"
                        }
                      }),
                      _c("v-divider", {
                        staticStyle: {
                          color: "white",
                          "border-color": "white",
                          "background-color": "white"
                        }
                      }),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                absolute: "",
                                bottom: "",
                                right: "",
                                color: "blue darken-1",
                                text: ""
                              },
                              on: { click: _vm.closeAndSaveResponderForm }
                            },
                            [_vm._v(" Uppdatera filter ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-4 ma-4",
                      attrs: {
                        absolue: "",
                        "bottom-right": "",
                        color: "blue darken-1",
                        text: ""
                      },
                      on: { click: _vm.closeResponderForm }
                    },
                    [_vm._v(" Stäng ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }