<template>
  <DialogCard title="Lägg till Respondent" :actions="actions">
    <v-text-field label="Förnamn" v-model="first_name" />
    <v-text-field label="Efternamn" v-model="last_name" />
    <v-text-field label="Födelseår" v-model="year_of_birth" />
    <v-text-field label="E-post" v-model="emailaddress" />
    <v-text-field label="Kommentar" v-model="info" />
    <v-checkbox
      v-model="notify_by_email"
      color="gray"
      class="align-center justify-center"
    >
      <template v-slot:label>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="align-center justify-center pt-3"
              v-bind="attrs"
              v-on="on"
              >e-postavisering</span
            >
          </template>
          <span
            >Undersökningar och meddelanden kommar att aviseras via e-post
          </span>
        </v-tooltip>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="notify_by_terminal"
      color="gray"
      class="align-center justify-center"
    >
      <template v-slot:label>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="align-center justify-center pt-3"
              v-bind="attrs"
              v-on="on"
              >terminalavisering</span
            >
          </template>
          <span
            >Undersökningar och meddelanden kommar att aviseras via notiser
            eller direkt i hemtermianlen
          </span>
        </v-tooltip>
      </template>
    </v-checkbox>
  </DialogCard>
</template>
<script>
export default {
  // overlay: 'default',
  //   asyncData () {
  //         return new Promise(resolve => {
  //           setTimeout(resolve, 3000)
  //         })
  // },
  data() {
    return {
      first_name: "",
      last_name: "",
      year_of_birth: "",
      emailaddress: "",
      info: "",
      notify_by_terminal: false,
      notify_by_email: false,
    };
  },
  computed: {
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            return {
              first_name: this.first_name,
              last_name: this.last_name,
              year_of_birth: this.$_.toNumber(this.year_of_birth),
              email: this.emailaddress,
              comment: this.comment,
              notify_by_email: this.notify_by_email,
              notify_by_terminal: this.notify_by_terminal,
            };
          },
        },
      };
    },
  },
};
</script>
