<template>
  <v-row class="ml-1">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          {{ buttontext }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Skapa nytt formulär</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Namn"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.queryTopic"
                  :items="queryTopics"
                  label="Kategori"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small
            >Skapas av <b>{{ token.name }}</b></small
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Avbryt
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleSubmit">
            Skapa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["buttontext"],
  data: () => ({
    dialog: false,
    form: {
      name: "",
      queryTopic: -1,
    },
  }),
  created() {},
  computed: {
    token() {
      // console.log('KeyCloak token', this.$keycloak)
      // return {token:{name:'Ulf Wilhelmsson'}}
      return this.$keycloak.tokenParsed;
    },
    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
  },
  methods: {
    handleSubmit() {
      const form = {
        name: this.form.name,
        topic_id: this.form.queryTopic,
      };
      this.$store.dispatch("createForm", form);
      this.dialog = false;
    },
  },
};
</script>
