var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 whitespace",
        attrs: {
          headers: _vm.headers,
          items: _vm.surveysInstances,
          "sort-by": "starts_at",
          "sort-desc": true,
          search: _vm.search,
          dense: "",
          "items-per-page": 5,
          loading: _vm.loading,
          triggSurveysInstanceTable: _vm.triggSurveysInstanceTable,
          "loading-text": "Laddar...",
          "footer-props": {
            showFirstLastPage: true,
            "items-per-page-text": "Rader per sida",
            "items-per-page-all-text": "Alla"
          }
        },
        on: { "click:row": _vm.handleRowClick },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm.showNewButton
                  ? _c(
                      "div",
                      [
                        item.locked
                          ? _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-sort-variant-lock")
                            ])
                          : _vm._e(),
                        !item.locked
                          ? _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.editItem(item)
                                  }
                                }
                              },
                              [_vm._v(" mdi-pencil ")]
                            )
                          : _vm._e(),
                        !item.locked
                          ? _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.addItem(item)
                                  }
                                }
                              },
                              [_vm._v(" mdi-account-multiple-plus ")]
                            )
                          : _vm._e(),
                        !item.locked
                          ? _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteItem(item)
                                  }
                                }
                              },
                              [_vm._v(" mdi-delete ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { staticStyle: { display: "none" }, attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title"),
                    _c("v-spacer"),
                    _vm._v(" --> "),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "600px" },
                        model: {
                          value: _vm.dialogDelete,
                          callback: function($$v) {
                            _vm.dialogDelete = $$v
                          },
                          expression: "dialogDelete"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "text-h5" }, [
                              _vm._v(
                                "Vill du verkligen ta bort denna undersökning? "
                              )
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.closeDelete }
                                  },
                                  [_vm._v("Avbryt")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.deleteItemConfirm }
                                  },
                                  [_vm._v("OK")]
                                ),
                                _c("v-spacer")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer.prepend",
            fn: function() {
              return [
                _c("v-spacer"),
                _c("v-text-field", {
                  staticClass: "mb-5",
                  attrs: {
                    "append-icon": "mdi-magnify",
                    label: "Sök",
                    "single-line": "",
                    "hide-details": ""
                  },
                  on: { keyup: _vm.clearOnEscape },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                }),
                _c("v-spacer")
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }