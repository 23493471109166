<template>
  <v-row fluid justify="center" align="end">
    <v-dialog v-model="dialog" width="auto" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Skapa och starta undersökning (genväg)
        </v-btn>
      </template>
      <v-card class="dialog-card" fluid>
        <div v-if="loadingStatus">
          <v-card>
            <v-card-title class="text-h5">Ny undersökning?</v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="surveyname"
                    label="Namn"
                    required
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                  <v-select
                    v-model="form.queryTopic"
                    :items="queryTopics"
                    label="Kategori"
                    required
                  ></v-select>
                </v-col> -->
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false"
                >Avbryt</v-btn
              >
              <v-btn color="blue darken-1" text @click="CreateNewConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
        <div v-else>
          <v-card color="primary" dark>
            <v-card-text>
              Var god dröj...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { mapGetters} from 'vuex';
// https://dev.to/andynoir/draggable-table-row-with-vuejs-vuetify-and-sortablejs-1o7l
// import draggable from "vuedraggable";

export default {
  props: ["form"],
  name: "StartSurveyFromForm",
  data() {
    return {
      dialog: false,
      surveyname: "",
      dialogCreateNew: false,
      valid: true,
    };
  },
  computed: {
    loadingStatus() {
      console.log(
        `StartSurveyFromForm: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
  },
  watch: {
    dialog: function () {
      if (this.surveyname == "") {
        this.surveyname =
          this.form.name + " " + this.$moment().format("YYYY-MM-DD");
      }
      if (this.dialog) {
        console.log("StartSurveyFromForm: Watch dialog");
      }
    },
  },
  methods: {
    closeCancel() {
      this.dialog = false;
    },
    createAndGoToStartSurvey() {
      this.dialog = false;
      console.log("createAndGoToStartSurvey clicked...");
      //   const myNewSurvey = {
      //     id: -1,
      //   };
    },
    async CreateNewConfirm() {
      console.log(this.form);
      this.dialogCreateNew = false;
      this.dialog = false;
      this.form.name = this.surveyname;
      this.form.forms = [{"form_id": this.form.id, "days_offset": 0}];
      this.form.id = -1;

      const id = await this.$store.dispatch("saveSurvey", this.form);
      //   const id = 51; Debug help
      // Set current surveyInstans
      // emulate function handleRowClick in SurveyTable.vue
      const d = await this.$store.dispatch("setCurrentSurveyId", id);
      console.log("CreateNewConfirm saveSurvey new id:", id, d);
      const res = this.$router.push({
        path: `/surveysInstances?survey=${id}`,
        params: { survey: id },
      });
      console.log("CreateNewConfirm saveSurvey", res);
    },
  },
  created() {
    console.log("create StartSurveyFromForm");
    // this.$store.dispatch("fetchQuestions");
    console.log(this.questions);
  },
  beforemount() {
    this.$store.dispatch("fetchQuestions");
  },
  mounted() {
    console.log("Mount StartSurveyFromForm");
  },
};
</script>

<style scoped>
td {
  padding-left: 6px;
}
.box {
  float: left;
  height: 10px;
  width: 10px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
.dialog-card {
  width: 100%;
  max-width: 100%;
  min-width: 800px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  margin: 0px;
  overflow-x: hidden;
}
p {
  text-align: left;
  color: darkblue;
  padding-left: 12px;
  margin: 0 !important;
}
.hsh {
  min-width: 700px;
}
</style>
