var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { "fill-height": "", "justify-space-between": "" } },
            [
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    color: _vm.color,
                    dark: "",
                    permanent: "",
                    rail: "",
                    width: _vm.$store.state.withOfDrawer,
                    height: "100vh"
                  }
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.items, function(item, i) {
                      return _c(
                        "v-list-item",
                        { key: i, attrs: { to: item.route, router: "" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(item.title) }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _vm.$store.state.withOfDrawer === null
                        ? _c(
                            "v-icon",
                            {
                              attrs: { "withjustify-end": "" },
                              on: { click: _vm.ToggleDrawerWith }
                            },
                            [_vm._v("keyboard_double_arrow_left")]
                          )
                        : _c(
                            "v-icon",
                            {
                              attrs: { "withjustify-end": "" },
                              on: { click: _vm.ToggleDrawerWith }
                            },
                            [_vm._v("keyboard_double_arrow_right")]
                          )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                {
                  staticClass: "ma-0",
                  staticStyle: { "max-width": "100 vW" },
                  attrs: { id: "surveyArea", fluid: "" }
                },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }