<template>
  <v-app v-if="updateValueAtEvent != update">
    <v-card>
      <v-layout>
        <!-- <v-hover v-slot="{ hover }"> -->
        <v-navigation-drawer
          :color="color"
          dark
          permanent
          :width="$store.state.withOfDrawer"
          rail
          :height="messAreaHeight"
        >
          <!-- v-model="drawer"
        :mini-variant="!hover || navigationDrawerMiniVariantAlways"
        :clipped="true" -->
          <v-list>
            <v-list-item
              v-for="(item, i) in messageItems"
              :key="i"
              :to="item.route"
              router
            >
              <v-list-item-action>
                <div v-if="nrOfMessages > 0 && item.icon == 'mdi-message-text-fast'">
                  <v-badge color="red" :content="nrOfMessages">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  <v-icon>{{ item.icon }}</v-icon>
                </div>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list-item>
            <v-icon
              v-if="$store.state.withOfDrawer === null"
              withjustify-end
              @click="ToggleDrawerWith"
              >keyboard_double_arrow_left</v-icon
            >
            <v-icon v-else withjustify-end @click="ToggleDrawerWith"
              >keyboard_double_arrow_right</v-icon
            >
          </v-list-item>
        </v-navigation-drawer>
        <!-- </v-hover> -->
        <!-- <v-main style="border: 1px solid red;"> -->
        <v-container id="messArea" fluid class="ma-0" style="max-width: 100vw">
          <router-view />
        </v-container>
        <!-- </v-main> -->
      </v-layout>
    </v-card>
  </v-app>
</template>

<script>
// import Vue from 'vue'
// const apiUrl = "https://api.homesvithome.se/v1.0/forms";
// import axios from "axios";

export default {
  name: "MessageArea",
  // props: ["keycloak"],
  async created() {
    // console.log(this.keycloak.tokenParsed);
    // this.$store.commit("setKeycloakToken", this.$keycloak.tokenParsed.name);
    // this.$store.state.mainVueInstance=this
    // console.log("fetch base media",this.$keycloak);
    // this.$store.dispatch('getTopics')
    // await this.$store.dispatch('getMediaList');

    // console.log("fetch media",this.$store.state.medialist);
    // this.$store.state.medialist.forEach( (item) => {
    //   if (item.type=="image") {
    //     this.$store.dispatch('getImgMedia',item.id)
    //   } else if (item.type=="audio") {
    //     this.$store.dispatch('getAudioMedia',item.id)
    //   }
    // })
    // this.$store.dispatch("fetchForms");
    // this.$store.dispatch("fetchResponders");
    // this.$store.dispatch("getTerminals");
    // As a start poll messages to se if someone require action
    this.CheckMessagesStatus();
    setInterval(this.CheckMessagesStatus, 60000);
    // this.$store.getters.getMessages
    window.addEventListener("resize", () => {
      // Resize of screen
      // console.log("Window size change")
      this.update += 1;
    });
    if (this.update == 0) {
      // Just install once
      this.update += 1;
      this.$root.$on("new-tab-selected", () => {
        this.updateValueAtEvent = this.update; // force rerender of navigation list
        this.$forceUpdate();
        this.$nextTick(() => {
          this.update += 1;
        });
        console.log("Got event new-tab-selected", this.update);
      });
    }
  },
  data() {
    return {
      form_names: "Loading...",
      token_expiry: "Loading...",
      color: "blue darken-4",
      drawer: true,
      right: true,
      rightDrawer: false,
      update: 0,
      updateValueAtEvent: 0,
      // title: "Home SvIT Home Admin App",
      // items: [
      //   { title: "Startsidan", icon: "mdi-home", route: "/" },
      //   { title: "Respondenter", icon: "mdi-account", route: "/responders" },
      //   { title: "Urvalsgrupper", icon: "mdi-account-multiple", route: "/selections" },
      //   { title: "Undersökningar", icon: "mdi-folder", route: "/surveys" },
      //   { title: "Formulär", icon: "mdi-form-select", route: "/forms" },
      //   { title: "Frågor", icon: "mdi-pencil", route: "/questions" },
      //   { title: "Filter", icon: "mdi-filter", route: "/filter" },
      //   { title: "Kategorier", icon: "mdi-shape", route: "/categories" },
      //   { title: "Starta undersökning", icon: "mdi-run-fast", route: "/SurveysInstances" },
      //   { title: "Rapporter", icon: "mdi-poll", route: "/reports" },
      //   // { title: "Login", icon: "mdi-flag", route: "/login" },
      // ],
      messageItems: [
        {
          title: "Skapa meddelanden",
          icon: "mdi-message-draw",
          route: "/CreateMessage",
        },
        {
          title: "Skickade meddelanden",
          icon: "mdi-message-text-fast",
          route: "/SendMessages",
        },
      ],
      actionMessages: ["wantContact"],
      nrOfMessages: 0,
    };
  },
  computed: {
    title() {
      return "Admin - " + this.$route.name ? this.$route.name : "";
    },
    navigationDrawerMiniVariantAlways() {
      return this.$store.state.navigationDrawerMiniVariantAlways;
    },
    messAreaHeight() {
      this.update;
      let height;
      try {
        const e = document.getElementById("messArea").clientHeight;
        height = `${e}px`;
      } catch {
        height = "100vh";
      }
      console.log("messAreaHeight", height);
      return height;
    },
  },
  methods: {
    // Calculate if there is any messages that require action
    async CheckMessagesStatus() {
      try {
        await this.$store.dispatch("fetchMessages", false);
        this.nrOfMessages = 0;
        const messages = this.$store.getters.getMessages;
        messages.forEach((m) => {
          let ackActionResponders = m.respondents.filter((r) => {
            return this.actionMessages.includes(r.ack_msg) && m.revoked_at == undefined;
          });
          this.nrOfMessages += ackActionResponders.length;
        });
        // console.log("Fetched messages ", messages,this.nrOfMessages);
      } catch (err) {
        console.error("Fetch messages failed", err);
      }
    },
    mounted() {
      this.update;
    },
    Logout() {
      this.$keycloak.logout({ redirectUri: window.location.toString() });
    },
    ToggleDrawerWith() {
      if (this.$store.state.withOfDrawer === null) {
        this.$store.state.withOfDrawer = 55;
      } else {
        this.$store.state.withOfDrawer = null;
      }
    },
  },
};
</script>
