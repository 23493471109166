<!-- eslint-disable prettier/prettier -->
<template>

    <v-app>
   
    <v-app-bar :color="color" :clipped-left="true" fixed app dark>
      <v-col cols=1>
      <v-img
        alt="HomeSvITHome Logo"
        class="shrink mr-2"
        contain
        src="./assets/HomeSvITHome_light.png"
        transition="scale-transition"
        width="40"
        position="bottom"
        @click.stop="drawer = !drawer"
      />
      </v-col>
      <v-col cols=5 align="left">
      <v-toolbar-title  v-text="title" class="ml-auto" />
      </v-col>
      <v-col cols=2>
       <v-progress-linear :value="loadingStatus"></v-progress-linear>
       </v-col>
       <v-col cols=4 align="right">
       <v-spacer></v-spacer>
      <v-btn depressed :color="color" @click="Logout">Logga ut - {{$keycloak.tokenParsed.name}} - {{company}}</v-btn>
      </v-col>

     <template v-slot:extension>
        <!-- <v-tabs
          v-model="tab"
          align-with-title
          background-color="primary" dark
        > -->
        <!-- <v-tabs-slider color="red"></v-tabs-slider> - No effect from this row here tabClicked(item) -->

      <v-tabs v-model="tab" background-color="primary" dark >
        <v-tab v-for="item in items" :key="item.tab"  @click="tabClicked(item)">
          {{ item.tab }}
           <v-badge v-if="item.content=='MessageArea' && nrOfMessages>0"
              color="red"
              :content="nrOfMessages"
            >
            </v-badge>
            <v-badge v-if="item.content=='RegistrationArea' && nrOfRegRequests>0"
              color="red"
              :content="nrOfRegRequests"
            >
            </v-badge> 
        </v-tab>

      <v-tabs-items v-model="tab"  >
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat  class="fill-height scroll" :style="countHeight" >
              <component v-bind:is="item.content"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      </v-tabs>
        <!-- </v-tabs> -->
     </template>
    </v-app-bar>
    <v-footer :absolute="false" app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>

</template>

<script>
// const apiUrl = "https://api.homesvithome.se/v1.0/forms";
// import axios from "axios";
import SurveyArea from "./views/SurveyArea.vue";
import MessageArea from "./views/MessageArea.vue";
import RegistrationArea from "./views/RegistrationArea.vue";
// import HelloWorld from "./components/HelloWorld.vue";

export default {
  components: { SurveyArea, MessageArea, RegistrationArea },
  name: "App",
  // props: ["keycloak"],
  async created() {
    // console.log(this.keycloak.tokenParsed);
    // this.$store.commit("setKeycloakToken", this.$keycloak.tokenParsed.name);
    this.$store.state.mainVueInstance = this;
    console.log("fetch base media", this.$keycloak);
    this.$store.dispatch("getTopics");
    await this.$store.dispatch("getMediaList");

    // this.$store.dispatch("testLocal");

    console.log("fetch media", this.$store.state.medialist);
    // this.$store.state.medialist.forEach((item) => {
    //   if (item.type == "image") {
    //     this.$store.dispatch("getImgMedia", item.id);
    //   } else if (item.type == "audio") {
    //     this.$store.dispatch("getAudioMedia", item.id);
    //   }
    // });
    this.$store.dispatch("fetchForms");
    this.$store.dispatch("fetchResponders");
    this.$store.dispatch("getTerminals");
    this.$store.dispatch(
      "getOrganisation",
      this.$keycloak.tokenParsed.organisation
    );
    // As a start poll messages to se if someone require action
    this.CheckMessagesStatus();
    setInterval(this.CheckMessagesStatus, 60000);
    this.$root.$off("time_to_CheckMessagesStatus");
    this.$root.$on("time_to_CheckMessagesStatus", () => {
      console.log("Got time_to_CheckMessagesStatus event");
      this.CheckMessagesStatus();
    });
    // this.$store.getters.getMessages
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    console.log("Screen data", viewportHeight, viewportWidth);
    window.addEventListener("resize", () => {
      // Resize of screen
      // console.log("Window size change");
      this.rerender += 1;
    });
  },
  data() {
    return {
      form_names: "Loading...",
      token_expiry: "Loading...",
      color: "blue darken-4",
      drawer: true,
      right: true,
      rightDrawer: false,
      rerender: 0,
      tab: null,
      maxLoadingVal: 100,
      nrOfRegRequests: 0,
      items: [
        { tab: "Undersökningar", content: "SurveyArea", route: "/" },
        { tab: "Meddelanden", content: "MessageArea", route: "/CreateMessage" },
        {
          tab: "Kontohantering",
          content: "RegistrationArea",
          route: "/AccountApplication",
        },
      ],
      // title: "Home SvIT Home Admin App",
      // items: [
      //   { title: "Startsidan", icon: "mdi-home", route: "/" },
      //   { title: "Respondenter", icon: "mdi-account", route: "/responders" },
      //   { title: "Urvalsgrupper", icon: "mdi-account-multiple", route: "/selections" },
      //   { title: "Undersökningar", icon: "mdi-folder", route: "/surveys" },
      //   { title: "Formulär", icon: "mdi-form-select", route: "/forms" },
      //   { title: "Frågor", icon: "mdi-pencil", route: "/questions" },
      //   { title: "Filter", icon: "mdi-filter", route: "/filter" },
      //   { title: "Kategorier", icon: "mdi-shape", route: "/categories" },
      //   { title: "Starta undersökning", icon: "mdi-run-fast", route: "/SurveysInstances" },
      //   { title: "Rapporter", icon: "mdi-poll", route: "/reports" },
      //   // { title: "Login", icon: "mdi-flag", route: "/login" },
      // ],
      // messageItems: [
      //   { title: "Skapa meddelanden", icon: "mdi-message-draw", route: "/CreateMessage" },
      //   { title: "Skickade meddelanden", icon: "mdi-message-text-fast", route: "/SendMessages" },
      // ],
      actionMessages: ["wantContact"],
      nrOfMessages: 0,
    };
  },
  watch: {
    tab: function () {
      console.log("App - tab change ", this.tab);
    },
  },
  computed: {
    title() {
      return "Admin - " + this.$route.name ? this.$route.name : "";
    },
    navigationDrawerMiniVariantAlways() {
      return this.$store.state.navigationDrawerMiniVariantAlways;
    },
    company() {
      return this.$_.get(this, "$store.state.organisation.name", "-");
    },
    countHeight() {
      this.rerender;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const heightStr = `height: ${
        viewportHeight - 148
      }px; width: ${viewportWidth}px;`;
      // console.log("countHeight", heightStr);
      return heightStr;
    },
    loadingStatus() {
      const number = this.$store.getters.getLoadingStatus;
      const res = 100 - Math.floor((number / this.maxLoadingVal) * 100);
      // console.log("loadingStatus...",number,res,this.maxLoadingVal)
      return res;
    },
  },
  methods: {
    tabClicked(item) {
      console.log("tabClicked", item);
      this.$router.push({ path: item.route });
      this.$root.$emit("new-tab-selected");
      // this.$router.push(item.route)
    },
    // Calculate if there is any messages that require action
    async CheckMessagesStatus() {
      try {
        if (this.$store.getters.getLoadingStatus > this.maxLoadingVal) {
          this.maxLoadingVal = this.$store.getters.getLoadingStatus;
        }
        await this.$store.dispatch("fetchMessages", false);
        this.nrOfMessages = 0;
        const messages = this.$store.getters.getMessages;
        messages.forEach((m) => {
          let ackActionResponders = m.respondents.filter((r) => {
            return (
              this.actionMessages.includes(r.ack_msg) &&
              m.revoked_at == undefined
            );
          });
          this.nrOfMessages += ackActionResponders.length;
        });
        // console.log("Fetched messages ", messages,this.nrOfMessages);
      } catch (err) {
        console.error("Fetch messages failed", err);
      }
      await this.$store.dispatch("fetchAccountApplicants");
      this.nrOfRegRequests = this.$store.state.nrOfRegRequests;
    },
    Logout() {
      console.log("redirectUri:", window.location.toString())
      console.log("process.env.BASE_URL:", process.env.BASE_URL)
      this.$keycloak.logout({ redirectUri: window.location.toString() });
    },
  },
};
</script>
<style scoped>
.scroll {
  overflow-y: scroll;
  min-height: 400px;
  width: 100%;
  /* height: 780px; */ /* 74vh header and footer 148px; */
}
</style>
