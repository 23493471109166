<template>
  <div>
    <DialogCard :title="title" :actions="actions">
      Vill du att vi nollställer kontaktbegäran?
    </DialogCard>
  </div>
</template>

<script>
export default {
  name: "HshMessageHandler",
  props: ["respondent", "title"],
  data() {
    return {
      
    };
  },
  mount() {
    console.log("HshMessageHandler", this.respondent, this.title);
  },
  methods: {
    
  },
  watch: {
  },
  computed: {
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            return true;
          },
        },
       
      };
    },
  },
};
</script>

<style scoped>
</style>
