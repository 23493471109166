class templates {
  static question = {
    id: -1,
    owner_org_id: -1,
    topic_id: -1,
    format: "",
    ui_hint: {
      bgcolor: "#cccccc",
    },
    i18n: [
      {
        lang: "",
        text: "",
        media: {
          image: "",
          audio: "",
        },
      },
    ],
    answers: [
      {
        id: -1,
        owner_org_id: -1,
        owner_org_name: "",
        author: "",
        topic_id: -1,
        topic_name: "",
        format: "integer",
        ui_hint: {
          bgcolor: "#ffffff",
        },
        i18n: {
          se: {
            text: "",
            media: {
              image: "",
              audio: "",
            },
          },
        },
        value: "",
      },
    ],
  };
  static form = {
    id: -1,
    name: "",
    locked: false,
    order: -1,
    startDate: "",
    createDate: "",
    author: "",
    initials: "",
    questions: this.question,
  };
}

export default templates;
