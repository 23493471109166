<template>
  <div>
    <div v-if="!hide">
      <v-icon @click="toggleDataTable"
        >mdi-arrow-down-drop-circle-outline</v-icon
      >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="messages"
        sort-by="name"
        class="elevation-1 whitespace"
        :search="search"
        dense
        :single-select="true"
        :items-per-page="10"
        :loading="!loadingStatus"
        loading-text="Laddar..."
        :item-class="itemActionRowBackground"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Rader per sida',
          'items-per-page-options': [5, 10, 15, -1],
          'items-per-page-all-text': 'Alla',
        }"
        @click:row="handleRowClick"
      >
        <!-- :item-class="itemActionRowBackground" -->
        <template v-slot:top>
          <v-toolbar flat style="display: none"> </v-toolbar>
        </template>

        <template v-slot:item.nb="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="
                  item.nrOfActionResponders > 0 && item.revoked_at_date == '-'
                "
                class="blink"
                small
                color="red"
              >
                mdi-exclamation-thick
              </v-icon>
            </template>
            <span class="text-center d-flex align-center justify-space-around">
              {{ item.nb }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:item.state="{ item }">
          <span v-if="isStillValid(item)">
            <v-progress-linear
              :value="item.progress"
              :color="item.progressColor"
              height="25"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.floor(value) }}%</strong>
              </template>
            </v-progress-linear>
          </span>
          <span v-else>
            <strong>{{ Math.floor(item.progress) }}%</strong>
          </span>
        </template>

        <template v-slot:item.answers="{ item }">
          {{ nrOfAnswers(item) }}
        </template>

        <template v-slot:item.nrOfResponders="{ item }">
          <!-- <span @mouseover="selectItem(item)" @mouseleave="unSelectItem()"> -->
          <!-- {{ item.nrOfResponders }} -->
          <popup-list
            :dataToShow="item.nrOfResponders"
            title="Mottagare"
            :headers="respondentHeaders"
            :items="respondersList(item)"
          ></popup-list>
          <!-- </span> -->
        </template>

        <template v-slot:item.nrOfAckResponders="{ item }">
          <popup-list
            :dataToShow="item.nrOfAckResponders"
            title="Har konfirmerat meddelandet"
            :headers="respondentHeaders"
            :items="ackRespondersList(item)"
          ></popup-list>
        </template>

        <template v-slot:item.nrOfActionResponders="{ item }">
          <popup-list
            :dataToShow="item.nrOfActionResponders"
            title="Vill bli kontaktade"
            :headers="respondentHeaders"
            :items="actionRespondersList(item)"
          ></popup-list>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="!item.locked"
                small
                @click="revokeItem(item)"
              >
                mdi-card-bulleted-off-outline
              </v-icon>
            </template>
            <span class="text-center d-flex align-center justify-space-around">
              Återkalla frågan
            </span>
          </v-tooltip>
        </template>

        <template v-slot:footer.prepend>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök fråga"
            single-line
            hide-details
            class="mb-5"
            v-on:keyup="clearOnEscape"
          ></v-text-field>
          <v-spacer></v-spacer>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-icon @click="toggleDataTable"
        >mdi-arrow-right-drop-circle-outline</v-icon
      >
      expandera listan med meddelanden...
    </div>

    <!-- Dialogs -->
    <v-dialog v-model="dialogRevoke" max-width="600px">
      <v-card>
        <v-card-title class="text-h5"
          >Vill du verkligen återkalla detta meddelande?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Avbryt</v-btn>
          <v-btn color="blue darken-1" text @click="revokeItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogShowResponders" max-width="800px">
      <div  @mouseover="upHereTrue()" @mouseleave="upHereFalse()" >
      </div>
    </v-dialog> -->
  </div>
</template>

<script>
// import { component } from 'vue/types/umd';
import hshApi from "../services/hshapi";
import popupList from "./popupList.vue";

// import editQuestion from "./EditQuestion.vue"

export default {
  components: { popupList },
  name: "MessageTable",
  // props: ["questionId"],
  data() {
    return {
      dialog: false,
      dialogRevoke: false,
      loading: false,
      search: "",
      hide: false,
      language: "sv",
      // bus: new Vue(),
      respondentHeaders: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Namn", value: "name", sortable: true },
        { text: "E-post", value: "email" },
        { text: "Terminal", value: "terminal_id" },
        // { text: "Hantera", value: "actions", sortable: false },
      ],
      headers: [
        { text: "NB", value: "nb", width: "20px" },
        { text: "", value: "state", width: "100px" },
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Text", value: "cleanText" },
        { text: "Skickat", value: "send_at_date" },
        { text: "giltigt t.o.m", value: "valid_to_date" },
        { text: "Antal mottagare", value: "nrOfResponders" },
        { text: "Viktighetsgrad", value: "importanceText" },
        { text: "Antal konfirmerade svar", value: "nrOfAckResponders" },
        { text: "Återkallat", value: "revoked_at_date" },
        {
          text: "Antal svar som kräver handling",
          value: "nrOfActionResponders",
        },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      importanceItems: [
        { value: 1, name: "Mycket viktigt" },
        { value: 2, name: "Ganska Viktigt" },
        { value: 3, name: "Normalt" },
        { value: 4, name: "Oviktigt" },
      ],
      ackMessages: ["confirmed", "wantContact", "OK","system confirmed","Kontakta mig"],
      actionMessages: ["wantContact","Kontakta mig"],
      progressColors: [
        { limit: 20, color: "red" },
        { limit: 70, color: "blue-grey" },
        { limit: 100, color: "green" },
      ],
      itemToRevoke: null,
      // question: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      selected: [],
      selectedItem: false,
      dialogShowResponders: false,
      upHere: false,
      // respondersArray:[],
    };
  },
  computed: {
    messages() {
      const messages = this.$store.getters.getMessages;
      console.log("QuestionTable: Hämtar alla messages ", messages);
      messages.forEach((m) => {
        m.nb = "NB!";
        m.valid_to_date = this.$moment(m.valid_to).format("YY-MM-DD HH:mm");
        m.send_at_date = this.$moment(m.send_at).format("YY-MM-DD HH:mm");
        // console.log("messages revoke_at= ", m.revoked_at);
        m.cleanText = m.text.replace(/<\/?[^>]+(>|$)/g, "");
        if (m.cleanText.length > 53) {
          m.cleanText = m.cleanText.substring(0, 50) + "...";
        }

        if (m.revoked_at === undefined) {
          m.revoked_at_date = "-";
        } else {
          m.revoked_at_date = this.$moment(m.revoked_at).format(
            "YY-MM-DD HH:mm"
          );
        }
        m.nrOfResponders = m.respondents.length;
        let ackResponders = m.respondents.filter((r) =>
          this.ackMessages.includes(r.ack_msg)
        );
        m.ackResponders = ackResponders;
        m.nrOfAckResponders = ackResponders.length;
        let ackActionResponders = m.respondents.filter((r) =>
          this.actionMessages.includes(r.ack_msg)
        );
        m.ackActionResponders = ackActionResponders;
        m.nrOfActionResponders = ackActionResponders.length;
        const t = this.importanceItems.find((r) => r.value === m.importance);
        m.importanceText = t == null ? "-" : t.name;
        m.progress = Math.floor((m.nrOfAckResponders * 100) / m.nrOfResponders);
        const progressColor = this.progressColors.find(
          (c) => c.limit >= m.progress
        );
        m.progressColor =
          progressColor == undefined ? "red" : progressColor.color;
        if (isNaN(m.progress)) m.progress = 0;
        // console.log("ProgressColor",m)
      });
      // return this.$store.state.messages
      return messages;
    },
    loadingStatus() {
      console.log(
        `MessagesTable: loadingStatus(${this.$store.getters.getLoadingStatus})`,
        this.$store.state.messages
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  beforeMount() {
    this.editedItem = {
      ...this.$store.state.questionItemTemplate,
      nrOfmessages: 0,
    };
    this.defaultItem = this.editedItem;
    // this.$store.dispatch("fetchMessages", this.editedItem.id);
    //this.$store.dispatch("fetchMessages");
    this.$root.$off("questiontable-question-toggle-hide");
    this.$root.$on("questiontable-question-toggle-hide", () => {
      console.log("Got hide toggle event");
      this.hide = !this.hide;
    });
    console.log("questionTable beforeMount");
  },
  mounted() {
    this.focusCurrentquestionId();
  },

  methods: {
    currentTime() {
      const res = this.$moment().valueOf();
      console.log("currentTime", res, new Date().getTime() / 1000);
      return res;
    },
    timeSinceEpoch(valid_to) {
      const res = this.$moment(valid_to).valueOf();
      console.log("timeSinceEpoch", res);
      return res;
    },
    isStillValid(item) {
      console.log("isStillValid", item.valid_to);

      const to = this.$moment(item.valid_to).valueOf();
      const now = this.$moment().valueOf();
      console.log("isStillValid", to, now);
      if (item.revoked_at !== undefined) {
        return false;
      }
      return to > now ? true : false;
    },
    respondersArray(item, respondentsIdArr) {
      // if (this.selectedItem !== false) {
      console.log("respondersArray", item);
      const allrespondents = this.$store.getters.getReponders;
      const respondents = allrespondents.reduce(function (
        filtered,
        respondent
      ) {
        const found = respondentsIdArr.some(
          (el) => el.respondent_id === respondent.id
        );
        if (found) {
          const someNewValue = {
            id: respondent.id,
            name: respondent.first_name + " " + respondent.last_name,
            email: respondent.email !== undefined ? respondent.email : "",
            terminal_id: respondent.terminal_id !== undefined ? "Ja" : "-",
          };
          filtered.push(someNewValue);
        }
        return filtered;
      },
      []);
      return respondents;
    },

    respondersList(item) {
      console.log("respondersList", item, this.selectedItem);
      return this.respondersArray(item, item.respondents);
    },

    ackRespondersList(item) {
      console.log("ackRespondersList", item.ackResponders);
      return this.respondersArray(item, item.ackResponders);
    },

    actionRespondersList(item) {
      console.log("actionRespondersList", item.ackActionResponders);
      return this.respondersArray(item, item.ackActionResponders);
    },
    // const respondents=allrespondents.map((r) => ({
    //   id: r.id,
    //   name: r.first_name + ' ' + r.last_name,
    //   email: 'nisse@nisse.com' ,
    //   terminal_id: r.terminal_id
    // }))

    upHereTrue() {
      console.log("upHereTrue");
      this.upHere = true;
      return true;
    },
    upHereFalse() {
      // outOfCompFlag
      // console.log("upHereFalse", this.$refs.popupList.outOfCompFlag);
      console.log("upHereFalse");
      this.upHere = false;
      this.dialogShowResponders = false;
      return false;
    },
    selectItem(item) {
      this.selectedItem = item;
      // this.respondersArray(item)
      this.dialogShowResponders = true;
      this.upHere = true;
      console.log("selected...", item);
    },
    unSelectItem(item) {
      // this.dialogShowResponders = false;
      console.log("unselected...", item);
      // this.$refs.popupList.menu = false;
      this.selectedItem = false;
    },
    itemActionRowBackground(item) {
      let style =
        item.nrOfActionResponders > 0 ? "action-STYLE" : "normal-STYLE";
      if (item.revoked_at !== undefined) {
        style = "revoked-STYLE";
      }
      // console.log("itemActionRowBackground",style)
      return style;
    },
    nrOfAnswers(item) {
      try {
        // console.log(item,this.language)
        return item.answers.length;
      } catch {
        return 0;
      }
    },
    progressValue(item) {
      try {
        return item.progress;
      } catch {
        return 0;
      }
    },
    isImagePresent(item) {
      try {
        if (item.i18n.sv.media.image.id > 0) {
          return true;
        }
      } catch {
        return false;
      }
      return false;
    },
    playSound(id) {
      console.log(`QuestionForm:playSound Will play id ${id}`);
      this.playPhrase(id);
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    focusCurrentquestionId() {
      const item = this.$store.getters.getCurrentQuestion;
      if (item != null) {
        this.focusQuestionquestion(item); //uw
      }
    },
    focusQuestionquestion(item) {
      console.log("emit questiontable-question-clicked");
      // this.$root.$emit("questiontable-question-clicked", item);
      this.$emit("messageChoosed", item)
      this.selected = [];
      this.selected.push(item);
    },
    handleRowClick(item) {
      console.log("questionTable: You clicked ", item);
      // this.$store.dispatch("setCurrentQuestionId", item.id);
      console.log("Clicked row", item);
      //this.$root.$emit("showMessage-table-clicked", item);
      this.$emit("messageChoosed", item)
      // this.selected = []
      // this.selected.push(item)
    },
    async editItem(item) {
      // alert("editItem You clicked pen " , item);
      // this.editedIndex = this.editedItem.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      //   const res = await this.$dialog.show(editQuestion, { waitForResult: true,width:"90%",question:this.editedItem,$store:this.$store }); // Show dialog
      //this.dialog = true;
      // this.bus.$emit('show')
      //   console.log("MessagesTable editItem",res);
    },

    revokeItem(item) {
      // this.editedIndex = this.editedItem.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      console.log("revokeItem", item);
      this.dialogRevoke = true;
      this.itemToRevoke = item;
    },

    revokeItemConfirm() {
      this.$store.dispatch("revokeMessage", this.itemToRevoke);
      console.log("revokeConfirm", this.itemToRevoke);
      this.itemToRevoke = null;
      // this.editedItem.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.itemToRevoke = null;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogRevoke = false;
      hshApi;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.editedItem[this.editedIndex], this.editedItem);
      } else {
        this.editedItem.push(this.editedItem);
      }
      this.close();
    },
    itemRowBackground(item) {
      if (this.selected.length <= 0) {
        return "normal-STYLE";
      }
      console.log(
        "itemRowBackground QuestionTable called " +
          item.id +
          " " +
          this.selected[0].id
      );
      return item.id == this.selected[0].id ? "action-STYLE" : "normal-STYLE";
    },
    toggleDataTable() {
      this.hide = !this.hide
      // this.$root.$emit("questiontable-question-toggle-hide");
    },
  },
};
</script>

<style lang="css">
.action-STYLE {
  /* background-color: rgb(237, 24, 24) !important; */
  color: rgb(237, 24, 24);
}
.normal-STYLE {
  /* background-color: rgb(255, 255, 255) !important; */
}
.revoked-STYLE {
  /* background-color: rgb(205, 205, 206) !important; */
  font-style: italic;
  color: rgb(180, 180, 180, 1);
}
td {
  vertical-align: middle !important;
}
.blink {
  color: red;
  margin-right: 0.5rem;
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>
