<template>
  <div>
    <div v-show="loadingStatus == false">
      <v-card color="primary" dark>
        <v-card-text>
          Var god dröj...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </div>
    <div v-show="loadingStatus == true">
      <template>
        <v-data-table
          v-show="haveReceived"
          v-model="selected"
          :headers="headers"
          :items="accountsApplicationsTable"
          :single-select="singleSelect"
          item-key="id"
          :search="search"
          show-select
          @input="TableSelectChange"
          class="elevation-1"
          :footer-props="{
            'items-per-page-text': 'Rader per sida',
            'items-per-page-all-text': 'Alla',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Sök"
              single-line
              hide-details
              class="mb-5 mx-6"
              v-on:keyup="clearOnEscape"
            ></v-text-field>
          </template>
          <!-- @item-selected="TableSelectChange"
        @toggle-select-all="TableSelectChange" -->
          <template v-slot:item.terminal_id="{ item }">
            <!-- <v-icon small @click.stop="copyJwt(item)"> mdi-content-copy </v-icon> -->
            <div v-if="haveTerminal(item)">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="pl-3"
                  >
                    mdi-desktop-classic
                  </v-icon>
                </template>
                <span>Har terminal hemma</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.nb="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.email_exists"
                  class="blink"
                  small
                  color="red"
                >
                  mdi-exclamation-thick
                </v-icon>
              </template>
              <span
                class="text-center d-flex align-center justify-space-around"
              >
                {{ item.nb }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.nrOfProperties="{ item }">
            <popup-list
              :dataToShow="item.nrOfProperties"
              title="Data om personen"
              :headers="possibleHelp"
              :items="item.moment"
            ></popup-list>
          </template>
          <template v-slot:footer.prepend>
            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Spara som urvalsgrupp
        </v-btn> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Sök"
              single-line
              hide-details
              class="mb-5"
              v-on:keyup="clearOnEscape"
            ></v-text-field>
            <v-spacer></v-spacer>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import hshApi from "../services/hshapi";
import popupList from "./popupList.vue";

export default {
  name: "AccountApplicationsList",
  components: { popupList },
  props: ["survey"],
  data() {
    return {
      dialog: false,
      selected: [],
      selected2: [1, 2, 3, 4, 5, 6, 7, 8],
      accountsApplicationsTable: [],
      haveReceived: false,
      update: 0,
      nrOfRegRequests: 0,
      singleSelect: false,
      hide: false,
      search: "",
      headers: [
        { text: "NB", value: "nb", width: "20px" },
        {
          text: "Efternamn",
          align: "start",
          sortable: true,
          value: "last_name",
        },
        { text: "Förnamn", value: "first_name", sortable: true },
        { text: "E-post", value: "email" },
        { text: "Telefonnummer", value: "phonenumber" },
        { text: "Vårdtagare", value: "ct_name" },
        { text: "Antal angivna hjälpinsatser", value: "nrOfProperties" },
        // { text: "Hantera", value: "actions", sortable: false },
      ],
      possibleHelp: [{ text: "Angivna moment", value: "text", sortable: true }],
      // moments: [
      //   { text: "Toalettbesök", value: "toiletVisit" },
      //   { text: "Dusch/bad", value: "shower" },
      //   { text: "På- och avklädning", value: "dressing" },
      //   { text: "Måltidshjälp", value: "mealAssistance" },
      //   { text: "Tillsyn och trygghetsringning", value: "supervision" },
      //   { text: "Ledsagning", value: "accompaniment" },
      //   { text: "Födelseår", value: "ct_born" },
      //   { text: "Beviljade timmar per månad", value: "ct_hours" },
      //   { text: "Inköp", value: "purchase" },
      //   { text: "Tvätt", value: "laundry" },
      //   { text: "Städning", value: "cleaning" },
      //   { text: "Trygghetslarm", value: "securityAlarm" },
      //   { text: "Ser dåligt", value: "seeBadly" },
      //   { text: "Hör dåligt", value: "hearBadly" },
      //   { text: "Svårt att hänga med när man pratar", value: "demented" },
      //   { text: "Jag använder rullator inomhus", value: "walkerHome" },
      //   { text: "Jag använder rullator utomhus", value: "walkerOut" },
      // ],
    };
  },
  beforeMount() {
    // this.$store.dispatch("fetchResponders");
    // this.$root.$off("Responderstable-form-toggle-hide")
    // this.$root.$on("Responderstable-form-toggle-hide", () => {
    //   console.log("Got hide toggle event");
    //   this.hide = !this.hide;
    // });
    console.log(
      "AccountApplicationList beforeMount - NB! Need to be change so that just one instance creted"
    );
  },
  mounted() {
    console.log("AccountApplicationList Mount");
    //   this.setSelect();
    this.fetchAccountApplication();
    console.log("AccountApplicationList Mount after fetch");
  },
  computed: {
    loadingStatus() {
      // console.log(
      //   `CreateMessage: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      // );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  methods: {
    fetchAccountApplication() {
      this.selected = [];
      hshApi
        .accountApplication()
        .then((res) => {
          if (res.status == 200) {
            this.accountsApplicationsTable = res.data;
            this.accountsApplicationsTable.forEach((u) => {
              if (u.ct_first_name == undefined) {
                u.ct_first_name = "";
              }
              if (u.ct_last_name == undefined) {
                u.ct_last_name = "";
              }
              u.ct_name = u.ct_first_name + " " + u.ct_last_name;
              if (u.ct_name === " ") u.ct_name = "-";
              u.moment = [];
              u.nb = "NB! E-postadressen finns redan";

              for (const property in u) {
                if (property == "moment") continue;
                if (property == "ct_last_name") continue;
                if (property == "ct_first_name") continue;

                const m = this.moments().find((m) => m.value == property);
                if (m == undefined) continue;
                if (property == "ct_born") {
                  // Numeric values
                  u.moment.push({
                    text: `${m.text}:${u.ct_born}`,
                    value: "ct_born",
                  });
                  continue;
                }
                if (property == "ct_hours") {
                  u.moment.push({
                    text: `${m.text}:${u.ct_hours}`,
                    value: "ct_hours",
                  });
                  continue;
                }
                // Checkbox values
                console.log("m=", m, property);
                if (m) {
                  u.moment.push({ text: m.text, value: m.value });
                } else {
                  console.log("user moment not specified", m.text);
                }
              }
              u.nrOfProperties = Object.keys(u.moment).length; // std
            });
            this.haveReceived = true;
            this.nrOfRegRequests = this.accountsApplicationsTable.length;
            console.log("AccountApplications", this.accountsApplicationsTable);
          }
        })
        .catch((err) => {
          console.error("Failed to fetch accountsApplicationsTable", err);
        });
      this.$root.$emit(
        "applicantslist-table-have-applicants",
        this.nrOfRegRequests
      );
      console.log(
        "this.accountsApplicationsTable",
        this.accountsApplicationsTable
      );
    },
    haveTerminal(item) {
      if (item.terminal_id != "") {
        return true;
      }
      return false;
    },
    setSelect() {
      const responders = this.responderTable;
      // console.log("setSelected=", responders.length,responders,this.survey,this.selected);
      for (let i = 0; i < responders.length; i++) {
        const id = responders[i].id;
        if (this.survey.respondent_ids.find((s) => s == id) == id) {
          this.selected.push(this.responderTable[i]);
          console.log(
            "setSelect is selected",
            i,
            this.responderTable[i],
            this.selected
          );
        }
      }
    },
    selectedApplicants() {
      console.log("selectedApplicants", this, this.selected2);
      return this.selected2;
    },
    createRespondersTable() {
      const responders = this.$store.state.responders.map((r) => ({
        id: r.id,
        name: r.first_name + " " + r.last_name,
        born: r.year_of_birth,
        email: "email" in r ? r.email : "",
        terminal_id: "terminal_id" in r ? r.terminal_id : "",
        // info: JSON.stringify(r.caregiver_info),
        // info: this.buildStringFromJson(r.caregiver_info.category),
        info: r.comment,
        notify_by_terminal: r.notify_by_terminal,
        notify_by_email: r.notify_by_email
      }));
      console.log("Responders=", responders, this.survey);
      return responders;
    },
    buildStringFromJson(o) {
      let res = "";
      let first = true;
      o.forEach((item) => {
        const category = Object.keys(item)[0];
        const value = this.$_.join(item[category], ",");
        res += first ? "" : ", ";
        first = false;
        res += `${category} (${value})`;
      });
      // console.log("buildStringFromJson", o, res);
      return res;
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
      console.log("Who is selected?", this.selected);
    },
    // TableSelectChange(item) {
    // if (item.value == true) {
    //   this.selected.indexOf(item.item.id) === -1 ? this.selected.push(item.item.id): () => {};//NOP
    // } else {
    //   this.selected.splice(this.selected.indexOf(item.item.id), 1);  //deleting
    // }
    // console.log("TableSelectChange ",item.value,item.item.id,this.selected,this.responderTable)
    //
    // },
    TableSelectChange(selectedItems) {
      this.selected = selectedItems;
      this.selected2 = JSON.parse(JSON.stringify(selectedItems));
      console.log("TableSelectChange ", this.selected, this.selected2);
      this.$store.state.applStore.hasUnsavedChanges = true;
      this.$emit("applicantslist-table-clicked", this.selected);
      console.log("Emitted applicantslist-table-clicked", this);
    },
    getSelectedArray() {
      return this.selected;
    },
  },
};
</script>

<style scoped></style>
