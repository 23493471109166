<template>
  <div>
    <div v-if="!hide">
      <v-icon @click="toggleDataTable"
        >mdi-arrow-down-drop-circle-outline</v-icon
      >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="forms"
        sort-by="name"
        class="elevation-1 whitespace"
        :search="search"
        dense
        :single-select="true"
        :items-per-page="3"
        :otions.sync="pagination"
        :loading="!loadingStatus"
        loading-text="Laddar..."
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Rader per sida',
          'items-per-page-options': [3, 5, 10, -1],
          'items-per-page-all-text': 'Alla',
        }"
        @click:row="handleRowClick"
      >
        <!-- @click:row="handleRowClick" -->
        <!-- v-if="loadingStatus" -->
        <!-- items-per-page=5 -->

        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.locked" small>mdi-sort-variant-lock</v-icon>
          <v-icon
            v-if="!item.locked && isOwner(item)"
            small
            @click="editFormItem(item)">
                mdi-pencil
          </v-icon>
          <v-icon
            v-if="!item.locked && isOwner(item)"
            small
            @click="deleteItem(item)">
                mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.nrOfForms="{ item }">
          {{ item.questions.length }}
        </template>
        <template v-slot:top>
          <v-toolbar flat style="display: none">
            <v-toolbar-title></v-toolbar-title>
            <!-- <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer> -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                formulär
              </v-btn>
            </template>
            <!-- <survey-form :form-title="formTitle" :formItem="formItem"></survey-form>> -->

            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="text-h5"
                  >Vill du verkligen ta bort detta formulär?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Avbryt</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:footer.prepend>
          <CreateForm buttontext="Skapa nytt formulär" />
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök formulär"
            single-line
            hide-details
            class="mb-5"
            v-on:keyup="clearOnEscape"
          ></v-text-field>
          <v-spacer></v-spacer>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-icon @click="toggleDataTable"
        >mdi-arrow-right-drop-circle-outline</v-icon
      >
      expandera listan...
    </div>
  </div>
</template>

<script>
import CreateForm from "./CreateForm.vue";
import EditForm from "./EditForm.vue";

export default {
  name: "Forms",
  props: ["buttontext","survey"],

  components: {
    CreateForm,
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      hide: false,
      // bus: new Vue(),
      headers: [
        {
          text: "Formulärnamn",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Låst  ", value: "locked" },
        { text: "Antal frågor", value: "nrOfForms" },
        { text: "Kategori", value: "topic_name" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      // form: [],
      editedIndex: -1,
      deleteCandidate: {},
      formItem: {},
      defaultFormItem: {},
      selected: [],
      pagination: {
        page: 1,
        itemsPerPage: 3,
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Ny undersökning"
        : this.survey[this.editedIndex].name;
    },
    forms() {
      this.$log.info("SurveyFormsTable: Hämtar alla formulär ");
      this.$log.info(this.$store.state.forms);
      return this.$store.getters.getForms;
      // return this.$store.state.forms
    },
    loadingStatus() {
      console.log(
        `TableForm: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },

  async beforeMount() {
    this.$root.$off("formtable-form-toggle-hide");
    this.$root.$on("formtable-form-toggle-hide", () => {
      console.log("Got hide toggle event");
      this.hide = !this.hide;
    });
    this.$log.info("FormTable beforeMount");
  },

  mounted() {
    this.focusCurrentFormId();
  },

  methods: {
    isOwner(item) {
      try {
        return item.owner_org_id == this.$keycloak.tokenParsed.organisation;
      } catch {
        return false;
      }
    },

    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },

    focusCurrentFormId() {
      const item = this.$store.getters.getCurrentForm;
      if (item != null) {
        this.focusQuestionForm(item);
      }
    },

    focusQuestionForm(item) {
      console.log("focusQuestionForm", item);
      this.$root.$emit("formtable-form-clicked", item);
      this.selected = [];
      this.selected.push(item);
      // this.jumpToSelection();
    },

    jumpToSelection: function () {
      this.$nextTick(() => {
        let selected = this.selected[0];
        let page = Math.ceil(
          (this.forms.indexOf(selected) + 1) / this.pagination.rowsPerPage
        );
        this.pagination.sortBy = "id";
        this.$nextTick(() => {
          this.pagination.page = page;
        });
      });
    },

    handleRowClick(item) {
      console.log("FormTable: You clicked " + item.name);
      console.log(this.selected);
      this.$store.dispatch("setCurrentFormId", item.id);
      this.focusQuestionForm(item);
    },

    async editFormItem(item) {
      // alert("You clicked pen " + item.name);
      this.editedIndex = this.forms.indexOf(item);
      this.formItem = Object.assign({}, item);
      console.log("editFormItem");
      const changedForm = await this.$dialog.show(EditForm, {
        waitForResult: true,
        title: "Ändra formulär",
        $store: this.$store,
        form: item,
      }); // Show dialog
      console.log("editFormItem gave dialog res", changedForm);
      if (changedForm != "cancel") {
        // Make a new responder
        this.$store.dispatch("saveForm", changedForm);
      }
    },

    deleteItem(item) {
      this.editedIndex = this.forms.indexOf(item);
      this.deleteCandidate = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.forms.splice(this.editedIndex, 1);
      this.$store.dispatch("deleteForm", this.deleteCandidate);
      this.$root.$emit("formtable-form-clicked", null);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.formItem = Object.assign({}, this.defaultFormItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formItem = Object.assign({}, this.defaultFormItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.forms[this.editedIndex], this.formItem);
      } else {
        this.forms.push(this.formItem);
      }
      this.close();
    },

    itemRowBackground(item) {
      if (this.selected.length <= 0) {
        return "style-2";
      }
      console.log(
        "itemRowBackground FormTable called " +
          item.id +
          " " +
          this.selected[0].id
      );
      return item.id == this.selected[0].id ? "style-1" : "style-2";
    },

    toggleDataTable() {
      this.$root.$emit("formtable-form-toggle-hide");
    },

  },
};
</script>

<style scoped>
.style-1 {
  background-color: rgb(53, 32, 146) !important;
}
.style-2 {
  background-color: rgb(114, 114, 67) !important;
}
</style>
