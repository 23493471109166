<template>
  <v-container>
    <FilterTable></FilterTable>
  </v-container>
</template>

<script>
import FilterTable from "../components/FilterTable.vue"

export default {
  components:{
    FilterTable
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>