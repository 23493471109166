var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DialogCard",
        {
          attrs: { title: _vm.title, actions: _vm.actions, "min-width": "100%" }
        },
        [
          _c("v-subheader", { staticClass: "pl-0" }, [_vm._v("Fraser")]),
          _c(
            "v-list",
            { attrs: { dense: "", elevation: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.selectedPhrase2,
                    callback: function($$v) {
                      _vm.selectedPhrase2 = $$v
                    },
                    expression: "selectedPhrase2"
                  }
                },
                _vm._l(_vm.getMediaFiles, function(item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.id,
                      staticClass: "smaller",
                      on: {
                        click: function($event) {
                          return _vm.changePhrase(item)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "smaller" },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.playSound(item.id)
                                }
                              }
                            },
                            [_vm._v(" mdi-play-box-outline ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "smaller" },
                        [
                          _c("v-list-item-title", {
                            attrs: { value: "item.id" },
                            domProps: { textContent: _vm._s(item.text) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }