var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    {
      attrs: {
        title: "Ändra fråga (Språk: Svenska) ID:" + _vm.quest.id,
        actions: _vm.actions
      }
    },
    [
      _c("div", { staticClass: "shrink" }, [
        _vm._v("Skapare:" + _vm._s(_vm.token.name))
      ]),
      _c(
        "v-container",
        [
          _c("h2", [_vm._v("Frågan")]),
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-10",
                  attrs: { color: "primary" },
                  on: { click: _vm.HshUploadFileActivate }
                },
                [_vm._v(" Ladda upp media filer... ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "1" } },
                [
                  _c("hsh-color", {
                    attrs: {
                      color: _vm.quest.ui_hint.bgcolor,
                      title: "Frågefärg"
                    },
                    on: { newColor: _vm.QuestColorChange }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("HshImage", {
                    attrs: { label: "Frågeikon" },
                    model: {
                      value: _vm.questIcon,
                      callback: function($$v) {
                        _vm.questIcon = $$v
                      },
                      expression: "questIcon"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "9" } },
                [
                  _c("v-text-field", {
                    staticClass: "shrink",
                    attrs: { label: "Frågan:" },
                    model: {
                      value: _vm.quest.i18n.sv.text,
                      callback: function($$v) {
                        _vm.$set(_vm.quest.i18n.sv, "text", $$v)
                      },
                      expression: "quest.i18n.sv.text"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "1" } },
                [
                  _vm.isAudioPresent
                    ? _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "" },
                          on: {
                            click: function($event) {
                              return _vm.playSound(
                                _vm.quest.i18n.sv.media.audio.id
                              )
                            }
                          }
                        },
                        [_vm._v(" mdi-play-box-outline ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "shrink",
                    attrs: { label: "Nuvarande fras text:", readonly: "" },
                    model: {
                      value: _vm.description,
                      callback: function($$v) {
                        _vm.description = $$v
                      },
                      expression: "description"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c("v-text-field", {
                    staticClass: "shrink",
                    attrs: { label: "Nuvarande fras namn:", readonly: "" },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { color: "primary" },
                      on: { click: _vm.HshPhrasePickerQuest }
                    },
                    [_vm._v("Välj ny fras...")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.queryTopics,
                      label: "Kategori",
                      required: ""
                    },
                    model: {
                      value: _vm.queryTopic,
                      callback: function($$v) {
                        _vm.queryTopic = $$v
                      },
                      expression: "queryTopic"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.queryTypes,
                      label: "Typ av fråga",
                      required: ""
                    },
                    model: {
                      value: _vm.queryType,
                      callback: function($$v) {
                        _vm.queryType = $$v
                      },
                      expression: "queryType"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c("h2", [_vm._v("Svarsalternativ")]),
          _c("br"),
          _vm._l(_vm.quest.answers, function(answer, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "v-card",
                  { attrs: { elevation: "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "10" } },
                          [_c("EditAnswer", { attrs: { answer: answer } })],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "py-2",
                                attrs: { large: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteAnswer(answer, index)
                                  }
                                }
                              },
                              [_vm._v(" mdi-delete ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("br"),
                _c("v-divider")
              ],
              1
            )
          }),
          _c(
            "v-row",
            [
              _c(
                "v-btn",
                {
                  staticClass: "pa-4 mx-4",
                  attrs: { color: "primary" },
                  on: { click: _vm.CreateNewAnswer }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v(" mdi-plus-thick ")
                  ]),
                  _vm._v(" Lägg till svar... ")
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }