<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card class="mx-10 px-10 py-10" min-width="744" max-width="744">
            <h2>Här kommer vi ha lite allmän information</h2>
            <br />
            T.ex. vem du är inloggad som och lite om ditt kontos rättigheter
            <br />
            <p class="pt-4">
              Man kommer också få en snabb överblick över vilka undersöknignar
              som är pågående
            </p>
            <p class="pt-4">
              Ev lite data över hur många Respondenter som finns i databasen
              etc.
            </p>
            <template>
              <div>
                <v-btn color="primary" @click="downloadPDFquestionsDocument">
                  Hämta alla nuvarande frågor
                </v-btn>
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-card cols="8" class="mx-10 px-10 mb-10" max-width="744">
        <v-card-title class="mt-10"> Översikt av begrepp </v-card-title>
        <v-card
          v-for="(concept, index) in concepts"
          :key="index"
          class="mx-auto mb-10"
          max-width="644"
        >
          <v-card-title>
            {{ concept.title }}
          </v-card-title>

          <v-card-subtitle>
            {{ concept.explanation }}
          </v-card-subtitle>

          <v-card-actions>
            <v-btn color="orange lighten-2" text> Mer info... </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="concept.show = !concept.show">
              <v-icon>{{
                concept.show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="concept.show">
              <v-divider></v-divider>

              <v-card-text>
                <span v-html="concept.text" />
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import HelloWorld from "../components/HelloWorld";
import hshApi from "../services/hshapi";

export default {
  name: "Home",
  data() {
    return {
      show: false,
      concepts: [
        {
          title: "Uppdragsgivare",
          explanation:
            "Vår kund. Kan vara utförande bolag t.ex. vårdstyrkan men även kommun, Socialstyrelsen etc.",
          text: "<p>Vår kund. Kan vara utförande bolag t.ex. vårdstyrkan men även kommun, Socialstyrelsen etc. </p><p>Uppdragsgivare bör vara beskrivna i en hierarkisk struktur, jfr. Sollentuna kommun styr Vårdstyrkan</p>",
          show: false,
          index: 0,
        },
        {
          title: "Undersökning",
          explanation:
            "En undersökning har till syfte att ta reda på ett förhållande.",
          text: "<p>En undersökning har till syfte att ta reda på ett förhållande.</p><p>En undersökning kan bestå av en eller flera formulär.</p><p>En undersöklning består av en eller flera urvalsgrupper (eller skall det bara vara en urvalsgrupp?)</p><p>En undersökning riktar sig mot en utvald grupp av respondenter</p><p>En undersökning kan bestå av formulär som används vid olika tidpunkter t.ex. före/efter en åtgärd</p><p>En undersökning kan bestå av olika grupperingar av respondenter</p><p>En undersökning skall kunna delas mellan olika uppdragsgivare – hur? (privat/publik)</p>",
          show: false,
        },
        {
          title: "Formulär",
          explanation: "Ett formulär består av ett antal frågor.",
          text: "<p>Ett formulär består av ett antal frågor</p><p>Ett formulär bör kunna återanvändas</p><p>Ett formulär kan riktas mot olika respondenter, med logik om vem som får vilken fråga</p><p>Ett formulär bör kunna återanvändas</p>",
          show: false,
        },
        // {
        //   title: "Urvalsgrupp",
        //   explanation:
        //     "En utsortering av respondenter som skall ingå i en undersökning.",
        //   text: "<p>En utsortering av respondenter som skall ingå i en undersökning</p><p>Denna grupp skall kunna filtreras ut bland respondenter beroende av kategori och uppdragsgivare och tidigare svar t.ex. Respondent har varit med i en tidigare undersökning, formulär, eller tidigare urvalsgrupp</p>",
        //   show: false,
        // },
        {
          title: "Frågor",
          explanation:
            "Är just en fråga som skall kunna besvaras, med ett antal alternativ.",
          text: "<p>Är just en fråga som skall kunna besvaras, med ett antal alternativ</p><p>En öppen fråga kan existera men kan inte användas mot vår huvudgrupp – hemtjänstmottagare</p><p>En fråga skall finnas på flera språk och i text- och ljudform (webfråga för mobil, dator och ”vår terminal”, IVR) </p><p>Frågor är generella och skall kunna återanvändas</p>",
          show: false,
        },
        {
          title: "Selektering / Filter",
          explanation: "En logik som hanteras vem som får vilken fråga.",
          text: "<p>En logik som hanteras vem som får vilken fråga</p><p>Logiken bör styras av tidigare svar, både i samma formulär som tidigare undersökningar</p><p>Logiken bör styras av kategoriprofilen hos respondenterna knutna till formuläret och undersökning</p>",
          show: false,
        },
        {
          title: "Svar",
          explanation: "Ett svar är ett just ett svar på en fråga.",
          text: "<p>Ett svar är ett just ett svar på en fråga</p><p>Ett svar ska kunna härledas till respondent, formulär, undersökning, uppdragsgivare, kategoriinställning vid frågetillfället  och tidpunkt</p>",
          show: false,
        },
        {
          title: "Respondent",
          explanation: "En respondent är den person som besvarar frågan.",
          text: "<p>En respondent är den person som besvarar frågan</p><p>En respondent är oftast en hemtjänstmottagare, men kan vara en hemtjänstutförare, närstående, administratör (både vårdbolag och/eller ansvarig på kommunen) </p><p>En respondent är alltid knuten mot minst en kategori, men självklart kan den vara mot fler</p><p>En respondentlog som ”bokför” alla förändringar i kategoritillhörighet </p><p>En respondent förblir en respondent, men vi behöver ha kategori aktiv vs passiv</p>",
          show: false,
        },
        {
          title: "Respondentstatistik",
          explanation:
            "En respondentstatistik är personlig statistik kring respondentens förmåga att besvara frågor.",
          text: "<p>En respondentstatistik är lite personlig statistik kring respondentens historiska förmåga att besvara frågor.</p><p>Denna statistik knyts också mot mikrobelöningar och återkoppling till respondenten</p>",
          show: false,
        },
        {
          title: "Kategori",
          explanation:
            "En kategori beskriver ett attribut (kön, födelseår, Clinical Frailty Scale, utbildningsgrad, modersmål etc).",
          text: "<p>En kategori beskriver ett attribut (kön, födelseår, Clinical Frailty Scale, utbildningsgrad, modersmål etc)</p><p>En kategori består av minst ett attribut </p><p>En respondent behöver inte vara knuten mot alla kategorier</p><p>Varje kategori består av ett antal attribut (minst 1), eller en siffra (vikt, födelseår) </p><p>En kategori kan vara allmän eller tillhöra en viss uppdragsgivare</p>",
          show: false,
        },
      ],
    };
  },
  components: {
    // HelloWorld,
  },
  mounted() {
    this.$log.warn();
    `The time is ${this.$moment().format("HH:mm")}`;
    this.$log.info(this.$templates.form);
  },
  methods: {
    async downloadPDFquestionsDocument() {
      try {
        // Make GET request to retrieve HTML document
        const response = await hshApi.getQuestionPdfDocument("questions");

        const html = response.data;

        // Create a blob from the HTML document
        const blob = new Blob([html], { type: "text/pdf" });

        // Create a link element to click
        const link = document.createElement("a");

        // Set the link element's href to point to the blob
        link.href = window.URL.createObjectURL(blob);

        // Set the link element's download attribute
        link.download = "Frågor hemkontakt.pdf";

        // Append the link element to the document
        document.body.appendChild(link);

        // Click the link element to trigger the download
        link.click();

        // Remove the link element from the document
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
