var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    { attrs: { title: "Lägg till Respondent", actions: _vm.actions } },
    [
      _c("v-text-field", {
        attrs: { label: "Förnamn" },
        model: {
          value: _vm.first_name,
          callback: function($$v) {
            _vm.first_name = $$v
          },
          expression: "first_name"
        }
      }),
      _c("v-text-field", {
        attrs: { label: "Efternamn" },
        model: {
          value: _vm.last_name,
          callback: function($$v) {
            _vm.last_name = $$v
          },
          expression: "last_name"
        }
      }),
      _c("v-text-field", {
        attrs: { label: "Födelseår" },
        model: {
          value: _vm.year_of_birth,
          callback: function($$v) {
            _vm.year_of_birth = $$v
          },
          expression: "year_of_birth"
        }
      }),
      _c("v-text-field", {
        attrs: { label: "E-post" },
        model: {
          value: _vm.emailaddress,
          callback: function($$v) {
            _vm.emailaddress = $$v
          },
          expression: "emailaddress"
        }
      }),
      _c("v-text-field", {
        attrs: { label: "Kommentar" },
        model: {
          value: _vm.info,
          callback: function($$v) {
            _vm.info = $$v
          },
          expression: "info"
        }
      }),
      _c("v-checkbox", {
        staticClass: "align-center justify-center",
        attrs: { color: "gray" },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { left: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "span",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "align-center justify-center pt-3"
                                  },
                                  "span",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("e-postavisering")]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Undersökningar och meddelanden kommar att aviseras via e-post "
                      )
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.notify_by_email,
          callback: function($$v) {
            _vm.notify_by_email = $$v
          },
          expression: "notify_by_email"
        }
      }),
      _c("v-checkbox", {
        staticClass: "align-center justify-center",
        attrs: { color: "gray" },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { left: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "span",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "align-center justify-center pt-3"
                                  },
                                  "span",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("terminalavisering")]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Undersökningar och meddelanden kommar att aviseras via notiser eller direkt i hemtermianlen "
                      )
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.notify_by_terminal,
          callback: function($$v) {
            _vm.notify_by_terminal = $$v
          },
          expression: "notify_by_terminal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }