<template>
  <DialogCard :actions="actions" class="dialog-card">
    <v-row fluid class="dialog-card">
      <v-col cols="6">
        <v-card-title class="mb-4 pb-4 blue darken-4 white--text">
          Ändra/skapa filter {{ item.name }}
          <span v-if="$store.state.applStore.hasUnsavedChanges"
            >&nbsp;- Ej i synk med servern - synkronsiera eller hämta från
            serven
          </span>
        </v-card-title>
        <v-form v-model="valid" ref="form" class="py-6">
          <v-text-field
            v-model="item.name"
            :disabled="type == 'edit'"
            label="Name"
            class="px-2"
            :rules="nameRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="item.expression"
            class="px-2"
            label="Filterdefinition"
          ></v-text-field>
        </v-form>
        <v-btn class="py-4 my-4" color="primary" @click="testFilter">
          Prova filtret
        </v-btn>
        <v-btn class="pa-4 ma-4" color="primary" @click="clearFilter">
          Rensa
        </v-btn>
        <div class="mb-5" v-if="nrOfHits >= 0">
          Testet gav <b>{{ nrOfHits }} st träffar</b> med denna definition på filter.
          Upp till de 10 första visas nedan
          <v-data-table
            class="pt-5"
            v-model="selected"
            :headers="headersresponder"
            :items="responders"
            :hide-default-header="false"
            :hide-default-footer="true"
            disable-pagination
          >
          </v-data-table>
        </div>
        <div v-else>
          <v-card>
            <v-card-title class="my-1 py-1 black white--text">
              Filters
            </v-card-title>
            <v-card-text class="hsh">
              Ett filter har ett unikt namn, vissa är fördefinierade, och en
              filterdefinition (expression el uttryck). Filterdefinitionen måste 
              vara ett giltigt "Python-expression" med filtervariabel som variabelnamn.
            </v-card-text>
            <p>Exampel:</p>

            <p>Alla män: <b>"kön == 'man'"</b></p>
            <v-card-text class="hsh">
              Parenteser och logiska uttryck (and, or, not) kan användas:
            </v-card-text>
            <p>Exampel:</p>

            <p>Ej män: <b>"(kön == 'kvinna') or (kön == 'annat')"</b></p>
            <v-card-text class="hsh">
              Följande Python-funktioner kan också användas: len
            </v-card-text>
            <p>Exampel:</p>

            <p>Gamla: <b>"1920 &lt; född &lt; 1930"</b></p>
            <v-card-text class="hsh">
              Listor kan också undersökas. Om en respondent har filtervariabeln
              besöksdagar = [måndag,torsdag], som har värdemängden
              [måndag|tisdag|onsdag|torsdag|fredag|lördag] så kan man skapa
              följande filter..
            </v-card-text>

            <p>Exempel:</p>
            <p>Alla med torsdagar: <b>"'torsdag in besöksdagar"</b></p>

            <v-card-text class="hsh">
              Alla med måndagar och torsdagar:
              <b>"('torsdag' in besöksdagar) and ('måndag' in besöksdagar)"</b>

              Alla med måndagar eller torsdagar:
              <b>"('torsdag' in besöksdagar) or ('måndag' in besöksdagar)"</b>
            </v-card-text>
            <v-card-text class="hsh">
              Tider datum och/eller tid på dygnet i ett filter beskriver man dessa på följande sätt..
            </v-card-text>
              <p> Datum och tid: "<b>mindatumtid > dtm('2022-12-01 12:00:00')</b>"</p>
              <p> Bara tid:"<b>mintid > tm('12:00:00')</b>" </p>
              <p> Bara datum: "mittdatum > dt('2022-12-10')"</p>
          </v-card>
        </div>
      </v-col>
      <v-col cols="6">
        <v-card-title class="my-1 py-1 black white--text">
          Filterparametrar för information
        </v-card-title>

        <v-data-table
          v-show="haveReceived"
          v-model="selected"
          :items="filterAttributes"
          :headers="headers"
          item-key="id"
          class="elevation-1"
          :items-per-page="-1"
          :height="600"
          :footer-props="{
            'items-per-page-text': 'Rader per sida',
            'items-per-page-all-text': 'Alla',
          }"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </DialogCard>
</template>

<script>
import hshApi from "../services/hshapi";

export default {
  props: ["title", "$store", "type", "item"],
  data() {
    return {
      topic_name: "",
      valid: true,
      nameRules: [(v) => !!v || "Name is required"],
      filterAttributes: [],
      nrOfHits: -1,
      haveReceived: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Filtervariabel/respondent\xa0data", value: "name" },
        { text: "Beskrivning", value: "description" },
        { text: "Filterkategori", value: "groupname" },
        { text: "Typ", value: "type" },
        { text: "Möjliga värden", value: "valid_values" },
      ],
      selected: [],
      responders: [],
      headersresponder: [
        { text: "Id", value: "id" },
        { text: "Förnamn", value: "first_name" },
        { text: "Efternamn", value: "last_name" },
        { text: "e-post", value: "mail" },
      ],
      //   queryTopics:['adam','bertil']
    };
  },
  created() {
    console.log("EditFilter created", this.item);
  },
  computed: {
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: this.type == "edit" ? "Uppdatera" : "Skapa",
          color: "blue darken-1",
          handle: async () => {
            try {
              if (this.type == "edit") {
                await this.updateFilter();
              } else {
                await this.createFilter();
              }
            } catch (error) {
              await this.$dialog.error({
                text: `Misslyckades med att spara (${error.message} - ${error.response.data})`,
                title: `Varning!`,
                actions: {
                  ok: {
                    text: "OK",
                    color: "blue darken-1",
                    handle: () => {
                      return undefined;
                    },
                  },
                },
              });
            }
            return this.item;
          },
        },
      };
    },
  },
  methods: {
    async updateFilter() {
      try {
        const res = await hshApi.updateFilter(
          this.$keycloak.tokenParsed.organisation,
          this.item
        );
        this.$store.state.applStore.hasUnsavedChanges = false;
        console.log("updateFilter", res);
      } catch (error) {
        await this.$dialog.error({
          text: `Filtervärden gick inte att uppdatera (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
    },
    async createFilter() {
      try {
        const res = await hshApi.createFilter(this.$keycloak.tokenParsed.organisation,this.item);
        this.$store.state.applStore.hasUnsavedChanges = false;
        console.log("createFilter", res);
      } catch (error) {
        await this.$dialog.error({
          text: `Filtervärden gick inte att skapa (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
    },
    async fetchAttributes() {
      try {
        const res = await hshApi.getFilterAttributes(
          this.$keycloak.tokenParsed.organisation
        );
        console.log("EditFilter: fetchAttributes", res);
        this.filterAttributes = res;
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch getFilterAttributes",
          err
        );
      }
    },
    async testFilter() {
      try {
        this.nrOfHits = -1;
        this.responders = [];
        const name=this.item.name
        this.item.name = this.$keycloak.tokenParsed.uuid
        await hshApi.createFilter(this.$keycloak.tokenParsed.organisation,this.item)
        const res = await hshApi.runFilter(this.item.name);
        await hshApi.deleteFilter(this.$keycloak.tokenParsed.organisation,this.item)
        this.item.name = name
        console.log("testFilter: fetchAttributes", res);
        this.nrOfHits = res.length;
        const allrespondents = this.$store.getters.getReponders;
        const temp = allrespondents.filter((r) => res.includes(r.id));
        this.responders = temp.slice(0, 10);

        console.log(
          "testFilter: fetchAttributes responders",
          this.responders,
          allrespondents,
          res
        );
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch result from runFilter",
          err
        );
      }
    },
    clearFilter() {
      this.nrOfHits = -1;
      this.responders = [];
    },
  },
  async mounted() {
    console.log("EditFilter: mounted");
    await this.fetchAttributes();
    this.haveReceived = true;
  },
};
</script>
<style scoped>
.dialog-card {
  width: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  margin: 0px;
}
p {
  text-align: left;
  color: darkblue;
  padding-left: 12px;
  margin: 0 !important;
}
.hsh {
  padding: 4px;
  padding-left: 12px;
}
</style>
