<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <QuestionsTable />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <QuestionForm />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import QuestionsTable from "../components/QuestionsTable.vue";
import QuestionForm from "../components/QuestionForm2.vue";
// import FormsForm from "../components/FormsForm.vue";
export default {
  components: {
    QuestionsTable,
    QuestionForm,
    // FormsForm
  },
  data() {
    return {
      data: -1, // Not used
    };
  },
  //   beforeMount() {
  //     this.$store.dispatch("fetchForms", -1);
  //     console.log("Forms:beforeMount...");
  //   },
};
</script>
