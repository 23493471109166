var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [_vm.ready ? _c("v-col", [_c("FormsTable")], 1) : _vm._e()],
        1
      ),
      _c("v-row", [_vm.ready ? _c("v-col", [_c("FormsForm")], 1) : _vm._e()], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }