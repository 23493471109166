<template>
  <div @mouseleave="outOfComp()">
    <div class="text-center">
      <v-menu
        v-model="menu"
        open-on-hover
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="extra-info">
            {{ dataToShow }}
          </span>
        </template>
        <v-card flat>
          <v-card-title class="my-1 py-1 black white--text">
            {{ title }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="items"
              :hide-default-header="false"
              :hide-default-footer="true"
              disable-pagination
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "headers", "items", "dataToShow"],
  data() {
    return {
      search: "",
      //   headers: [
      //     { text: "One", align: "left", value: "one" },
      //     { text: "Two", value: "two" },
      //     { text: "Three", value: "three" },
      //     { text: "Actions", align: "center", value: "actions", width: "200px" },
      //   ],
      selected: [],
      //   items: [
      //     { one: 1, two: 2, three: 3, id: 1 },
      //     { one: 1, two: 2, three: 3, id: 2 },
      //     { one: 1, two: 2, three: 3, id: 3 },
      //     { one: 1, two: 2, three: 3, id: 4 },
      //   ],
      selectedItem: false,
      // outOfCompFlag: false,
      menu: false,
    };
  },
  mounted() {
    console.log("popupList mounted", this.headers);
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
      console.log("popupList selected", item);
    },
    unSelectItem(item) {
      this.selectedItem = false;
      console.log("popupList unSelected", item);
    },
    outOfComp() {
      console.log("popupList outOfComp", this.menu);
      // this.outOfCompFlag = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  font-style: italic !important;
  font-size: 0.9rem !important;
  height: 32px !important;
  vertical-align: text-top !important;
}
.extra-info {
  border: 1px solid;
  padding: 3px;
}
td {
  vertical-align: middle !important;
}
</style>
