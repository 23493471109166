var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("AccountApplicationsList", {
                ref: "applicants",
                on: { "applicantslist-table-clicked": _vm.tableClicked }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-4",
                  attrs: { color: "success", disabled: !_vm.activeButtons },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.acceptList.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " Godkänn markerade användare (" +
                      _vm._s(_vm.count) +
                      " st) "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4",
                  attrs: { color: "error", disabled: !_vm.activeButtons },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.rejectList.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " Avvisa och ta bort markerade användare (" +
                      _vm._s(_vm.count) +
                      " st) "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }