var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingStatus
    ? _c("div", [
        _vm.isThereACurrentSurvey
          ? _c(
              "div",
              [
                _c(
                  "v-form",
                  {
                    attrs: { "max-width": "90%" },
                    model: {
                      value: _vm.dialog,
                      callback: function($$v) {
                        _vm.dialog = $$v
                      },
                      expression: "dialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _vm._v(
                            ' Formulärhantering för undersökning "' +
                              _vm._s(_vm.surveyName) +
                              '" '
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "4", sm: "6", md: "4" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "Namn" },
                                          model: {
                                            value: _vm.surveyName,
                                            callback: function($$v) {
                                              _vm.surveyName = $$v
                                            },
                                            expression: "surveyName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-col", {
                                      attrs: { cols: "6", sm: "3", md: "2" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "8" } },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "elevation-10 pa-4" },
                                          [
                                            _c("h3", { staticClass: "mb-5" }, [
                                              _vm._v("Ingående Formulär")
                                            ]),
                                            _c("v-divider", {
                                              staticClass: "mb-5"
                                            }),
                                            _c("SurveyFormsTable", {
                                              key: _vm.rerenderTrigger,
                                              ref: "formsList",
                                              attrs: {
                                                survey: _vm.currentSurvey
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "4" } })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "blue darken-1", text: "" },
                                on: { click: _vm.close }
                              },
                              [_vm._v(" Avbryt ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: !_vm.possibleToSave
                                },
                                on: { click: _vm.save }
                              },
                              [_vm._v(" Spara ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }