const applStore = {
  state: () => ({
    // Other flags like user capabilities
    // PrasePrompts flags
    nosound: false,
    playYesNoButtoninstruction: true,
    questionnaireType: 1,
    hasUnsavedChanges: false,
  }),
  mutations: {
    SET_QUESTIONNAIRE_TYPE(state, payload) {
      state.questionnaireType = payload;
    },
    SET_NO_SOUND(state, payload) {
      state.nosound = payload == false;
    },
  },
  actions: {
    // usage:
    // console.log('setQuestionnaireType now dispatch')
    // this.$store.dispatch('setQuestionnaireType',2)

    async setQuestionnaireType(context, type) {
      const { commit } = context;
      try {
        commit("SET_QUESTIONNAIRE_TYPE", type);
      } catch (e) {
        console.log(e);
      }
    },
    async setSound(context, type) {
      const { commit } = context;
      try {
        commit("SET_NO_SOUND", type);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    getQuestionnaireType: (state) => () => {
      return state.questionnaireType;
    },
    getNoSound: (state) => () => {
      return state.nosound;
    },
  },
};
export default applStore;
