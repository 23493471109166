var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: { items: _vm.imageArr, label: _vm.label },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("img", {
              staticClass: "imageUrl pt-2 mr-4",
              attrs: { src: item.url }
            }),
            _vm._v(_vm._s(item.id) + " ")
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("img", {
              staticClass: "imageUrl pt-2 mr-4",
              attrs: { src: item.url }
            }),
            _vm._v(_vm._s(item.id) + " ")
          ]
        }
      }
    ]),
    model: {
      value: _vm.inputVal,
      callback: function($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }