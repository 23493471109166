<template>
  <div>
    <v-container class="px-0">
      <v-card class="px-6 py-4" max-width="1920">
        <v-row>
          <v-col cols="2">
            <v-card-title class="text-h5"> Meddelande </v-card-title>
          </v-col>
          <v-col cols="2">
            <!-- :class="{ notSet: !isImportanceSet }" -->
            <v-select
              v-model="importance"
              :background-color="
                isImportanceSet ? null : 'rgba(255, 0, 0, 0.5)'
              "
              :items="importanceItems"
              item-text="name"
              label="Meddelandetyp"
              outlined
              @change="setImportance"
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-card :class="{ notSet: validUntilTime == null, 'pa-4': true }">
              <span class="text-h6 mb-3"> Giltigt till kl: </span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="setTimeFast"
                  >
                    mdi-calendar
                  </v-icon>
                </template>
                <span> Meddelandet försvinner efter denna tid </span>
              </v-tooltip>
              <p/>
              <v-datetime-picker
                class="pt-3 mt-3"
                label="Datum och tid"
                v-model="validUntilTime"
                timeFormat="HH:mm"
                :time-picker-props="timeProps"
                clearText="Avbryt"
                :textFieldProps="dateTimeTextFieldProps"
                @input="dateTimeGiven"
                ref="dateTimeRef"
              >
              </v-datetime-picker>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-card elevation="0" max-width="800px">
              <v-tiptap
                :class="{ notSet: !isMessageSet }"
                v-model="textvalue"
                placeholder="Skriv ditt meddelande här"
                ref="editor"
                max-width="800px"
              >
              </v-tiptap>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="12">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="formIsFilledIn == false"
              elevation="2"
              color="primary"
              @click="sendTheMessage"
            >
              Skicka meddelandet
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-divider></v-divider>
      <div v-if="loadingStatus == false">
        <v-card color="primary" dark>
          <v-card-text>
            Var god dröj...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-card-title class="text-h5">
                <v-col cols="2"> Skicka till: </v-col>
                <v-col cols="5">
                  <v-card class="pa-4">
                    <span class="text-h6"> Skicka senare: </span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="grey" dark v-bind="attrs" v-on="on">
                          mdi-calendar
                        </v-icon>
                      </template>
                      <span> Om annan tid än nu genast </span>
                    </v-tooltip>
                    <v-datetime-picker
                      class="mt-3"
                      label="Datum och tid"
                      v-model="sendTime"
                      timeFormat="HH:mm"
                      :time-picker-props="timeProps"
                      clearText="Avbryt"
                      :textFieldProps="dateTimeTextFieldProps"
                      @input="dateTimeGiven"
                    >
                    </v-datetime-picker>
                  </v-card>
                </v-col>
              </v-card-title>
            </v-col>
          </v-row>
          <!-- Filter select part BEGIN -->
          <div>
            <v-divider class="mb-5"></v-divider>
            <h3 class="mb-5">
              Filter just nu {{ nrOfMatchingRespondents }} respondenter
            </h3>
            <v-select
              v-model="filterToUse"
              :items="allFilterNames"
              chips
              deletable-chips
              clearable
              label="Pivot"
              multiple
              outlined
              prepend-icon="mdi-filter-variant"
              solo
              @change="filterChoosen"
              :menu-props="{ y: 'below', offsetY: true }"
            ></v-select>
            <v-divider class="mb-5"></v-divider>
          </div>
          <!-- Filter select part END -->
          <v-row class="mx-5">
            <RespondersList :survey="currentSurvey" ref="respondersList" />
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import RespondersList from "../components/RespondersList.vue";
import DatetimePicker from "vuetify-datetime-picker";
import hshApi from "../services/hshapi";

// import { Editor } from '@tiptap/vue-2'
// {Edirot,EditorContent } ???  import StarterKit from '@tiptap/starter-kit'

Vue.use(DatetimePicker);
export default {
  components: {
    RespondersList,
  },
  data() {
    return {
      importanceItems: [
        { value: 1, name: "Mycket viktigt" },
        { value: 2, name: "Ganska Viktigt" },
        { value: 3, name: "Normalt" },
        { value: 4, name: "Oviktigt" },
      ],
      textvalue: {},
      currentSurvey: {},
      editedItem: {},
      setTimeFlag: true,
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
        format: "24hr",
      },
      dateTimeTextFieldProps: {},
      sendTime: null,
      validUntilTime: null,
      rerenderTrigger: 0,
      formIsFilledIn: false,
      // editor: Editor,
      isImportanceSet: false,
      importance: {},
      isMessageSet: false,

      // Filter associated variables
      allFilters: [],
      allFilterNames: [],
      filterToUse: [],
      nrOfMatchingRespondents: 0,
      dateTimeRef: null,
    };
  },
  computed: {
    loadingStatus() {
      // console.log(
      //   `CreateMessage: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      // );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  methods: {
    async setTimeFast() {
      let res = await this.$dialog.info({
        text: `Giltighetstid för meddelandet`,
        title: `Snabbinställning av giltighetstid`,
        actions: {
          hour1: {
            text: "1 timme",
            color: "blue darken-1",
            handle: () => {
              return this.$moment().add(1, "hours").format("YYYY-MM-DD HH:mm");
            },
          },
          hour2: {
            text: "2 timmar",
            color: "blue darken-1",
            handle: () => {
              return this.$moment().add(2, "hours").format("YYYY-MM-DD HH:mm");
            },
          },
          hour4: {
            text: "4 timmar",
            color: "blue darken-1",
            handle: () => {
              return this.$moment().add(4, "hours").format("YYYY-MM-DD HH:mm");
            },
          },
          hour8: {
            text: "8 timmar",
            color: "blue darken-1",
            handle: () => {
              return this.$moment().add(4, "hours").format("YYYY-MM-DD HH:mm");
            },
          },
        },
      });
      this.validUntilTime = res;
      console.log("setTimeFast clicked", res);
    },
    async sendTheMessage() {
      const respondersArray = this.$refs.respondersList.getSelectedArray();
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill skicka meddelandet till ${respondersArray.length} mottagare?`,
        title: `Bekräftelse av skicka meddelande`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      if (res === "ok") {
        let respondersIdArray = [];
        respondersArray.forEach((i) => {
          respondersIdArray.push(i.id);
        });
        let t = this.$moment().format();
        if (this.sendTime != null) {
          t = this.$moment(this.sendTime).format();
        }
        const timeUtcFormat = this.$moment(this.validUntilTime).format();
        console.log(
          "sendTheMessage",
          respondersIdArray,
          this.importance,
          t,
          this.textvalue,
          timeUtcFormat
        );

        const message = {
          text: this.textvalue,
          importance: this.importance,
          send_at: t,
          send_to: respondersIdArray,
          valid_to: timeUtcFormat,
        };
        console.log("sendTheMessage compleated", message);
        try {
          const resultFromMessage = await hshApi.createMessage(message);
          console.log("sendTheMessage", resultFromMessage);
          this.$dialog.notify.success(
            `Meddelandet skickat till ${respondersIdArray.length} mottagare`,
            {
              position: "center",
              timeout: 2000,
            }
          );
        } catch (error) {
          console.error("sendTheMessage dispatch", error);
        }

        this.$store.state.applStore.hasUnsavedChanges = false;
      }
    },
    setImportance() {
      this.isImportanceSet = true;
      this.$store.state.applStore.hasUnsavedChanges = true;
      this.setSendbuttonState();
    },
    setTimeForMessage() {
      console.log("setTimeForMessage", this.dateTimeRef);
      this.setTimeFlag = true;
      //   const changedForm = await this.$dialog.show(EditForm, { waitForResult: true, title:'Ändra formulär',$store:this.$store,form:item }); // Show dialog
      //   console.log('editFormItem gave dialog res',changedForm)
      //   if (changedForm != 'cancel') {
      //     // Make a new responder
      //      this.$store.dispatch('saveForm',changedForm)
      //   }
    },
    setSendbuttonState() {
      if (this.$store.getters.getLoadingStatus != 0) {
        return; // Component not yet loaded
      }
      console.log(
        "setSendbuttonState",
        this.$refs.dateTimeRef.date,
        this.$refs.dateTimeRef.time
      );
      const respondersArray = this.$refs.respondersList.getSelectedArray();
      if (this.validUntilTime === null) {
        const timeString =
          this.$refs.dateTimeRef.date + " " + this.$refs.dateTimeRef.time;
        if (this.$moment(timeString, "YYYY-MM-DD HH:mm", true).isValid()) {
          this.validUntilTime = timeString;
        }
      }
      console.log(
        "setSendbuttonState",
        respondersArray.length,
        this.isMessageSet,
        this.textvalue,
        this.isImportanceSet,
        this.validUntilTime
      );
      if (
        respondersArray.length > 0 &&
        this.isMessageSet &&
        this.textvalue !== "<p></p>" &&
        this.textvalue.length > 10 &&
        this.isImportanceSet == true &&
        this.validUntilTime !== null
      ) {
        this.formIsFilledIn = true;
      } else {
        this.formIsFilledIn = false;
      }
      console.log("setSendbuttonState isFormFilledIn", this.formIsFilledIn);
    },
    dateTimeGiven() {
      const respondersArray = this.$refs.respondersList.getSelectedArray();
      console.log("dateTimeGiven", this.textvalue, respondersArray);
      // this.setTimeFlag=false
    },
    allowedDates: (val) => this.$moment().isSameOrAfter(val),

    async filterChoosen() {
      console.log("filterChoosen", this.filterToUse);
      const filternames = this.filterToUse.join("|");
      if (filternames.length > 0) {
        const res = await hshApi.countMatchFilters(filternames);
        console.log("filterChoosen res", res);
        this.nrOfMatchingRespondents = res;
        this.fetchRespondentsMatchFilter(filternames);
      } else {
        this.nrOfMatchingRespondents = 0;
        this.$refs.respondersList.TableSelectChange([]); // deselect all
      }
      this.rerenderTrigger++;
    },
    async fetchRespondentsMatchFilter(filternames) {
      try {
        this.nrOfHits = -1;
        let responders = [];
        const res = await hshApi.runFilter(filternames);
        console.log("fetchRespondentsMatchFilter: fetchAttributes", res);
        this.nrOfHits = res.length;
        const allrespondents = this.$store.getters.getReponders;
        responders = allrespondents.filter((r) => res.includes(r.id));
        this.$refs.respondersList.TableSelectChange(responders);
        const arr = this.$refs.respondersList.getSelectedArray();
        console.log(
          "fetchRespondentsMatchFilter: fetchAttributes responders",
          responders,
          allrespondents,
          res,
          arr
        );
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch result from runFilter",
          err
        );
      }
    },
  },
  async mounted() {
    // this.sendTime = this.$moment().format("YY-MM-DD HH.mm");
    console.log(
      "sendTime satt till:",
      this.$refs,
      this.sendTime,
      this.textvalue,
      this.$root
    );
    // Add handler for take care of input
    // this.$refs.editor.editor.on('update', ({ editor }) => {
    this.$refs.editor.editor.on("update", () => {
      console.log("update ", this.textvalue);
      if (this.textvalue.length > 10) {
        this.isMessageSet = true;
      } else {
        this.isMessageSet = false;
      }
      this.setSendbuttonState();
      this.$store.state.applStore.hasUnsavedChanges = true;
    });
    this.$root.$off("responderslist-table-clicked");
    this.$root.$on("responderslist-table-clicked", () => {
      console.log("Got responderslist event");
      this.setSendbuttonState();
      this.$store.state.applStore.hasUnsavedChanges = true;
      this.rerenderTrigger += 1;
    });
    this.allFilters = await this.hshFetchFilter();
    this.allFilterNames = this.allFilters.map((m) => m.name);
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.applStore.hasUnsavedChanges) {
      const answer = window.confirm(
        "Du har osparade ändringar. Vill du verkligen byta sida?"
      );
      if (answer) {
        this.$store.state.applStore.hasUnsavedChanges = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.notSet {
  border: 2px solid red !important;
}
.h6 {
  font-size: 0.8 em;
}
</style>
