// https://www.bezkoder.com/vue-upload-image-axios/
// Modified to fit in with hsh
import hshApi from "./hshapi";

class UploadFilesService {
  upload(file, metadata,onUploadProgress) {
    metadata.name = file.name.replace(/ /g,"_");
    metadata.name = metadata.name.replace(/.wav$/,"_"+metadata.lang+".wav")
    console.log("upload",file,metadata,onUploadProgress)
    return hshApi.postMediaFile(file,metadata,onUploadProgress)
    // let formData = new FormData();
    // formData.append("file", file);
    // return http.post("/media", formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   },
    //   onUploadProgress
    // });
  }
  getFiles() {
    return hshApi.getMediaList() //hshApi.get("/media");
  }
}

export default new UploadFilesService();