var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.quest != -1
      ? _c(
          "div",
          [
            _c(
              "v-card",
              { attrs: { elevation: "2", "min-height": "100%" } },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "6" } }, [
                      _c("table", { class: _vm.typeSize(_vm.quest) }, [
                        _c("tr", [
                          _c("td", [_vm._v("Fråga:")]),
                          _c("td", [_vm._v(_vm._s(_vm.quest.i18n.sv.text))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Id:")]),
                          _c("td", [_vm._v(_vm._s(_vm.quest.id))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Skapare:")]),
                          _c("td", [_vm._v(_vm._s(_vm.quest.author))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Kategori:")]),
                          _c("td", [_vm._v(_vm._s(_vm.quest.topic_name))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Typ:")]),
                          _c("td", [_vm._v(_vm._s(_vm.quest.format))])
                        ])
                      ])
                    ]),
                    _c("v-col", [
                      _c(
                        "table",
                        { staticStyle: { "text-align": "left" } },
                        [
                          _c("tr", [_c("th", [_vm._v("Svarsalternativ")])]),
                          _vm._l(_vm.answersToShow, function(answer) {
                            return _c("div", { key: answer.id }, [
                              _c("table", { staticStyle: {} }, [
                                _c("tr", [
                                  _c("td", [
                                    _vm.useImage(answer)
                                      ? _c("div", [
                                          _c("img", {
                                            staticClass: "imageUrl",
                                            attrs: { src: _vm.imgSrc(answer) }
                                          })
                                        ])
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "box",
                                            style: {
                                              "background-color":
                                                answer.ui_hint.bgcolor
                                            }
                                          },
                                          [_c("span", { staticClass: "mx-24" })]
                                        )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(answer.i18n.sv.text) + " "
                                    )
                                  ])
                                ])
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider", { staticClass: "my-6" })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }