<template>
  <div>
    <v-card v-if="show">
      <v-row>
        <v-col cols="5" class="ml-6 pt-8">
          <v-card-title class="my-1 py-1 black white--text">
            Uppgifter {{ name }}
          </v-card-title>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-for="(item, index) in responderItems"
                  :key="index"
                >
                  <v-text-field
                    :label="item.label"
                    v-model="item.value"
                    :disabled="!item.enabled"
                  ></v-text-field>
                </v-col>
                  <v-col cols="4" >
                    <v-checkbox
                      v-model="notify_by_email"
                      color="gray"
                      class="align-center justify-center"
                    >
                      <template v-slot:label>
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="align-center justify-center pt-3"
                              v-bind="attrs"
                              v-on="on"
                              ><nobr>e-postavisering</nobr></span
                            >
                          </template>
                          <span
                            >Undersökningar och meddelanden kommar att aviseras
                            via e-post
                          </span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="notify_by_terminal"
                      color="gray"
                      class="align-right justify-center"
                    >
                      <template v-slot:label>
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="align-center justify-center pt-3"
                              v-bind="attrs"
                              v-on="on"
                              >terminalavisering</span
                            >
                          </template>
                          <span
                            >Undersökningar och meddelanden kommar att aviseras
                            via notiser eller direkt i hemtermianlen
                          </span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              <v-divider
                style="
                  color: white;
                  border-color: white;
                  background-color: white;
                "
              ></v-divider>
              <v-divider
                style="
                  color: white;
                  border-color: white;
                  background-color: white;
                "
              ></v-divider>
              <v-row>
                <v-btn
                  absolute
                  bottom
                  right
                  color="blue darken-1"
                  text
                  @click="updateResponder"
                >
                  Uppdatera uppgifter
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <!-- <iframe :src="urlToShow" frameborder="1"  onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' style="height:1200px;width:100%;border:none;overflow:hidden;"></iframe> -->
        <v-col cols="5" class="ml-6 pt-8">
          <v-card-title class="my-1 py-1 black white--text">
            Filterparametrar
          </v-card-title>
          <v-data-table
            v-show="haveReceived"
            v-model="selected"
            :items="usertable"
            item-key="value"
            @input="TableSelectChange"
            @click="TableSelectChange"
            class="elevation-1"
            item-class="row-height-50"
            group-by="groupname"
            :items-per-page="-1"
            :height="700"
            :footer-props="{
              'items-per-page-text': 'Rader per sida',
              'items-per-page-all-text': 'Alla',
            }"
            :update="update"
          >
            <template
              v-slot:group.header="{ group, headers, items, isOpen, toggle }"
            >
              <th :colspan="2">
                <v-icon
                  @click="toggle"
                  small
                  icon
                  :ref="group"
                  :data-open="isOpen"
                  >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                {{ items[0].groupname }}
              </th>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <span>
                    <strong>{{ item.description }}</strong>
                  </span>
                </td>
                <td
                  @click="TableSelectChange(item)"
                  @input="TableSelectChange(item)"
                  :class="multiselect4(item)"
                >
                  <span
                    v-if="
                      ['string', 'date', 'datetime', 'time'].find(
                        (e) => e == item.gui_type
                      )
                    "
                  >
                    <input
                      style="border: 1px solid black; padding: 3px !important"
                      v-model="item.resp_value"
                    />
                  </span>
                  <span
                    v-else-if="['dropdown'].find((e) => e == item.gui_type)"
                  >
                    <v-select
                      v-model="item.resp_value"
                      :items="item.choices"
                      :label="item.description"
                      class="pr-6"
                    ></v-select>
                  </span>
                  <span
                    v-else-if="['multiselect'].find((e) => e == item.gui_type)"
                  >
                    <v-select
                      v-model="item.resp_value"
                      :items="item.choices"
                      chips
                      deletable-chips
                      :label="item.name"
                      multiple
                      outlined
                    ></v-select>
                  </span>
                  <span
                    v-else-if="['checkbox'].find((e) => e == item.gui_type)"
                  >
                    <v-checkbox
                      v-model="item.resp_value"
                      color="gray"
                      value="true"
                    >
                    </v-checkbox>
                  </span>
                  <span
                    v-else-if="
                      ['integer', 'limitedInteger'].find(
                        (e) => e == item.gui_type
                      )
                    "
                  >
                    <input
                      style="border: 1px solid black; padding: 3px !important"
                      v-model="item.resp_value"
                    />
                  </span>
                </td>
              </tr>
            </template>

            <template v-slot:footer.prepend>
              <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Spara som urvalsgrupp
        </v-btn> -->
              <v-spacer></v-spacer>
            </template>
          </v-data-table>
          <v-divider
            style="color: white; border-color: white; background-color: white"
          ></v-divider>
          <v-divider
            style="color: white; border-color: white; background-color: white"
          ></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              absolute
              bottom
              right
              color="blue darken-1"
              text
              @click="closeAndSaveResponderForm"
            >
              Uppdatera filter
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          absolue
          bottom-right
          class="pa-4 ma-4"
          color="blue darken-1"
          text
          @click="closeResponderForm"
        >
          Stäng
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import hshApi from "../services/hshapi";

export default {
  name: "ResponderForm",
  props: ["responder"],
  data() {
    return {
      urlToShow: process.env.VUE_APP_API+"/v1.2/userapplicationform/vst",
      show: true,
      selected: [],
      search: "",
      respondentsSettings: [],
      responderItems: [],
      notify_by_email: false,
      notify_by_terminal: false,
      singleSelect: false,
      haveReceived: false,
      update: 0,
      usertable: [],
      filterAttributes: [],
      headers: [
        { text: "", value: "text" },
        { text: "", value: "resp_value" },
      ],
    };
  },
  methods: {
    updateValue(index, value) {
      this.responderItem[index].value = value;
    },
    closeResponderForm() {
      console.log("ResponderForm: closeResponderForm ");
      // this.show=false
      this.$emit("form-ready");
      // this.value=false
      // this.$emit('input',this.$event.target.value)
    },
    async closeAndSaveResponderForm() {
      // Prepare to senda to server
      console.log(
        "ResponderForm: closeAndSaveResponderForm",
        this.respondentsSettings,
        this.usertable
      );
      // const userInfo = this.usertable.map((u) => {
      let settingsArray = [];
      for (let i = 0; i < this.usertable.length; i++) {
        // Create objects attribute + value
        const attribute = this.usertable[i].name;
        let value;
        if (this.usertable[i].gui_type == "multiselect") {
          if (Array.isArray(this.usertable[i].resp_value)) {
            value = JSON.stringify(this.usertable[i].resp_value);
          } else {
            value = this.usertable[i].resp_value;
          }
        } else {
          value = this.usertable[i].resp_value;
        }
        // Check input values
        const interval = this.$_.get(
          this.usertable[i],
          "valid_values",
          ""
        ).split(":");
        if (
          this.hshCheckInput(value, this.usertable[i].gui_type, interval) ==
          false
        ) {
          console.error("Check of input data did not pass!");
          await this.$dialog.error({
            text: `Filtervärden gick inte att uppdatera ${this.usertable[i].description} värde: ${value}`,
            title: `Varning!`,
            actions: {
              ok: {
                text: "OK",
                color: "blue darken-1",
                handle: () => {
                  return undefined;
                },
              },
            },
          });
          return;
        }
        settingsArray.push({
          attribute: attribute,
          value: value,
        });
      }
      try {
        await hshApi.storeSettingsAdm(this.responder.uuid, settingsArray);
        await this.$dialog.info({
          text: `Filtervärden uppdaterade`,
          title: `Info`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      } catch (error) {
        await this.$dialog.error({
          text: `Filtervärden gick inte att uppdatera (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
      // })
      // this.closeResponderForm();
    },
    async fetchSettings() {
      try {
        console.log("ResponderForm: fetchSettings  ", this.responder);

        const res = await hshApi.respondentsSettings(this.responder.uuid);
        this.respondentsSettings = res;
        console.log(
          "ResponderForm: fetchSettings",
          this.respondentsSettings,
          this.filterAttributes,
          res
        );
        this.usertable = this.filterAttributes; // this.moments(); // No sort here? .sort((a,b) => {a.text < b.text})  // Translation between attribute and text

        for (let i = 0; i < this.usertable.length; i++) {
          const property = this.usertable[i].name;
          // Detect type
          this.usertable[i].choices = [];
          this.usertable[i].interval = [];

          if (this.usertable[i].type == "string") {
            const str = this.$_.get(this.usertable[i], "valid_values", "");
            if (str.charAt(0) === "[" && str.charAt(str.length - 1) === "]") {
              const multichoices = str.substring(1, str.length - 1).split("|");
              this.usertable[i].choices = multichoices;
              this.usertable[i].gui_type = "multiselect";
            } else {
              const choices = this.$_.get(
                this.usertable[i],
                "valid_values",
                ""
              ).split("|");
              this.usertable[i].choices = choices;
              if (choices.length >= 2) {
                this.usertable[i].gui_type = "dropdown";
              } else {
                this.usertable[i].gui_type = "string";
              }
            }
          } else if (this.usertable[i].type == "integer") {
            const interval = this.$_.get(
              this.usertable[i],
              "valid_values",
              ""
            ).split(":");
            console.log("intervall=", interval);
            if (interval.length == 2) {
              this.usertable[i].gui_type = "limitedInteger";
              this.usertable[i].interval = interval;
            } else if (interval.length < 2) {
              this.usertable[i].gui_type = "integer";
            } else {
              this.usertable[i].gui_type = "unknown";
            }
          } else if (this.usertable[i].type == "boolean") {
            this.usertable[i].gui_type = "checkbox";
          } else if (this.usertable[i].type == "date") {
            this.usertable[i].gui_type = "date";
          } else if (this.usertable[i].type == "datetime") {
            this.usertable[i].gui_type = "datetime";
          } else if (this.usertable[i].type == "time") {
            this.usertable[i].gui_type = "time";
          } else {
            this.usertable[i].gui_type = "unknown";
            console.error(
              "filterAttributes unsupported type",
              this.usertable[i].type,
              this.usertable,
              i
            );
          }

          // Read user settings
          const m = this.respondentsSettings.find((m) => {
            // if (m.attribute == property)  console.log("find:", m.attribute, property);
            return m.attribute == property;
          });
          if (m === undefined) {
            this.usertable[i].resp_value = "";
            continue;
          }
          // if (
          //   property == "ct_born" ||
          //   property == "ct_hours" ||
          //   property == "ct_first_name" ||
          //   property == "ct_last_name"
          // ) {
          //   this.usertable[i].resp_value = m.value;
          //   continue;
          // }
          // if (property == "relation") {
          //   const relations = this.relations();
          //   const res = relations.find((r) => r.value == m.value);
          //   this.usertable[i].resp_value = res.text;
          //   continue;
          // }
          this.usertable[i].resp_value = m.value;
        }

        this.haveReceived = true;
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch accountApplications",
          err
        );
      }
      // Close all except first segment => Allmänt
      Object.keys(this.$refs).forEach((k) => {
        console.log("RespondersForm: Object", k);
        if (k == this.usertable[0].groupname) {
          if (this.$refs[k] && !this.$refs[k].$attrs["data-open"]) {
            this.$refs[k].$el.click();
          }
        } else {
          if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
            this.$refs[k].$el.click();
          }
        }
      });
      console.log("ResponderForm: fetchSettings parsed", this.usertable);
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    TableSelectChange(item) {
      console.log("TableSelectChange ", item, this.usertable);
      const inx = this.usertable.findIndex((i) => i.value == item.value);
      if (this.usertable[inx].resp_value.length > 30) {
        this.usertable[inx].resp_value = this.usertable[
          inx
        ].resp_value.substring(1, 30);
        console.log(
          "TableSelectChange to long ",
          this.usertable[inx].resp_value,
          this.usertable
        );
        item.resp_value = "";
      }
      //   this.$store.state.applStore.hasUnsavedChanges = true;
      //   this.$root.$emit("responderslist-table-clicked");
      //   console.log("Emitted responderslist-table-clicked", this.$root);
    },
    getSelectedArray() {
      return this.selected;
    },
    // component methods go here
    async fetchAttributes() {
      try {
        const res = await hshApi.getFilterAttributes(
          this.$keycloak.tokenParsed.organisation
        );
        console.log("ResponderForm: fetchAttributes", res);
        this.filterAttributes = res;
      } catch (err) {
        console.error(
          "ResponderForm: Failed to fetch getFilterAttributes",
          err
        );
      }
    },
    multiselect4(item) {
      if (item.gui_type == "multiselect") return "py-4";
      return "";
    },
    setRespondersItem() {
      this.responderItems = [
        { label: "Förnamn", value: this.responder.first_name, enabled: true },
        { label: "Efternamn", value: this.responder.last_name, enabled: true },
        { label: "e-post", value: this.responder.email, enabled: true },
        { label: "Id", value: this.responder.id, enabled: false },
        {
          label: "Terminald",
          value: this.responder.terminal_id,
          enabled: true,
        },
        {
          label: "Födelseår",
          value: this.responder.year_of_birth,
          enabled: true,
        },
        { label: "Kommentar", value: this.responder.comment, enabled: true },
        // Implemented as checkboxes
        // {
        //   label: "terminalavisering",
        //   value: this.responder.notify_by_terminal,
        //   enabled: true,
        // },
        // {
        //   label: "e-postavisering",
        //   value: this.responder.notify_by_email,
        //   enabled: true,
        // },
      ];
      this.notify_by_email = this.responder.notify_by_email;
      this.notify_by_terminal = this.responder.notify_by_terminal;
    },
    async updateResponder() {
      console.log(
        "ResponderForm: updateResponer",
        this.responderItems,
        this.responder
      );
      this.responder.first_name = this.responderItems.find(
        (i) => i.label == "Förnamn"
      ).value;
      this.responder.last_name = this.responderItems.find(
        (i) => i.label == "Efternamn"
      ).value;
      this.responder.email = this.responderItems.find(
        (i) => i.label == "e-post"
      ).value;
      // this.responder.id=this.responderItems.find((i)          => i.label=="Id").value
      this.responder.terminal_id = this.responderItems.find(
        (i) => i.label == "Terminald"
      ).value;
      this.responder.comment = this.responderItems.find(
        (i) => i.label == "Kommentar"
      ).value;
      this.responder.year_of_birth = parseInt(
        this.responderItems.find((i) => i.label == "Födelseår").value
      );
      this.responder.notify_by_terminal =
        Boolean(this.notify_by_terminal) || false;
      this.responder.notify_by_email = Boolean(this.notify_by_email) || false;
      console.log("updateResponder", this.responder, this.responderItems);
      try {
        await this.$store.dispatch("updateResponder", this.responder);
        // await this.$dialog.info({
        //   text: `Uppgifterna uppdaterade`,
        //   title: `Info`,
        //   actions: {
        //     ok: {
        //       text: "OK",
        //       color: "blue darken-1",
        //       handle: () => {
        //         return undefined;
        //       },
        //     },
        //   },
        // });
      } catch (error) {
        console.error("ResponderForm: updateResponer update failed", error);
      }
      this.$store.dispatch("getTerminals");
    },
  },
  computed: {
    name() {
      console.log(" Computed called", this.responder);
      this.fetchAttributes();
      this.fetchSettings();
      this.setRespondersItem();
      return this.responder.first_name + " " + this.responder.last_name;
    },

    // component computed properties go here
  },
  components: {
    // other components that this component depends on go here
  },
  created() {
    console.log("ResponderForm: created  with ", this.responder);
    this.show = true; // code that runs when the component is reactivated goes here
  },
  async mounted() {
    console.log("ResponderForm: Mounted with ", this.responder);
    await this.fetchAttributes();
    await this.fetchSettings();
    this.setRespondersItem();
  },
};
</script>

<style scoped>
.v-data-table.row-height-50 td {
  max-height: 18px !important;
  height: 20px !important;
}
tr {
  height: 20px !important;
}
.hsh-relation {
  background-color: blue !important;
}
</style>
