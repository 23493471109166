var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DialogCard", { attrs: { title: _vm.title, actions: _vm.actions } }, [
        _vm._v(" Vill du att vi nollställer kontaktbegäran? ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }