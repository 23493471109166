var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    { attrs: { title: _vm.title, actions: _vm.actions } },
    [
      _c(
        "v-card",
        { attrs: { height: "600px" } },
        [
          _c("v-card-title", [
            _vm._v(" " + _vm._s(_vm.question.question.i18n.sv.text) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.questions,
                              label: "Villkorsfråga",
                              required: ""
                            },
                            model: {
                              value: _vm.questionChoosen,
                              callback: function($$v) {
                                _vm.questionChoosen = $$v
                              },
                              expression: "questionChoosen"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.answers,
                              attach: "",
                              chips: "",
                              required: "",
                              label:
                                "Vilka svar skall krävas för att frågan skall aktiveras?",
                              multiple: ""
                            },
                            model: {
                              value: _vm.answersChoosen,
                              callback: function($$v) {
                                _vm.answersChoosen = $$v
                              },
                              expression: "answersChoosen"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }