var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c("v-row", [_c("v-col", [_c("QuestionsTable")], 1)], 1),
          _c("v-row", [_c("v-col", [_c("QuestionForm")], 1)], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }