var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-0" },
        [
          _c(
            "v-card",
            { staticClass: "px-6 py-4", attrs: { "max-width": "1920" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-card-title", { staticClass: "text-h5" }, [
                        _vm._v(" Meddelande ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "background-color": _vm.isImportanceSet
                            ? null
                            : "rgba(255, 0, 0, 0.5)",
                          items: _vm.importanceItems,
                          "item-text": "name",
                          label: "Meddelandetyp",
                          outlined: ""
                        },
                        on: { change: _vm.setImportance },
                        model: {
                          value: _vm.importance,
                          callback: function($$v) {
                            _vm.importance = $$v
                          },
                          expression: "importance"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          class: {
                            notSet: _vm.validUntilTime == null,
                            "pa-4": true
                          }
                        },
                        [
                          _c("span", { staticClass: "text-h6 mb-3" }, [
                            _vm._v(" Giltigt till kl: ")
                          ]),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color: "grey",
                                                dark: ""
                                              },
                                              on: { click: _vm.setTimeFast }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-calendar ")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " Meddelandet försvinner efter denna tid "
                                )
                              ])
                            ]
                          ),
                          _c("p"),
                          _c("v-datetime-picker", {
                            ref: "dateTimeRef",
                            staticClass: "pt-3 mt-3",
                            attrs: {
                              label: "Datum och tid",
                              timeFormat: "HH:mm",
                              "time-picker-props": _vm.timeProps,
                              clearText: "Avbryt",
                              textFieldProps: _vm.dateTimeTextFieldProps
                            },
                            on: { input: _vm.dateTimeGiven },
                            model: {
                              value: _vm.validUntilTime,
                              callback: function($$v) {
                                _vm.validUntilTime = $$v
                              },
                              expression: "validUntilTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { elevation: "0", "max-width": "800px" } },
                        [
                          _c("v-tiptap", {
                            ref: "editor",
                            class: { notSet: !_vm.isMessageSet },
                            attrs: {
                              placeholder: "Skriv ditt meddelande här",
                              "max-width": "800px"
                            },
                            model: {
                              value: _vm.textvalue,
                              callback: function($$v) {
                                _vm.textvalue = $$v
                              },
                              expression: "textvalue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.formIsFilledIn == false,
                            elevation: "2",
                            color: "primary"
                          },
                          on: { click: _vm.sendTheMessage }
                        },
                        [_vm._v(" Skicka meddelandet ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _vm.loadingStatus == false
            ? _c(
                "div",
                [
                  _c(
                    "v-card",
                    { attrs: { color: "primary", dark: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _vm._v(" Var god dröj... "),
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "white" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "text-h5" },
                                [
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _vm._v(" Skicka till: ")
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-4" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-h6" },
                                            [_vm._v(" Skicka senare: ")]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                dark: ""
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " mdi-calendar "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " Om annan tid än nu genast "
                                                )
                                              ])
                                            ]
                                          ),
                                          _c("v-datetime-picker", {
                                            staticClass: "mt-3",
                                            attrs: {
                                              label: "Datum och tid",
                                              timeFormat: "HH:mm",
                                              "time-picker-props":
                                                _vm.timeProps,
                                              clearText: "Avbryt",
                                              textFieldProps:
                                                _vm.dateTimeTextFieldProps
                                            },
                                            on: { input: _vm.dateTimeGiven },
                                            model: {
                                              value: _vm.sendTime,
                                              callback: function($$v) {
                                                _vm.sendTime = $$v
                                              },
                                              expression: "sendTime"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("v-divider", { staticClass: "mb-5" }),
                          _c("h3", { staticClass: "mb-5" }, [
                            _vm._v(
                              " Filter just nu " +
                                _vm._s(_vm.nrOfMatchingRespondents) +
                                " respondenter "
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              items: _vm.allFilterNames,
                              chips: "",
                              "deletable-chips": "",
                              clearable: "",
                              label: "Pivot",
                              multiple: "",
                              outlined: "",
                              "prepend-icon": "mdi-filter-variant",
                              solo: "",
                              "menu-props": { y: "below", offsetY: true }
                            },
                            on: { change: _vm.filterChoosen },
                            model: {
                              value: _vm.filterToUse,
                              callback: function($$v) {
                                _vm.filterToUse = $$v
                              },
                              expression: "filterToUse"
                            }
                          }),
                          _c("v-divider", { staticClass: "mb-5" })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-5" },
                        [
                          _c("RespondersList", {
                            ref: "respondersList",
                            attrs: { survey: _vm.currentSurvey }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }