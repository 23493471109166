var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Reports")]),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("SurveysInstancesTable", {
                    on: { selectSurveyInstance: _vm.surveInstanceChoosen }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.waiting
            ? _c("div", [_c("div", { staticClass: "spinner spinner-border" })])
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-card", { staticClass: "elevation-3" }, [
                    _vm.reportToShow
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              [
                                _c("v-divider", { staticClass: "mb-5" }),
                                _c(
                                  "div",
                                  { staticClass: "image-container" },
                                  [
                                    _c("v-img", {
                                      staticClass: "shrink mr-2",
                                      attrs: {
                                        alt: "Csv file",
                                        contain: "",
                                        src: require("../assets/downloadCsv.png"),
                                        transition: "scale-transition",
                                        width: "40",
                                        position: "top"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.fetchCsvFile()
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("h3", { staticClass: "mb-5 ml-10" }, [
                                  _vm._v("Välj filter för jämförelse")
                                ]),
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.allFilterNames,
                                    chips: "",
                                    "deletable-chips": "",
                                    clearable: "",
                                    label: "Pivot",
                                    multiple: "",
                                    outlined: "",
                                    "prepend-icon": "mdi-filter-variant",
                                    solo: "",
                                    "menu-props": { y: "below", offsetY: true }
                                  },
                                  model: {
                                    value: _vm.filterToUse,
                                    callback: function($$v) {
                                      _vm.filterToUse = $$v
                                    },
                                    expression: "filterToUse"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-10",
                                    attrs: {
                                      color: "primary",
                                      disabled: _vm.waiting
                                    },
                                    on: { click: _vm.filterChoosen }
                                  },
                                  [_vm._v(" Uppdatera graf ")]
                                ),
                                _c("h6", { staticClass: "ml-10 mt-5" }, [
                                  _c("i", [_vm._v("Esc för att stänga...")])
                                ]),
                                _c("v-divider", { staticClass: "mb-5" })
                              ],
                              1
                            ),
                            _c(
                              "v-card",
                              {
                                staticClass: "d-flex align-stretch flex-wrap",
                                attrs: {
                                  color: "grey lighten-2",
                                  flat: "",
                                  tile: "",
                                  "min-height": "200"
                                }
                              },
                              _vm._l(_vm.surveyInstanceReports, function(
                                quest,
                                index2
                              ) {
                                return _c(
                                  "v-card",
                                  {
                                    key: index2,
                                    staticClass: "pa-2",
                                    attrs: {
                                      outlined: "",
                                      title: "",
                                      width: "50%"
                                    }
                                  },
                                  [
                                    _c("h3", { staticClass: "ma-4" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            quest.barchart.bar_options.chart.id
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("apexchart", {
                                          attrs: {
                                            width: "500",
                                            type: "bar",
                                            options: _vm.barOptionsNumbers(
                                              quest
                                            ),
                                            series: quest.barchart.bar_series
                                          }
                                        }),
                                        quest.showLegend
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  quest.barchart.bar_options
                                                    .xaxis.categories,
                                                  function(textLegend, index3) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index3,
                                                        staticClass: "text-h7"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(index3 + 1) +
                                                            ". " +
                                                            _vm._s(textLegend) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c("p")
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c("h4", [
                                          _vm._v(
                                            " Antal besvarade " +
                                              _vm._s(
                                                quest.metadata
                                                  .nbr_of_respondent_answers
                                              ) +
                                              " / " +
                                              _vm._s(
                                                quest.metadata
                                                  .total_nbr_of_respondents
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }