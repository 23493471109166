var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingStatus
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "px-0" },
            [
              _c(
                "v-card",
                { staticClass: "px-6 py-4", attrs: { "max-width": "1920" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("h5", [
                          _c("table", [
                            _c("tr", [
                              _c("td", [_vm._v("Formulärnamn:")]),
                              _c("td", [_vm._v(_vm._s(_vm.formItem.name))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Id:")]),
                              _c("td", [_vm._v(_vm._s(_vm.formItem.id))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Status:")]),
                              _c("td", [
                                _vm.formItem.locked
                                  ? _c("span", [_vm._v("Låst!")])
                                  : _c("span", [_vm._v("OLåst!")])
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Antal Frågor:")]),
                              _c("td", [_vm._v(_vm._s(_vm.nrOfQuestions))])
                            ])
                          ])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("h5", [
                          _c("table", [
                            _c("tr", [
                              _c("td", [_vm._v("Skapad:")]),
                              _c("td", [_vm._v(_vm._s(_vm.wasCreated))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Senast ändrad:")]),
                              _c("td", [_vm._v(_vm._s(_vm.wasModified))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Tillhör org:")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.formItem.owner_org_name))
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Skapad av:")]),
                              _c("td", [_vm._v(_vm._s(_vm.formItem.author))])
                            ])
                          ])
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("h5", [
                          _c("table", [
                            _c("tr", [
                              _c("td", [_vm._v("Kategori:")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.formItem.topic_name))
                              ])
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "pb-6",
                      attrs: { justify: "center", align: "end" }
                    },
                    [
                      _c("QuestionSelector", {
                        attrs: { form: _vm.currentForm }
                      }),
                      _c("StartSurveyFromForm", {
                        attrs: {
                          form: _vm.currentForm,
                          buttontext: "Skapa och starta undersökning (genväg)"
                        }
                      }),
                      _c("FormPreview", {
                        attrs: {
                          url:
                            "https://form.homesvithome.se/demoform/" +
                            _vm.formItem.id
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-6" }),
                  _c(
                    "h5",
                    _vm._l(_vm.formItem.questions, function(quest) {
                      return _c(
                        "div",
                        { key: quest.id },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "2", "min-height": "100%" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "table",
                                      { class: _vm.typeSize(quest) },
                                      [
                                        _c("tr", [
                                          _c("td", [_vm._v("Fråga:")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                quest.question.i18n.sv.text
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("td", [_vm._v("Id:")]),
                                          _c("td", [
                                            _vm._v(_vm._s(quest.question.id))
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("td", [_vm._v("Skapare:")]),
                                          _c("td", [
                                            _vm._v(_vm._s(quest.author))
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("td", [_vm._v("Villkor:")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    "max-width": "400",
                                                    tile: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setRule(
                                                        _vm.formItem,
                                                        quest
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.rule(quest),
                                                    function(rule, index) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "hsh-list"
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              staticClass:
                                                                "hsh-list",
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  rule
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("tr", [_c("td")])
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "v-col",
                                    _vm._l(quest.question.answers, function(
                                      answer
                                    ) {
                                      return _c("div", { key: answer.id }, [
                                        _c("table", { staticStyle: {} }, [
                                          _c("tr", [
                                            _c("td", [
                                              _vm.useImage(answer)
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass: "imageUrl",
                                                      attrs: {
                                                        src: _vm.imgSrc(answer)
                                                      }
                                                    })
                                                  ])
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass: "box",
                                                      style: {
                                                        "background-color":
                                                          answer.ui_hint.bgcolor
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "mx-24"
                                                      })
                                                    ]
                                                  )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(answer.i18n.sv.text) +
                                                  " "
                                              )
                                            ])
                                          ])
                                        ])
                                      ])
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-6" })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }