// import { configs } from "eslint-plugin-prettier";
// import moment from "moment";
import Vue from "vue";
import Vuex from "vuex";
import SurveyService from "../services/SurveyService";
import hshApi from "../services/hshapi";
import localhostApi from "../services/LocalhostApi";
import applStore from "./applStore";
// import { _ } from "core-js";
import $_ from "lodash";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Which API to use SurveyService or hshApi
    api: {
      fetchQuestions: hshApi,
      createQuestion: hshApi,
      updateQuestion: hshApi,
      saveQuestion: hshApi,
      fetchForm: hshApi,
      fetchForms: hshApi,
      createForm: hshApi,
      saveForm: hshApi,
      deleteForm: hshApi,
      fetchSurveys: hshApi,
      saveSurvey: hshApi,
      deleteSurvey: hshApi,
      deleteSurveyInstance: hshApi,
      updateSurvey: hshApi,
      startSurvey: hshApi,
      startRepeateSurvey: hshApi,
      sendConfirmedAnswers: hshApi,
      fetchResponders: hshApi,
      createResponder: hshApi,
      updateResponder: hshApi,
      fetchToken: localhostApi,
      surveyReport: hshApi,
      terminals: hshApi,
      fetchMessages: hshApi,
      accountApplication: hshApi,
      getOrganisations: hshApi,
      test: SurveyService,
    },
    infrastructureSetupDone: false,
    mainVueInstance: {},
    surveys: [],
    errorMessage: "",
    currentSurveyId: null,
    currentSurveysInstanceId: null,

    forms: [],
    currentFormId: -1,

    loadingStatus: 0,

    questions: [], // The questions in current form
    currentQuestionId: -1, // Stored ID reteived from API
    currentQuestionIndex: 0, // Im questions array
    questionsUpdateReq: true, // Flag if it's time to read all questions
    answers: [], // corresponding answers to the questions
    answers2: {},
    // {
    //   "surveyId": 32,
    //   "runid": "20220107223000_4",
    //   "responderinstans": "9a1de3f0-9c41-466e-a4dc-7a73cce4215c",
    //   "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXJ2ZXlJZCI6MzIsInJ1bmlkIjoiMjAyMjAxMDcyMjMwMDBfNCIsInJlc3BvbmRlcmluc3RhbnMiOiI5YTFkZTNmMC05YzQxLTQ2NmUtYTRkYy03YTczY2NlNDIxNWMifQ.9tqX9rx24ULiLD6HSI-Pa5hQ4t69_AyfLnokVyT2stA"
    // }
    messages: [],
    currentMessageId: -1,
    currentMessageIndex: 0,
    accessToken: "",
    token: "",
    medialist: [],
    audiomedia_url: [],
    imgmedia_url: [],
    queryTopics: [],
    responders: [],
    surveysInstances: [],
    surveyReport: {},
    terminals: [],
    languages: ["sv", "en"],
    nrOfRegRequests: 0,
    nrOfErrors: 0,
    organisation: {},
    showMessageLoaded: 0,
    withOfDrawer: null,
  },
  mutations: {
    setSurveys(state, payload) {
      state.surveys = payload;
    },
    async setErrorMessage(state, payload) {
      state.nrOfErrors++;
      state.errorMessage = payload;
      if (state.nrOfErrors > 1) {
        state.mainVueInstance.$dialog.notify.error(
          `Kommunikationsfel (${state.nrOfErrors})` + payload,
          {
            position: "top-left",
            timeout: 5000,
          }
        );
      } else {
        await state.mainVueInstance.$dialog.error({
          text: `Kommunikationsfel ${payload})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
      state.nrOfErrors--;
    },
    setNamedErrorMessage(state, name, payload) {
      state.errorMessage = name + ":" + payload;
    },
    setCurrentSurveyId(state, surveyId) {
      state.currentSurveyId = surveyId;
    },
    setCurrentSurveysInstanceId(state, surveysInstanceId) {
      state.currentSurveysInstanceId = surveysInstanceId;
    },
    setCurrentFormId(state, payload) {
      state.currentFormId = payload;
    },
    setForms(state, payload) {
      console.log("setForms");
      console.log(payload);
      state.forms = payload;
    },
    setForm(state, payload) {
      console.log("Store: mutation setForm", payload);
      const index = state.forms.findIndex((x) => x.id == payload.id);
      if (index == -1) {
        state.forms.push(payload);
      } else {
        state.forms[index] = payload;
      }
      state.currentFormId = payload.id;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setQuestion(state, quest) {
      const index = state.questions.findIndex((q) => q.id == quest.id);
      if (index >= 0) {
        state.questions[index] = quest;
      } else {
        state.questions.push(quest);
      }
    },
    deleteQuestion(state, quest) {
      const index = state.questions.findIndex((q) => q.id == quest.id);
      state.questions.splice(index, 1);
    },
    setAnswerCurrentQuestionId(state, payload) {
      console.log(`Got answer nr ${state.currentQuestionIndex} in store `, payload);
      if (!state.answers2.form) {
        state.answers2.form = [];
        state.answers2.form[0] = { id: state.currentFormId };
        state.answers2.form[0].questions = [];
      }
      state.answers2.form[0].questions[state.currentQuestionIndex] = {
        id: state.currentQuestionId,
        answer: {
          value: payload.value,
          presented_at: payload.voted_at.presented_at,
          confirmed_at: payload.voted_at.confirmed_at,
          voted_at: payload.voted_at.voted_at,
        },
      };
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    revokeMessage(state, message) {
      let index = state.messages.findIndex((m) => m.id === message.id);
      console.log("revokeMessage", index, message);
      if (index > -1) {
        state.messages.splice(index, 1);
        // state.messages[index]=message
        state.messages.push(message);
      }
    },
    setSurveysInstances(state, payload) {
      console.log("Store: mutation setSurveysInstances", payload);
      state.surveysInstances = payload;
    },
    CLEAR_ANSWERS(state) {
      state.answers2.form = null;
    },
    SET_LOADING_STATUS(state, status) {
      state.loadingStatus += status ? 1 : -1;
      console.log("SET_LOADING_STATUS count=", state.loadingStatus);
      if (state.loadingStatus === 0) {
        state.mainVueInstance.$root.$emit("store-loadingstatus-0");
      }
    },
    SET_CURRENT_QUESTION_INDEX(state, index) {
      state.currentQuestionIndex = index;
      if (index < 0) {
        state.currentQuestionId = -1;
      } else {
        state.currentQuestionId = $_.get(
          state.forms[0],
          `questions[${state.currentQuestionIndex}].question.id`,
          -2
        );
      }
    },
    SET_CURRENT_MESSAGE_INDEX(state, index) {
      state.currentMessageIndex = index;
    },
    SET_KEYCLOAK_TOKEN(state, payload) {
      state.token = payload;
      // console.log("token set to:",state.token)
    },
    SET_AUDIO_MEDIA(state, payload) {
      state.audiomedia_url.push(payload);
    },
    SET_IMG_MEDIA(state, payload) {
      state.imgmedia_url.push(payload);
    },
    SET_MEDIA_LIST(state, payload) {
      state.medialist = payload;
    },
    SET_TOPIC_TABLE(state, payload) {
      state.queryTopics = payload;
    },
    SET_REPONDERS(state, payload) {
      console.log("SET_REPONDERS");
      console.log(payload);
      state.responders = payload;
      // state.responders.forEach((r) => {
      //   if (!$_.has(r, "caregiver_info")) {
      //     r.caregiver_info = {};
      //   }
      //   if (!$_.has(r.caregiver_info, "category")) {
      //     r.caregiver_info.category = [];
      //   }
      // });
    },
    UPDATE_REPONDER(state, payload) {
      let responder = state.responders.find((r) => r.id === payload.id);
      if (responder !== undefined) {
        responder = payload;
      }
    },
    DELETE_REPONDER(state, payload) {
      let index = state.responders.findIndex((r) => r.id === payload.id);
      if (index > -1) {
        state.responders.splice(index, 1);
      }
    },
    NEW_REPONDER(state, payload) {
      state.responders.push(payload);
    },
    UPDATE_REPONDER_JWT(state, payload) {
      console.log("Update responders JWT", state, payload);
      const itemIndex = state.responders.findIndex((x) => x.id == payload.id);
      if (itemIndex >= 0) {
        state.responders[itemIndex] = payload;
      }
    },
    CREATE_SURVEY(state, payload) {
      state.surveys.unshift({ ...payload });
    },
    UPDATE_SURVEY(state, payload) {
      const index = state.surveys.findIndex((s) => s.id == payload.id);
      console.log("UPDATE_SURVEY", payload, index);
      if (index > -1) {
        state.surveys[index] = payload;
      } else {
        state.mainVueInstance.$dialog.notify.error("Undersökning saknas - anmäl detta ", {
          position: "top-left",
          timeout: 5000,
        });
      }
    },
    DELETE_SURVEY(state, payload) {
      const index = state.surveys.findIndex((s) => s.id == payload.id);
      if (index > -1) {
        state.surveys.splice(index, 1);
      } else {
        state.mainVueInstance.$dialog.notify.error(
          "Ta bort, Undersökning saknas - anmäl detta ",
          {
            position: "top-left",
            timeout: 5000,
          }
        );
      }
    },
    SET_START_ANSWERS(state, payload) {
      state.answers2 = payload;
      state.answers2.form = null;
    },
    SET_SURVEY_REPORT(state, report) {
      state.surveyReport = report;
    },
    SET_TERMINALS(state, payload) {
      state.terminals = payload;
    },
    SET_ORGANISATION(state, payload) {
      state.organisation = payload;
    },
    SET_NR_OF_REG_REQUESTS(state, nrOfRegRequests) {
      state.nrOfRegRequests = nrOfRegRequests;
    },
    SET_SHOW_MESSAGE_LOADED_TAB(state, inc) {
      state.showMessageLoaded += inc;
      console.log("ShowMessage index.js set to ", state.showMessageLoaded)
    },
  },
  actions: {
    setStartinfoAnswers(context, surveyKeys) {
      context.commit("SET_START_ANSWERS", surveyKeys);
    },
    async getMediaList(context) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        const mediaList = await hshApi.getMediaList();
        console.log("SET_MEDIA_LIST", mediaList);
        context.commit("SET_MEDIA_LIST", mediaList);
        const prom = hshApi.getZipMediaFile();
        console.log("getMedialist prom", prom);
        prom.then((urls) => {
          // console.log("getMedialist in prom-> urls",urls,typeof(urls),Object.getOwnPropertyNames(urls))
          console.log("getMedialist in prom-> urls", urls);
          // parse them in into the media and audio arrays
          context.state.medialist.forEach((m) => {
            // console.log("getMedialist in prom forEach-> ",m)
            let found = false;
            urls.forEach((url) => {
              if (url.filename == m.hash.md5) {
                found = true;
                const payload = {
                  id: m.id,
                  url: url.url,
                };
                if (m.type == "audio") {
                  payload.type = "audio/wav";
                  const audio = new Audio(payload);
                  context.commit("SET_AUDIO_MEDIA", audio);
                } else {
                  context.commit("SET_IMG_MEDIA", payload);
                }
              }
            });
            if (found == false) {
              console.error("getMedialist: Media Missing in zip-file", m);
            }
          });
        });
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async getImgMedia(context, id) {
      context.commit("SET_LOADING_STATUS", true);
      console.log("getImgMedia rereive img ", id);
      hshApi
        .getImgMedia(id)
        .then((res) => {
          const media = res;
          const payload = { id: id, url: media };
          context.commit("SET_IMG_MEDIA", payload);
        })
        .catch((error) => {
          context.commit("setErrorMessage", error);
        });
      context.commit("SET_LOADING_STATUS", false);
    },
    async getAudioMedia(context, id) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        const media = await hshApi.getAudioMedia(id);
        const payload = { id: id, url: media };
        // console.log('SET_AUDIO_MEDIA',payload)
        context.commit("SET_AUDIO_MEDIA", payload);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async getTopics(context) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        const topics = await hshApi.getTopics();
        console.log("SET_TOPIC_TABLE ", topics, context);
        const topicMap = topics.map((qt) => ({
          topic_id: qt.id,
          topic_name: qt.name,
        }));
        context.commit("SET_TOPIC_TABLE", topicMap);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }

      context.commit("SET_LOADING_STATUS", false);
    },
    async updateTopics(context, topic) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        const updatedtopic = await hshApi.updateTopics(topic);
        console.log("updateTopics", updatedtopic);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      await context.dispatch("getTopics");
      context.commit("SET_LOADING_STATUS", false);
    },
    async newTopics(context, topic) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        const newtopic = await hshApi.newTopics(topic);
        console.log("newTopic", newtopic);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      await context.dispatch("getTopics");
      context.commit("SET_LOADING_STATUS", false);
    },
    async deleteTopics(context, id) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        const deltopic = await hshApi.deleteTopics(id);
        console.log("deleteTopics", deltopic);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      await context.dispatch("getTopics");
      context.commit("SET_LOADING_STATUS", false);
    },
    async updateToken(context, token) {
      context.commit("SET_KEYCLOAK_TOKEN", token);
    },
    setCurrentQuestionIndex(context, payload) {
      const { commit } = context;
      commit("SET_CURRENT_QUESTION_INDEX", payload);
    },
    async fetchSurveys(context) {
      const { commit } = context;
      try {
        context.commit("SET_LOADING_STATUS", true);
        const surveys = await context.state.api.fetchSurveys.getSurveys();
        console.log("fetchSurveys", surveys);
        commit("setSurveys", surveys);
      } catch (error) {
        commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    setCurrentSurveyId(context, payload) {
      const { commit } = context;
      console.log("setCurrentSurveyId", payload);
      commit("setCurrentSurveyId", payload);
    },
    setCurrentSurveysInstanceId(context, payload) {
      const { commit } = context;
      commit("setCurrentSurveysInstanceId", parseInt(payload));
    },
    async saveSurvey(context, payload) {
      let id = -1;
      context.commit("SET_LOADING_STATUS", true);
      try {
        if (payload.id === -1) {
          const res = await context.state.api.saveSurvey.createSurvey(payload);
          payload.id = res.id;
          payload.respondent_ids = [];
          context.commit("CREATE_SURVEY", payload);
          id = res.id;
        } else {
          await context.state.api.saveSurvey.updateSurvey(payload);
          context.commit("UPDATE_SURVEY", payload);
          id = payload.id;
        }
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
      return id;
    },
    async updateSurvey(context, payload) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        await context.state.api.updateSurvey.updateSurvey(payload);
        context.commit("UPDATE_SURVEY", payload);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async deleteSurvey(context, payload) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        if (payload.id > -1) {
          await context.state.api.deleteSurvey.deleteSurvey(payload);
          context.commit("DELETE_SURVEY", payload);
        }
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async deleteSurveyInstance(context, instanceId) {
      try {
        await context.state.api.deleteSurveyInstance.deleteSurveyInstance(instanceId);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
    },
    async startSurvey(context, startSurveyObject) {
      const { commit } = context;
      try {
        await commit("SET_LOADING_STATUS", true);
        // const commToUse = context.state.api.fetchForms
        console.log("startSurvey: Before API ", context, startSurveyObject);
        const startSurveyObj = await context.state.api.startSurvey.startSurvey(
          startSurveyObject,
          startSurveyObject.survey_id
        );
        console.log("startSurve got:", startSurveyObj);
      } catch (error) {
        commit("setErrorMessage", error);
        console.log(error);
      }
      await commit("SET_LOADING_STATUS", false);
    },
    async startRepeateSurvey(context, startRepeateSurveyObject) {
      const { commit } = context;
      try {
        await commit("SET_LOADING_STATUS", true);
        console.log("startRepeateSurveyObject: Before API ", context, startRepeateSurveyObject);
        const startRepeateSurveyObj = await context.state.api.startRepeateSurvey.startRepeateSurvey(
          startRepeateSurveyObject
          // startRepeateSurveyObject.survey_id
        );
        console.log("startRepeateSurveyObject got:", startRepeateSurveyObj);
        // await commit("setCurrentStartSurvey", startSurveyObj);
      } catch (error) {
        commit("setErrorMessage", error);
        console.log(error);
      }
      await commit("SET_LOADING_STATUS", false);
    },
    
    async fetchSurveysInstances(context) {
      const { commit } = context;
      try {
        await commit("SET_LOADING_STATUS", true);
        // const commToUse = context.state.api.fetchForms
        console.log("fetchSurveysInstances: Before API ", context);
        const surveysInstances =
          await context.state.api.fetchForms.fetchSurveysInstances();
        console.log("Store: Got from fetchSurveysInstances", surveysInstances);
        await commit("setSurveysInstances", surveysInstances);
      } catch (error) {
        commit("setErrorMessage", error);
        console.log(error);
      }
      await commit("SET_LOADING_STATUS", false);
    },
    // Forms action
    // =====================
    async fetchForms(context, belongToSurveyId) {
      const { commit } = context;
      try {
        await commit("SET_LOADING_STATUS", true);
        // const commToUse = context.state.api.fetchForms
        console.log("fetchForms: Before API ", context);
        const forms = await context.state.api.fetchForms.getForms(-1);
        console.log("Store: Got from fetchForms", forms, belongToSurveyId);
        console.log(forms);
        await commit("setForms", forms);
      } catch (error) {
        commit("setErrorMessage", error);
        console.log(error);
      }
      await commit("SET_LOADING_STATUS", false);
    },
    async fetchForm(context, formId) {
      const { commit } = context;
      try {
        await commit("SET_LOADING_STATUS", true);
        const form = await context.state.api.fetchForms.getForms(formId);
        console.log("Store: Got from fetchForm", form);
        console.log(form);
        await commit("setForm", form);
      } catch (error) {
        commit("setErrorMessage", error);
        console.log(error);
      }
      await commit("SET_LOADING_STATUS", false);
    },
    async createForm(context, payload) {
      // payload.author = context.state.token.name;
      // payload.created = moment().format();
      // mockup helper
      // payload.owner_org_id = 100;
      // payload.owner_org_name = "Vårdstyrkan";
      payload.questions = [];
      console.log(payload);
      try {
        context.commit("SET_LOADING_STATUS", true);
        await context.state.api.createForm.createForm(payload);
        context.dispatch("fetchForms");
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    setCurrentFormId(context, payload) {
      const { commit } = context;
      commit("setCurrentFormId", payload);
    },
    async saveForm(context, payload) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        if (payload.id === -1) {
          await context.state.api.saveForm.createForm(payload);
        } else {
          //await context.state.api.saveForm.createForm(payload);
          await context.state.api.saveForm.updateForm(payload);
        }
        await context.dispatch("fetchForms");
      } catch (error) {
        context.commit("setErrorMessage", error);
        console.log(error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async deleteForm(context, payload) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        //await context.state.api.saveForm.createForm(payload);
        await context.state.api.deleteForm.deleteForm(payload);
        await context.dispatch("fetchForms");
      } catch (error) {
        context.commit("setErrorMessage", error);
        console.log(error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    // Questions
    // =================================
    async fetchQuestions(context) {
      const { commit } = context;
      if (this.state.questions.length == 0 || this.state.questionsUpdateReq == true) {
        context.commit("SET_LOADING_STATUS", true);
        try {
          console.log("fetchQuestions", context.state.api.fetchQuestions);
          const questions = await context.state.api.fetchQuestions.getQuestions();
          commit("setQuestions", questions);
        } catch (error) {
          commit("setNamedErrorMessage", "fetchQuestions", error);
        }
        this.state.questionsUpdateReq = false;
        context.commit("SET_LOADING_STATUS", false);
      }
    },
    async fetchMessages(context, handleLoadingFlag) {
      const { commit } = context;
      if (handleLoadingFlag) {
        context.commit("SET_LOADING_STATUS", true);
      }
      try {
        const messages = await context.state.api.fetchMessages.getMessages();
        commit("setMessages", messages);
      } catch (error) {
        commit("setNamedErrorMessage", "fetchMessages", error);
      }
      if (handleLoadingFlag) {
        context.commit("SET_LOADING_STATUS", false);
      }
    },
    async revokeMessage(context, item) {
      const { commit } = context;
      context.commit("SET_LOADING_STATUS", true);
      try {
        const message = await context.state.api.fetchMessages.revokeMessage(item.id);
        commit("revokeMessage", message);
        console.log("revokeMessage action", item);
      } catch (error) {
        commit("setNamedErrorMessage", "revokeMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async saveQuestion(context, payload) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        if (payload.id === -1) {
          const questRes = await context.state.api.saveQuestion.createQuestion(payload);
          const res = await context.state.api.saveQuestion.getQuestion(questRes.id);
          context.commit("setQuestion", res);
        } else {
          await context.state.api.saveQuestion.updateQuestion(payload);
          const res = await context.state.api.saveQuestion.getQuestion(payload.id);
          context.commit("setQuestion", res);
        }
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    async deleteQuestion(context, payload) {
      context.commit("SET_LOADING_STATUS", true);
      try {
        if (payload.id >= 0) {
          await context.state.api.saveQuestion.deleteQuestion(payload);
        }
        context.commit("deleteQuestion", payload);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      context.commit("SET_LOADING_STATUS", false);
    },
    setAnswerCurrentQuestionId(context, payload) {
      const { commit } = context;
      commit("setAnswerCurrentQuestionId", payload);
    },
    async sendConfirmedAnswers(context) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      console.log("sendConfirmedAnswers");
      try {
        await context.state.api.sendConfirmedAnswers.sendAnswers(context.state.answers2);
      } catch (error) {
        commit("setNamedErrorMessage", "sendConfirmedAnswers", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    clearAnswers(context) {
      const { commit } = context;
      console.log("clearAnswers");
      commit("CLEAR_ANSWERS");
    },
    async fetchResponders(context) {
      const { commit } = context;
      try {
        // if (context.state.responders.length > 1) {
        //   return
        // }
        commit("SET_LOADING_STATUS", true);
        // const commToUse = context.state.api.fetchForms
        console.log("fetchResponders: Before API ", context);
        const reponders = await context.state.api.fetchResponders.getResponders();
        console.log("Store: Got from getResponders", reponders);
        await commit("SET_REPONDERS", reponders);
      } catch (error) {
        commit("setErrorMessage", error);
        console.log(error);
      }
      await commit("SET_LOADING_STATUS", false);
    },
    async updateResponder(context, payload) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      try {
        await context.state.api.updateResponder.updateResponder(payload);
        await commit("UPDATE_REPONDER", payload);
        console.log("updateResponder", payload);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    async addNewResponder(context, payload) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      try {
        const newUser = await context.state.api.createResponder.createResponder(payload);
        payload.id = newUser.id;
        await commit("NEW_REPONDER", payload);
        console.log("addNewResponder", payload);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    async deleteResponder(context, payload) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      try {
        const delResponder = await context.state.api.createResponder.deleteResponder(
          payload
        );
        await commit("DELETE_REPONDER", payload);
        console.log("deleteResponder", payload, delResponder);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    async updateJWT(context, payload) {
      const { commit } = context;
      let responder;
      commit("SET_LOADING_STATUS", true);
      try {
        responder = await context.state.api.createResponder.updateResponderJwt(payload);
        await commit("UPDATE_REPONDER_JWT", responder);
        console.log("updateJWT", payload);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
      return responder;
    },
    async getSurveyReport(context, surveyId) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      try {
        const report = await context.state.api.surveyReport.getSurveyReport(surveyId);
        await commit("SET_SURVEY_REPORT", report);
        console.log("getSurveyReport", report);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    async getOrganisation(context, orgid) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      try {
        const org = await context.state.api.getOrganisations.getOrganisations(orgid);
        if (org !== undefined) {
          await commit("SET_ORGANISATION", org);
        }
        console.log("getTerminals", org, orgid);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    async getTerminals(context) {
      const { commit } = context;
      commit("SET_LOADING_STATUS", true);
      try {
        const terminals = await context.state.api.terminals.getTerminals();
        await commit("SET_TERMINALS", terminals);
        console.log("getTerminals", terminals);
      } catch (error) {
        context.commit("setErrorMessage", error);
      }
      commit("SET_LOADING_STATUS", false);
    },
    setnrOfRegRequests(context, nrOfRegRequests) {
      const { commit } = context;
      commit("SET_NR_OF_REG_REQUESTS", nrOfRegRequests);
    },
    fetchAccountApplicants(context) {
      const { commit } = context;
      context.state.api.accountApplication
        .accountApplication()
        .then((res) => {
          if (res.status == 200) {
            const accountsApplication = res.data;
            const nrOfRegRequests = accountsApplication.length;
            commit("SET_NR_OF_REG_REQUESTS", nrOfRegRequests);
          }
        })
        .catch((err) => {
          console.error("Failed to fetch accountApplications", err);
          commit("setNamedErrorMessage", "accountApplication", err);
        });
    },
    testLocal(context) {
      // const { commit } = context;
      context.state.api.test
        .getTestData()
        .then((res) => {
          console.log("Testdata", res);
        })
        .catch((err) => {
          console.error("Failed to fetch test", err);
        });
    },
    setShowMessageLoaded(context, inc) {
      context.commit("SET_SHOW_MESSAGE_LOADED_TAB", inc);
    },
  },
  getters: {
    getShowMessageLoaded(state) {
      return state.showMessageLoaded;
    },
    getImageById: (state) => (id) => {
      const img = state.imgmedia_url.find((img) => img.id === id);
      if (img == undefined) {
        console.error("MediaNotFound with Id:", id);
        return undefined;
      }
      return img.url;
    },
    getAudioById: (state) => async (id) => {
      const audio = state.audiomedia_url.find((audio) => audio.id === id);
      if (audio == undefined) {
        console.error("AudioMediaNotFound with Id:", id, state.audiomedia_url.length);
        return undefined;
      }
      // console.log('getAudioById will play')
      // audio.url.play()
      return audio.url;
    },
    getAudioMetaDataWithName: (state) => (name) => {
      const media = state.medialist.find((media) => media.name === name);
      if (media == undefined) {
        console.log(
          "getAudioMetaDataWithName => media not found",
          name,
          state.medialist.length
        );
        return 0;
      }
      console.log("getAudioMetaDataWithName => found", name, state.medialist.length);
      return media.id;
    },
    getAudio(state, id) {
      return state.audiomedia_url[id];
    },
    getCurrentQuestionIndex(state) {
      return state.currentQuestionIndex;
    },
    getSurveys(state) {
      console.log("getSurveys: Fetched all surveys");
      return state.surveys;
    },
    getSurveysInstances(state) {
      console.log("getSurveysInstances: Fetched all getSurveysInstances");
      return state.surveysInstances;
    },
    getCurrentSurvey(state) {
      const id = state.currentSurveyId;
      if (id == null || id == -1 || state.surveys.length <= 0) {
        console.log("getCurrentSurvey: No survey selcted");
        return null;
      }
      const obj = state.surveys.find((s) => s.id == id);
      console.log(`getCurrentSurvey: Survey nr ${id} selcted`, obj, state.surveys);
      return obj;
    },
    getForms(state) {
      console.log("getForms: Fetched all forms " + state.forms.length);
      return state.forms;
    },
    async getCurrentForm(state) {
      const id = state.currentFormId;
      console.log(`getCurrentForm: Form requested is ${id}`);
      if (id == null || id == -1) {
        // || state.form.length <= 0 necessary?
        console.log("getCurrentForm: No form selcted");
        return null;
      }
      console.log(`getCurrentForm: form nr ${id} selcted`);
      let form;
      console.log(
        "getCurrentForm state have this form ",
        state.forms,
        state.forms.length
      );
      try {
        form = await state.forms.find((s) => s.id == id);
      } catch (e) {
        console.log("getCurrentForm missing form ", id, state.forms, e);
      }
      console.log("getCurrentForm found this ", form);
      return form;
    },
    getLoadingStatus(state) {
      return state.loadingStatus;
    },
    getCurrentFormId(state) {
      console.log(`getter: getCurrentFormId: ${state.currentFormId}`);
      return state.currentFormId;
    },
    getCurrentSurveysInstanceId(state) {
      console.log(
        `getter: getCurrentSurveysInstanceId: ${state.currentSurveysInstanceId}`
      );
      return state.currentSurveysInstanceId;
    },
    getQuestions(state) {
      console.log("getQuestions: Fetched all questions");
      return state.questions;
    },
    getQuestionWithId: (state) => (id) => {
      const q = state.questions.find((s) => s.id === id);
      console.log("getQuestionWithId", q, id);
      return q;
    },
    getCurrentQuestion(state) {
      let id = state.currentQuestionId;
      if (id == null || id < 0 || state.questions.length <= 0) {
        state.currentQuestionId = $_.get(
          state.forms[0],
          `questions[${state.currentQuestionIndex}].question.id`,
          -2
        );
        id = state.currentQuestionId;
        if (id < 0) {
          console.log("getCurrentQuestion: No question selcted");
          return null;
        }
      }
      console.log(`getCurrentQuestion: Question nr ${id} selcted`);
      return state.questions.find((s) => s.id === id);
    },
    getAnswers(state) {
      return state.answers2;
    },
    getReponders(state) {
      console.log(
        "getReponders: Fetched all responders nrOfResponders " + state.responders.length
      );
      return state.responders;
    },
    getTopics(state) {
      return state.queryTopics;
    },
    getSurveyReport(state) {
      return state.surveyReport;
    },
    getMessages(state) {
      return state.messages;
    },
  },
  modules: {
    applStore: applStore,
  },
});
