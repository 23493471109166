var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    { staticClass: "dialog-card", attrs: { actions: _vm.actions } },
    [
      _c(
        "v-row",
        { staticClass: "dialog-card", attrs: { fluid: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card-title",
                { staticClass: "mb-4 pb-4 blue darken-4 white--text" },
                [
                  _vm._v(
                    " Ändra/skapa Filtervariabel/respondent data " +
                      _vm._s(_vm.item.name) +
                      " "
                  )
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "py-6",
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "px-2",
                        attrs: { disabled: "", label: "Id" },
                        model: {
                          value: _vm.item.id,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "id", $$v)
                          },
                          expression: "item.id"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "px-2",
                        attrs: {
                          disabled: _vm.type == "edit",
                          label: "Filtervariabel/respondent data",
                          rules: _vm.nameRules,
                          required: ""
                        },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "px-2",
                        attrs: { label: "Beskrivning", rules: _vm.nameRules },
                        model: {
                          value: _vm.item.description,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item.description"
                        }
                      }),
                      _c("v-combobox", {
                        attrs: {
                          items: _vm.availableGroups,
                          label: "Filterkategori"
                        },
                        model: {
                          value: _vm.item.groupname,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "groupname", $$v)
                          },
                          expression: "item.groupname"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          items: _vm.typeitems,
                          label: "Typ av variabel",
                          required: ""
                        },
                        model: {
                          value: _vm.item.type,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "type", $$v)
                          },
                          expression: "item.type"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "px-2",
                        attrs: { label: "Möjliga värden" },
                        model: {
                          value: _vm.item.valid_values,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "valid_values", $$v)
                          },
                          expression: "item.valid_values"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "px-2",
                        attrs: { label: "default värden" },
                        model: {
                          value: _vm.item.default_value,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "default_value", $$v)
                          },
                          expression: "item.default_value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }