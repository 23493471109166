<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <surveys-table 
            @selectSurvey="ServeySelected"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <survey-form ref=instansForm />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SurveysTable from "../components/SurveysTable.vue";
import SurveyForm from "../components/SurveyForm.vue";
// const apiUrl = "https://api.homesvithome.se/v1.0/surveys";
// import axios from "axios";

export default {
  components: {
    SurveysTable,
    SurveyForm,
  },
  data() {
    return {};
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    surveys() {
      return this.$store.getters["getSurveys"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  beforeMount() {
    this.$store.dispatch("fetchSurveys");
    // this.$store.dispatch("fetchForms");
  },
   beforeRouteLeave(to, from, next) {
     if (this.$store.state.applStore.hasUnsavedChanges) {
       const answer = window.confirm(
         "Du har osparade ändringar. Vill du verkligen byta sida?"
      );
      if (answer) {
        this.$store.state.applStore.hasUnsavedChanges = false
        next();
      } else {
        next(false);
      }
    } else {
      next()
    }
  },

  methods: {
    ServeySelected(item) {
      this.$refs.instansForm.surveyTableClicked(item)
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style></style>
