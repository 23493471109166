import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Surveys from "../views/Surveys.vue";
import SurveysInstances from "../views/SurveysInstances.vue";
import Forms from "../views/Forms.vue";
import Questions from "../views/Questions.vue";
// import Selections from "../views/Selections.vue";
import Responders from "../views/Responders.vue";
import Filter from '../views/Filter.vue';
import Categories from '../views/Categories.vue';
import Reports from '../views/Reports.vue';
import CreateMessage from '../views/CreateMessage';
import SendMessages from '../views/SendMessages';
import AccountApplication from '../views/AccountApplication'
import AddApplicant from '../views/AddApplicant'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: Home,
    meta: {
      isAuthenticated: false
    },
  },
  {
    path: "/responders",
    name: "Respondenter",
    component: Responders,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/surveys",
    name: "Skapa undersökningar",
    component: Surveys,
    meta: {
      isAuthenticated: false
    },
  },
  {
    path: "/surveysInstances",
    name: "Starta undersökning",
    component: SurveysInstances,
    meta: {
      isAuthenticated: false
    },
  },
  {
    path: "/forms",
    name: "Formulär",
    component: Forms,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/questions",
    name: "Frågor",
    component: Questions,
    meta: {
      isAuthenticated: true
    },
  },
  // {
  //   path: "/selections",
  //   name: "Urvalsgrupper",
  //   component: Selections,
  //   meta: {
  //     isAuthenticated: true
  //   },
  // },
  {
    path: "/filter",
    name: "Filter",
    component: Filter,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/categories",
    name: "Kategorier & Filtervariabler",
    component: Categories,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/reports",
    name: "Rapporter",
    component: Reports,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/createMessage",
    name: "Skapa meddelande",
    component: CreateMessage,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/sendMessages",
    name: "Skickade meddelanden",
    component: SendMessages,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/AccountApplication",
    name: "Nya kontoansökningar",
    component: AccountApplication,
    meta: {
      isAuthenticated: true
    },
  },
  {
    path: "/AddApplicant",
    name: "Ny kontoansökan",
    component: AddApplicant,
    meta: {
      isAuthenticated: true
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("Route:",to, from, next)
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
    } else if (Vue.$keycloak.hasRealmRole('default-roles-hsh')) {
      console.log("Route: BeforeEach check keycloak",Vue.$keycloak,Vue.$keycloak.hasRealmRole('default-roles-hsh'),Vue.$keycloak.hasResourceRole('admin_read','scope'))
      // The user was authenticated, and has the app role
      next()
    } else {
      // The user was authenticated, but did not have the correct role
      // Redirect to an error page

      next({ name: 'Unauthorized' })
    }
  } else {
    // This page did not require authentication
    next()
  }
});

// router.afterEach((to, from )  => {
//   console.log("afterEach router",router.app.$store.state)
//   if (router.app.$store.state.applStore.hasUnsavedChanges) {
//     const answer = window.confirm('Du har osparade ändringar. Vill du verkligen byta sida?')
//     console.log("afterEach answer", answer, to,from)
//     router.app.$store.state.applStore.hasUnsavedChanges=false // Clear
//     if (answer) {
//       next()
//     } else {
//       next(false)
//     }
//   }
//   next()
// });

export default router;
