<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <surveys-table
            :showNewButton="false"
            :emitMessage="emitMessage"
            :startSearch="startSearch"
            @selectSurvey="ServeySelected"
          ></surveys-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SurveyInstanceForm ref=instansForm></SurveyInstanceForm>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SurveysTable from "../components/SurveysTable.vue";
import SurveyInstanceForm from "../components/SurveyInstanceForm.vue";
// const apiUrl = "https://api.homesvithome.se/v1.0/surveys";
// import axios from "axios";

export default {
  components: {
    SurveysTable,
    SurveyInstanceForm,
  },
  data() {
    return {
      emitMessage: "surveysTable-surveyInstans-clicked",
      startSearch: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    surveys() {
      return this.$store.getters["getSurveys"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  beforeMount() {
    this.$store.dispatch("fetchSurveys");
    // this.$store.dispatch("fetchForms");
  },
  async mounted() {
    const id = this.$_.get(this.$route, "query.survey", -1);
    console.log(
      "SurveyInstances mounted route",
      this.$route.query.survey,
      this.$route.query,
      this.$route.params,
      id
    );
    if (id > 0) {
      console.log("SurveyInstances mounted", id);
      // Set current surveyInstans
      await this.$store.dispatch("setCurrentSurveyId", id);
      // search current instans object just choosen
      const thisSurvey = this.$store.getters.getCurrentSurvey;
      // emit as it was clicked
      this.startSearch = thisSurvey.name;
      console.log(
        "SurveyInstances mounted fakesetup",
        thisSurvey,
        this.startSearch
      );
      this.$root.$emit("surveysTable-surveyInstans-clicked", thisSurvey);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.applStore.hasUnsavedChanges) {
      const answer = window.confirm(
        "Du har osparade ändringar. Vill du verkligen byta sida?"
      );
      if (answer) {
        this.$store.state.applStore.hasUnsavedChanges = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    ServeySelected(item) {
      this.$refs.instansForm.showSurvey(item)
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style></style>
