<template>
<div>
  <iframe :src="urlToShow" frameborder="1"></iframe>
  </div>
</template>

<script>
export default {
  name: "AddApplicant",
  components: {  },
  props: [],
  data() {
    return {
      urlToShow:`${process.env.VUE_APP_API}userapplicationform/${this.$store.state.organisation.code}`
    }
  },
  computed: {
  }
}
</script>

<style scoped>
iframe {
width: 100%;
height: 100vh;
max-width: 6000px;
min-width: 500px;
}

</style>