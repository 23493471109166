var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            color: _vm.color,
            "clipped-left": true,
            fixed: "",
            app: "",
            dark: ""
          },
          scopedSlots: _vm._u([
            {
              key: "extension",
              fn: function() {
                return [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "background-color": "primary", dark: "" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _vm._l(_vm.items, function(item) {
                        return _c(
                          "v-tab",
                          {
                            key: item.tab,
                            on: {
                              click: function($event) {
                                return _vm.tabClicked(item)
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(item.tab) + " "),
                            item.content == "MessageArea" &&
                            _vm.nrOfMessages > 0
                              ? _c("v-badge", {
                                  attrs: {
                                    color: "red",
                                    content: _vm.nrOfMessages
                                  }
                                })
                              : _vm._e(),
                            item.content == "RegistrationArea" &&
                            _vm.nrOfRegRequests > 0
                              ? _c("v-badge", {
                                  attrs: {
                                    color: "red",
                                    content: _vm.nrOfRegRequests
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        _vm._l(_vm.items, function(item) {
                          return _c(
                            "v-tab-item",
                            { key: item.tab },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "fill-height scroll",
                                  style: _vm.countHeight,
                                  attrs: { flat: "" }
                                },
                                [_c(item.content, { tag: "component" })],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    2
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c("v-img", {
                staticClass: "shrink mr-2",
                attrs: {
                  alt: "HomeSvITHome Logo",
                  contain: "",
                  src: require("./assets/HomeSvITHome_light.png"),
                  transition: "scale-transition",
                  width: "40",
                  position: "bottom"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "5", align: "left" } },
            [
              _c("v-toolbar-title", {
                staticClass: "ml-auto",
                domProps: { textContent: _vm._s(_vm.title) }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [_c("v-progress-linear", { attrs: { value: _vm.loadingStatus } })],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4", align: "right" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { depressed: "", color: _vm.color },
                  on: { click: _vm.Logout }
                },
                [
                  _vm._v(
                    "Logga ut - " +
                      _vm._s(_vm.$keycloak.tokenParsed.name) +
                      " - " +
                      _vm._s(_vm.company)
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-footer", { attrs: { absolute: false, app: "" } }, [
        _c("span", [_vm._v("© " + _vm._s(new Date().getFullYear()))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }