var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hide
        ? _c(
            "div",
            [
              _c("v-icon", { on: { click: _vm.toggleDataTable } }, [
                _vm._v("mdi-arrow-down-drop-circle-outline")
              ]),
              _c("v-data-table", {
                staticClass: "elevation-1 whitespace",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.messages,
                  "sort-by": "name",
                  search: _vm.search,
                  dense: "",
                  "single-select": true,
                  "items-per-page": 10,
                  loading: !_vm.loadingStatus,
                  "loading-text": "Laddar...",
                  "item-class": _vm.itemActionRowBackground,
                  "footer-props": {
                    showFirstLastPage: true,
                    "items-per-page-text": "Rader per sida",
                    "items-per-page-options": [5, 10, 15, -1],
                    "items-per-page-all-text": "Alla"
                  }
                },
                on: { "click:row": _vm.handleRowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c("v-toolbar", {
                            staticStyle: { display: "none" },
                            attrs: { flat: "" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.nb",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        item.nrOfActionResponders > 0 &&
                                        item.revoked_at_date == "-"
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "blink",
                                                    attrs: {
                                                      small: "",
                                                      color: "red"
                                                    }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " mdi-exclamation-thick "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-center d-flex align-center justify-space-around"
                                },
                                [_vm._v(" " + _vm._s(item.nb) + " ")]
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.state",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.isStillValid(item)
                            ? _c(
                                "span",
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      value: item.progress,
                                      color: item.progressColor,
                                      height: "25"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var value = ref.value
                                            return [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(Math.floor(value)) +
                                                    "%"
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            : _c("span", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(Math.floor(item.progress)) + "%"
                                  )
                                ])
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.answers",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(" " + _vm._s(_vm.nrOfAnswers(item)) + " ")
                        ]
                      }
                    },
                    {
                      key: "item.nrOfResponders",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("popup-list", {
                            attrs: {
                              dataToShow: item.nrOfResponders,
                              title: "Mottagare",
                              headers: _vm.respondentHeaders,
                              items: _vm.respondersList(item)
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.nrOfAckResponders",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("popup-list", {
                            attrs: {
                              dataToShow: item.nrOfAckResponders,
                              title: "Har konfirmerat meddelandet",
                              headers: _vm.respondentHeaders,
                              items: _vm.ackRespondersList(item)
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.nrOfActionResponders",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("popup-list", {
                            attrs: {
                              dataToShow: item.nrOfActionResponders,
                              title: "Vill bli kontaktade",
                              headers: _vm.respondentHeaders,
                              items: _vm.actionRespondersList(item)
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        !item.locked
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.revokeItem(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " mdi-card-bulleted-off-outline "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-center d-flex align-center justify-space-around"
                                },
                                [_vm._v(" Återkalla frågan ")]
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "footer.prepend",
                      fn: function() {
                        return [
                          _c("v-spacer"),
                          _c("v-text-field", {
                            staticClass: "mb-5",
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Sök fråga",
                              "single-line": "",
                              "hide-details": ""
                            },
                            on: { keyup: _vm.clearOnEscape },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _c("v-spacer")
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2501154231
                ),
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("v-icon", { on: { click: _vm.toggleDataTable } }, [
                _vm._v("mdi-arrow-right-drop-circle-outline")
              ]),
              _vm._v(" expandera listan med meddelanden... ")
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.dialogRevoke,
            callback: function($$v) {
              _vm.dialogRevoke = $$v
            },
            expression: "dialogRevoke"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Vill du verkligen återkalla detta meddelande?")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeDelete }
                    },
                    [_vm._v("Avbryt")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.revokeItemConfirm }
                    },
                    [_vm._v("OK")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }