var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DialogCard",
        { attrs: { title: _vm.title, actions: _vm.actions } },
        [
          _c("v-color-picker", {
            attrs: { flat: "" },
            model: {
              value: _vm.privColor,
              callback: function($$v) {
                _vm.privColor = $$v
              },
              expression: "privColor"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }