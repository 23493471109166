var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "auto", persistent: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Välj frågor ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("v-card", [
            _vm.loadingStatus
              ? _c(
                  "div",
                  [
                    _c(
                      "v-container",
                      {
                        attrs: {
                          fluid: "",
                          "mx-5": "",
                          "pt-10": "",
                          "px-5": "",
                          "fill-height": ""
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-3",
                                  attrs: {
                                    headers: _vm.questionHeaders,
                                    items: _vm.questions,
                                    "single-expand": _vm.singleExpand,
                                    expanded: _vm.expanded,
                                    search: _vm.search,
                                    "sort-by": "topic_name",
                                    "item-key": "question.id",
                                    "show-expand": "",
                                    disable: "false",
                                    "footer-props": {
                                      "items-per-page-text": "Rader per sida",
                                      "items-per-page-all-text": "Alla"
                                    }
                                  },
                                  on: {
                                    "update:expanded": function($event) {
                                      _vm.expanded = $event
                                    },
                                    "click:row": _vm.handleRowClick
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "top",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-toolbar",
                                              { attrs: { flat: "" } },
                                              [
                                                _c("v-toolbar-title", [
                                                  _vm._v("Frågebank")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "body",
                                        fn: function(props) {
                                          return [
                                            _c(
                                              "draggable",
                                              {
                                                attrs: {
                                                  list: props.items,
                                                  tag: "tbody",
                                                  options:
                                                    _vm.availableItemOptions,
                                                  clone: _vm.onCloneCallback
                                                },
                                                on: { end: _vm.onDropCallback },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "expanded-item",
                                                      fn: function(ref) {
                                                        var headers =
                                                          ref.headers
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan:
                                                                  headers.length
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "my-4"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Svarsalternativ"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "table",
                                                                _vm._l(
                                                                  item.answers,
                                                                  function(
                                                                    answer,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key: index
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "box mr-2 mt-1",
                                                                            style: {
                                                                              "background-color":
                                                                                answer
                                                                                  .ui_hint
                                                                                  .bgcolor
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " - " +
                                                                                _vm._s(
                                                                                  answer
                                                                                    .i18n
                                                                                    .sv
                                                                                    .text
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                              _c("div", {
                                                                staticClass:
                                                                  "my-4"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              _vm._l(props.items, function(
                                                quest,
                                                index
                                              ) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "page__grab-icon",
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-arrow-all " +
                                                                _vm._s(
                                                                  _vm.what(
                                                                    "props",
                                                                    props
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          quest.question.i18n.sv
                                                            .text
                                                        )
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          quest.question.id
                                                        )
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          quest.question.author
                                                        )
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          quest.question
                                                            .topic_name
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "footer.prepend",
                                        fn: function() {
                                          return [
                                            _c("v-spacer"),
                                            _c("v-text-field", {
                                              staticClass: "mb-5",
                                              attrs: {
                                                "append-icon": "mdi-magnify",
                                                label: "Sök fråga",
                                                "single-line": "",
                                                "hide-details": ""
                                              },
                                              on: { keyup: _vm.clearOnEscape },
                                              model: {
                                                value: _vm.search,
                                                callback: function($$v) {
                                                  _vm.search = $$v
                                                },
                                                expression: "search"
                                              }
                                            }),
                                            _c("v-spacer")
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    582530424
                                  ),
                                  model: {
                                    value: _vm.selected,
                                    callback: function($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "2", "max-height": "" } },
                              [
                                _c(
                                  "h4",
                                  {
                                    staticClass: "pb-5",
                                    attrs: { align: "center" }
                                  },
                                  [_vm._v("Instruktion")]
                                ),
                                _c("h5", { attrs: { align: "left" } }, [
                                  _vm._v("Nya frågor:")
                                ]),
                                _vm._v(
                                  " Drag önskad fråga från frågebanken till vänster över till formuläret [" +
                                    _vm._s(_vm.clonedItems.name) +
                                    "] till höger "
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass: "pt-10",
                                    attrs: { align: "left" }
                                  },
                                  [_vm._v("Sortera frågor:")]
                                ),
                                _vm._v(
                                  " Ändra ordningen på frågorna i tabellen för 'Valda frågor' genom att drar och släpper dem i den ordning du vill ha dem "
                                )
                              ]
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pr-12", attrs: { cols: "5" } },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-3",
                                  attrs: {
                                    headers: _vm.questionHeaders,
                                    items: _vm.currentQuestions,
                                    "single-expand": _vm.singleExpand,
                                    expanded: _vm.expanded,
                                    "item-key": "id",
                                    "show-expand": "",
                                    "hide-default-footer": ""
                                  },
                                  on: {
                                    "update:expanded": function($event) {
                                      _vm.expanded = $event
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "top",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-toolbar",
                                              { attrs: { flat: "" } },
                                              [
                                                _c("v-toolbar-title", [
                                                  _vm._v(
                                                    "Valda frågor - " +
                                                      _vm._s(
                                                        _vm.clonedItems.name
                                                      )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "expanded-item",
                                        fn: function(ref) {
                                          var headers = ref.headers
                                          var item = ref.item
                                          return [
                                            _c(
                                              "td",
                                              {
                                                attrs: {
                                                  colspan: headers.length
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " More info about " +
                                                    _vm._s(item.name) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "body",
                                        fn: function(props) {
                                          return [
                                            _c(
                                              "draggable",
                                              {
                                                attrs: {
                                                  list:
                                                    _vm.clonedItems.questions,
                                                  tag: "tbody",
                                                  options:
                                                    _vm.clonedItemOptions,
                                                  move: _vm.onMoveCallback,
                                                  clone: _vm.onCloneCallback,
                                                  ondrop: _vm.onDropCallback
                                                },
                                                on: { end: _vm.onDropCallback }
                                              },
                                              _vm._l(
                                                _vm.clonedItems.questions,
                                                function(quest, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "page__grab-icon",
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-arrow-all " +
                                                                  _vm._s(
                                                                    _vm.what(
                                                                      "clonedItems",
                                                                      _vm.clonedItems
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            quest.question.i18n
                                                              .sv.text
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            quest.question.id
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("quest.author")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            quest.question
                                                              .topic_name
                                                          )
                                                        )
                                                      ]),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteQuest(
                                                                    quest,
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-delete "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(
                                              " " + _vm._s(props.items.id) + " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "footer",
                                        fn: function() {
                                          return undefined
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1621624953
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "my-8",
                            attrs: { align: "center", justify: "center" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "blue darken-1", text: "" },
                                on: { click: _vm.closeDelete }
                              },
                              [_vm._v("Avbryt")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "blue darken-1", text: "" },
                                on: { click: _vm.saveForm }
                              },
                              [_vm._v("Spara")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "primary", dark: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _vm._v(" Var god dröj... "),
                            _c("v-progress-linear", {
                              staticClass: "mb-0",
                              attrs: { indeterminate: "", color: "white" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }