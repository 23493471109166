<template>
  <DialogCard :title="title" :actions="actions">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="form.name" label="Namn" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="form.topic_id"
              :items="queryTopics"
              label="Kategori"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </DialogCard>
</template>

<script>
export default {
  props: ["form", "title", "$store"],

  data() {
    return {
      topic_name: "",
    };
  },

  created() {
    console.log("EditForm", this, this.form);
  },

  computed: {
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            const topic = this.$store.state.queryTopics.find(
              (q) => q.topic_id == this.form.topic_id
            );
            if (topic != undefined) {
              this.form.topic_name = topic.topic_name;
            }
            return this.form;
          },
        },
      };
    },

    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
  },
};
</script>
