var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-card",
                {
                  style: _vm.vCardStyle(_vm.answer),
                  attrs: { outlined: "", "min-width": "80%" }
                },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "cardstyle",
                      staticStyle: { width: "100%" },
                      attrs: { "two-line": "" }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { staticClass: "pt-10 pb-5" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("HshImage", {
                                    attrs: { label: "Svarsikon" },
                                    model: {
                                      value: _vm.answerIcon,
                                      callback: function($$v) {
                                        _vm.answerIcon = $$v
                                      },
                                      expression: "answerIcon"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                                _c(
                                  "div",
                                  {
                                    ref: "divbox",
                                    class: _vm.getTextSpec(_vm.answer)
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "shrink ml-4",
                                      staticStyle: { width: "710px" },
                                      attrs: { label: "Svaret:" },
                                      model: {
                                        value: _vm.answer.i18n.sv.text,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.answer.i18n.sv,
                                            "text",
                                            $$v
                                          )
                                        },
                                        expression: "answer.i18n.sv.text"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-4" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "1" } },
                [
                  _c("hsh-color", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      color: _vm.answer.ui_hint.bgcolor,
                      title: "Svarsfärg"
                    },
                    on: { newColor: _vm.AnswerColorChange }
                  })
                ],
                1
              ),
              _c("v-text-field", {
                staticClass: "px-0 !important; mx-0 !important shrink; my-2",
                staticStyle: { width: "80px" },
                attrs: { label: "Svarets värde:" },
                model: {
                  value: _vm.answer.value,
                  callback: function($$v) {
                    _vm.$set(_vm.answer, "value", $$v)
                  },
                  expression: "answer.value"
                }
              }),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "1" } },
                [
                  _vm.isAudioPresent
                    ? _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "" },
                          on: {
                            click: function($event) {
                              return _vm.playSound(
                                _vm.answer.i18n.sv.media.audio.id
                              )
                            }
                          }
                        },
                        [_vm._v(" mdi-play-box-outline ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "shrink my-2 py-0",
                    attrs: { label: "Nuvarande fras text:", readonly: "" },
                    model: {
                      value: _vm.description,
                      callback: function($$v) {
                        _vm.description = $$v
                      },
                      expression: "description"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c("v-text-field", {
                    staticClass: "shrink; my-2 py-0",
                    attrs: { label: "Nuvarande fras namn:", readonly: "" },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { color: "primary" },
                      on: { click: _vm.HshPhrasePickerAnswer }
                    },
                    [_vm._v("Välj ny fras...")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }