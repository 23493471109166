var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        mouseleave: function($event) {
          return _vm.outOfComp()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "open-on-hover": "",
                "close-on-content-click": false,
                "nudge-width": 200,
                "offset-x": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            { staticClass: "extra-info" },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" " + _vm._s(_vm.dataToShow) + " ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "my-1 py-1 black white--text" },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.items,
                          "hide-default-header": false,
                          "hide-default-footer": true,
                          "disable-pagination": ""
                        },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }