var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogCard",
    { staticClass: "dialog-card", attrs: { actions: _vm.actions } },
    [
      _c(
        "v-row",
        { staticClass: "dialog-card", attrs: { fluid: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card-title",
                { staticClass: "mb-4 pb-4 blue darken-4 white--text" },
                [
                  _vm._v(" Ändra/skapa filter " + _vm._s(_vm.item.name) + " "),
                  _vm.$store.state.applStore.hasUnsavedChanges
                    ? _c("span", [
                        _vm._v(
                          " - Ej i synk med servern - synkronsiera eller hämta från serven "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "py-6",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "px-2",
                    attrs: {
                      disabled: _vm.type == "edit",
                      label: "Name",
                      rules: _vm.nameRules,
                      required: ""
                    },
                    model: {
                      value: _vm.item.name,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "name", $$v)
                      },
                      expression: "item.name"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "px-2",
                    attrs: { label: "Filterdefinition" },
                    model: {
                      value: _vm.item.expression,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "expression", $$v)
                      },
                      expression: "item.expression"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "py-4 my-4",
                  attrs: { color: "primary" },
                  on: { click: _vm.testFilter }
                },
                [_vm._v(" Prova filtret ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "pa-4 ma-4",
                  attrs: { color: "primary" },
                  on: { click: _vm.clearFilter }
                },
                [_vm._v(" Rensa ")]
              ),
              _vm.nrOfHits >= 0
                ? _c(
                    "div",
                    { staticClass: "mb-5" },
                    [
                      _vm._v(" Testet gav "),
                      _c("b", [_vm._v(_vm._s(_vm.nrOfHits) + " st träffar")]),
                      _vm._v(
                        " med denna definition på filter. Upp till de 10 första visas nedan "
                      ),
                      _c("v-data-table", {
                        staticClass: "pt-5",
                        attrs: {
                          headers: _vm.headersresponder,
                          items: _vm.responders,
                          "hide-default-header": false,
                          "hide-default-footer": true,
                          "disable-pagination": ""
                        },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "my-1 py-1 black white--text" },
                            [_vm._v(" Filters ")]
                          ),
                          _c("v-card-text", { staticClass: "hsh" }, [
                            _vm._v(
                              ' Ett filter har ett unikt namn, vissa är fördefinierade, och en filterdefinition (expression el uttryck). Filterdefinitionen måste vara ett giltigt "Python-expression" med filtervariabel som variabelnamn. '
                            )
                          ]),
                          _c("p", [_vm._v("Exampel:")]),
                          _c("p", [
                            _vm._v("Alla män: "),
                            _c("b", [_vm._v("\"kön == 'man'\"")])
                          ]),
                          _c("v-card-text", { staticClass: "hsh" }, [
                            _vm._v(
                              " Parenteser och logiska uttryck (and, or, not) kan användas: "
                            )
                          ]),
                          _c("p", [_vm._v("Exampel:")]),
                          _c("p", [
                            _vm._v("Ej män: "),
                            _c("b", [
                              _vm._v(
                                "\"(kön == 'kvinna') or (kön == 'annat')\""
                              )
                            ])
                          ]),
                          _c("v-card-text", { staticClass: "hsh" }, [
                            _vm._v(
                              " Följande Python-funktioner kan också användas: len "
                            )
                          ]),
                          _c("p", [_vm._v("Exampel:")]),
                          _c("p", [
                            _vm._v("Gamla: "),
                            _c("b", [_vm._v('"1920 < född < 1930"')])
                          ]),
                          _c("v-card-text", { staticClass: "hsh" }, [
                            _vm._v(
                              " Listor kan också undersökas. Om en respondent har filtervariabeln besöksdagar = [måndag,torsdag], som har värdemängden [måndag|tisdag|onsdag|torsdag|fredag|lördag] så kan man skapa följande filter.. "
                            )
                          ]),
                          _c("p", [_vm._v("Exempel:")]),
                          _c("p", [
                            _vm._v("Alla med torsdagar: "),
                            _c("b", [_vm._v('"\'torsdag in besöksdagar"')])
                          ]),
                          _c("v-card-text", { staticClass: "hsh" }, [
                            _vm._v(" Alla med måndagar och torsdagar: "),
                            _c("b", [
                              _vm._v(
                                "\"('torsdag' in besöksdagar) and ('måndag' in besöksdagar)\""
                              )
                            ]),
                            _vm._v(" Alla med måndagar eller torsdagar: "),
                            _c("b", [
                              _vm._v(
                                "\"('torsdag' in besöksdagar) or ('måndag' in besöksdagar)\""
                              )
                            ])
                          ]),
                          _c("v-card-text", { staticClass: "hsh" }, [
                            _vm._v(
                              " Tider datum och/eller tid på dygnet i ett filter beskriver man dessa på följande sätt.. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(' Datum och tid: "'),
                            _c("b", [
                              _vm._v("mindatumtid > dtm('2022-12-01 12:00:00')")
                            ]),
                            _vm._v('"')
                          ]),
                          _c("p", [
                            _vm._v(' Bara tid:"'),
                            _c("b", [_vm._v("mintid > tm('12:00:00')")]),
                            _vm._v('" ')
                          ]),
                          _c("p", [
                            _vm._v(
                              " Bara datum: \"mittdatum > dt('2022-12-10')\""
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card-title",
                { staticClass: "my-1 py-1 black white--text" },
                [_vm._v(" Filterparametrar för information ")]
              ),
              _c("v-data-table", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.haveReceived,
                    expression: "haveReceived"
                  }
                ],
                staticClass: "elevation-1",
                attrs: {
                  items: _vm.filterAttributes,
                  headers: _vm.headers,
                  "item-key": "id",
                  "items-per-page": -1,
                  height: 600,
                  "footer-props": {
                    "items-per-page-text": "Rader per sida",
                    "items-per-page-all-text": "Alla"
                  }
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }