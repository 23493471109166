var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DialogCard",
        { attrs: { title: _vm.title, actions: _vm.actions } },
        [
          _c("upload-file", {
            ref: "filehandler",
            attrs: { metadata: _vm.compileMetadata() }
          }),
          _c("v-select", {
            attrs: { items: _vm.typeItems, label: "Typ av fil", required: "" },
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          }),
          _c("v-text-field", {
            attrs: { label: "Beskrivning av filen här..." },
            model: {
              value: _vm.description,
              callback: function($$v) {
                _vm.description = $$v
              },
              expression: "description"
            }
          }),
          _c("v-select", {
            attrs: { items: _vm.langItems, label: "Språk", required: "" },
            model: {
              value: _vm.lang,
              callback: function($$v) {
                _vm.lang = $$v
              },
              expression: "lang"
            }
          }),
          _c("v-select", {
            attrs: { items: _vm.topics, label: "Kategori", required: "" },
            model: {
              value: _vm.topic_id,
              callback: function($$v) {
                _vm.topic_id = $$v
              },
              expression: "topic_id"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }