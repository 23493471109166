<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="800" height="480">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Förhandsgranska (Demo)
        </v-btn>
      </template>
      <iframe width="800" height="480" :src="url_to_show" frameborder="1"></iframe>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["url"],
  data() {
    return {
      dialog: false,
      url_to_show:''
    };
  },
  created() {
    this.url_to_show = this.url
  },
  watch:{
    "dialog": function (oldVal,newVal) {
      console.log("Watch dialog" + oldVal + " " + newVal);
      if (newVal == true && oldVal == false) {
        this.url_to_show="about:blank"
      } else {
        this.url_to_show = this.url
      }
    }
  }
};
</script>
