import axios from "axios";
//import store from '../store'

const apiHshClient = axios.create({
  baseURL: "https://127.0.0.1//",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const localhostApi = {
  // =================================================
  // Topics
  // =================================================
  async getTopics() {
    const response = await apiHshClient.get("/gettoken");
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
}


export default localhostApi;
