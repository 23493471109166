<template>
  <v-container>
    <v-row>
      <v-col>
        <AccountApplicationsList @applicantslist-table-clicked="tableClicked" ref="applicants"></AccountApplicationsList>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="mx-4"
          color="success"
          :disabled="!activeButtons"
          @click.stop="acceptList"
        >
          Godkänn markerade användare ({{ count }} st)
        </v-btn>
        <v-btn
          class="mx-4"
          color="error"
          :disabled="!activeButtons"
          @click.stop="rejectList"
        >
          Avvisa och ta bort markerade användare ({{ count }} st)
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import hshApi from "../services/hshapi";
import AccountApplicationsList from "../components/AccountApplicationsList.vue";

export default {
  components: {
    AccountApplicationsList,
  },
  data() {
    return {
      activeButtons: false,
      count: 0,
    };
  },
  computed: {},

  watch: {},
  methods: {
    callServer(endpointFunc, okStatus) {
      const list = this.$refs.applicants.selectedApplicants();
      const prom = [];
      let counter = 0;
      let successCounter = 0;
      let errormessage = "";
      list.forEach((u) => {
        counter += 1;
        const p = endpointFunc(u)
          .then((res) => {
            if (res.status == okStatus) {
              console.log("Accept", u);
              successCounter += 1;
            }
          })
          .catch((err) => {
            console.error("Failed to grant access for user", u, err);
            errormessage += `\nFailed to grant access for user", ${u.email}, ${err.toString()}`;
          });
        prom.push(p);
      });
      Promise.all(prom)
        .then((v) => {
          if (counter == successCounter) {
            this.$dialog.notify.success("Begäran levererad... ", {
              position: "top-left",
              timeout: 5000,
            });
          } else {
            this.$dialog.notify.error(
              `Problem ${successCounter} av totalt ... ${counter} levererade (${errormessage}) `,
              {
                position: "top-left",
                timeout: 5000,
              }
            );
          }

          console.log("Promises gave", v);
          this.$refs.applicants.fetchAccountApplication();
        })
        .catch((e) => {
          this.$dialog.notify.error(
            `Problem ${successCounter} av totalt ... ${counter} levererade (${e})`,
            {
              position: "top-left",
              timeout: 5000,
            }
          );
        });
    },
    async acceptList() {
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill skapa markerade användare?`,
        title: `Bekräftelse av skapa användare`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      if (res === "ok") {
        this.callServer(hshApi.grantApplicant, 200);
        this.$store.state.applStore.hasUnsavedChanges = false;
        this.$root.$emit("time_to_CheckMessagesStatus");
      }
    },
    async rejectList() {
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill avvisa markerade användare?`,
        title: `Bekräftelse av avvisande av användare`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      if (res === "ok") {
        this.callServer(hshApi.rejectApplicant, 204);
        this.$store.state.applStore.hasUnsavedChanges = false;
        this.$root.$emit("time_to_CheckMessagesStatus");
      }
    },
    tableClicked() {
      const list = this.$refs.applicants.selectedApplicants()
      this.count = list.length;
      // const list = this.$refs.applicants.selectedApplicants()
      if (this.count == 0) {
        this.activeButtons = false;
      } else {
        this.activeButtons = true;
      }
      console.log(
        "Got applicationslist event in tableClicked",
        this.activeButtons,
        list,
        this
      );
    }
  },
  beforeMount() {},
  mounted() {
    this.$root.$off("applicantslist-table-clicked");
    this.$root.$on("applicantslist-table-clicked", (selected) => {
      const list = selected; // This doesn't work due to multiple creation of objects this.$refs.applicants.selectedApplicants()
      this.count = list.length;
      // const list = this.$refs.applicants.selectedApplicants()
      if (this.count == 0) {
        this.activeButtons = false;
      } else {
        this.activeButtons = true;
      }
      console.log(
        "Got applicationslist event",
        this.activeButtons,
        selected,
        list,
        this
      );
      // this.$store.state.applStore.hasUnsavedChanges = true;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.activeButtons == false) {
      this.$store.state.applStore.hasUnsavedChanges = false;
    }
    if (this.$store.state.applStore.hasUnsavedChanges) {
      const answer = window.confirm(
        "Du har osparade ändringar. Vill du verkligen byta sida?"
      );
      if (answer) {
        this.$store.state.applStore.hasUnsavedChanges = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style></style>
