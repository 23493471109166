var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 whitespace",
            attrs: {
              headers: _vm.headers,
              items: _vm.surveys,
              search: _vm.startSearch,
              dense: "",
              "items-per-page": 5,
              options: _vm.options,
              loading: _vm.loading,
              triggSurveyTable: _vm.triggSurveyTable,
              "loading-text": "Laddar...",
              "footer-props": {
                showFirstLastPage: true,
                "items-per-page-text": "Rader per sida",
                "items-per-page-all-text": "Alla"
              }
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              },
              "click:row": _vm.handleRowClick
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm.showNewButton
                        ? _c(
                            "div",
                            [
                              item.locked
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-sort-variant-lock")
                                  ])
                                : _vm._e(),
                              !item.locked
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" mdi-delete ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticStyle: { display: "none" },
                          attrs: { flat: "" }
                        },
                        [
                          _c("v-toolbar-title"),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "600px" },
                              model: {
                                value: _vm.dialogDelete,
                                callback: function($$v) {
                                  _vm.dialogDelete = $$v
                                },
                                expression: "dialogDelete"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [
                                      _vm._v(
                                        "Vill du verkligen ta bort denna undersökning?"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: ""
                                          },
                                          on: { click: _vm.closeDelete }
                                        },
                                        [_vm._v("Avbryt")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: ""
                                          },
                                          on: { click: _vm.deleteItemConfirm }
                                        },
                                        [_vm._v("OK")]
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "600px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mb-2",
                                                attrs: {
                                                  color: "primary",
                                                  dark: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" Ny undersökning ")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4281548365
                              ),
                              model: {
                                value: _vm.dialogCreateNew,
                                callback: function($$v) {
                                  _vm.dialogCreateNew = $$v
                                },
                                expression: "dialogCreateNew"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [_vm._v("Ny undersökning?")]
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-text-field", {
                                                ref: "dialogCreateNewName",
                                                attrs: {
                                                  label: "Namn",
                                                  required: ""
                                                },
                                                model: {
                                                  value: _vm.form.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.name"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.queryTopics,
                                                  label: "Kategori",
                                                  required: ""
                                                },
                                                model: {
                                                  value: _vm.form.queryTopic,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "queryTopic",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.queryTopic"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dialogCreateNew = false
                                            }
                                          }
                                        },
                                        [_vm._v("Avbryt")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: ""
                                          },
                                          on: { click: _vm.CreateNewConfirm }
                                        },
                                        [_vm._v("OK")]
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer.prepend",
                  fn: function() {
                    return [
                      _vm.showNewButton
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "primary", dark: "" },
                              on: { click: _vm.dialogCreateNewfunction }
                            },
                            [_vm._v(" Ny undersökning ")]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c("v-text-field", {
                        staticClass: "mb-5",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Sök undersökningsnamn",
                          "single-line": "",
                          "hide-details": ""
                        },
                        on: { keyup: _vm.clearOnEscape },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _c("v-spacer")
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2942730520
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }