<template>
  <div>
    <DialogCard :title="title" :actions="actions" min-width="100%">
      <v-subheader class="pl-0">Fraser</v-subheader>
      <v-list dense elevation>
        <v-list-item-group v-model="selectedPhrase2" color="primary">
          <v-list-item
            v-for="item in getMediaFiles"
            :key="item.id"
            class="smaller"
            @click="changePhrase(item)"
          >
            <v-list-item-icon class="smaller">
              <v-icon  @click="playSound(item.id)">
                mdi-play-box-outline
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content class="smaller">
              <v-list-item-title
                v-text="item.text"
                value="item.id"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </DialogCard>
  </div>
</template>

<script>
export default {
  name: "HshPhrasePicker",
  props: [ "title","i18nBase","$store","env"],
  // emits: ["update:modelValue"],
  data() {
    return {
      phrase: this.color,
      showPicker: false,
      selectedPhrase2: {},
      choosenPhrase:{},
      rerender:0
    };
  },
  mount() {
    console.log("HshPhrasePicker", this.color, this.privColor);
  },
  methods: {
    playSound(id) {
      console.log(`HshPhrasePicker:playSound Will play id ${id}`);
      this.env.stopAndClearAllPhrases();
      this.env.playPhrase(id);
    },
    changePhrase(item) {
      // const phrase = this.allMediaFiles.find((m) => m.id== this.selectedPhrase2)
      const phrases = this.$store.state.medialist.filter(
        (m) => m.type == 'audio'
      )
      if (phrases != undefined) {
        const phrase = phrases.find((m) => m.id == item.id);
        // const phrase = phrases[this.selectedPhrase2]
        // console.log("changePhrase",item,this.selectedPhrase2, this.i18nBase.i18n.sv.media.audio,phrase)
        console.log("changePhrase",item,this.selectedPhrase2, this.i18nBase,phrase)
        if (phrase != undefined) {
          this.i18nBase.i18n.sv.media.audio=phrase
          this.choosenPhrase = phrase
        }
      }
      this.rerender++
    },
    // No use of this function, all presented items have  by definition a phrase...
    // isAudioPresent(item) {
    //   console.log("isAudioPresent in getMediaFiles",item, this.i18nBase)
    //   try {
    //     if (this.i18nBase.i18n.sv.media.audio.id > 0) {
    //       return true;
    //     }
    //   } catch {
    //     return false;
    //   }
    //   return false;
    // },
  },
  watch: {
  },
  computed: {
     getMediaFiles() {
      const arr = this.$store.state.medialist.filter(
        (m) => m.type == 'audio'
      );
      // const arr2 = arr.map((e) => ({text:e.description + " (" + e.name + ")", id:e.id}));
      const arr2 = arr.map((e) => ({text:e.description+` (${e.name})`, id:e.id}));
      arr2.push({text:"-- Ingen fras --", id:0})
      arr2.sort((a, b) => (a.text > b.text) ? 1 : -1);
      console.log("getMediaFiles",arr2)
      this.rerender
      return arr2;
    },
     
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            return this.choosenPhrase;
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.v-list{
  border: 1px solid black !important;
  height:30em;/* or any height you want */
  width:100%;
  padding: 0  !important;
  margin: 0 !important;
  overflow-y:auto
}
.smaller {
  min-height: 2px !important;
  padding: 0  !important;
  margin: 0 !important;
  line-height: 2px;
}

</style>
