<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="thisSurveysForms"
      sort-by="name"
      class="elevation-1 whitespace"
      dense
      :items-per-page="10"
      :loading="loading"
      loading-text="Laddar..."
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Rader per sida',
        'items-per-page-options': [3, 5, 10, -1],
        'items-per-page-all-text': 'Alla',
      }"
      @click:row="handleRowClickFormlist"
    >
      <!-- @click:row="handleRowClick" -->
      <!-- items-per-page=5 -->
      <template v-slot:top>
        <v-toolbar flat style="display: none">
          <v-toolbar-title></v-toolbar-title>
          <!-- <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer> -->
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              formulär
            </v-btn>
          </template>
          <!-- <survey-form :form-title="formTitle" :editedItem="editedItem"></survey-form>> -->

          <v-dialog v-model="dialogDeleteFormFromSurvey" max-width="800px" persistent>
            <v-card>
              <v-card-title class="text-h5"
                >Vill du verkligen ta bort detta formulär från
                undersökningen?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deleteFormFromSurveyCanceled"
                  >Avbryt</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteFormFromSurveyConfirmed"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #body>
        <!-- <template #body="props">-->
        <draggable
          :list="thisSurveysForms"
          tag="tbody"
          group="items"
          :move="onMoveCallback"
          :clone="onCloneCallback"
          @end="onDropCallback"
          :ondrop="onDropCallback"
        >
          <tr v-for="(form, index) in thisSurveysForms" :key="index">
            <td>
              <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
              {{ form.name }}
            </td>
            <td>{{ form.questions.length }}</td>
            <td>{{ form.topic_name }}</td>
            <td><v-text-field v-if="index>0"
                  v-model="thisSurveysForms[index].days_offset "
                  outlined
                  dense
                  maxlength="3"
                  class="digit-input small-font pt-5"
                  @input="setTimeBetween(form)"
                ></v-text-field><div v-else>-</div></td>
                
          </tr>
        </draggable>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.locked" small>mdi-sort-variant-lock</v-icon>
        <!-- <v-icon v-if="!item.locked" small @click="editForm(item)">
          mdi-pencil
        </v-icon> -->
        <v-icon v-if="!item.locked" small @click="RemoveFormFromSurvey(item)">
          mdi-delete
        </v-icon>
      </template>
      <!-- <template v-slot:item.nrOfForms="{ item }">
        {{ item.questions.length }}
      </template> -->

      <template v-slot:footer.prepend>
        <!-- <v-btn color="primary" dark class="mb-2" v-on:click="dialogAddForms=true">
          Lägg till formulär
        </v-btn> -->
      </template>
    </v-data-table>
    <v-divider class="py-2"></v-divider>
    <v-dialog v-model="dialogAddForms" max-width="80%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          v-bind="attrs"
          v-on="on"
          @click="setSelect"
        >
          Välj ingående formulär
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Välj de formulär som skall med i undersöknignen
        </v-card-title>
        <v-divider class="my-6"></v-divider>
        <v-data-table
          v-model="selectedForms"
          :headers="headersForm"
          :items="formArray"
          :single-select="false"
          editable
          item-key="id"
          sort-by="name"
          class="elevation-1 whitespace"
          :search="searchInFormlist"
          show-select
          dense
          :items-per-page="10"
          :loading="false"
          loading-text="Laddar..."
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': 'Rader per sida',
            'items-per-page-options': [3, 5, 10, -1],
            'items-per-page-all-text': 'Alla',
          }"
        >
          <!-- :otions.sync="pagination"
          @click:row="handleRowClick" -->
          <!-- v-if="loadingStatus" -->
          <!-- items-per-page=5 -->

          <!-- <template v-slot:item.nrOfForms="{ item }">
                {{ item.questions.length }}
              </template> -->

          <template v-slot:top>
            <v-toolbar flat style="display: none">
              <v-toolbar-title></v-toolbar-title>
              <!-- <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer> -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  formulär
                </v-btn>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:footer.prepend>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchInFormlist"
              append-icon="mdi-magnify"
              label="Sök formulär"
              single-line
              hide-details
              class="mb-5"
              v-on:keyup="clearOnEscapeFormList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeChooseForm"> Avbryt </v-btn>
          <v-btn color="blue darken-1" text @click="closeAndSaveChooseForm"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "Forms",
  components: {
    draggable,
  },
  props: ["survey"],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogAddForms: false,
      dialogDeleteFormFromSurvey: false,
      loading: false,
      search: "",
      searchInFormlist: "",
      formArray: [],
      selectedForms: [],
      thisSurveysForms: [],
      headers: [
        {
          text: "Formulärnamn",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Antal frågor", value: "questions.length" },
        { text: "Kategori", value: "topic_name" },
        { text: "Antal dagars fördröjning", value: "days_offset", editable: true },
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        locked: false,
        nrOfForms: 0,
        order: 0,
        startDate: "",
      },
      defaultItem: {
        name: "",
        locked: false,
        nrOfForms: 0,
        order: 0,
        startDate: "",
      },
      headersForm: [
        {
          text: "Formulärnamn",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Låst  ", value: "locked" },
        { text: "Antal frågor", value: "questions.length" },
        { text: "Kategori", value: "topic_name" },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Ny undersökning"
        : this.surveys[this.editedIndex].name;
    },
  },

  beforeMount() {
    this.formArray = this.$store.getters.getForms;
    this.formArray.map((f) => {
      f.days_offset = f.days_offset || 0;
    });
    console.log("SurveysFormsTable #Before",this.formArray);
    this.$store.state.applStore.hasUnsavedChanges = false;
  },

  mounted() {
    this.makeThisSurveyForms();
  },
  watch:{
    thisSurveysForms: function() { 
      console.log("thisSurveysForms watch called")
    }
  },
  methods: {
    setTimeBetween(form) {
      console.log("thisSurveysForms setTimeBetween called",form)
      this.$store.state.applStore.hasUnsavedChanges=true
      this.$root.$emit("surveyformtable-close");
    },
    what(what, info) {
      console.log(`What?(${what})`, info);
      return "what?";
    },

    onCloneCallback(item) {
      // Create a fresh copy of item
      console.log("onCloneCallback", item);
      const cloneMe = JSON.parse(JSON.stringify(item));
      return cloneMe;
    },

    onMoveCallback(evt, originalEvent) {
      const item = evt.draggedContext.element;
      const itemIdx = evt.draggedContext.futureIndex;

      console.log("onMoveCallback", originalEvent, itemIdx);

      if (item.locked) {
        return false;
      }
      return true;
    },

    onDropCallback(evt, originalEvent) {
      console.log("onDropCallback", originalEvent);
      this.$store.state.applStore.hasUnsavedChanges = true;
    },

    setSelect() {
      this.selectedForms = this.thisSurveysForms;
      console.log("setSelect", this.selectedForms, this.thisSurveysForms);
    },

    makeThisSurveyForms() {
      console.log(
        "SurveyFormsTable: Fetch all forms " +
          this.$store.state.forms.length +
          " " +
          this.$store.state.applStore.hasUnsavedChanges
      );
      // ToDo: Här skall det konfirmeras om editering har skett i
      if (this.$store.state.applStore.hasUnsavedChanges == true) {
        const answer = window.confirm(
          "Du har osparade ändringar. Vill du verkligen byta undersökning?"
        );
        if (answer == false) {
          return;
        }
      }
      this.$store.state.applStore.hasUnsavedChanges = false;
      this.collectFormsForDetailView();
    },

    collectFormsForDetailView() {
      console.log("Collect forms for detail view");
      console.log("=============================");
      if (this.survey == undefined) {
        console.log("No survey selected");
        return;
      }

      console.log("Survey:", this.survey.id, " has forms:", this.survey.forms);
      console.log("Available forms:", this.formArray);
      this.survey.forms.forEach((form) => {
        console.log("   Matching form:", form.form_id, "with available forms",form);
        const formToAdd = this.formArray.find((f) => f.id === form.form_id);
        if (formToAdd != undefined) {
          console.log("   Got a match with:", formToAdd);
          formToAdd.days_offset = form.days_offset
          //   formToAdd.timeBetween = form.days_offset;
          this.thisSurveysForms.push(formToAdd);
         }
      });
      console.log("==(Done)=====================");
    },

    clearOnEscapeFormList(event) {
      if (event.key === "Escape") {
        this.searchInFormlist = "";
      }
    },

    handleRowClickFormlist(item) {
      console.log("SurveyFormTable: You clicked " + item.name);
      this.$store.dispatch("setCurrentFormId", item.id);
    },

    editItem(item) {
      this.editedIndex = this.forms.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log("Emit show");
    },

    editForm(item) {
      this.$store.dispatch("setCurrentFormId", item.id);
      return this.$router.push("/forms");
    },

    RemoveFormFromSurvey() {
      this.dialogDeleteFormFromSurvey = true;
    },

    deleteFormFromSurveyConfirmed() {
      const id = this.$store.getters.getCurrentFormId;
      console.log("deleteFormFromSurveyConfirmed", id);
      const index = this.thisSurveysForms.findIndex((s) => s.id === id);
      this.thisSurveysForms.splice(index, 1);
      this.dialogDeleteFormFromSurvey = false;
      this.$store.state.applStore.hasUnsavedChanges = true;
    },

    deleteFormFromSurveyCanceled() {
      this.dialogDeleteFormFromSurvey = false;
    },

    deleteSurveyItemConfirm() {
      this.survey.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeAndSaveChooseForm() {
      console.log("closeAndSaveChooseForm()");
      console.log("------------------------");
      this.thisSurveysForms = this.selectedForms;
      console.log("  Selected Survey Forms:", this.thisSurveysForms);
      this.closeChooseForm();
      console.log("  closeChooseForm");
      this.$store.state.applStore.hasUnsavedChanges = true;
      this.$root.$emit("surveyformtable-close");
      console.log("--(Done)----------------");
    },

    closeChooseForm() {
      this.dialogAddForms = false;
    },

    choosenForms() {
      return this.thisSurveysForms;
    },
  },
};
</script>
<style scoped>
.digit-input {
  width: 60px;
  text-align: center;
}
.small-font input {
  font-size: 1em;
}
</style>