<template>
  <div>
    <DialogCard :title="title" :actions="actions">
      <v-color-picker v-model="privColor" flat></v-color-picker>
    </DialogCard>
  </div>
</template>

<script>
export default {
  name: "HshColorPicker",
  props: ["color", "title"],
  // emits: ["update:modelValue"],
  data() {
    return {
      privColor: this.color,
      showPicker: false,
    };
  },
  mount() {
    console.log("HshColorPicker", this.color, this.privColor);
  },
  methods: {
    signalChange(color) {
      // this.$emit('update:modelValue', color)
      this.$emit("newColor", color);
    },
  },
  watch: {
    color: function () {
      console.log("HshColorPicker Watch color ", this.color);
      this.signalChange(this.color);
    },
  },
  computed: {
    actions() {
      return {
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
        ok: {
          text: "OK",
          color: "blue darken-1",
          handle: () => {
            return this.privColor;
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.boxtext {
  text-align: center;
}
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  border: 1px solid black;
  clear: both;
}
.imageUrl {
  max-width: 30px;
  max-height: 100%;
  justify-content: left;
  align-content: flex-start;
}
</style>
