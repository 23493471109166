<template>
  <div v-if="loadingStatus">
    <div v-if="isThereACurrentSurvey">
      <v-form v-model="dialog" max-width="90%">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4" sm="6" md="4">
                  <v-card-title v-if="surveyChoosed">
                    Starta undersökningen
                  </v-card-title>
                  <v-card-subtitle>
                    <b>{{ editedItem.name }}</b>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="4" sm="6" md="4">
                  <v-datetime-picker
                    label="Starttid"
                    v-model="startDate"
                    :disabled="editedItem.locked"
                    timeFormat="HH:mm"
                    :time-picker-props="timeProps"
                    clearText="Avbryt"
                    :textFieldProps="dateTimeTextFieldProps"
                    @input="dateTimeGiven"
                  >
                  </v-datetime-picker>
                  <v-checkbox
                    v-model="markIsRepeatSurvey"
                    color="gray"
                    value="true"
                    class="align-center justify-center"
                    @click="dateTimeCheck"
                  >
                    <template v-slot:label>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="align-center justify-center pt-3"
                            v-bind="attrs"
                            v-on="on"
                            >Skapa en repeterande undersökning</span
                          >
                        </template>
                        <span
                          >Om du vill att denna undersökning skall repeteras automatiskt
                        </span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                  <v-card v-if="markIsRepeatSurveyBool">
                    <v-card-title> Repeterande undersökning </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-tooltip left>
                            <template #activator="{ on }">
                              <v-text-field
                                v-on="on"
                                label="Antal ggr "
                                v-model="repeate"
                                outlined
                                dense
                                maxlength="4"
                                class="small-font"
                                type="number"
                                @input="checkRepeate"
                              ></v-text-field>
                            </template>
                            <span
                              >Antalet gånger som en undersökning skall genomföras</span
                            >
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6">
                          <v-tooltip right>
                            <template #activator="{ on }">
                              <div>
                                <v-select
                                  v-on="on"
                                  v-model="period"
                                  :items="periodItems"
                                  item-text="name"
                                  label="Period enhet"
                                  outlined
                                  dense
                                  :maxlength="4"
                                  class="small-font"
                                ></v-select>
                              </div>
                            </template>
                            <span
                              >Vilken period som undersökningen skall ha. per
                              dag/vecka/månad etc.</span
                            >
                          </v-tooltip>
                        </v-col></v-row
                      >
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            :label="frequencyText"
                            v-model="frequence"
                            outlined
                            dense
                            maxlength="4"
                            class="digit-input small-font"
                            type="number"
                            @input="checkFrequence"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Varaktighet timmar"
                            v-model="surveyTimespanHours"
                            outlined
                            dense
                            maxlength="4"
                            class="digit-input small-font"
                            type="number"
                            @input="checkTimespan"
                            @change="checkTimespan"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="4" sm="6" md="4">
                  <v-datetime-picker
                    label="Sluttid"
                    v-model="endDate"
                    :disabled="editedItem.locked || markIsRepeatSurveyBool"
                    timeFormat="HH:mm"
                    :time-picker-props="timeProps"
                    clearText="Avbryt"
                    :textFieldProps="dateTimeTextFieldProps"
                    @input="dateTimeGiven"
                  >
                  </v-datetime-picker>

                  <v-checkbox
                    v-model="markRespondentDone"
                    color="gray"
                    value="true"
                    class="align-center justify-center"
                  >
                    <template v-slot:label>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="align-center justify-center pt-3"
                            v-bind="attrs"
                            v-on="on"
                            >Lägg till som passivt formulär</span
                          >
                        </template>
                        <span
                          >Om undersökningen inte skall resultera i aktiviteter, utan
                          användas t.ex. för självinitierade synpunkter
                        </span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="6" sm="3" md="2"> </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12">
                  <div>
                    <v-divider class="mb-5"></v-divider>
                    <h3 class="mb-5">Antal respondenter {{ nrOfRespondentsSelected }}</h3>
                    <h5>
                      Valda Filter tillför {{ nrOfMatchingRespondents }} respondenter
                    </h5>
                    <v-select
                      v-model="filterToUse"
                      :items="allFilterNames"
                      chips
                      deletable-chips
                      clearable
                      label="Pivot"
                      multiple
                      outlined
                      prepend-icon="mdi-filter-variant"
                      solo
                      @change="filterChoosen"
                      :menu-props="{ y: 'below', offsetY: true }"
                    ></v-select>
                    <h6><i>Esc för att stänga...</i></h6>

                    <v-divider class="mb-5"></v-divider>
                  </div>
                  <div>
                    <v-card class="elevation-10 pa-4 mt-10">
                      <h3 class="mb-5">Respondenter</h3>
                      <div v-if="!useSelction">
                        <RespondersList
                          :survey="currentSurvey"
                          :key="rerenderTrigger"
                          ref="respondersList"
                          @responderClicked="responderClicked"
                        />
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Avbryt </v-btn>
            <!-- <v-btn color="blue darken-1" text @click="save"> Spara </v-btn> 
             
            -->
            <v-btn
              color="blue darken-1"
              text
              @click="startSurvey"
              :disabled="!isPossibleToStartSurvey"
            >
              Starta undersökning
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// import SelectionPicker from "./SelectionPicker.vue";
// import SurveyFormsTable from "./SurveyFormsTable.vue";
// import CreateSelection from "./CreateSelection.vue";
import RespondersList from "./RespondersList.vue";
import DatetimePicker from "vuetify-datetime-picker";
import hshApi from "../services/hshapi";
// import { integer } from 'vee-validate/dist/rules';

Vue.use(DatetimePicker);
// const apiUrl = "https://api.homesvithome.se/v1.0/forms";
// import axios from "axios";

export default {
  components: {
    // SelectionPicker,
    // SurveyFormsTable,
    // CreateSelection,
    RespondersList,
  },
  name: "SurveyInstanceForm",
  props: [],
  data() {
    return {
      dialog: true,
      useSelction: false,
      editedItem: {},
      rerenderTrigger: 0,
      startDate: "",
      endDate: "",
      isPossibleToStartSurvey: false,
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
        format: "24hr",
      },
      dateTimeTextFieldProps: {},
      markRespondentDone: false,
      markIsRepeatSurvey: false,
      // Filter associated variables
      allFilters: [],
      allFilterNames: [],
      filterToUse: [],
      nrOfMatchingRespondents: 0,
      repetition: 0,
      period: "DAY",
      periodItems: [
        { value: "DAY", name: "Dag" },
        { value: "WEEK", name: "Vecka" },
        { value: "MONTH", name: "Månad" },
        { value: "YEAR", name: "År" },
      ],
      repeate: 2,
      frequence: 1,
      selectedRespondentsArray: [],
      surveyTimespanHours: 4,
    };
  },
  computed: {
    markIsRepeatSurveyBool() {
      return Boolean(this.markIsRepeatSurvey)
    },
    nrOfRespondentsSelected() {
      try {
        // const respondersArray = this.$refs.respondersList.getSelectedArray();
        return this.selectedRespondentsArray.length;
      } catch {
        return 0;
      }
    },
    currentSurvey() {
      console.log(
        "SurveysInstanceForm: currentSurvey()",
        this.$store.getters.getCurrentSurvey
      );
      return this.$store.getters.getCurrentSurvey;
    },
    isThereACurrentSurvey() {
      if (this.$store.state.currentSurveyId > 0) {
        return true;
      }
      return false;
    },
    currentForm() {
      console.log("SurveysForm: currentForm()");
      return this.$store.getters.getCurrentForm;
    },
    loadingStatus() {
      console.log(
        `SurveysInstansForm: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
    frequencyText() {
      console.log("frequencyText", this.frequence, this.period);
      if (this.frequence <= 2) {
        let infoText;
        if (this.frequence == 1) {
          infoText = "en gång varje";
        } else {
          infoText = "varannan";
        }
        switch (this.period) {
          case "DAY":
            return `${infoText} dag`;
          case "WEEK":
            return `${infoText} vecka`;
          case "MONTH":
            return `${infoText} månad`;
          case "YEAR":
            if (this.frequence == 1) {
              return `${infoText} år`;
            } else {
              return `vartannat år`;
            }
        }
      } else {
        switch (this.period) {
          case "DAY":
            return `var ${this.frequence}:e dag`;
          case "WEEK":
            return `var ${this.frequence}:e vecka`;
          case "MONTH":
            return `var ${this.frequence}:e månad`;
          case "YEAR":
            return `var ${this.frequence}:e år`;
        }
      }
      return "Error";
    },
  },
  watch: {
    repeate: function () {
      console.log("SurveyInstanceForm repeater", this.repeate);
    },
    surveyTimespanHours: function () {
      console.log("SurveyInstanceForm surveyTimespanHours", this.surveyTimespanHours);
    },
  },
  methods: {
    responderClicked(selectedArray) {
      this.selectedRespondentsArray = selectedArray;
      console.log("responderClicked", selectedArray);
    },
    checkRepeate() {
      if (this.repeate <= 0) {
        this.repeate = 0;
      }
      console.log("SurveyInstanceForm checkRepeate", this.repeate);
      this.dateTimeCheck();
      return this.repeate;
    },
    checkFrequence() {
      if (this.frequence <= 0) {
        this.frequence = 0;
      }
      console.log("SurveyInstanceForm frequence", this.frequence);
      this.dateTimeCheck();
      return this.frequence;
    },
    checkTimespan() {
      if (this.surveyTimespanHours <= 0) {
        this.surveyTimespanHours = 0;
      }
      this.endDate = this.startDate.add(this.surveyTimespanHours, "hours");
      console.log(
        "SurveyInstanceForm surveyTimespanHours",
        this.endDate,
        this.surveyTimespanHours
      );
      this.dateTimeCheck();
      return this.frequence;
    },
    showSurvey(survey) {
      this.editedItem = survey;
    },
    surveyChoosed() {
      return this.editedItem.name != "";
    },
    openup() {
      this.editedItem.locked = false;
    },
    show() {
      this.dialog = true;
    },
    close() {
      console.log("Nu stänger vi SurveyInstans");
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(
          JSON.stringify(this.$store.getters.getCurrentSurvey)
        );
      });
    },
    async save() {
      const selectedUsers = this.$refs.respondersList.getSelectedArray();
      const selectedForms = this.$refs.formsList.choosenForms();
      this.editedItem.forms = []; // Start with empty
      selectedForms.forEach((f) => {
        this.editedItem.forms.push({ form_id: f.id, days_offset: f.days_offset });
      });
      this.editedItem.respondent_ids = selectedUsers;
      console.log("save-1", this.editedItem, selectedUsers, selectedForms);
      await this.$store.dispatch("saveSurvey", this.editedItem);
      // if (this.editedIndex > -1) {
      //   Object.assign(this.surveys[this.editedIndex], this.editedItem);
      // } else {
      //   this.surveys.push(this.editedItem);
      // }
      this.$store.state.applStore.hasUnsavedChanges = false;
      this.close();
      console.log("SurveyInstanceForm: Save will now emit event");
      this.$root.$emit("surveyinstance-table-survey-clicked", null);
    },
    async startSurvey() {
      let infotext;
      if (this.markIsRepeatSurvey) {
        infotext = `Är du säker på att du vill starta en repeterande (${this.repeate} ggr) undersökning?`;
      } else {
        infotext = `Är du säker på att du vill starta undersökningen?`;
      }
      let res = await this.$dialog.info({
        text: infotext,
        title: `Bekräftelse av start av undersökning`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      if (res === "ok") {
        const selectedResponders = this.$refs.respondersList.getSelectedArray();
        if (Boolean(this.markIsRepeatSurvey) === false) {
          // Normal case - NOT repeate
          let startSurveyObject = {
            starts_at: this.$moment(this.startDate).format(),
            ends_at: this.$moment(this.endDate).format(),
            mark_respondents_done: this.markRespondentDone == true,
            survey_id: this.$store.state.currentSurveyId,
            respondents: [],
          };
          selectedResponders.forEach((r) => {
            startSurveyObject.respondents.push(r.id);
          });
          console.log("Start Surve Instance", startSurveyObject);
          await this.$store.dispatch("startSurvey", startSurveyObject);
        } else {
          let endDate = this.$moment(this.endDate);
          console.log("Start Surve Instance type of vars", typeof endDate,typeof this.endDate)
          if (endDate.isValid() == false) {
            if (this.$moment().diff(this.$moment(this.startDate)) < 0) {
              console.log("Start Surve Instance use startDate")
              endDate = this.$moment(this.startDate).add(this.surveyTimespanHours, "hours").format();
            } else {
              console.log("Start Surve Instance use now")
              this.startDate = this.$moment().format("YYYY-MM-DD HH:MM")
              endDate = this.$moment().add(this.surveyTimespanHours, "hours").format();
            }
          }
          let startRepeateSurveyObject = {
            starts_at: this.$moment(this.startDate).format(),
            ends_at: endDate,
            mark_respondents_done: false,
            survey_id: this.$store.state.currentSurveyId,
            nbr_of_repetitions: parseInt(this.repeate),
            period: this.period,
            frequency: parseInt(this.frequence),
            respondents: [],
          };
          selectedResponders.forEach((r) => {
            startRepeateSurveyObject.respondents.push(r.id);
          });
          console.log("Start RepeateSurve Instance", startRepeateSurveyObject);
          await this.$store.dispatch("startRepeateSurvey", startRepeateSurveyObject);
        }
        this.$store.state.applStore.hasUnsavedChanges = false;
      }
    },
    async deleteSelection(index) {
      this.editedItem.selections.splice(index, 1);
      await this.$store.dispatch("updateSurvey", this.editedItem);
    },
    dateTimeGiven() {
      console.log("dateTimeGiven");
      this.dateTimeCheck();
    },
    dateTimeCheck() {
      Boolean(this.markIsRepeatSurvey);

      this.dateTimeTextFieldProps = { color: "red" };
      var ok = false;
      var beginningTime = this.$moment(this.startDate);
      var endTime = this.$moment(this.endDate);
      console.log("dateTimeGiven: endTime", endTime, this.endDate);
      if (beginningTime.isBefore(endTime) && this.$moment().diff(endTime) < 0) {
        ok = true;
      }
      if (ok === false) {
        if (
          Boolean(this.markIsRepeatSurvey) &&
          this.repeate > 0 &&
          this.surveyTimespanHours > 0 &&
          this.frequence > 0
        ) {
          ok = true;
        }
      }
      if (ok) {
        this.dateTimeTextFieldProps = { color: "green" };
        this.isPossibleToStartSurvey = true;
      } else {
        this.isPossibleToStartSurvey = false;
      }
      console.log(
        "dateTimeCheck:",
        Boolean(this.markIsRepeatSurvey),
        this.repeate,
        this.surveyTimespanHours,
        this.frequence
      );
      console.log(beginningTime.isBefore(endTime)); // true
      console.log(beginningTime.toDate()); // Mon May 12 2014 08:45:00
      console.log(endTime.toDate()); // Mon May 12 2014 09:00:00
      console.log(
        "dateTimeCheck: editedItem.locked=",
        this.editedItem.locked,
        this.editedItem
      );
      // if (start > stop )
    },
    async filterChoosen() {
      console.log("filterChoosen", this.filterToUse);
      const filternames = this.filterToUse.join("|");
      if (filternames.length > 0) {
        const res = await hshApi.countMatchFilters(filternames);
        console.log("filterChoosen res", res);
        this.nrOfMatchingRespondents = res;
        this.fetchRespondentsMatchFilter(filternames);
      } else {
        this.nrOfMatchingRespondents = 0;
        this.$refs.respondersList.TableSelectChange([]); // deselect all
      }
    },
    async fetchRespondentsMatchFilter(filternames) {
      try {
        this.nrOfHits = -1;
        let responders = [];
        const res = await hshApi.runFilter(filternames);
        console.log("fetchRespondentsMatchFilter: fetchAttributes", res);
        this.nrOfHits = res.length;
        const allrespondents = this.$store.getters.getReponders;
        responders = allrespondents.filter((r) => res.includes(r.id));
        this.$refs.respondersList.TableSelectChange(responders);
        console.log(
          "fetchRespondentsMatchFilter: fetchAttributes responders",
          responders,
          allrespondents,
          res
        );
      } catch (err) {
        console.error("ResponderForm: Failed to fetch result from runFilter", err);
      }
    },
  },
  async mounted() {
    // this.bus.$on('show',this.show) -- surveyinstance-table-survey-clicked

    this.$root.$off("surveysTable-surveyInstans-clicked");
    this.$root.$on("surveysTable-surveyInstans-clicked", async (item) => {
      if (item == null) {
        await this.$store.dispatch("setCurrentSurveyId", -1);
        return;
      }
      console.log("SurveyInstanceForm: Dialog received:" + item.name);
      // this.editedItem = item;
      this.editedItem = JSON.parse(JSON.stringify(this.$store.getters.getCurrentSurvey));
      this.editedItem = this.$store.getters.getCurrentSurvey;
      this.startDate = this.$moment().format("YYYY-MM-DD HH:MM");
      // Make components to rerender
      //vm.$forceUpdate();
      this.rerenderTrigger += 1;
      this.nrOfMatchingRespondents = 0;
      console.log(
        "SurveyInstanceForm: After fetchForm:" +
          item.name +
          " (" +
          this.editedItem.id +
          ") now have nrofforms=" +
          this.$store.getters.getForms.length,
        this.rerenderTrigger
      );
    });
    // If anyone else waits for this event
    this.$root.$off("responderslist-table-clicked");
    this.allFilters = await this.hshFetchFilter();
    this.allFilterNames = this.allFilters.map((m) => m.name);
  },
};
</script>
<style scoped>
.four-characters input {
  width: 4ch; /* Adjust the width as needed */
}
.limited-width .v-input__slot input {
  /* width: 4ch; Adjust the width as needed */
  /* max-width: 4ch; */
  width: 60px;
  max-width: 60px;
  text-align: center;
}
</style>
