var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.message !== null
      ? _c(
          "div",
          { key: _vm.trigger },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "ma-4",
                        attrs: { "max-width": "800px", flat: "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "speech-bubble pa-4",
                            style: _vm.cssVars(0)
                          },
                          [
                            _c("h2", [_vm._v(_vm._s(_vm.level(0)))]),
                            _c("div", { staticClass: "text" }, [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.message.text)
                                }
                              })
                            ])
                          ]
                        ),
                        _c("br")
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      { staticClass: "ma-4", attrs: { flat: "" } },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "my-1 py-1 black white--text" },
                          [_vm._v(" Info: ")]
                        ),
                        _c("table", { staticClass: "hsh" }, [
                          _c("tr", { staticClass: "ma-4 pa-4" }, [
                            _c("td", [_vm._v("Skapat:")]),
                            _c("td", [_vm._v(_vm._s(_vm.message.send_at_date))])
                          ]),
                          _c("tr", { staticClass: "ma-4 pa-4" }, [
                            _c("td", [_vm._v("Giltigt t.o.m:")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.message.valid_to_date))
                            ])
                          ]),
                          _vm.message.revoked_at_date != undefined
                            ? _c("tr", { staticClass: "ma-4 pa-4" }, [
                                _c("td", [_vm._v("Återkallat:")]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.message.revoked_at_date))
                                ])
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-card",
              { attrs: { flat: "" } },
              [
                _c(
                  "v-card-title",
                  { staticClass: "my-1 py-1 black white--text" },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                ),
                _c(
                  "v-card-text",
                  [
                    _c("v-data-table", {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.items,
                        "hide-default-header": false,
                        "hide-default-footer": true,
                        "disable-pagination": ""
                      },
                      on: { "click:row": _vm.ChangeStatus },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }