<template>
  <div>
    <!-- <h6>{{showMessageCnt}} "{{message}}"</h6> -->
    <div v-if="message !== null" :key="trigger">
      <v-row>
        <v-col class="text-center">
          <v-card class="ma-4" max-width="800px" flat>
            <div class="speech-bubble pa-4" :style="cssVars(0)">
              <h2>{{ level(0) }}</h2>
              <div class="text">
                <!-- <h3>{{messages.text}}</h3> -->
                <p v-html="message.text"></p>
              </div>
            </div>
            <br />
          </v-card>
        </v-col>
        <v-col>
          <v-card flat class="ma-4">
            <v-card-title class="my-1 py-1 black white--text"> Info: </v-card-title>
            <table class="hsh">
              <tr class="ma-4 pa-4">
                <td>Skapat:</td>
                <td>{{ message.send_at_date }}</td>
              </tr>
              <tr class="ma-4 pa-4">
                <td>Giltigt t.o.m:</td>
                <td>{{ message.valid_to_date }}</td>
              </tr>
              <tr v-if="message.revoked_at_date != undefined" class="ma-4 pa-4">
                <td>Återkallat:</td>
                <td>{{ message.revoked_at_date }}</td>
              </tr>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-card flat>
        <v-card-title class="my-1 py-1 black white--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :hide-default-header="false"
            :hide-default-footer="true"
            disable-pagination
            @click:row="ChangeStatus"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import hshApi from "../services/hshapi";
import $_ from "lodash";
import $moment from "moment";
import HshMessageHandler from "./HshMessageHandler.vue";

export default {
  components: {
    // HshMessageHandler
  },
  data() {
    return {
      message: null,
      origMessage: null,
      baseColor: "#ff101077",
      trigger: 0,
      title: "Mottagare",
      showMessageCnt: 0,
      importenceItems: [
        { value: 1, name: "Mycket viktigt meddelande", color: "#c93e34AA" },
        { value: 2, name: "Ganska Viktigt meddelande", color: "#c9bf34ff" },
        { value: 3, name: "Normalt meddelande", color: "#32a85277" },
        { value: 4, name: "Oviktigt meddelande", color: "#3490c977" },
      ],
      selected: [],
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Namn", value: "name", sortable: true },
        { text: "E-post", value: "email" },
        { text: "Terminal", value: "terminal_id" },
        { text: "Medd. visat", value: "presented_at" },
        { text: "Svar", value: "ack_answer" },
        // { text: "Hantera", value: "actions", sortable: false },
      ],
      items: [],
      isSetup: false,
    };
  },
  methods: {
    async ChangeStatus(item) {
      console.log("ChangeStatus", item);
      const res = await this.$dialog.show(HshMessageHandler, {
        waitForResult: true,
        title: "Hantera meddelande",
        $store: this.$store,
      }); // Show dialog
      if (res == true) {
        const allrespondents = this.$store.getters.getReponders;
        const respondent = allrespondents.find((r) => item.id == r.id);

        //this.$emit("", changeColor);
        // this.$store.state.applStore.hasUnsavedChanges=true
        console.log("HshMessageHandler OK", res, respondent, this.message);
        try {
          const apires = await hshApi.systemAckMessage(
            respondent.uuid,
            this.message.id,
            "system confirmed"
          );
          await this.$store.dispatch("fetchMessages", true);
          this.$root.$emit("showMessage-table-clicked", this.message);
          console.log("ChangeStatus succeed", apires);
        } catch (e) {
          console.log("ChangeStatus failed", e);
        }
      } else {
        console.log("HshMessageHandler Avbryt", res);
      }
    },
    level(index) {
      console.log("level", this.message, index);
      const levelInfo = this.importenceItems.find(
        (i) => i.value === this.message.importance
      );
      return (
        (this.message.messageConfirmed ? " Bekräftat meddelande - " : "") +
        (levelInfo == null ? "Meddelande" : levelInfo.name)
      );
    },
    cssVars(index) {
      let color = "white";
      try {
        console.log("cssVars", this.message, index);
        const levelInfo = this.importenceItems.find(
          (i) => i.value === this.message.importance
        );
        color = levelInfo != null ? levelInfo.color : this.baseColor;
        if (this.message.messageConfirmed) {
          color = this.baseColor;
        }
      } catch {
        color = "#FFF";
      }
      return { "--color": color };
    },
    async showMessage(message) {
      console.log("ShowMessage event called! ", this.showMessageCnt, message); //$store.state.mainVueInstance.$el.getAttribute('tabid') ) // this.$refs.tabid) // .$el.getAttribute('key')
      this.message = message;
      this.trigger++;
      try {
        const origMessage = await hshApi.getMessage(message.id);
        const allrespondents = this.$store.getters.getReponders;
        const respondents = allrespondents.reduce(function (filtered, respondent) {
          // const found = origMessage.receivers.some(
          //   (el) => el.respondent_id === respondent.id
          // );
          const foundItem = origMessage.receivers.find(
            (el) => el.respondent_id === respondent.id
          );
          if (foundItem != undefined) {
            console.log("showMessage-table-clicked foundItem", foundItem);
            const someNewValue = {
              id: respondent.id,
              name: respondent.first_name + " " + respondent.last_name,
              email: respondent.email !== undefined ? respondent.email : "",
              terminal_id: respondent.terminal_id !== undefined ? "Ja" : "-",
              presented_at:
                foundItem.presented_at != undefined
                  ? $moment(foundItem.presented_at).format("YY-MM-DD HH:mm")
                  : "-",
              ack_answer: $_.get(foundItem, "ack_msg", "-"),
            };
            // console.log("someNewValue",someNewValue,foundItem,$_.get(foundItem,"ack_msg",'-'))
            filtered.push(someNewValue);
          }
          return filtered;
        }, []);
        this.items = respondents;
      } catch (err) {
        console.error("showMessage-table-clicked", err);
      }
      console.log("Got showMessage-table-clicked event", message, this.origMessage);
    },
  },
  computed: {
    loadingStatus() {
      console.log(
        `ShowMessage: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  async mounted() {
    this.showMessageCnt = await this.$store.getters.getShowMessageLoaded;
    console.log("ShowMessage mount called!", this.showMessageCnt, this.$root); //$store.state.mainVueInstance.$el.getAttribute('tabid') ) // this.$refs.tabid) // .$el.getAttribute('key')
    if (this.showMessageCnt < 500) {
      console.log("ShowMessage eventhandler setup");
      this.isSetup = true;
      //this.$root.$off("showMessage-table-clicked");
      // this.$root.$on("showMessage-table-clicked", async (message) => {
      //   console.log("ShowMessage event called! ",this.showMessageCnt,message); //$store.state.mainVueInstance.$el.getAttribute('tabid') ) // this.$refs.tabid) // .$el.getAttribute('key')
      //   this.message = message;
      //   this.trigger++;
      //   try {
      //     const origMessage = await hshApi.getMessage(message.id);
      //     const allrespondents = this.$store.getters.getReponders;
      //     const respondents = allrespondents.reduce(function (filtered, respondent) {
      //       // const found = origMessage.receivers.some(
      //       //   (el) => el.respondent_id === respondent.id
      //       // );
      //       const foundItem = origMessage.receivers.find(
      //         (el) => el.respondent_id === respondent.id
      //       );
      //       if (foundItem != undefined) {
      //         console.log("showMessage-table-clicked foundItem", foundItem);
      //         const someNewValue = {
      //           id: respondent.id,
      //           name: respondent.first_name + " " + respondent.last_name,
      //           email: respondent.email !== undefined ? respondent.email : "",
      //           terminal_id: respondent.terminal_id !== undefined ? "Ja" : "-",
      //           presented_at:
      //             foundItem.presented_at != undefined
      //               ? $moment(foundItem.presented_at).format("YY-MM-DD HH:mm")
      //               : "-",
      //           ack_answer: $_.get(foundItem, "ack_msg", "-"),
      //         };
      //         // console.log("someNewValue",someNewValue,foundItem,$_.get(foundItem,"ack_msg",'-'))
      //         filtered.push(someNewValue);
      //       }
      //       return filtered;
      //     }, []);
      //     this.items = respondents;
      //   } catch (err) {
      //     console.error("showMessage-table-clicked", err);
      //   }
      //   console.log("Got showMessage-table-clicked event", message, this.origMessage);
      // });
      this.$store.dispatch("setShowMessageLoaded", 1);
    }
    // this.messages = this.$store.getters.getMessages;
    console.log("ShowMessages mounted", this.messages);
  },
  beforeDestroy() {
    console.log("ShowMessage destroy will be called!");
    if (this.isSetup == true) {
      this.$store.dispatch("setShowMessageLoaded", -1);
      this.isSetup = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.box .text {
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40%; /* Adjust this value to move the positioned div up and down */
  text-align: center;
  width: 60%; /* Set the width of the positioned div */
}
.image {
  position: relative;
  width: 100%; /* for IE 6 */
}
h3 {
  font-size: 30px;
}
.speech-bubble {
  position: relative;
  background: var(--color); /* #0052bd;*/
  border-radius: 0.4em;
  text-align: left;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-right-color: var(--color); /* #0052bd; */
  border-left: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-left: -27px;
}

/* @group Blink */
.blink {
  -webkit-animation: blink 1.75s linear infinite;
  -moz-animation: blink 1.75s linear infinite;
  -ms-animation: blink 1.75s linear infinite;
  -o-animation: blink 1.75s linear infinite;
  animation: blink 1.75s linear infinite;
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
.hsh {
  padding-left: 4 px;
  margin: 4 px;
  // border: 1px solid;
}
.hsh.td {
  padding-left: 4px !important;
  margin-left: 4px;
}
/* @end */
</style>
