<template>
<div>
  <DialogCard title="" :actions="actions">
    <div v-if="ready">
      <v-card flat>
        <v-container>
          <v-row >
            <v-col cols=2 class="d-flex">
              <v-spacer></v-spacer>
              <v-card-title class="justify-right">
              Starttid:
              </v-card-title>
            </v-col>
            <v-col cols=2>
              <v-datetime-picker  
                :label="'Ändra starttid från '+starts_at"
                  v-model="startDate" 
                  timeFormat="HH:mm"
                  :time-picker-props="timeProps"
                  clearText="Avbryt"
                > 
              </v-datetime-picker>
            </v-col>
            <v-col cols=1></v-col>
             <v-col cols=2 class="d-flex">
              <v-spacer></v-spacer>
              <v-card-title>
              Sluttid:
              </v-card-title>
            </v-col>
            <v-col cols=2>
              <v-datetime-picker  
                :label="'Ändra sluttid från '+ends_at"
                  v-model="endDate" 
                  timeFormat="HH:mm"
                  :time-picker-props="timeProps"
                  clearText="Avbryt"
                > 
               </v-datetime-picker>
            </v-col>
            <v-col cols=1></v-col>
            <v-col cols=2>
              <v-btn :disabled="startDate == starts_at && endDate == ends_at" color="success" @click="UpdateTimeSettings">
                Uppdatera tiderna
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-title class="my-1 py-1 black white--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :rerender="rerender"
            :hide-default-header="false"
            :hide-default-footer="true"
            disable-pagination
          >
            <!-- @click:row="ChangeStatus" -->
            <template v-slot:item.actions="{ item }">
              <!-- <v-icon v-if="!item.locked" small @click="editFormItem(item)">
            mdi-pencil
          </v-icon> -->
              <v-icon v-if="!item.locked" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </DialogCard>
 
  </div>
</template>

<script>
import Vue from 'vue'
import hshApi from "../services/hshapi";
import DatetimePicker from 'vuetify-datetime-picker';
// import $_ from "lodash";
// import moment from "moment";
Vue.use(DatetimePicker)

export default {
  components: {
  },
  props: ["survey", "$store"],
  data() {
    return {
      message: true,
      origMessage: null,
      baseColor: "#ff101077",
      repeater: null,
      title: "Mottagare",
      rerender: 0,
      editedItem: {},
      selected: [],
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Namn", value: "name", sortable: true },
        { text: "E-post", value: "email" },
        { text: "Terminal", value: "terminal_id" },
        { text: "Medd. visat", value: "presented_at" },
        { text: "Svar", value: "ack_answer" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      items: [],
      key: 0,
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
        format: "24hr",
      },
      dateTimeTextFieldProps:{},
      startDate:"",
      starts_at:"",
      endDate:"",
      ends_at:"",
      ready:false
    };
  },
  methods: {
    async UpdateTimeSettings() {
      console.log("UpdateTimeSettings", this.endDate,':',this.startDate,':',this.survey.ends_at,':',this.survey.starts_at)
      
      const res = await hshApi.ChangeSurveyInstanceTimes(this.survey.id,this.$moment(this.startDate).format(),this.$moment(this.endDate).format())
      if (res.status === 200) {
        console.log("SurveyInstans updated with new start/stop dates")
      }
      
    },
    ChangeStatus() {
      console.log("ChangeStatus");
    },
    closeDialog() {
      console.log("CloseDialog set show");
      this.show = false;
    },
    async deleteItem(item) {
      console.log("deleteItem", item);
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill ta bort respondenten från undersökningen?`,
        title: `Ta bort svar`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      if (res === "ok") {
        const index = this.items.findIndex((i) => i.id === item.id);
        if (index >= 0) {
          this.items.splice(index, 1);
          console.log("deleteAnswer choosen", this.survey.id, item.id);
          const surveyInstanceRespondent =
            await hshApi.delSurveInstanceResponders(this.survey.id, item.id); // DELETE /surveys/instances/{id}/{rid}
          console.log(
            "SurveyInstance respondent deleted",
            surveyInstanceRespondent
          );
        }
        this.rerender++;
      }
    },
  },
  computed: {
    actions() {
      return {
        // cancel: {
        //   text: "Avbryt",
        //   color: "blue darken-1",
        //   handle: () => {
        //     return undefined;
        //   },
        // },
        ok: {
          text: "Stäng",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
      };
    },
  },
  async mounted() {
    this.starts_at = this.$moment(this.survey.starts_at,"YY-MM-DD HH.mm").format("YYYY-MM-DD HH:mm");
    this.startDate = this.starts_at 
    this.ends_at   = this.$moment(this.survey.ends_at,"YY-MM-DD HH.mm").format("YYYY-MM-DD HH:mm");
    this.endDate   = this.ends_at 
    const currSurveys = this.$store.getters.getSurveysInstances;
    const currSurvey = currSurveys.find((s) => s.id === this.survey.id);
    console.log(
      "RespondersInSurveyInstance",
      currSurvey,
      currSurveys,
      this.survey,
      this.startDate,
      this.endDate
    );
    const allrespondents = this.$store.getters.getReponders;
    const surveyInstanceRespondents = await hshApi.getSurveInstanceResponders(
      this.survey.id
    );
    console.log("sir", surveyInstanceRespondents);

    let resp = surveyInstanceRespondents.map((r) => ({
      respondent: allrespondents.find((rs) => rs.id === r.respondent_id),
      presented_at: this.$_.get(r, "forms[0].questions[0].presented_at", ""),
      voted_at: this.$_.get(r, "forms[0].questions[0].voted_at", ""),
    }));
    resp = resp.filter(e => e.respondent !== undefined)
    console.log("sir2", resp);
    this.items = resp.map((r) => ({
      id: r.respondent.id,
      name: r.respondent.first_name + " " + r.respondent.last_name,
      email: this.$_.get(r.respondent, "email", ""),
      terminal_id: this.$_.get(r.respondent, "terminal_id", ""),
      presented_at: this.$moment(r.presented_at).format("YYYY-MM-DD HH:mm"),
      ack_answer: this.$moment(r.voted_at).format("YYYY-MM-DD HH:mm"),
    }));
    console.log("mounted RespondersInSurveyInstance", this.items);
    this.ready=true
   
  },
};
</script>

<style lang="scss" scoped>
.dialogStyle {
  text-align: center;
}
headStyle {
  text-align: left;
  display: block;
}
</style>
