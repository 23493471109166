<template>
  <div>
    <div v-if="loadingStatus == false">
      <v-card color="primary" dark>
        <v-card-text>
          Var god dröj...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <template>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="responderTable"
          :single-select="singleSelect"
          item-key="id"
          :search="search"
          show-select
          @input="TableSelectChange"
          class="elevation-1"
          :footer-props="{
            'items-per-page-text': 'Rader per sida',
            'items-per-page-all-text': 'Alla',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Sök"
              single-line
              hide-details
              class="mb-5 mx-6"
              v-on:keyup="clearOnEscape"
            ></v-text-field>
          </template>
          <!-- @item-selected="TableSelectChange"
        @toggle-select-all="TableSelectChange" -->
          <template v-slot:item.terminal_id="{ item }">
            <!-- <v-icon small @click.stop="copyJwt(item)"> mdi-content-copy </v-icon> -->
            <div v-if="haveTerminal(item)">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="pl-3"
                  >
                    mdi-desktop-classic
                  </v-icon>
                </template>
                <span>Har terminal hemma</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:footer.prepend>
            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Spara som urvalsgrupp
        </v-btn> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Sök"
              single-line
              hide-details
              class="mb-5"
              v-on:keyup="clearOnEscape"
            ></v-text-field>
            <v-spacer></v-spacer>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "RespondersList",
  components: {},
  props: ["survey", "responders"],
  data() {
    return {
      dialog: false,
      selected: [],
      responderTable: [],
      singleSelect: false,
      hide: false,
      search: "",
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Namn", value: "name", sortable: true },
        { text: "Födelseår", value: "born" },
        { text: "Kommentar", value: "comment" },
        { text: "E-post", value: "email" },
        { text: "Terminal", value: "terminal_id" },
        // { text: "Hantera", value: "actions", sortable: false },
      ],
    };
  },
  beforeMount() {
    // this.$store.dispatch("fetchResponders");
    // this.$root.$off("Responderstable-form-toggle-hide")
    // this.$root.$on("Responderstable-form-toggle-hide", () => {
    //   console.log("Got hide toggle event");
    //   this.hide = !this.hide;
    // });
    this.$log.info("ResponderList beforeMount", this.survey);
    if (this.survey.respondent_ids == undefined) {
      this.$log.info("ResponderList beforeMount create empty respondent array");
      this.survey.respondent_ids = [];
    }
  },
  mounted() {
    //   this.setSelect();
    this.responderTable = this.createRespondersTable();
    this.setSelect();
  },
  computed: {
    loadingStatus() {
      // console.log(
      //   `CreateMessage: loadingStatus(${this.$store.getters.getLoadingStatus}) ${this.$store.state.forms.length}`
      // );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  methods: {
    haveTerminal(item) {
      if (item.terminal_id != "") {
        return true;
      }
      return false;
    },
    setSelect() {
      const responders = this.responderTable;
      // console.log("setSelected=", responders.length,responders,this.survey,this.selected);
      for (let i = 0; i < responders.length; i++) {
        const id = responders[i].id;
        if (this.survey.respondent_ids.find((s) => s == id) == id) {
          this.selected.push(this.responderTable[i]);
          console.log(
            "setSelect is selected",
            i,
            this.responderTable[i],
            this.selected
          );
        }
      }
    },
    createRespondersTable() {
      let respondersArray;
      if (this.responders !== undefined) {
        respondersArray = this.responders;
      } else {
        respondersArray = this.$store.state.responders;
      }
      const responders = respondersArray.map((r) => ({
        id: r.id,
        name: r.first_name + " " + r.last_name,
        born: r.year_of_birth,
        email: "email" in r ? r.email : "",
        terminal_id: "terminal_id" in r ? r.terminal_id : "",
        // info: JSON.stringify(r.caregiver_info),
        // info: this.buildStringFromJson(r.caregiver_info.category),
        comment: "comment" in r ? r.comment : "",
        notify_by_terminal: r.notify_by_terminal,
        notify_by_email: r.notify_by_email,
      }));
      console.log("Responders=", responders, this.survey);
      return responders;
    },
    buildStringFromJson(o) {
      let res = "";
      let first = true;
      o.forEach((item) => {
        const category = Object.keys(item)[0];
        const value = this.$_.join(item[category], ",");
        res += first ? "" : ", ";
        first = false;
        res += `${category} (${value})`;
      });
      // console.log("buildStringFromJson", o, res);
      return res;
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
      console.log("Who is selected?", this.selected);
    },
    // TableSelectChange(item) {
    // if (item.value == true) {
    //   this.selected.indexOf(item.item.id) === -1 ? this.selected.push(item.item.id): () => {};//NOP
    // } else {
    //   this.selected.splice(this.selected.indexOf(item.item.id), 1);  //deleting
    // }
    // console.log("TableSelectChange ",item.value,item.item.id,this.selected,this.responderTable)
    //
    // },
    TableSelectChange(selectedItems) {
      this.selected = selectedItems;
      console.log("TableSelectChange ", this.selected);
      this.$store.state.applStore.hasUnsavedChanges = true;
      this.$root.$emit("responderslist-table-clicked");
      this.$emit("responderClicked", this.selected);
      console.log("Emitted responderslist-table-clicked", this.$root);
    },
    getSelectedArray() {
      return this.selected;
    },
  },
};
</script>

<style scoped></style>
