var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 whitespace",
        attrs: {
          headers: _vm.headers,
          items: _vm.thisSurveysForms,
          "sort-by": "name",
          dense: "",
          "items-per-page": 10,
          loading: _vm.loading,
          "loading-text": "Laddar...",
          "footer-props": {
            showFirstLastPage: true,
            "items-per-page-text": "Rader per sida",
            "items-per-page-options": [3, 5, 10, -1],
            "items-per-page-all-text": "Alla"
          }
        },
        on: { "click:row": _vm.handleRowClickFormlist },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  {
                    staticStyle: { display: "none" },
                    attrs: { flat: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mb-2",
                                    attrs: { color: "primary", dark: "" }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" formulär ")]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("v-toolbar-title"),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "800px", persistent: "" },
                        model: {
                          value: _vm.dialogDeleteFormFromSurvey,
                          callback: function($$v) {
                            _vm.dialogDeleteFormFromSurvey = $$v
                          },
                          expression: "dialogDeleteFormFromSurvey"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "text-h5" }, [
                              _vm._v(
                                "Vill du verkligen ta bort detta formulär från undersökningen?"
                              )
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: {
                                      click: _vm.deleteFormFromSurveyCanceled
                                    }
                                  },
                                  [_vm._v("Avbryt")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: {
                                      click: _vm.deleteFormFromSurveyConfirmed
                                    }
                                  },
                                  [_vm._v("OK")]
                                ),
                                _c("v-spacer")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "draggable",
                  {
                    attrs: {
                      list: _vm.thisSurveysForms,
                      tag: "tbody",
                      group: "items",
                      move: _vm.onMoveCallback,
                      clone: _vm.onCloneCallback,
                      ondrop: _vm.onDropCallback
                    },
                    on: { end: _vm.onDropCallback }
                  },
                  _vm._l(_vm.thisSurveysForms, function(form, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "td",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "page__grab-icon",
                              attrs: { small: "" }
                            },
                            [_vm._v(" mdi-arrow-all ")]
                          ),
                          _vm._v(" " + _vm._s(form.name) + " ")
                        ],
                        1
                      ),
                      _c("td", [_vm._v(_vm._s(form.questions.length))]),
                      _c("td", [_vm._v(_vm._s(form.topic_name))]),
                      _c(
                        "td",
                        [
                          index > 0
                            ? _c("v-text-field", {
                                staticClass: "digit-input small-font pt-5",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  maxlength: "3"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.setTimeBetween(form)
                                  }
                                },
                                model: {
                                  value:
                                    _vm.thisSurveysForms[index].days_offset,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.thisSurveysForms[index],
                                      "days_offset",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "thisSurveysForms[index].days_offset "
                                }
                              })
                            : _c("div", [_vm._v("-")])
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                item.locked
                  ? _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-sort-variant-lock")
                    ])
                  : _vm._e(),
                !item.locked
                  ? _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.RemoveFormFromSurvey(item)
                          }
                        }
                      },
                      [_vm._v(" mdi-delete ")]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "footer.prepend",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      }),
      _c("v-divider", { staticClass: "py-2" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "80%", persistent: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mb-2 ml-2",
                          attrs: { color: "primary", dark: "" },
                          on: { click: _vm.setSelect }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Välj ingående formulär ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialogAddForms,
            callback: function($$v) {
              _vm.dialogAddForms = $$v
            },
            expression: "dialogAddForms"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Välj de formulär som skall med i undersöknignen ")
              ]),
              _c("v-divider", { staticClass: "my-6" }),
              _c("v-data-table", {
                staticClass: "elevation-1 whitespace",
                attrs: {
                  headers: _vm.headersForm,
                  items: _vm.formArray,
                  "single-select": false,
                  editable: "",
                  "item-key": "id",
                  "sort-by": "name",
                  search: _vm.searchInFormlist,
                  "show-select": "",
                  dense: "",
                  "items-per-page": 10,
                  loading: false,
                  "loading-text": "Laddar...",
                  "footer-props": {
                    showFirstLastPage: true,
                    "items-per-page-text": "Rader per sida",
                    "items-per-page-options": [3, 5, 10, -1],
                    "items-per-page-all-text": "Alla"
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-toolbar",
                          {
                            staticStyle: { display: "none" },
                            attrs: { flat: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mb-2",
                                            attrs: {
                                              color: "primary",
                                              dark: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" formulär ")]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("v-toolbar-title")],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "footer.prepend",
                    fn: function() {
                      return [
                        _c("v-spacer"),
                        _c("v-text-field", {
                          staticClass: "mb-5",
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Sök formulär",
                            "single-line": "",
                            "hide-details": ""
                          },
                          on: { keyup: _vm.clearOnEscapeFormList },
                          model: {
                            value: _vm.searchInFormlist,
                            callback: function($$v) {
                              _vm.searchInFormlist = $$v
                            },
                            expression: "searchInFormlist"
                          }
                        }),
                        _c("v-spacer"),
                        _c("v-card-actions", [_c("v-spacer")], 1)
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.selectedForms,
                  callback: function($$v) {
                    _vm.selectedForms = $$v
                  },
                  expression: "selectedForms"
                }
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeChooseForm }
                    },
                    [_vm._v(" Avbryt ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.closeAndSaveChooseForm }
                    },
                    [_vm._v(" OK ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }