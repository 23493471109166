var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { fluid: "", justify: "center", align: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "auto", persistent: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Skapa och starta undersökning (genväg) ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("v-card", { staticClass: "dialog-card", attrs: { fluid: "" } }, [
            _vm.loadingStatus
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "text-h5" }, [
                          _vm._v("Ny undersökning?")
                        ]),
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Namn", required: "" },
                                      model: {
                                        value: _vm.surveyname,
                                        callback: function($$v) {
                                          _vm.surveyname = $$v
                                        },
                                        expression: "surveyname"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "blue darken-1", text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.dialog = false
                                  }
                                }
                              },
                              [_vm._v("Avbryt")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "blue darken-1", text: "" },
                                on: { click: _vm.CreateNewConfirm }
                              },
                              [_vm._v("OK")]
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { attrs: { color: "primary", dark: "" } },
                      [
                        _c(
                          "v-card-text",
                          [
                            _vm._v(" Var god dröj... "),
                            _c("v-progress-linear", {
                              staticClass: "mb-0",
                              attrs: { indeterminate: "", color: "white" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }