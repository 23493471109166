<template>
  <v-select v-model="inputVal" :items="imageArr" :label="label">
    <template v-slot:selection="{ item }">
      <img :src="item.url" class="imageUrl pt-2 mr-4" />{{ item.id }}
    </template>
    <template v-slot:item="{ item }">
      <img :src="item.url" class="imageUrl pt-2 mr-4" />{{ item.id }}
    </template>
  </v-select>
</template>

<script>
/*
 --original in EditQuestion.vue--
 <!-- <v-select v-model="questIcon" :items="getImageFiles"  label="Frågeikon">
    <template v-slot:selection="{ item }">
        <img :src="item.url" class="imageUrl pt-2 mr-4">{{ item.id }}
    </template>
    <template v-slot:item="{ item }">
        <img :src="item.url" class="imageUrl pt-2 mr-4">{{ item.id }}
    </template>
    </v-select> -->


*/
export default {
name: "HshColor",
  props: ["label","value"],
  data() {
    return {
        icon:{},
        imageArr:[],
    };
  },
  computed: {
    inputVal: {
      get() {
        console.log("HshImage get", this.value)
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },

  mounted() {
    // this.icon=this.currentIcon
    this.imageArr = [...this.$store.state.imgmedia_url]
    this.imageArr.sort((o1,o2) => o1.id - o2.id);
    this.imageArr.unshift({url:null,id:-1})
  },
  methods: {
    getImageFiles() {
      const arr = this.$store.state.imgmedia_url
      return arr.sort((o1,o2) => o1.id - o2.id);
    },
    updateValue: function (value) {
        console.log("updatevalue...",value,this.value)
        this.$emit('change', value)
    }
  }       
}
</script>

<style scoped>
.imageUrl {
  max-width: 30px;
  max-height: 100%;
  justify-content: left;
  align-content: flex-start;
}
</style>