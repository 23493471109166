import { render, staticRenderFns } from "./MessageArea.vue?vue&type=template&id=49ed722c&"
import script from "./MessageArea.vue?vue&type=script&lang=js&"
export * from "./MessageArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VBadge,VCard,VContainer,VIcon,VLayout,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Projects\\HomeSvITHome\\hsh-admin\\hsh-admin-app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49ed722c')) {
      api.createRecord('49ed722c', component.options)
    } else {
      api.reload('49ed722c', component.options)
    }
    module.hot.accept("./MessageArea.vue?vue&type=template&id=49ed722c&", function () {
      api.rerender('49ed722c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/MessageArea.vue"
export default component.exports