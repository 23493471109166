<template>
  <v-container>
    <v-row>
      <v-col v-if="ready">
        <MessageTable @messageChoosed="showMessage"/>
      </v-col>
    </v-row>
    <div>
      <ShowMessage ref="messageShow" />
    </div>
  </v-container>
</template>

<script>
import MessageTable from "../components/MessageTable.vue";
import ShowMessage from "../components/ShowMessage.vue";
export default {
  components: {
    MessageTable,
    ShowMessage,
  },
  data() {
    return {
      ready: false,
    };
  },
  async beforeMount() {
    await this.$store.dispatch("fetchMessages",true);
    this.ready = true;
    console.log("Messages:beforeCreated Have fetched messages");
  },
  methods: {
    showMessage(message) {
      console.log("messageChoosed event",message)
      this.$refs.messageShow.showMessage(message);
    }
  },
};
</script>

<style lang="scss" scoped></style>
