<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="surveysInstances"
      sort-by="starts_at"
      :sort-desc="true"
      class="elevation-1 whitespace"
      :search="search"
      dense
      :items-per-page="5"
      :loading="loading"
      :triggSurveysInstanceTable="triggSurveysInstanceTable"
      loading-text="Laddar..."
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Rader per sida',
        'items-per-page-all-text': 'Alla',
      }"
      @click:row="handleRowClick"
    >
      <!-- @click:row="handleRowClick" -->
      <!-- items-per-page=5 -->

      <template v-slot:item.actions="{ item }">
        <div v-if="showNewButton">
          <v-icon v-if="item.locked" small>mdi-sort-variant-lock</v-icon>
          <v-icon v-if="!item.locked" small @click.stop="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="!item.locked" small @click.stop="addItem(item)">
            mdi-account-multiple-plus
          </v-icon>
          <v-icon v-if="!item.locked" small @click.stop="deleteItem(item)">
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:top>
        <v-toolbar flat style="display: none">
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer> -->
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >Vill du verkligen ta bort denna undersökning?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Avbryt</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:footer.prepend>
        <!-- <v-btn v-if="showNewButton" color="primary" dark class="mb-2" v-on:click="dialogCreateNew=true">
          Ny undersökning
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
          class="mb-5"
          v-on:keyup="clearOnEscape"
        ></v-text-field>
        <v-spacer></v-spacer>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import Vue from "vue"
// import SurveyForm from "./SurveyForm";
import RespondersInSurveyInstance from "./RespondersInSurveyInstance.vue";
import RespondersAddToSurveyInstance from "./RespondersAddToSurveyInstance.vue";

export default {
  components: {
    // SurveyForm
  },
  props: {
    showNewButton: {
      type: Boolean,
      default: true,
    },
  },
  name: "SurveysInstancesTable",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      dialogCreateNew: false,
      selected: [],

      // bus: new Vue(),
      headers: [
        {
          text: "Undersökning",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Starttid", value: "starts_at" },
        { text: "Sluttid", value: "ends_at" },
        { text: "Antal respondenter", value: "nbr_of_respondents" },
        { text: "Nuvarande antal svar", value: "nbr_of_answered" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      // surveys: [],
      triggSurveysInstanceTable: 0,
    };
  },
  async beforeMount() {
    // this.$store.dispatch("fetchSurveys");
    console.log("SurveysInstancesTable beforeMount");
    await this.$store.dispatch("fetchSurveysInstances");
    this.selected = [];
  },
  computed: {
    surveysInstances() {
      const surveyInstancesStart = this.$store.getters.getSurveysInstances.filter(
        (i) => i.nbr_of_respondents > 0
      );
      const surveysInstances = surveyInstancesStart.map((s) => ({
        name: s.name,
        starts_at: this.$moment(s.starts_at).format("YY-MM-DD HH.mm"),
        ends_at: this.$moment(s.ends_at).format("YY-MM-DD HH.mm"),
        nbr_of_respondents: s.nbr_of_respondents,
        nbr_of_answered: s.nbr_of_answered,
        id: s.id,
        questions: s.questions,
      }));
      return surveysInstances;
    },
    queryTopics() {
      return this.$store.state.queryTopics.map((qt) => ({
        value: qt.topic_id,
        text: qt.topic_name,
      }));
    },
  },
  methods: {
    clearOnEscape(event) {
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    async handleRowClick(item) {
      console.log(
        "SurveyInstancetable: You clicked " + item.name + " emit=> selectSurveyInstance",
        item
      );
      await this.$store.dispatch("setCurrentSurveysInstanceId", item.id);
      // this.$root.$emit("surveysInstanceTable-survey-clicked", item);
      this.$emit("selectSurveyInstance", item)
      this.selected = [];
      this.selected.push(item);
    },
    async editItem(item) {
      console.log("Emit show", item);
      const res = await this.$dialog.show(RespondersInSurveyInstance, {
        waitForResult: true,
        width: "90%",
        survey: item,
        $store: this.$store,
      }); // Show dialog
      this.loading = true;
      await this.$store.dispatch("fetchSurveysInstances");
      this.loading = false;
      console.log("Emit show", res);
    },
    async addItem(item) {
      const res = await this.$dialog.show(RespondersAddToSurveyInstance, {
        waitForResult: true,
        width: "90%",
        survey: item,
        $store: this.$store,
      }); // Show dialog
      console.log("Add item res", res);
      this.loading = true;
      await this.$store.dispatch("fetchSurveysInstances");
      this.loading = false;
    },
    deleteItem(item) {
      console.log("deleteItem", item);
      const surveyInstances = this.$store.getters.getSurveysInstances;
      this.editedIndex = surveyInstances.findIndex((s) => s.id == item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("deleteSurveyInstance", this.editedItem.id);
      const surveyInstances = this.$store.getters.getSurveysInstances;
      surveyInstances.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    CreateNewConfirm() {
      console.log(this.form);
      this.dialogCreateNew = false;
      this.form.id = -1;
      this.form.forms = [];
      this.$store.dispatch("saveSurvey", this.form);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.surveys[this.editedIndex], this.editedItem);
      } else {
        this.surveys.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.whitespace {
  white-space: pre;
  text-align: right;
  /* border: 3px solid green; */
}
</style>
