var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-10 px-10 py-10",
                      attrs: { "min-width": "744", "max-width": "744" }
                    },
                    [
                      _c("h2", [
                        _vm._v("Här kommer vi ha lite allmän information")
                      ]),
                      _c("br"),
                      _vm._v(
                        " T.ex. vem du är inloggad som och lite om ditt kontos rättigheter "
                      ),
                      _c("br"),
                      _c("p", { staticClass: "pt-4" }, [
                        _vm._v(
                          " Man kommer också få en snabb överblick över vilka undersöknignar som är pågående "
                        )
                      ]),
                      _c("p", { staticClass: "pt-4" }, [
                        _vm._v(
                          " Ev lite data över hur många Respondenter som finns i databasen etc. "
                        )
                      ]),
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.downloadPDFquestionsDocument }
                              },
                              [_vm._v(" Hämta alla nuvarande frågor ")]
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "mx-10 px-10 mb-10",
              attrs: { cols: "8", "max-width": "744" }
            },
            [
              _c("v-card-title", { staticClass: "mt-10" }, [
                _vm._v(" Översikt av begrepp ")
              ]),
              _vm._l(_vm.concepts, function(concept, index) {
                return _c(
                  "v-card",
                  {
                    key: index,
                    staticClass: "mx-auto mb-10",
                    attrs: { "max-width": "644" }
                  },
                  [
                    _c("v-card-title", [
                      _vm._v(" " + _vm._s(concept.title) + " ")
                    ]),
                    _c("v-card-subtitle", [
                      _vm._v(" " + _vm._s(concept.explanation) + " ")
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-btn",
                          { attrs: { color: "orange lighten-2", text: "" } },
                          [_vm._v(" Mer info... ")]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                concept.show = !concept.show
                              }
                            }
                          },
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  concept.show
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-expand-transition", [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: concept.show,
                              expression: "concept.show"
                            }
                          ]
                        },
                        [
                          _c("v-divider"),
                          _c("v-card-text", [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(concept.text) }
                            })
                          ])
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }