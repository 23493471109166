var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.updateValueAtEvent != _vm.update
    ? _c(
        "v-app",
        [
          _c(
            "v-card",
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-navigation-drawer",
                    {
                      attrs: {
                        color: _vm.color,
                        dark: "",
                        permanent: "",
                        rail: "",
                        width: _vm.$store.state.withOfDrawer,
                        height: _vm.regAreaHight,
                        redraw: _vm.update
                      }
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.regItems, function(item, i) {
                          return _c(
                            "v-list-item",
                            { key: i, attrs: { to: item.route, router: "" } },
                            [
                              _c("v-list-item-action", [
                                _vm.nrOfRegRequests > 0 &&
                                item.icon == "mdi-account-check"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-badge",
                                          {
                                            attrs: {
                                              color: "red",
                                              content: _vm.nrOfRegRequests
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(item.icon))
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(item.icon))
                                        ])
                                      ],
                                      1
                                    )
                              ]),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(item.title)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _vm.$store.state.withOfDrawer === null
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { "withjustify-end": "" },
                                  on: { click: _vm.ToggleDrawerWith }
                                },
                                [_vm._v("keyboard_double_arrow_left")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  attrs: { "withjustify-end": "" },
                                  on: { click: _vm.ToggleDrawerWith }
                                },
                                [_vm._v("keyboard_double_arrow_right")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    {
                      staticClass: " ma-0",
                      staticStyle: { "max-width": "100vw" },
                      attrs: { id: "regArea", fluid: "" }
                    },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }