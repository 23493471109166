var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.hide
      ? _c(
          "div",
          [
            _c("v-icon", { on: { click: _vm.toggleDataTable } }, [
              _vm._v("mdi-arrow-down-drop-circle-outline")
            ]),
            _c("v-data-table", {
              key: _vm.rerender,
              staticClass: "elevation-1 whitespace",
              attrs: {
                headers: _vm.headers,
                items: _vm.questions,
                "sort-by": "name",
                search: _vm.search,
                dense: "",
                "single-select": true,
                loading: !_vm.loadingStatus,
                "loading-text": "Laddar...",
                "items-per-page": _vm.itemsPerPage,
                page: _vm.page,
                "footer-props": {
                  showFirstLastPage: true,
                  "items-per-page-text": "Rader per sida",
                  "items-per-page-options": [5, 10, 15, -1],
                  "items-per-page-all-text": "Alla"
                }
              },
              on: {
                "click:row": _vm.handleRowClick,
                "update:options": function($event) {
                  return _vm.handlerItemsPerPage($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.locked || !_vm.isOwner(item)
                          ? _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-pencil-lock-outline")
                            ])
                          : _vm._e(),
                        !item.locked
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _vm.isOwner(item)
                                            ? _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editItem(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" mdi-pencil ")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-center d-flex align-center justify-space-around"
                                  },
                                  [_vm._v("ändra frågan")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      !item.locked && _vm.isOwner(item)
                                        ? _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteItem(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-delete ")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-center d-flex align-center justify-space-around"
                              },
                              [_vm._v("ta bort frågan")]
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.answers",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(" " + _vm._s(_vm.nrOfAnswers(item)) + " ")]
                    }
                  },
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-toolbar",
                          {
                            staticStyle: { display: "none" },
                            attrs: { flat: "" }
                          },
                          [
                            _c("v-toolbar-title"),
                            _c(
                              "v-dialog",
                              {
                                attrs: { "max-width": "600px" },
                                model: {
                                  value: _vm.dialogDelete,
                                  callback: function($$v) {
                                    _vm.dialogDelete = $$v
                                  },
                                  expression: "dialogDelete"
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "text-h5" },
                                      [
                                        _vm._v(
                                          "Vill du verkligen ta bort denna fråga?"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "blue darken-1",
                                              text: ""
                                            },
                                            on: { click: _vm.closeDelete }
                                          },
                                          [_vm._v("Avbryt")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "blue darken-1",
                                              text: ""
                                            },
                                            on: { click: _vm.deleteItemConfirm }
                                          },
                                          [_vm._v("OK")]
                                        ),
                                        _c("v-spacer")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.i18n.sv.media.image",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("v-hover", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var hover = ref.hover
                                  return hover
                                    ? _c("v-img", {
                                        attrs: {
                                          src: item.i18n.sv.media.image,
                                          width: "300px",
                                          height: "auto"
                                        }
                                      })
                                    : _c("v-img", {
                                        attrs: {
                                          src: item.i18n.sv.media.image,
                                          width: "20px",
                                          height: "auto"
                                        }
                                      })
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    }
                  },
                  {
                    key: "item.i18n.sv.media.audio",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.isAudioPresent(item)
                          ? _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.playSound(
                                      item.i18n.sv.media.audio.id
                                    )
                                  }
                                }
                              },
                              [_vm._v(" mdi-play-box-outline ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "footer.prepend",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mb-2",
                            attrs: { dark: "", color: "primary" },
                            on: { click: _vm.createNewQuestion }
                          },
                          [
                            _c("v-icon", { attrs: { left: "", dark: "" } }, [
                              _vm._v(" mdi-plus-thick ")
                            ]),
                            _vm._v(" Ny fråga... ")
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          staticClass: "mb-5",
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Sök fråga",
                            "single-line": "",
                            "hide-details": ""
                          },
                          on: { keyup: _vm.clearOnEscape },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        }),
                        _c("v-spacer")
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1843229166
              ),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("v-icon", { on: { click: _vm.toggleDataTable } }, [
              _vm._v("mdi-arrow-right-drop-circle-outline")
            ]),
            _vm._v(" expandera listan med frågor... ")
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }