<template>
  <div>
    <div class="my-4">
      <h3>Respondenter</h3>
    </div>
    <template>
      <!-- show-select -->
      <div v-if="triggResponderTable > 0">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="responderTable()"
          :single-select="singleSelect"
          item-key="id"
          :triggResponderTable="triggResponderTable"
          :search="search"
          class="elevation-1"
          @click:row="rowClicked"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': 'Rader per sida',
            'items-per-page-all-text': 'Alla',
          }"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click.stop="updateJwt(item)"
                >
                  mdi-update
                </v-icon>
              </template>
              <span style="background-color: red; color: white"
                >Uppdatera id-nyckeln</span
              >
            </v-tooltip>
          </template>
          <template v-slot:item.jwt="{ item }">
            <!-- <v-icon small @click.stop="copyJwt(item)"> mdi-content-copy </v-icon> -->
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyJwt(item)"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Kopiera långa nyckeln</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click.stop="copyHash(item)"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Kopiera korta nyckeln</span>
            </v-tooltip>
          </template>

          <template v-slot:footer.prepend>
            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Spara som urvalsgrupp
        </v-btn> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Sök"
              single-line
              hide-details
              class="mb-5"
              v-on:keyup="clearOnEscape"
            ></v-text-field>
            <v-spacer></v-spacer>
          </template>
        </v-data-table>
      </div>
      <v-btn color="primary" dark @click="addNewResponder" class="mt-4">
        lägg till Respondent
      </v-btn>
      <v-dialog v-model="dialogHandleResponder" max-width="80%" persistent>
        <ResonderForm
          :responder="currentResponder"
          @form-ready="closeResponderForm"
        />

        <!-- <v-card>
          <v-card-title class="text-h5">
            Hantera {{ currentResponder.first_name }}
            {{ currentResponder.last_name }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentResponder.first_name"
                    label="Förnamn"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentResponder.last_name"
                    label="Efternamn"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentResponder.year_of_birth"
                    label="Födelseår"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="currentResponder.email"
                    label="E-post"
                    required
                  ></v-text-field>
                </v-col>
                 <v-col cols="2">
                  <v-select
                    v-model="currentResponder.terminal_id"
                    :items="terminals"
                    label="Terminal"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div :updatekey="updatekey">
                    <v-data-table
                      :headers="caregiver_categoryHeaders"
                      :items="currentResponder.category"
                      item-key="category"
                      class="elevation-1"
                      disable-pagination
                      :updatekey="updatekey"
                      :hide-default-footer="true"
                      :footer-props="{
                        'disable-pagination': true,
                      }"
                    >
                      
                    </v-data-table>
                  </div>
                </v-col>
               
              </v-row>

              <v-row>
                <v-btn
                  color="primary"
                  dark
                  @click="addMoreCategoryInfo"
                  class="mt-4"
                >
                  lägg till info
                </v-btn>
               
              </v-row>
              <v-row> </v-row>
            </v-container>
          </v-card-text>
          <v-divider class="my-6"></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeResponderForm">
              Avbryt
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="closeAndSaveRespondersForm"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card> -->
      </v-dialog>
    </template>
  </div>
</template>

<script>
// import Confirmation from "./components/Confirmation";
// import Notification from "./components/Notification";
// import "material-design-icons-iconfont/dist/material-design-icons.css";
import category from "@/components/CategoryAndValue";
import newResponder from "@/components/NewResponder";
import ResonderForm from "@/components/ResponderForm";

export default {
  name: "Responders",
  components: {
    ResonderForm,
    // hello
    // Notification
    //Confirmation
  },
  // props: ["formId"],
  data() {
    return {
      dialog: false,
      dialogHandleResponder: false,
      currentResponder: {},
      currentResponderOrig: {},
      dialogHandleCategory: false,
      setCategoryName: "",
      setCategoryValue: "",
      updatekey: 1,
      triggResponderTable: 1,
      selected: [],
      singleSelect: false,
      hide: false,
      search: "",
      no_terminal_choise: "- saknar terminal -",
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Namn", value: "name", sortable: true },
        { text: "Födelseår", value: "born" },
        { text: "E-post", value: "email" },
        { text: "Kommentar", value: "comment" },
        { text: "Identifikation", value: "jwt" },
        { text: "TerminalID", value: "terminal_id" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      // caregiver_categoryHeaders: [
      //   { text: "Kategori", align: "start", value: "category" },
      //   { text: "Värde", value: "value" },
      // ],
      terminals: [],
    };
  },
  async beforeMount() {
    this.currentResponder.category = [];
    await this.$store.dispatch("fetchResponders");
    this.$root.$off("Responderstable-form-toggle-hide");
    this.$root.$on("Responderstable-form-toggle-hide", () => {
      console.log("Got hide toggle event");
      this.hide = !this.hide;
    });
    this.$log.info("Responder beforeMount");
    const terminalsToUse = this.$store.state.terminals.filter(
      (t) => t.in_use != true
    );
    // this.$store.state.terminals.map((t) => (
    this.terminals = terminalsToUse.map((t) => t.serial_number);
    this.terminals.unshift(this.no_terminal_choise);
  },
  computed: {},
  methods: {
    responderTable() {
      const responders = this.$store.state.responders.map((r) => ({
        id: r.id,
        name: r.first_name + " " + r.last_name,
        born: r.year_of_birth,
        email: r.email,
        jwt: r.jwt,
        hash: r.hash,
        terminal_id: r.terminal_id,
        comment: r.comment,
        notify_by_terminal: r.notify_by_terminal,
        notify_by_email: r.notify_by_email,
        // pointsOfView: r

        // info: JSON.stringify(r.caregiver_info),
        // info: this.buildStringFromJson(r.caregiver_info.category),

        info: this.buildStringFromJson(r),
      }));
      console.log("Responders=", responders, this.currentResponder);
      return responders;
    },
    async copyJwt(item) {
      try {
        await navigator.clipboard.writeText(item.jwt);
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    async copyHash(item) {
      try {
        await navigator.clipboard.writeText(item.hash);
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    async updateJwt(item) {
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill uppdatera nyckeln? <p> Har ${item.name} (ID:${item.id}) en utestående nyckel någonstans kommer denna att sluta fungera!`,
        title: `Uppdatering av id-nyckel`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      if (res === "ok") {
        try {
          const updatedResponder = await this.$store.dispatch(
            "updateJWT",
            item.id
          );
          console.log("Update Responder", updatedResponder);
        } catch ($e) {
          alert("Huups! något gick fel. Meddela supporten (RL updateJWT)");
        }
      }
      this.responderTable();
      this.triggResponderTable++;
    },

    buildStringFromJson(o) {
      let res = "";
      if (this.$_.has(o, "caregiver_info.category")) {
        let first = true;
        o.caregiver_info.category.forEach((item) => {
          const category = Object.keys(item)[0];
          const value = this.$_.join(item[category], ",");
          res += first ? "" : ", ";
          first = false;
          res += `${category} (${value})`;
        });
      }
      // console.log("buildStringFromJson", o, res);
      return res;
    },
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    rowClicked(row) {
      this.currentResponder = this.$store.state.responders.find(
        (r) => r.id === row.id
      );
      this.currentResponderOrig = JSON.parse(
        JSON.stringify(this.currentResponder)
      ); // Make copy
      // this.currentResponder.category = [];
      if (!this.$_.has(this.currentResponder, "terminal_id")) {
        this.currentResponder.terminal_id = "";
      }
      // if (this.$_.has(this.currentResponder, "caregiver_info.category")) {
      //   this.currentResponder.caregiver_info.category.forEach((item) => {
      //     const category = Object.keys(item)[0];
      //     const value = this.$_.join(item[category], ",");
      //     this.currentResponder.category.push({
      //       category: category,
      //       value: value,
      //     });
      //   });
      // } else {
      //   this.currentResponder.caregiver_info.category = [];
      // }
      // this.currentResponder.tableData = Object.keys(this.currentResponder.caregiver_info).map(key => ({ key, value: this.currentResponder.caregiver_info[key] }));
      console.log("Responders: rowCklicked ", this.currentResponder, row);
      // this.currentResponder.infoparsed = JSON.parse(row.caregiver_info)
      this.dialogHandleResponder = true;
    },
    async deleteItem(item) {
      let res = await this.$dialog.info({
        text: `Är du säker på att du vill ta bort respondenten?`,
        title: `Borttagning av - ${item.name} (ID:${item.id})`,
        actions: {
          cancel: {
            text: "Avbryt",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
          ok: {
            text: "OK",
            color: "blue darken-1",
            handle: () => {
              return undefined;
            },
          },
        },
      });
      console.log("deleteItem called", item, res);
      if (res === "ok") {
        this.$store.dispatch("deleteResponder", item);
      }
    },
    closeResponderForm() {
      console.log("closeResponderForm");
      this.dialogHandleResponder = false;
      this.currentResponder = JSON.parse(
        JSON.stringify(this.currentResponderOrig)
      );
      if (this.currentResponder.terminal_id == this.no_terminal_choise) {
        this.currentResponder.terminal_id = "";
      }
    },
    closeAndSaveRespondersForm() {
      this.dialogHandleResponder = false;
      if (!this.$_.isEqual(this.currentResponder, this.currentResponderOrig)) {
        console.log("closeAndSaveRespondersForm", this.currentResponder);
        if (this.currentResponder.terminal_id == this.no_terminal_choise) {
          this.currentResponder.terminal_id = "";
        }
        let responder = JSON.parse(JSON.stringify(this.currentResponder));
        this.$store.dispatch(
          "updateResponder",
          this.$_.omit(responder, ["category"])
        );
        this.$store.dispatch("getTerminals");
      }
      this.currentResponder = JSON.parse(JSON.stringify(this.currentResponder)); // Force update?
      this.triggResponderTable++;
    },
    async addMoreCategoryInfo() {
      //addHandleCategory() {
      console.log(
        "addMoreCategoryInfo clicked",
        this.currentResponder.caregiver_info.category
      );

      try {
        const res = await this.$dialog.show(category, { waitForResult: true }); // Show dialog
        if (res != undefined) {
          let obj = {};
          obj[res.category] = [];
          res.value.split(",").forEach((str) => {
            obj[res.category].push(str);
          });
          this.currentResponder.caregiver_info.category.push(obj);
          // this.currentResponder.category.push({category:});
          this.rowClicked({ id: this.currentResponder.id });
        }
        this.updatekey++;
        console.log("addMoreCategoryInfo", res, this.currentResponder.category);
        // this.currentResponder.category=[]
        // this.currentResponder.last_name += 's'
      } catch (err) {
        this.$dialog.notify.error("Den gubben gick inte " + err.message, {
          position: "top-left",
          timeout: 5000,
        });
      }
    },
    async addNewResponder() {
      console.log("addNewResponder");
      const res = await this.$dialog.show(newResponder, {
        waitForResult: true,
      }); // Show dialog
      console.log("addNewResponder", res, typeof res);
      if (typeof res === "object") {
        // Make a new responder
        try {
          const obj = this.$_.pick(res, [
            "first_name",
            "last_name",
            "year_of_birth",
            "email",
            "terminal_id",
            "comment",
            "notify_by_terminal",
            "notify_by_email"
          ]);
          obj.year_of_birth = this.$_.toNumber(obj.year_of_birth);
          this.$store.dispatch("addNewResponder", obj);
          console.log("addNewResponder dispatched", obj);
        } catch (err) {
          this.$dialog.notify.error("Något gick fel => " + err.message, {
            position: "top-left",
            timeout: 5000,
          });
        }
      }
    },
    closeHandleCategory() {
      this.dialogHandleCategory = false;
    },
    closeAndSaveHandleCategory() {
      this.currentResponder.category.push({
        category: this.setCategoryName,
        value: this.setCategoryValue,
      });
      this.dialogHandleCategory = false;
    },
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100vh;
  max-width: 6000px;
  min-width: 500px;
}
</style>
