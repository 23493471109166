var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questions.length > 1
    ? _c(
        "DialogCard",
        { attrs: { title: _vm.title, actions: _vm.actions } },
        [
          _c(
            "v-card",
            { attrs: { height: "600px" } },
            [
              _c("v-card-title", [_vm._v(" Välj fråga och svar nedan! ")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Filternamn:" },
                            model: {
                              value: _vm.filterName,
                              callback: function($$v) {
                                _vm.filterName = $$v
                              },
                              expression: "filterName"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-switch", {
                        attrs: { label: _vm.SwitchLabel },
                        model: {
                          value: _vm.isSwitchOn,
                          callback: function($$v) {
                            _vm.isSwitchOn = $$v
                          },
                          expression: "isSwitchOn"
                        }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.questions,
                                  label: "Villkorsfråga",
                                  required: ""
                                },
                                model: {
                                  value: _vm.questionChoosen,
                                  callback: function($$v) {
                                    _vm.questionChoosen = $$v
                                  },
                                  expression: "questionChoosen"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.answers,
                                  attach: "",
                                  chips: "",
                                  required: "",
                                  label:
                                    "Vilka svar skall krävas för att uppfylla filtret?",
                                  multiple: ""
                                },
                                model: {
                                  value: _vm.answersChoosen,
                                  callback: function($$v) {
                                    _vm.answersChoosen = $$v
                                  },
                                  expression: "answersChoosen"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }