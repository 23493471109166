<template>
  <div v-if="ready">
    <DialogCard title="" :actions="actions">
      <v-card-title class="my-1 py-1 black white--text"
        >Lägg till nya respondenter...
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="markRespondentDone"
          color="gray"
          value="true"
          dark
          class="align-center justify-center"
        >
          <template v-slot:label>
            <span class="align-center justify-center pt-3"
              >Lägg till som passivt formulär</span
            >
          </template>
        </v-checkbox>
      </v-card-title>
      <RespondersList
        :responders="items"
        :survey="survey"
        :key="key"
        ref="respondersList"
      >
      </RespondersList>
      <!-- <v-btn
          color="primary"
          class="ma-5"
          dark
          justify-center
          @click="AddUserToSurveyInstance"
        >
        Lägg till markerade repondenter
        </v-btn> -->
    </DialogCard>
  </div>
</template>

<script>
import hshApi from "../services/hshapi";
import RespondersList from "./RespondersList.vue";
// import $_ from "lodash";
// import moment from "moment";

export default {
  components: {
    RespondersList,
  },
  props: ["survey", "$store"],
  data() {
    return {
      message: true,
      origMessage: null,
      baseColor: "#ff101077",
      repeater: null,
      title: "Mottagare",
      rerender: 0,
      ready: false,
      editedItem: {},
      selected: [],
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Namn", value: "name", sortable: true },
        { text: "E-post", value: "email" },
        { text: "Terminal", value: "terminal_id" },
        { text: "Medd. visat", value: "presented_at" },
        { text: "Svar", value: "ack_answer" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      items: [],
      key: 0,
      markRespondentDone: false,
    };
  },
  methods: {
    AddUserToSurveyInstance() {
      const selectedUsers = this.$refs.respondersList.getSelectedArray();
      console.log("AddUserToSurveyInstance", selectedUsers);
      selectedUsers.forEach((u) => {
        const payload = this.markRespondentDone == "true" ? {mark_respondents_done: true}:{mark_respondents_done: false}
        console.log("AddUserToSurveyInstans",payload)
        hshApi.AddUserToSurveyInstans(
          this.survey.id,
          u.id,
          payload
        );
      });
      this.ready = false;
    },
  
  },
  computed: {
    actions() {
      return {
        // cancel: {
        //   text: "Avbryt",
        //   color: "blue darken-1",
        //   handle: () => {
        //     return undefined;
        //   },
        // },
        ok: {
          text: "Lägg till",
          color: "blue darken-1",
          handle: () => {
            return this.AddUserToSurveyInstance();
          },
        },
        cancel: {
          text: "Avbryt",
          color: "blue darken-1",
          handle: () => {
            return undefined;
          },
        },
      };
    },
  },
  async mounted() {
    const currSurveys = this.$store.getters.getSurveysInstances;
    const currSurvey = currSurveys.find((s) => s.id === this.survey.id);
    console.log(
      "RespondersAddToSurveyInstance",
      currSurvey,
      currSurveys,
      this.survey
    );
    const allrespondents = this.$store.getters.getReponders;
    const surveyInstanceRespondents = await hshApi.getSurveInstanceResponders(
      this.survey.id
    );
    console.log("sir", surveyInstanceRespondents);
    this.items = allrespondents.filter((r) => {
      const sir = surveyInstanceRespondents.find(
        (ri) => ri.respondent_id === r.id
      );
      if (sir !== undefined) return false; // All not in surveyInstanceRespondents
      return true;
    });
    console.log("mounted RespondersAddToSurveyInstance", this.items);
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
.dialogStyle {
  text-align: center;
}
</style>
